import React from 'react';
import { useQuery } from 'react-query';
import _ from 'lodash';
import { GENERAL } from 'modules/core/constants';
import { Card, Charts } from 'modules/core/components';

const EconomicPieChart = ({
  title = '',
  description = '',
  fetch,
  params = {},
  height = 200,
}) => {
  const { data } = useQuery([title, { params }], fetch);

  const formatData = (data) => {
    const dataWithoutZeros = (d) =>
      _.pickBy(d, ({ cantidad }) => _.identity(cantidad));
    return _.map(
      dataWithoutZeros(data),
      ({ label, cantidad, porcentaje }, key) => {
        return {
          id: label || key,
          label: label || key,
          value: cantidad,
          sliceLabel: GENERAL.roundPercentage(porcentaje, 2, true),
        };
      }
    );
  };

  return (
    <Card
      title={title}
      description={description}
      type="secondary"
      style={{ height }}
    >
      {data && <Charts.Pie data={formatData(data)} />}
    </Card>
  );
};

export default EconomicPieChart;
