import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { MANAGEMENT, PRODUCTIVA } from 'modules/core/constants';
import Dashboard from './ServicioDashboard/ServicioDashboard';
import Individual from './ServicioIndividual/ServicioIndividual';
import Colectivo from './ServicioColectivo/ServicioColectivo';
import TransferenciaEmbrionaria from './TransferenciaEmbrionaria/TransferenciaEmbrionaria';
import RecordHistory from '../RecordHistory/RecordHistory';

const BASE = `/${MANAGEMENT.NAME_SPACE}/${PRODUCTIVA.NAME_SPACE}/${PRODUCTIVA.SERVICIOS.NAME_SPACE}`;
const eventTypes = PRODUCTIVA.SERVICIOS.EVENT_TYPES.join('|');

const Routes = () => {
  return (
    <Switch>
      <Route exact path={BASE} component={Dashboard} />
      <Route
        exact
        path={`${BASE}/${PRODUCTIVA.SERVICIO}`}
        component={Individual}
      />
      <Route
        exact
        path={`${BASE}/${PRODUCTIVA.SERVICIOCOLECTIVO}`}
        component={Colectivo}
      />
      <Route
        exact
        path={`${BASE}/${PRODUCTIVA.TRANSFERENCIAEMBRIONARIA}`}
        component={TransferenciaEmbrionaria}
      />
      <Route
        exact
        path={`${BASE}/:eventType(${eventTypes})/${MANAGEMENT.HISTORIAL_NAME_SPACE}`}
        component={RecordHistory}
      />
      <Redirect to={BASE} />
    </Switch>
  );
};

export default Routes;
