import React from 'react';
import _ from 'lodash';
import { UNITS } from 'modules/core/constants';
import './UnitInfo.scss';

const UnitInfoValue = ({ title, value }) => (
  <div className="unit-info-value">
    <span>{title}</span>
    <span>{value}</span>
  </div>
);

export const UnitHeader = ({ unit, masters }) => {
  const formatUnitHeader = UNITS.formatUnit(unit, masters).header;

  return (
    <div className="unit-header">
      <div className="unit-header-avatar">
        <img src={formatUnitHeader.image} alt="Categoría de unidad" />
      </div>
      <div className="unit-header-info">
        <div className="unit-header-title">{formatUnitHeader.title}</div>
        <div className="unit-header-subtitle">{formatUnitHeader.subtitle}</div>
        <div className="unit-tags">
          <div className="unit-tag">{formatUnitHeader.pill1}</div>
          <div className="unit-tag">{formatUnitHeader.pill2}</div>
        </div>
      </div>
    </div>
  );
};

export const UnitInfo = ({ unit, masters }) => {
  const formatUnitInfo = UNITS.formatUnit(unit, masters).info;
  return (
    <div className="unit-info">
      {formatUnitInfo.map((col, cix) => (
        <div key={cix} className="unit-info-column">
          {col.map((row, rix) => (
            <div key={`${cix}${rix}`} className="unit-info-section">
              {row.map((el, ix) => (
                <UnitInfoValue key={ix} title={el.title} value={el.value} />
              ))}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export const UnitExtraInfo = ({ data = [], numRows = 3 }) => {
  const dataChunked = _.chunk(data, numRows);
  return (
    <div className="unit-info">
      {dataChunked.map((group, ix) => {
        return (
          <div key={ix} className="unit-info-column">
            <div className="unit-info-section">
              {group.map((el, ix) => (
                <UnitInfoValue
                  key={ix}
                  title={el?.label}
                  value={el?.value || '-'}
                />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};
