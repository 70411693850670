import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { withAPI } from 'modules/api';
import { Row, Col } from 'antd';
import { CaretDownOutlined, DeleteOutlined } from '@ant-design/icons';
import { usePaginationWithFilters } from 'modules/core/hooks';
import { DropdownMenu, DeleteModal } from 'modules/core/components';
import FilterSider from 'modules/core/components/Filter';
import {
  InsumosHeader,
  InsumoModal,
  InsumosModalDecrement,
} from 'modules/management/components';
import { InsumosCards } from '../components/';
import SemenList from './SemenList/SemenList';
import SemenForm from './SemenForm';
import { GENERAL, PRODUCTIVA, UNITS } from 'modules/core/constants';

const Semen = ({ api }) => {
  const inputType = PRODUCTIVA.SEMEN;
  const masterName = {
    CAPITALIZE: 'Semen',
    LOWER: 'semen',
  };
  const { pagination, filters, sorter, params } = usePaginationWithFilters();
  const [addInsumo, setAddInsumo] = useState(false);
  const [decrementSemen, setDecrementSemen] = useState(null);
  const [deleteSemen, setDeleteSemen] = useState(null);

  const { data: cards, refetch: rKpis } = useQuery(
    ['Insumos::fetchKPISemen', { inputType, params: filters.get() }],
    api.fetchKPIProductivaInsumo
  );

  const {
    data: semen,
    isLoading: fetchingSemen,
    refetch: rSemen,
  } = useQuery(['Insumos::fetchSemen', { inputType, params }], api.fetchInsumo);

  const { data: males, isLoading: isLoadingMales } = useQuery(
    [
      'Units::males',
      { params: { sexo: UNITS.MACHOS, bajas: true, padres: true } },
    ],
    api.fetchUnits,
    { enabled: addInsumo }
  );
  const malesOptions = GENERAL.toRPApodoOptions(males?.results);

  const { mutate: deleteInsumo, isLoading: isDeleting } = useMutation(
    api.deleteInsumo
  );

  const refetch = () => {
    rKpis();
    rSemen();
  };

  const handleDelete = ({ onSuccess, onError }) => {
    deleteInsumo({ id: deleteSemen, inputType }, { onSuccess, onError });
  };

  const renderActions = ({ id }) => (
    <DropdownMenu
      menu={[
        {
          title: 'Decrementar',
          icon: <CaretDownOutlined />,
          onClick: () => setDecrementSemen(id),
        },
        {
          title: 'Eliminar',
          icon: <DeleteOutlined />,
          onClick: () => setDeleteSemen(id),
        },
      ]}
    />
  );

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <InsumosHeader
          title={masterName.CAPITALIZE}
          onClick={() => setAddInsumo(true)}
        />
      </Col>
      <Col span={4}>
        <InsumosCards data={cards} />
      </Col>
      <Col span={20}>
        <Row gutter={[16, 16]}>
          <Col span={4}>
            <FilterSider onChange={filters.set} />
          </Col>
          <Col span={24}>
            <SemenList
              data={semen}
              actions={renderActions}
              loading={fetchingSemen}
              showTotal={true}
              pagination={{ ...pagination.get(), total: semen?.count }}
              onChange={(p, f, s) => {
                pagination.set(p);
                sorter.set(s);
              }}
            />
          </Col>
          <InsumoModal
            visible={addInsumo}
            inputType={inputType}
            masterName={masterName}
            onSuccess={refetch}
            onCancel={() => setAddInsumo(false)}
            renderForm={() => (
              <SemenForm
                males={{
                  options: malesOptions,
                  isLoading: isLoadingMales,
                }}
              />
            )}
          />
          <InsumosModalDecrement
            id={decrementSemen}
            inputType={inputType}
            visible={!!decrementSemen}
            onSuccess={() => {
              setDecrementSemen(null);
              refetch();
            }}
            onCancel={() => setDecrementSemen(null)}
          />
          <DeleteModal
            title={`Eliminar ${masterName.LOWER}`}
            bodyText={<span>este insumo</span>}
            isDeleting={isDeleting}
            visible={!!deleteSemen}
            onCancel={() => setDeleteSemen(null)}
            onConfirm={handleDelete}
            onSuccess={() => {
              setDeleteSemen(null);
              refetch();
            }}
            onSuccessInfo={{
              description: `${masterName.CAPITALIZE} eliminado con éxito.`,
              showCheck: true,
            }}
          />
        </Row>
      </Col>
    </Row>
  );
};

export default withAPI(Semen);
