import React from 'react';
import { useQuery } from 'react-query';
import { Card, Charts } from 'modules/core/components';

const EconomicLineChart = ({
  title = '',
  description = '',
  fetch,
  height = 200,
  currency = '',
}) => {
  const { data } = useQuery([title], fetch);

  return (
    <Card
      title={title}
      description={description}
      type="secondary"
      style={{ height }}
    >
      {data && (
        <Charts.Line
          data={data}
          colors={['#74c476', '#f47560']}
          axisLeft={{ legend: currency }}
        />
      )}
    </Card>
  );
};

export default EconomicLineChart;
