import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withSubMenuLayout } from 'modules/core/layouts';
import { MANAGEMENT } from 'modules/core/constants';
import managementLeftMenuOptions from './managementLeftMenuOptions';
import ProductivaRoutes from './productiva/routes';
import SanitariaRoutes from './sanitaria/routes';
import NutricionalRoutes from './nutricional/routes';
import EconomicaRoutes from './economica/routes';

const withSubLayout = withSubMenuLayout(managementLeftMenuOptions);
const Routes = () => {
  return (
    <Switch>
      <Route
        path={`/${MANAGEMENT.NAME_SPACE}/${MANAGEMENT.PRODUCTIVA}`}
        component={withSubLayout(ProductivaRoutes)}
      />
      <Route
        path={`/${MANAGEMENT.NAME_SPACE}/${MANAGEMENT.SANITARIA}`}
        component={withSubLayout(SanitariaRoutes)}
      />
      <Route
        path={`/${MANAGEMENT.NAME_SPACE}/${MANAGEMENT.NUTRICIONAL}`}
        component={withSubLayout(NutricionalRoutes)}
      />
      <Route
        path={`/${MANAGEMENT.NAME_SPACE}/${MANAGEMENT.ECONOMICA}`}
        component={withSubLayout(EconomicaRoutes)}
      />
      <Redirect to={`/${MANAGEMENT.NAME_SPACE}/${MANAGEMENT.PRODUCTIVA}`} />
    </Switch>
  );
};

export default Routes;
