import { jwtDecode } from 'jwt-decode';
import queryString from 'query-string';
import { GENERAL, USERS } from 'modules/core/constants';

const APIPrototype = {
  withConfig(config) {
    this.config = config;
    return this;
  },

  withAuthUser(authToken) {
    this.authToken = authToken;
    this.userId = authToken && jwtDecode(authToken).user_id;
    this.userRole =
      authToken &&
      USERS.setRole({
        roleId: jwtDecode(authToken).rol_id,
        isSuperuser: jwtDecode(authToken).is_superuser,
      });
    this.userSpecies = authToken && jwtDecode(authToken).tenant_type;

    return this;
  },

  getUserId() {
    return this.userId;
  },

  getSimpleHeaders() {
    return {
      'Content-Type': 'application/json',
    };
  },

  getAuthHeaders() {
    return {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.authToken}`,
    };
  },

  getAuthFormHeaders() {
    return {
      Authorization: `Bearer ${this.authToken}`,
    };
  },

  makeQueryString(params = {}, options = null) {
    const formatParams = GENERAL.formatRangeParams(params);
    return queryString.stringify(formatParams, options);
  },

  parseQueryString(qs, options = null) {
    return queryString.parse(qs, options);
  },

  handleResponse(response) {
    function handle(r) {
      console.debug('raw response', r);
      if (r && !r.ok) {
        return r.text().then((t) => {
          const error = new Error(r.status);
          const description =
            typeof t === 'string' ? t.replace(/^"(.+)"$/, '$1') : t;
          throw Object.assign(error, { description });
        });
      }
      if (r.status === 204) return r;
      return Promise.resolve(r.json());
    }
    const handleIfExpired = (r) => {
      if (r?.status === 401)
        r.json().then((data) => {
          if (data?.code === 'token_not_valid') {
            // TODO: Find a better way to logout.
            // Maybe triggering a change in API context?.
            APIPrototype.withAuthUser(null);
            GENERAL.LOCAL_TOKEN.remove();
            window.location.reload();
          }
        });
    };
    handleIfExpired(response.clone());
    return handle(response);
  },

  handleBlob(response) {
    if (!response?.ok) throw new Error(response.status);
    return response.blob();
  },

  handleBlobOnerror(r) {
    if (r && !r.ok) {
      return r.text().then((t) => {
        const error = new Error(r.status);
        const description =
          typeof t === 'string' ? t.replace(/^"(.+)"$/, '$1') : t;
        throw Object.assign(error, { description });
      });
    }
    const errorMsg = r.headers.get('error-message');
    if (!errorMsg) return Promise.resolve(r.json());
    return r.blob().then((file) => ({
      error: true,
      message: errorMsg,
      file,
    }));
  },

  invalidateWithError(errorMessage) {
    return new Promise((_, reject) => {
      setTimeout(function () {
        reject(new Error(errorMessage));
      }, 500);
    });
  },
};

export { APIPrototype };
