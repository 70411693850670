import React, { useEffect, useCallback } from 'react';
import _ from 'lodash';
import { useMutation } from 'react-query';
import {
  Row,
  Col,
  Form,
  Button,
  Input,
  message as antMessage,
  InputNumber,
  Select,
} from 'antd';
import { withAPI } from 'modules/api';
import { displayInfoModal } from 'modules/core/components';
import { GENERAL, UNITS } from 'modules/core/constants';
import './EstablishmentFormAdd.scss';

const { Item: FormItem } = Form;

const EstablishmentFormAdd = ({
  api,
  onCancel = null,
  onSuccess = null,
  editEst = null,
}) => {
  const [form] = Form.useForm();

  const { mutate: create, isLoading: creatingEst } = useMutation(
    api.createEstablishment
  );
  const { mutate: edit, isLoading: editingEst } = useMutation(
    api.editEstablishment
  );

  const clearForm = useCallback(() => {
    form.resetFields();
  }, [form]);
  useEffect(clearForm, [clearForm, editEst]);

  const cancel = () => {
    clearForm();
    onCancel && onCancel();
  };
  const success = () => {
    clearForm();
    onSuccess && onSuccess();
  };

  const onFinish = (values) => {
    const formattedEst = _.mapValues(values, (v) => v ?? null);
    const onError = ({ message, description }) => {
      if (message === '400') antMessage.error(description);
      else antMessage.error('ERROR');
    };
    const showDisplay = ({ isEdit = false }) => {
      success();
      displayInfoModal({
        description: `Se ${
          isEdit ? 'modificó' : 'agregó'
        } el establecimiento con éxito`,
        showCheck: true,
      });
    };
    if (editEst) {
      const onSuccess = () => showDisplay({ isEdit: true });
      edit({ estId: editEst.id, est: formattedEst }, { onSuccess, onError });
    } else {
      const onSuccess = () => showDisplay({ isEdit: false });
      create({ est: formattedEst }, { onSuccess, onError });
    }
  };

  return (
    <Form
      form={form}
      className="est-add-form"
      layout="vertical"
      requiredMark={false}
      wrapperCol={{ span: 24 }}
      onFinish={onFinish}
      initialValues={editEst}
    >
      <Row gutter={16}>
        <Col span="12">
          <FormItem
            label="Nombre del establecimiento"
            name="name"
            rules={
              editEst
                ? [
                    {
                      required: true,
                      message: 'Ingrese el nombre del establecimiento',
                    },
                  ]
                : [
                    {
                      required: true,
                      type: 'string',
                      pattern: /^[a-z0-9]+$/,
                      message:
                        'Ingrese el nombre del establecimiento (Sólo minúsculas y números).',
                    },
                  ]
            }
          >
            <Input className="est-add-form-input" />
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem
            label="Especie"
            name="type"
            rules={[{ required: true, message: 'Seleccione una especie' }]}
          >
            <Select
              style={{ width: '100%' }}
              disabled={editEst}
              options={GENERAL.arrayToOptions(
                UNITS.ESPECIES_LIST,
                (e) => UNITS.ESPECIES_FORMATTED[e]
              )}
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span="12">
          <FormItem
            label="Razón social"
            name="razon_social"
            rules={[{ required: false, message: 'Ingrese razón social' }]}
          >
            <Input className="est-add-form-input" />
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem
            label="CUIT"
            name="cuit"
            rules={[{ required: false, message: 'Ingrese CUIT' }]}
          >
            <Input className="est-add-form-input" />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span="12">
          <FormItem
            label="Dirección legal"
            name="direccion_legal"
            rules={[{ required: false, message: 'Ingrese dirección' }]}
          >
            <Input className="est-add-form-input" />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            label="Latitud"
            name="lat"
            rules={[{ required: false, message: 'Ingrese latitud' }]}
          >
            <InputNumber className="est-add-form-input" />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            label="Longitud"
            name="lon"
            rules={[{ required: false, message: 'Ingrese longitud' }]}
          >
            <InputNumber className="est-add-form-input" />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span="24">
          <FormItem
            label="RENSPA"
            name="renspa"
            rules={[{ required: false, message: 'Ingrese RENSPA' }]}
          >
            <Input className="est-add-form-input" />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span="12">
          <FormItem
            label="Dashboard Quicksight"
            name="dashboard_id"
            rules={[{ required: false, message: 'Ingrese ID del dashboard' }]}
          >
            <Input className="est-add-form-input" />
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem
            label="Usuario Quicksight"
            name="quicksight_user"
            rules={[
              { required: false, message: 'Ingrese usuario de Quicksight' },
            ]}
          >
            <Input className="est-add-form-input" />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16} className="est-add-form-footer">
        <Col span="12">
          <FormItem>
            <Button
              htmlType="button"
              className="est-add-form-button"
              onClick={cancel}
            >
              {editEst ? 'VOLVER' : 'CANCELAR'}
            </Button>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem>
            <Button
              type="primary"
              htmlType="submit"
              className="est-add-form-button"
              loading={creatingEst || editingEst}
            >
              {editEst ? 'MODIFICAR' : 'AGREGAR'}
            </Button>
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
};

export default withAPI(EstablishmentFormAdd);
