import { UNITS } from 'modules/core/constants';

const AnalyticsAPI = (API) => {
  const BASE_PATH = `/api/core/analytics`;

  // === Unidades ===
  // == Stock ==
  API.fetchUnitStockActivas = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`${BASE_PATH}/unidades/kpi-cantidad-unidades-activas/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchUnitStockByCategoria = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`${BASE_PATH}/unidades/kpi-unidades-por-categoria/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchUnitStockHembrasByEstado = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`${BASE_PATH}/unidades/kpi-hembras-por-estado/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchUnitStockBajas = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`${BASE_PATH}/bajas/kpi-cantidad-bajas/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchUnitStockBajasByMotivo = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`${BASE_PATH}/bajas/kpi-bajas-por-motivo/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchUnitStockEvolucion = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`${BASE_PATH}/unidades/kpi-evolucion-stock/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchUnitRanking = function ({ queryKey }) {
    const [, { unitType, params }] = queryKey;
    const qp = API.makeQueryString(params);
    const typeURL = unitType === UNITS.HEMBRAS ? 'top-hembras' : 'top-machos';
    return fetch(`${BASE_PATH}/unidades/${typeURL}/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.exportUnitRanking = function ({ unitType, params }) {
    const qp = API.makeQueryString(params);
    const typeURL = unitType === UNITS.HEMBRAS ? 'top-hembras' : 'top-machos';
    return fetch(`${BASE_PATH}/unidades/${typeURL}/excel-download/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleBlob);
  };

  API.fetchUnitConsolidado = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`${BASE_PATH}/unidades/eventos-consolidado/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fieldsExportUnitConsolidado = function () {
    return fetch(`${BASE_PATH}/unidades/eventos-consolidado/export-fields/`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.exportUnitConsolidado = function ({ fields, params = null }) {
    const qp = API.makeQueryString(params);
    return fetch(
      `${BASE_PATH}/unidades/eventos-consolidado/excel-download/?${qp}`,
      {
        method: 'POST',
        headers: API.getAuthHeaders(),
        body: JSON.stringify({ fields }),
      }
    ).then(API.handleBlob);
  };

  // === Productiva ===
  // == Destete ==
  API.fetchDestetePesoPromedio = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`${BASE_PATH}/destetes/kpi-peso-promedio-al-destete/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchDesteteGananciaDiaria = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(
      `${BASE_PATH}/destetes/kpi-ganancia-diaria-al-destete/?${qp}`,
      {
        method: 'GET',
        headers: API.getAuthHeaders(),
      }
    ).then(API.handleResponse);
  };

  API.fetchDesteteByServicios = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`${BASE_PATH}/destetes/kpi-destetes-sobre-servicios/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchDesteteByPreneces = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`${BASE_PATH}/destetes/kpi-destetes-sobre-preneces/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchDesteteByPartos = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`${BASE_PATH}/destetes/kpi-destetes-sobre-partos/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchDesteteEvolucion = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`${BASE_PATH}/destetes/kpi-evolucion-destetes/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchDesteteByPeso = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`${BASE_PATH}/destetes/kpi-pesos-al-destete/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  // ==  Pre-servicio / Servicio ==
  API.fetchPreServiciosByResultados = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(
      `${BASE_PATH}/servicios/kpi-pre-servicios-por-resultado/?${qp}`,
      {
        method: 'GET',
        headers: API.getAuthHeaders(),
      }
    ).then(API.handleResponse);
  };

  API.fetchServiciosByCategoria = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`${BASE_PATH}/servicios/kpi-servicios-por-categoria/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchServiciosByTipo = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`${BASE_PATH}/servicios/kpi-servicios-por-tipo/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchServiciosIndividualesByMacho = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(
      `${BASE_PATH}/servicios/kpi-servicios-individuales-por-macho/?${qp}`,
      {
        method: 'GET',
        headers: API.getAuthHeaders(),
      }
    ).then(API.handleResponse);
  };

  API.fetchServiciosEvolucion = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`${BASE_PATH}/servicios/kpi-evolucion-de-servicios/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  // ==  Preñez ==
  API.fetchPreniezPorcentaje = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`${BASE_PATH}/preneces/kpi-porcentaje-de-prenez/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchPreniezTasa = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`${BASE_PATH}/preneces/kpi-tasa-de-prenez/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchPreniezByResultado = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`${BASE_PATH}/preneces/kpi-preneces-por-resultado/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchPreniezEvolucion = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`${BASE_PATH}/preneces/kpi-evolucion-preneces/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  // ==  Parto ==
  API.fetchPartoAbortos = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`${BASE_PATH}/partos/kpi-porcentaje-de-abortos/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchPartoIEP = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`${BASE_PATH}/partos/kpi-promedio-de-iep/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchPartoPesoNac = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(
      `${BASE_PATH}/partos/kpi-pesos-promedio-al-nacimiento/?${qp}`,
      {
        method: 'GET',
        headers: API.getAuthHeaders(),
      }
    ).then(API.handleResponse);
  };

  API.fetchPartoByTipo = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`${BASE_PATH}/partos/kpi-partos-por-tipo/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchPartoEvolucion = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`${BASE_PATH}/partos/kpi-evolucion-partos/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  // ==  Pesaje ==
  API.fetchPesajeGanaciaDiariaVariable = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(
      `${BASE_PATH}/pesajes/kpi-promedio-de-ganancia-diaria-variable/?${qp}`,
      {
        method: 'GET',
        headers: API.getAuthHeaders(),
      }
    ).then(API.handleResponse);
  };

  API.fetchPesajeGanaciaDiariaTotal = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(
      `${BASE_PATH}/pesajes/kpi-promedio-de-ganancia-diaria-total/?${qp}`,
      {
        method: 'GET',
        headers: API.getAuthHeaders(),
      }
    ).then(API.handleResponse);
  };

  API.fetchPesajeGanaciaDiariaTotalByPadre = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(
      `${BASE_PATH}/pesajes/kpi-ganancia-diaria-total-por-padre/?${qp}`,
      {
        method: 'GET',
        headers: API.getAuthHeaders(),
      }
    ).then(API.handleResponse);
  };

  API.fetchPesajeEvolucion = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`${BASE_PATH}/pesajes/kpi-evolucion-pesos/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchPesajeIndividualEvolucion = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`${BASE_PATH}/pesajes/kpi-evolucion-pesaje-total/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchPesajeGananciaDiariaEvolucion = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(
      `${BASE_PATH}/pesajes/kpi-evolucion-ganancia-diaria-total/?${qp}`,
      {
        method: 'GET',
        headers: API.getAuthHeaders(),
      }
    ).then(API.handleResponse);
  };

  // ==  Rendimiento ==
  API.fetchResumenProductivoKPI = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`${BASE_PATH}/establecimiento/kpi-resumen-productivo/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchMermaProduccionKPI = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(
      `${BASE_PATH}/establecimiento/kpi-mermas-de-produccion/?${qp}`,
      {
        method: 'GET',
        headers: API.getAuthHeaders(),
      }
    ).then(API.handleResponse);
  };

  API.fetchResumenStockKPI = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`${BASE_PATH}/establecimiento/kpi-resumen-de-stock/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchProduccionKgKPI = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(
      `${BASE_PATH}/establecimiento/kpi-produccion-de-kilogramos/?${qp}`,
      {
        method: 'GET',
        headers: API.getAuthHeaders(),
      }
    ).then(API.handleResponse);
  };

  API.fetchCategoriasByUbicacionRodeo = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`${BASE_PATH}/unidades/kpi-unidades-por-ubicacion/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  return API;
};

export default AnalyticsAPI;
