import React from 'react';
import { Input, Select, InputNumber } from 'antd';
import { MASTERS } from 'modules/core/constants';

const DatoAdicionalInput = ({
  datoAdicionalType = null,
  disabled = false,
  selectOptions = {},
  ...restProps
}) => {
  if (MASTERS.DATOS_ADICIONALES_COMPONENT.SELECT.includes(datoAdicionalType)) {
    const allOptions = {
      ...selectOptions,
      ...MASTERS.DATOS_ADICIONALES_STATIC_OPTIONS,
    };
    return (
      <Select
        {...restProps}
        key={datoAdicionalType}
        placeholder="Seleccionar valor"
        style={{ width: '100%' }}
        disabled={disabled}
        options={allOptions[datoAdicionalType]}
      />
    );
  }

  if (MASTERS.DATOS_ADICIONALES_COMPONENT.INPUT.includes(datoAdicionalType)) {
    return (
      <Input
        {...restProps}
        style={{ width: '100%' }}
        placeholder="Ingrese valor"
        disabled={disabled}
      />
    );
  }

  return (
    <InputNumber
      {...restProps}
      min={0}
      style={{ width: '100%' }}
      placeholder="Ingresar valor"
      disabled={disabled}
    />
  );
};

export default DatoAdicionalInput;
