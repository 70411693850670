import { useState, useCallback } from 'react';

const SORTER_ORDER = {
  ascend: 'asc',
  descend: 'desc',
};
const usePaginationWithFilters = ({ pageSize = 50 } = {}) => {
  const [pag, setPag] = useState({ current: 1, pageSize });
  const [sorter, setSort] = useState(null);
  const [fil, setFil] = useState(null);
  const setFilters = useCallback((fil) => {
    setPag((p) => ({ ...p, current: 1 }));
    setFil(fil);
  }, []);

  const setSorter = useCallback((s) => {
    if (s?.order) {
      setSort(`${SORTER_ORDER[s.order]} ${s.columnKey || s.field}`);
    } else {
      setSort(null);
    }
  }, []);

  return {
    pagination: { get: () => pag, set: setPag },
    filters: { get: () => fil, set: setFilters },
    sorter: { get: () => sorter, set: setSorter },
    params: {
      ...fil,
      page: pag.current,
      page_size: pag.pageSize,
      ...(sorter && { order_by: sorter }),
    },
  };
};

export default usePaginationWithFilters;
