import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from 'antd';
import { ScreenHeader } from 'modules/core/components';
import { MANAGEMENT } from 'modules/core/constants';
import {
  EconomicalEventsMenu,
  NutricionalEventsMenu,
  ProductivaEventsMenu,
  SanitariaEventsMenu,
} from '../EventsMenu';

const EventsHeader = ({
  title = '',
  description = '',
  continueProps = { onClick: null, disabled: true },
  registerProps = { onClick: null, loading: false },
  eventMenuProps = {},
  rollbackAction = null,
  showRegister = false,
}) => {
  let history = useHistory();
  let location = useLocation();

  const renderEventsMenu = () => {
    switch (eventMenuProps.menuType) {
      case MANAGEMENT.PRODUCTIVA:
        return <ProductivaEventsMenu {...eventMenuProps} />;

      case MANAGEMENT.SANITARIA:
        return <SanitariaEventsMenu {...eventMenuProps} />;

      case MANAGEMENT.NUTRICIONAL:
        return <NutricionalEventsMenu {...eventMenuProps} />;

      case MANAGEMENT.ECONOMICA:
        return <EconomicalEventsMenu {...eventMenuProps} />;

      default:
        return null;
    }
  };

  return (
    <ScreenHeader
      title={title}
      description={description}
      rightActions={
        <>
          <Button type="primary" {...continueProps}>
            CONTINUAR
          </Button>
          <Button
            onClick={() =>
              history.push(
                `${location.pathname}/${MANAGEMENT.HISTORIAL_NAME_SPACE}`
              )
            }
          >
            HISTORIAL
          </Button>
          {renderEventsMenu()}
        </>
      }
      {...(showRegister && {
        onRollback: rollbackAction,
        rightActions: (
          <Button type="primary" {...registerProps}>
            REGISTRAR
          </Button>
        ),
      })}
    />
  );
};

export default EventsHeader;
