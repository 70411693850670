import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { Row, Col, Button } from 'antd';
import { EyeOutlined, ReloadOutlined, DeleteOutlined } from '@ant-design/icons';
import { withAPI } from 'modules/api';
import { usePaginationWithFilters } from 'modules/core/hooks';
import {
  DeleteModal,
  DropdownMenu,
  ScreenHeader,
} from 'modules/core/components';
import { AdminCards, FincaClients, FincaClientResetModal } from './components';
import FilterSider from 'modules/core/components/Filter';
import { EstablishmentDrawerAdd } from 'modules/admin/components';

const AdminHome = ({ api }) => {
  const history = useHistory();
  const { pagination, filters, params } = usePaginationWithFilters();
  const [addEst, setAddEst] = useState(false);
  const [resetEst, setResetEst] = useState(null);
  const [deleteEst, setDeleteEst] = useState(null);

  const { data: kpis, refetch: rKpis } = useQuery(
    ['Admin::fetchKPIAdmin'],
    api.fetchKPIAdmin
  );
  const {
    data: ests,
    isLoading: fetchingEsts,
    refetch: rEsts,
  } = useQuery(
    ['Admin::fetchEstablishments', { params }],
    api.fetchEstablishments
  );
  const { mutate: deleteEstablishment, isLoading: isDeleting } = useMutation(
    api.deleteEstablishment
  );

  const handleDelete = ({ onSuccess, onError }) => {
    deleteEstablishment({ estId: deleteEst.id }, { onSuccess, onError });
  };

  const refetch = () => {
    rKpis();
    rEsts();
  };

  const renderActions = (establishment) => (
    <DropdownMenu
      menu={[
        {
          title: 'Detalle',
          icon: <EyeOutlined />,
          onClick: () =>
            history.push(`/admin/establecimiento/${establishment.id}`),
        },
        {
          title: 'Restablecer datos',
          icon: <ReloadOutlined />,
          onClick: () => setResetEst(establishment),
        },
        {
          title: 'Eliminar',
          icon: <DeleteOutlined />,
          onClick: () => setDeleteEst(establishment),
        },
      ]}
    />
  );

  return (
    <>
      <Row style={{ padding: '3%' }} gutter={[0, 24]}>
        <Col span={24}>
          <ScreenHeader
            title="Administración"
            rightActions={
              <Button type="primary" onClick={() => setAddEst(true)}>
                AGREGAR ESTABLECIMIENTO
              </Button>
            }
          />
        </Col>
        <Col span={4}>
          <AdminCards data={kpis?.kpis} />
        </Col>
        <Col span={20}>
          <Row gutter={[16, 16]}>
            <Col span={4}>
              <FilterSider onChange={filters.set} />
            </Col>
            <Col span={24}>
              <FincaClients
                data={ests}
                actions={renderActions}
                loading={fetchingEsts}
                showTotal={true}
                pagination={{ ...pagination.get(), total: ests?.count }}
                onChange={pagination.set}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <EstablishmentDrawerAdd
        open={addEst}
        success={() => {
          setAddEst(false);
          refetch();
        }}
        close={() => setAddEst(false)}
      />
      {resetEst && (
        <FincaClientResetModal
          est={resetEst}
          visible={!!resetEst}
          success={() => {
            setResetEst(null);
          }}
          close={() => setResetEst(null)}
        />
      )}
      {deleteEst && (
        <DeleteModal
          title="Eliminar establecimiento"
          bodyText={<b>{deleteEst?.name}</b>}
          isDeleting={isDeleting}
          visible={!!deleteEst}
          onCancel={() => setDeleteEst(null)}
          onConfirm={handleDelete}
          onSuccess={() => {
            setDeleteEst(null);
            refetch();
          }}
          onSuccessInfo={{
            description: `El establecimiento ${deleteEst?.name} se eliminó con éxito.`,
            showCheck: true,
          }}
        >
          <div>
            Estás eliminando el establecimiento <b>{deleteEst?.name}</b>
            <br />
            ¿Confirmás que ya no formará parte de Finca?
          </div>
        </DeleteModal>
      )}
    </>
  );
};

export default withAPI(AdminHome);
