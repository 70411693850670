import React from 'react';
import { withAPI } from 'modules/api';
import { Row, Col } from 'antd';
import { ManagementKPIs, ManagementListCards } from './components';
import { PRODUCTIVA, SANITARIA } from 'modules/core/constants';

const HEIGHT_ROW_1 = 550;
const HEIGHT_ROW_2 = 460;

const HomeDashProductivo = ({ api }) => {
  return (
    <>
      <Row gutter={[24, 32]} className="data-row">
        <Col span={24}>
          <ManagementListCards.UnitsCategory />
        </Col>
      </Row>
      <Row gutter={[24, 32]} className="data-row">
        <Col xs={24} lg={12}>
          <ManagementListCards.Events
            height={HEIGHT_ROW_1}
            title="Índice de productividad"
            titleFormatter={PRODUCTIVA.EVENT_FORMATTED}
            description="Conoce como está tu establecimiento en el año actual."
            fetch={api.fetchKPIProductivityList}
          />
        </Col>
        <Col xs={24} lg={12}>
          <ManagementListCards.Events
            height={HEIGHT_ROW_1}
            title="Índice de sanidad"
            titleFormatter={SANITARIA.EVENT_FORMATTED}
            description="Conoce como está tu establecimiento en el año actual."
            fetch={api.fetchKPISanityList}
          />
        </Col>
      </Row>
      <Row gutter={[24, 32]} className="data-row">
        <Col xs={24} lg={12}>
          <ManagementListCards.UnitsStates height={HEIGHT_ROW_2} />
        </Col>
        <Col xs={24} lg={12}>
          <ManagementListCards.UnitsBajasByMotivo height={HEIGHT_ROW_2} />
        </Col>
      </Row>
      <Row gutter={[24, 32]} justify="center">
        <ManagementKPIs />
      </Row>
    </>
  );
};

export default withAPI(HomeDashProductivo);
