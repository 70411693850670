import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import _ from 'lodash';
import {
  Row,
  Col,
  Form,
  DatePicker,
  Select,
  Input,
  message as antMessage,
} from 'antd';
import { withAPI } from 'modules/api';
import { usePaginationWithFilters } from 'modules/core/hooks';
import {
  ECONOMICA,
  GENERAL,
  MANAGEMENT,
  MASTERS,
  UNITS,
} from 'modules/core/constants';
import { displayInfoModal } from 'modules/core/components';
import FilterSider, { UnitFilter } from 'modules/core/components/Filter';
import {
  EventsHeader,
  EventsTableActions,
} from 'modules/management/components';
import {
  UnitDrawerRecordHistory,
  UnitDrawerShow,
} from 'modules/units/components';
import CompraVentaUnidadTable from './CompraVentaUnidadTable/CompraVentaUnidadTable';

const { Item: FormItem } = Form;
const EVENT_ACTIONS_INITIAL_VALUES = { item: null, action: null };
const PESO_FORM_ITEM = {
  [ECONOMICA.EGRESO_COMPRA_UNIDAD]: {
    field: 'peso_inicial',
    label: 'Peso inicial',
  },
  [ECONOMICA.INGRESOS_VENTA_UNIDAD]: {
    field: 'peso_final',
    label: 'Peso final',
  },
};
const CRIADOR_LABEL = {
  [ECONOMICA.EGRESO_COMPRA_UNIDAD]: 'Vendedor',
  [ECONOMICA.INGRESOS_VENTA_UNIDAD]: 'Comprador',
};

const CompraVentaUnidad = ({ api, eventType }) => {
  const eventTypeTitle = ECONOMICA.EVENT_FORMATTED[eventType];
  let history = useHistory();
  let location = useLocation();
  const { pagination, filters, sorter, params } = usePaginationWithFilters();
  const [actionInProgress, setActionInProgress] = useState(false);
  const [selectedItem, setSelectedItem] = useState(
    EVENT_ACTIONS_INITIAL_VALUES
  );
  const [selectedRows, setSelectedRows] = useState({ keys: [], rows: [] });
  const [form] = Form.useForm();

  const { data, isLoading, refetch } = useQuery(
    ['CompraVentaUnidad::fetchByEvent', { eventType, params }],
    api.fetchByEvent
  );

  const { data: options, isLoading: isLoadingOptions } = useQuery(
    [
      'MAESTROS::fetchMastersAsFormOptions',
      {
        masterTypes: [MASTERS.PRODUCTIVA_TYPES.CRIADOR],
      },
    ],
    api.fetchMastersFormOptions
  );

  const { mutate: compraVentaUnidad, isLoading: registering } = useMutation(
    api.addEconomicaEvent
  );

  const emptySelection = _.isEmpty(selectedRows.keys);
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRows({ keys: selectedRowKeys, rows: selectedRows });
  };
  const rowSelection = {
    selectedRowKeys: selectedRows.keys,
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
  };

  const onAction = () => {
    setActionInProgress(true);
  };
  const rollbackAction = () => {
    setSelectedRows({ keys: [], rows: [] });
    form.resetFields();
    setActionInProgress(false);
  };

  const onActionApply = () => {
    form
      .validateFields()
      .then((values) => {
        const data = {
          fecha: GENERAL.dateToBack(values.date),
          monto: values.monto,
          ...(values.numero_comprobante && {
            numero_comprobante: values.numero_comprobante,
          }),
          criador: values.criador,
          unidades: selectedRows.keys.map((k) => ({
            id: k,
            ...(values.peso[k] && {
              [`${PESO_FORM_ITEM[eventType].field}`]: values.peso[k],
            }),
          })),
        };
        const onSuccess = () =>
          displayInfoModal({
            description: `Se realizó la ${eventTypeTitle.toLowerCase()} con éxito.\n Continuá tu gestión en Finca.`,
            showCheck: true,
            onOk: () => history.push(location.pathname),
          });
        const onError = ({ message, description }) => {
          if (message === '400') antMessage.error(description, 5);
        };
        compraVentaUnidad({ eventType, body: data }, { onSuccess, onError });
      })
      .catch(() => {});
  };

  const renderHeader = () => (
    <Col span={24}>
      <EventsHeader
        title={eventTypeTitle}
        description={
          !actionInProgress
            ? 'Unidades disponibles para realizar transacciones'
            : `${selectedRows.rows.length} unidades seleccionadas`
        }
        continueProps={{ onClick: onAction, disabled: emptySelection }}
        registerProps={{ onClick: onActionApply, loading: registering }}
        eventMenuProps={{
          menuType: MANAGEMENT.ECONOMICA,
          unitType: UNITS.UNIDAD,
          eventType: eventType,
          onUpload: refetch,
          exportParams: filters.get(),
        }}
        rollbackAction={rollbackAction}
        showRegister={actionInProgress}
      />
    </Col>
  );

  const renderTable = () => {
    return (
      <>
        <Col xs={6} xxl={4}>
          <FilterSider
            filters={[UnitFilter]}
            filtersProps={{ unitType: [UNITS.HEMBRAS, UNITS.MACHOS] }}
            onChange={filters.set}
          />
        </Col>
        <Col xs={18} xxl={20}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <CompraVentaUnidadTable
                data={data?.results}
                rowSelection={rowSelection}
                loading={isLoading}
                actions={(item) => (
                  <EventsTableActions item={item} onAction={setSelectedItem} />
                )}
                showTotal={true}
                pagination={{ ...pagination.get(), total: data?.count }}
                onChange={(p, f, s) => {
                  pagination.set(p);
                  sorter.set(s);
                }}
              />
              <UnitDrawerShow
                visible={selectedItem?.action === GENERAL.ACTIONS.VER_UNIDAD}
                unit={selectedItem.item}
                close={() => setSelectedItem(EVENT_ACTIONS_INITIAL_VALUES)}
              />
              <UnitDrawerRecordHistory
                visible={selectedItem?.action === GENERAL.ACTIONS.VER_HISTORIAL}
                unitId={selectedItem.item?.id}
                success={refetch}
                close={() => setSelectedItem(EVENT_ACTIONS_INITIAL_VALUES)}
              />
            </Col>
          </Row>
        </Col>
      </>
    );
  };

  const renderForm = () => (
    <Col span={24}>
      <Form form={form} layout="vertical" requiredMark={false}>
        <Row gutter={8}>
          <Col span={4}>
            <FormItem
              label="Fecha"
              name="date"
              rules={[{ required: true, message: 'Ingrese fecha' }]}
            >
              <DatePicker
                style={{ width: '100%' }}
                format={GENERAL.DATE_FORMAT.FRONT}
              />
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem
              label="Monto"
              name="monto"
              rules={[
                {
                  required: true,
                  type: 'string',
                  pattern: /^[0-9?]*\.?[0-9]*$/,
                  message: 'Ingrese monto',
                },
              ]}
            >
              <Input placeholder="Ingrese monto" />
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem label="Número de comprobante" name="numero_comprobante">
              <Input placeholder="Ingrese número de comprobante" />
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem
              label={CRIADOR_LABEL[eventType]}
              name="criador"
              rules={[
                {
                  required: true,
                  message: `Ingrese ${CRIADOR_LABEL[eventType].toLowerCase()}`,
                },
              ]}
            >
              <Select
                placeholder="Seleccionar"
                style={{ width: '100%' }}
                loading={isLoadingOptions}
                options={options?.[MASTERS.PRODUCTIVA_TYPES.CRIADOR]}
              />
            </FormItem>
          </Col>
          <Col span={24}>
            <CompraVentaUnidadTable
              form={form}
              pesoFormItemLabel={PESO_FORM_ITEM[eventType]?.label}
              loading={isLoading}
              data={selectedRows.rows}
              pagination={false}
            />
          </Col>
        </Row>
      </Form>
    </Col>
  );

  return (
    <Row gutter={[16, 16]}>
      {renderHeader()}
      {!actionInProgress && renderTable()}
      {actionInProgress && renderForm()}
    </Row>
  );
};

export default withAPI(CompraVentaUnidad);
