import { MANAGEMENT } from 'modules/core/constants';

const managementLeftMenuOptions = [
  {
    title: MANAGEMENT.MANAGEMENT_FORMATTED[MANAGEMENT.PRODUCTIVA],
    url: `/${MANAGEMENT.NAME_SPACE}/${MANAGEMENT.PRODUCTIVA}`,
    exact: false,
  },
  {
    title: MANAGEMENT.MANAGEMENT_FORMATTED[MANAGEMENT.SANITARIA],
    url: `/${MANAGEMENT.NAME_SPACE}/${MANAGEMENT.SANITARIA}`,
    exact: false,
  },
  {
    title: MANAGEMENT.MANAGEMENT_FORMATTED[MANAGEMENT.NUTRICIONAL],
    url: `/${MANAGEMENT.NAME_SPACE}/${MANAGEMENT.NUTRICIONAL}`,
    exact: false,
  },
  {
    title: MANAGEMENT.MANAGEMENT_FORMATTED[MANAGEMENT.ECONOMICA],
    url: `/${MANAGEMENT.NAME_SPACE}/${MANAGEMENT.ECONOMICA}`,
    exact: false,
  },
];

export default managementLeftMenuOptions;
