import React from 'react';
import { useQuery } from 'react-query';
import { withAPI } from 'modules/api';
import { Form, Col, Select } from 'antd';
import { MASTERS, PRODUCTIVA } from 'modules/core/constants';

const EmbrionAdquisicionesForm = ({ api, visible }) => {
  const { data: embriones, isLoading: isLoadingEmbriones } = useQuery(
    ['Productiva::Embriones', { inputType: PRODUCTIVA.EMBRION }],
    api.fetchInsumo,
    { enabled: visible }
  );

  const { data: options, isLoadingOptions } = useQuery(
    [
      'EmbrionesAdquisiciones::fetchOptions',
      {
        masterTypes: [
          MASTERS.PRODUCTIVA_TYPES.TECNICA_OBTENCION,
          MASTERS.PRODUCTIVA_TYPES.TIPO_EMBRION,
          MASTERS.PRODUCTIVA_TYPES.RESPONSABLE,
        ],
      },
    ],
    api.fetchMastersFormOptions
  );

  const embrionesOptions = embriones?.results.map((e) => ({
    value: e.id,
    label: `${e.hembra} ${e.macho && ' | ' + e.macho}`,
  }));

  return (
    <>
      <Col span={12}>
        <Form.Item
          label="Embrión"
          name="id"
          rules={[{ required: true, message: 'Ingrese embrión' }]}
        >
          <Select
            showSearch
            allowClear
            style={{ width: '100%' }}
            optionFilterProp="label"
            placeholder="Seleccionar embrión"
            loading={isLoadingEmbriones}
            options={embrionesOptions}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Tipo"
          name="tipo_embrion"
          rules={[{ required: true, message: 'Ingrese tipo' }]}
        >
          <Select
            showSearch
            allowClear
            style={{ width: '100%' }}
            optionFilterProp="label"
            placeholder="Seleccionar tipo"
            loading={isLoadingOptions}
            options={options?.[MASTERS.PRODUCTIVA_TYPES.TIPO_EMBRION]}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Técnica de obtención"
          name="tecnica_obtencion"
          rules={[{ required: true, message: 'Ingrese técnica de obtención' }]}
        >
          <Select
            showSearch
            allowClear
            style={{ width: '100%' }}
            optionFilterProp="label"
            placeholder="Seleccionar técnica de obtención"
            loading={isLoadingOptions}
            options={options?.[MASTERS.PRODUCTIVA_TYPES.TECNICA_OBTENCION]}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Responsable" name="responsable">
          <Select
            showSearch
            allowClear
            style={{ width: '100%' }}
            optionFilterProp="label"
            placeholder="Seleccionar responsable"
            loading={isLoadingOptions}
            options={options?.[MASTERS.PRODUCTIVA_TYPES.RESPONSABLE]}
          />
        </Form.Item>
      </Col>
    </>
  );
};

export default withAPI(EmbrionAdquisicionesForm);
