import React, { useCallback, useEffect } from 'react';
import { useQuery, useMutation } from 'react-query';
import { Col, Form, Modal, Row, DatePicker, Select } from 'antd';
import { withAPI } from 'modules/api';
import { GENERAL } from 'modules/core/constants';
import { displayInfoModal } from 'modules/core/components';
import './LicenseModalAdd.scss';

const { RangePicker } = DatePicker;
const LicenseModalAdd = ({ api, est, visible, close, success }) => {
  const { data: licenses, isLoading: loadingLicenses } = useQuery(
    ['Licenses::Types'],
    api.fetchLicenseTypes,
    { enabled: visible, staleTime: 5000 }
  );
  const { mutate: addLic, isLoading: addingLic } = useMutation(
    api.createLicense
  );
  const [form] = Form.useForm();

  const clearForm = useCallback(() => {
    form.resetFields();
  }, [form]);
  useEffect(clearForm, [clearForm, visible]);

  const onAddLicense = () => {
    form
      .validateFields()
      .then(({ license_duration: [startDate, endDate], tipo }) => {
        const license = {
          establecimiento: est.id,
          start_date: startDate,
          end_date: endDate,
          tipo,
        };
        const onSuccess = () => {
          success();
          displayInfoModal({
            description: `Se actualizó la nueva licencia para el establecimiento ${est.name}.`,
            showCheck: true,
          });
        };
        addLic({ license }, { onSuccess });
      });
  };

  return (
    <Modal
      title="Nueva licencia"
      forceRender
      className="license-add-modal"
      cancelText="CANCELAR"
      okText="CREAR LICENCIA"
      confirmLoading={addingLic}
      open={visible}
      onCancel={close}
      onOk={onAddLicense}
    >
      <Form form={form} layout="vertical" requiredMark={false}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="Nombre de la licencia"
              name="tipo"
              rules={[
                { required: true, message: 'Ingrese nombre de la licencia' },
              ]}
            >
              <Select
                className="license-add-form-input"
                allowClear
                showSearch
                loading={loadingLicenses}
                options={GENERAL.objsToOptions(licenses)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Duración de la licencia"
              name="license_duration"
              rules={[
                {
                  required: true,
                  message: 'Ingrese la duración de la licencia',
                },
              ]}
            >
              <RangePicker
                className="license-add-form-input"
                format={GENERAL.DATE_FORMAT.FRONT}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default withAPI(LicenseModalAdd);
