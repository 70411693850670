import React from 'react';
import { useQuery } from 'react-query';
import { withAPI } from 'modules/api';
import { Col, Row } from 'antd';
import { usePaginationWithFilters } from 'modules/core/hooks';
import { GENERAL, PRODUCTIVA } from 'modules/core/constants';
import { Card, Charts } from 'modules/core/components';
import FilterSider, {
  UnitFilter,
  getRecordFilters,
} from 'modules/core/components/Filter';

const HEIGHT_ROW_1 = 400;
const HEIGHT_ROW_2 = 550;

const PreServicioAnalytics = ({ api, isActive }) => {
  const { filters } = usePaginationWithFilters();
  const {
    data: preServiciosByResultados,
    isLoading: isLoadingPreServiciosByResultados,
  } = useQuery(
    ['Analytics::fetchPreServiciosByResultados', { params: filters.get() }],
    api.fetchPreServiciosByResultados,
    { enabled: isActive }
  );
  const {
    data: serviciosByCategoria,
    isLoading: isLoadingServiciosByCategoria,
  } = useQuery(
    ['Analytics::fetchServiciosByCategoria', { params: filters.get() }],
    api.fetchServiciosByCategoria,
    { enabled: isActive }
  );
  const { data: serviciosByTipo, isLoading: isLoadingServiciosByTipo } =
    useQuery(
      ['Analytics::fetchServiciosByTipo', { params: filters.get() }],
      api.fetchServiciosByTipo,
      { enabled: isActive }
    );
  const {
    data: serviciosIndividualesByMacho,
    isLoading: isLoadingServiciosIndividualesByMacho,
  } = useQuery(
    ['Analytics::fetchServiciosIndividualesByMacho', { params: filters.get() }],
    api.fetchServiciosIndividualesByMacho,
    { enabled: isActive }
  );
  const { data: serviciosEvolucion, isLoading: isLoadingServiciosEvolucion } =
    useQuery(
      ['Analytics::fetchServiciosEvolucion', { params: filters.get() }],
      api.fetchServiciosEvolucion,
      { enabled: isActive }
    );

  return (
    <>
      <Row gutter={[24, 32]} className="data-row">
        <Col span={24}>
          <FilterSider
            showDrawer
            showCurrentYear
            showGlobalSearch={false}
            filters={[
              UnitFilter,
              ...getRecordFilters(PRODUCTIVA.TACTO),
              ...getRecordFilters(PRODUCTIVA.SERVICIO),
            ]}
            onChange={filters.set}
          />
        </Col>
        <Col span={8}>
          <Card
            type="secondary"
            loading={isLoadingPreServiciosByResultados}
            title={preServiciosByResultados?.titulo}
            tooltip={preServiciosByResultados?.calculo}
            style={{ height: HEIGHT_ROW_1 }}
          >
            <Charts.Pie
              data={GENERAL.formatPieChartData(preServiciosByResultados?.data)}
              innerRadius={0.5}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card
            type="secondary"
            loading={isLoadingServiciosByCategoria}
            title={serviciosByCategoria?.titulo}
            tooltip={serviciosByCategoria?.calculo}
            style={{ height: HEIGHT_ROW_1 }}
          >
            <Charts.Pie
              data={GENERAL.formatPieChartData(serviciosByCategoria?.data)}
              innerRadius={0.5}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card
            type="secondary"
            loading={isLoadingServiciosByTipo}
            title={serviciosByTipo?.titulo}
            tooltip={serviciosByTipo?.calculo}
            style={{ height: HEIGHT_ROW_1 }}
          >
            <Charts.Pie
              data={GENERAL.formatPieChartData(serviciosByTipo?.data)}
              innerRadius={0.5}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={[24, 32]} className="data-row">
        <Col span={12}>
          <Card
            type="secondary"
            loading={isLoadingServiciosIndividualesByMacho}
            title={serviciosIndividualesByMacho?.titulo}
            tooltip={serviciosIndividualesByMacho?.calculo}
            style={{ height: HEIGHT_ROW_2 }}
          >
            <Charts.TreeMap
              data={serviciosIndividualesByMacho?.data}
              identity="label"
              value="value"
              label="id"
              valueFormat=">-.0f"
              enableParentLabel={false}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card
            type="secondary"
            loading={isLoadingServiciosEvolucion}
            title={serviciosEvolucion?.titulo}
            tooltip={serviciosEvolucion?.calculo}
            style={{ height: HEIGHT_ROW_2 }}
          >
            <Charts.Bar
              data={serviciosEvolucion?.data}
              indexBy="label"
              keys={['value']}
              enableLabel={false}
              legends={[]}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default withAPI(PreServicioAnalytics);
