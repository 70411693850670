import React from 'react';
import { Form, Row, Col, Collapse, DatePicker, Select } from 'antd';
import { GENERAL, MASTERS, SANITARIA } from 'modules/core/constants';
import { withAPI } from 'modules/api';
import { useQuery } from 'react-query';

const { Panel } = Collapse;
const { Item } = Form;
const { RangePicker } = DatePicker;

const PARAMS = {
  FECHA_EVENTO: `${GENERAL.FILTER_RANGE}fecha_vacunacion`,
  VACUNACION_VACUNA: 'vacuna_id',
};

const tags = {
  [PARAMS.FECHA_EVENTO]: 'Fecha vacunación',
  [PARAMS.VACUNACION_VACUNA]: 'Vacuna',
};

const FormFields = ({ api }) => {
  const queryOptions = { staleTime: 100000 };
  const { data: vacunas, isLoading: isLoadingVacunas } = useQuery(
    ['Sanitaria::Vacunas', { inputType: SANITARIA.VACUNA }],
    api.fetchInsumo,
    queryOptions
  );

  return (
    <Row gutter={[0]}>
      <Col span={24}>
        <Collapse ghost>
          <Panel header="Vacunas sin aplicar" key="vacunas_sin_aplicar">
            <Row gutter={8}>
              <Col span={24}>
                <Item
                  label={tags[PARAMS.FECHA_EVENTO]}
                  name={PARAMS.FECHA_EVENTO}
                >
                  <RangePicker style={{ width: '100%' }} />
                </Item>
              </Col>
              <Col span={24}>
                <Item
                  label={tags[PARAMS.VACUNACION_VACUNA]}
                  name={PARAMS.VACUNACION_VACUNA}
                >
                  <Select
                    placeholder="Seleccionar valor"
                    allowClear
                    showSearch
                    labelInValue={true}
                    optionFilterProp="label"
                    dropdownMatchSelectWidth={200}
                    loading={isLoadingVacunas}
                    options={MASTERS.generateOptions(vacunas?.results)}
                  />
                </Item>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </Col>
    </Row>
  );
};

const filterComponent = {
  tags,
  FormFields: withAPI(FormFields),
};

export default filterComponent;
