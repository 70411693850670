import { ESTABLISHMENT } from 'modules/core/constants';

const establishmentMenuOptions = [
  {
    title: 'Planificación',
    url: `/${ESTABLISHMENT.NAME_SPACE}/${ESTABLISHMENT.PLANIFICACION}`,
    exact: false,
  },
  {
    title: 'Ubicaciones',
    url: `/${ESTABLISHMENT.NAME_SPACE}/${ESTABLISHMENT.UBICACIONES}`,
    exact: false,
  },
  {
    title: 'Rodeos',
    url: `/${ESTABLISHMENT.NAME_SPACE}/${ESTABLISHMENT.RODEOS}`,
    exact: false,
  },
  {
    title: 'Criadores / Propietarios',
    url: `/${ESTABLISHMENT.NAME_SPACE}/${ESTABLISHMENT.CRIADORES}`,
    exact: false,
  },
  {
    title: 'Responsables',
    url: `/${ESTABLISHMENT.NAME_SPACE}/${ESTABLISHMENT.RESPONSABLES}`,
    exact: false,
  },
  {
    title: 'Tareas',
    url: `/${ESTABLISHMENT.NAME_SPACE}/${ESTABLISHMENT.TAREAS}`,
    exact: false,
  },
];

export default establishmentMenuOptions;
