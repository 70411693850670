import React from 'react';
import { ResponsivePie } from '@nivo/pie';

const PieChart = ({
  data = [],
  colors = { scheme: 'greens' },
  ...restProps
}) => {
  return (
    <ResponsivePie
      data={data}
      margin={{ top: 20, bottom: 50, right: 220, left: 10 }}
      innerRadius={0}
      padAngle={0}
      cornerRadius={0}
      colors={colors}
      borderWidth={0}
      enableArcLinkLabels={false}
      arcLabel={({ data }) => `${data?.sliceLabel}`}
      fit={false}
      legends={[
        {
          anchor: 'right',
          translateX: 150,
          direction: 'column',
          itemWidth: 135,
          itemHeight: 18,
          itemsSpacing: 5,
          itemTextColor: '#575757',
          symbolSize: 18,
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000',
              },
            },
          ],
        },
      ]}
      {...restProps}
    />
  );
};

export default PieChart;
