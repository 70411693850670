import React from 'react';
import { Form, Input, Row, Col } from 'antd';

const CriadoresForm = () => {
  return (
    <Row>
      <Col span={24}>
        <Form.Item
          label="Nombre del criador / propietario"
          name="nombre"
          rules={[
            {
              required: true,
              message: 'Ingrese nombre de criador / propietario',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default CriadoresForm;
