import React from 'react';
import { Layout } from 'antd';

import './SubMenuLayout.scss';

const { Header, Content } = Layout;
const SubMenuLayout = ({
  children,
  leftMenu: LeftMenu,
  rightMenu: RightMenu,
}) => {
  return (
    <Layout className="app-submenu-layout">
      <Header className="app-submenu-header">
        {LeftMenu && (
          <div className="app-submenu-header-left">
            <LeftMenu />
          </div>
        )}
        {RightMenu && (
          <div className="app-submenu-header-right">
            <RightMenu />
          </div>
        )}
      </Header>
      <Content className="app-submenu-content">{children}</Content>
    </Layout>
  );
};

export default SubMenuLayout;
