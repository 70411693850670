import React from 'react';
import { useParams } from 'react-router-dom';
import { withAPI } from 'modules/api';
import {
  AdquisicionesForms,
  COLUMNS_NUTRICIONAL,
  COLUMNS_PRODUCTIVA,
  COLUMNS_SANITARIA,
} from './components';
import { InsumosRecordHistory } from 'modules/core/components';
import { useInsumosRecordHistory } from 'modules/core/hooks';

const Adquisiciones = ({ api }) => {
  const { noEventType } = useParams();
  const recordState = useInsumosRecordHistory({
    type: noEventType,
    fetchFns: { fetchInsumos: api.fetchAdquisicion },
  });
  return (
    <InsumosRecordHistory
      state={recordState}
      columns={{
        ...COLUMNS_PRODUCTIVA,
        ...COLUMNS_SANITARIA,
        ...COLUMNS_NUTRICIONAL,
      }}
      customAddForm={AdquisicionesForms[noEventType]}
    />
  );
};

export default withAPI(Adquisiciones);
