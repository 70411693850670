import { PRODUCTIVA, UNITS } from 'modules/core/constants';

const unitsMenuOptions = [
  {
    title: 'Stock',
    url: `/${UNITS.NAME_SPACE}/stock`,
    exact: false,
  },
  {
    title: 'Movimientos',
    url: `/${UNITS.NAME_SPACE}/${PRODUCTIVA.MOVIMIENTO}`,
    exact: false,
  },
  {
    title: 'Bajas',
    url: `/${UNITS.NAME_SPACE}/${PRODUCTIVA.BAJA}`,
    exact: false,
  },
];

export default unitsMenuOptions;
