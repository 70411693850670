import React, { lazy, Suspense } from 'react';
import { withAPI } from 'modules/api';
import { UNITS } from '../constants';

const BovinoTheme = lazy(() => import('./BovinoTheme'));
const OvinoTheme = lazy(() => import('./OvinoTheme'));

const ThemeSelector = withAPI(({ children, api }) => {
  return (
    <>
      <Suspense fallback={null}>
        {api.userSpecies === UNITS.OVINO ? <OvinoTheme /> : <BovinoTheme />}
      </Suspense>
      {children}
    </>
  );
});

export default ThemeSelector;
