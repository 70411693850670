import { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { message as antMessage } from 'antd';
import usePaginationWithFilters from './usePaginationWithFilters';

const useEventsRecordHistory = ({ type, fetchFns }) => {
  const { pagination, filters, sorter, params } = usePaginationWithFilters();

  const {
    data: historial,
    isLoading,
    refetch: rList,
  } = useQuery(
    ['Records::List', { recordType: type, params }],
    fetchFns.fetchRecords
  );
  const { data: kpis, refetch: rKPI } = useQuery(
    ['Records::KPIs', { recordType: type, params }],
    fetchFns.fetchRecordsKPI
  );
  const refetch = () => {
    return Promise.all([rList(), rKPI()]);
  };

  // ACTIONS
  const [detail, setDetail] = useState(null);
  const [history, setHistory] = useState(null);
  const [undoingMultipleEvents, setUndoingMultipleEvents] = useState(false);
  const [selectedRows, setSelectedRows] = useState({ keys: [], rows: [] });
  const { mutate: undoEvent } = useMutation(fetchFns.undoEvent);
  const { mutate: undoMultipleEvents, isLoading: isUndoingMultipleEvents } =
    useMutation(fetchFns.undoMultipleEvents);

  const onUndoEvent = (eventId) =>
    undoEvent(
      { eventId, eventType: type },
      {
        onSuccess: () => {
          antMessage.success('Evento deshecho con éxito.');
          return refetch();
        },
        onError: ({ message, description }) => {
          if (message === '400') antMessage.error(description);
          else antMessage.error('No se pudo deshacer el evento.');
        },
      }
    );

  const onUndoMultipleEvents = (eventIds) =>
    undoMultipleEvents(
      { event_ids: eventIds, eventType: type },
      {
        onSuccess: () => {
          antMessage.success('Eventos deshechos con éxito.');
          return refetch();
        },
        onError: () => {
          antMessage.error('Algunos eventos no pudieron ser deshechos.');
        },
      }
    );

  // Selection Mode
  const onSelectionChange = (selectedRowKeys, selectedRows) => {
    setSelectedRows({ keys: selectedRowKeys, rows: selectedRows });
  };
  const clearSelection = () => {
    setSelectedRows({ keys: [], rows: [] });
    setUndoingMultipleEvents(false);
  };
  const confirmSelection = (apiFn) => {
    apiFn && apiFn(selectedRows.keys).then(clearSelection);
  };
  const rowSelection = undoingMultipleEvents
    ? {
        selectedRowKeys: selectedRows.keys,
        onChange: onSelectionChange,
        preserveSelectedRowKeys: true,
      }
    : null;

  return {
    refetch,
    isLoading,
    pagination,
    sorter,
    filters,
    detail,
    setDetail,
    history,
    setHistory,
    undoingMultipleEvents,
    setUndoingMultipleEvents,
    isUndoingMultipleEvents,
    onUndoEvent,
    onUndoMultipleEvents,
    clearSelection,
    confirmSelection,
    rowSelection,
    kpis,
    historial,
    type,
  };
};

export default useEventsRecordHistory;
