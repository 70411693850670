import React from 'react';
import { withAPI } from 'modules/api';
import { MASTERS } from 'modules/core/constants';
import { ManagementConfig } from 'modules/management/components';

const MotivosBaja = ({ api }) => {
  const masterType = MASTERS.PRODUCTIVA_TYPES.CLASIFICACION_DESTETE;
  const masterName = {
    CAPITALIZE: 'Clasificación al destete',
    LOWER: 'clasificación al destete',
    PLURAL: 'Clasificaciones al destete',
  };

  return <ManagementConfig masterType={masterType} masterName={masterName} />;
};

export default withAPI(MotivosBaja);
