import React from 'react';
import { GENERAL, MASTERS } from 'modules/core/constants';
import { EstablishmentMastersDeactivated } from 'modules/establishment/establishmentMasters/components';

const UbicacionesDeactivatedList = () => {
  return (
    <EstablishmentMastersDeactivated
      screenTitle="Ubicaciones desactivadas"
      masterType={MASTERS.PRODUCTIVA_TYPES.UBICACION}
      extraColumns={[
        {
          title: 'Superficie',
          dataIndex: 'superficie_ganadera',
          render: (a) => a && `${a} ha`,
        },
        {
          title: 'Carga animal por unidad',
          dataIndex: 'carga_animal_unidad',
          render: (w) => GENERAL.numToFront(w, 2),
        },
        {
          title: 'Peso total',
          dataIndex: 'peso_total',
          render: (w) => w && GENERAL.numToFront(w, 2, ' kg'),
        },
        {
          title: 'Porcentaje de peso',
          dataIndex: 'porcentaje_peso',
          render: (w) => (w === '-' ? w : `${w}%`),
        },
        {
          title: 'Carga animal por peso',
          dataIndex: 'carga_animal_peso',
          render: (w) => GENERAL.numToFront(w, 2),
        },
      ]}
    />
  );
};

export default UbicacionesDeactivatedList;
