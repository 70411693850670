import { useState } from 'react';
import { useQuery } from 'react-query';
import usePaginationWithFilters from './usePaginationWithFilters';

const useInsumosRecordHistory = ({ type, fetchFns }) => {
  const { pagination, filters, sorter, params } = usePaginationWithFilters();

  const {
    data: insumos,
    isLoading,
    refetch,
  } = useQuery(
    ['Adquisiciones::List', { inputType: type, params }],
    fetchFns.fetchInsumos
  );

  // ACTIONS
  const [editInsumo, setEditInsumo] = useState(null);
  const [deleteInsumo, setDeleteInsumo] = useState(null);

  const reset = () => {
    setEditInsumo(null);
    setDeleteInsumo(null);
    refetch();
  };

  return {
    insumos,
    isLoading,
    pagination,
    sorter,
    filters,
    editInsumo,
    setEditInsumo,
    deleteInsumo,
    setDeleteInsumo,
    refetch,
    reset,
    type,
  };
};

export default useInsumosRecordHistory;
