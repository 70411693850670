import React from 'react';
import _ from 'lodash';
import { useQuery } from 'react-query';
import { withAPI } from 'modules/api';
import { UNITS } from 'modules/core/constants';
import { Card, DashboardList } from 'modules/core/components';

const UnitsStatesCard = ({ api, height = 200 }) => {
  const { data, isLoading } = useQuery(
    ['Home::fetchKPIUnitStates'],
    api.fetchKPIUnitStates
  );

  const formatData = (dataToFormat) =>
    _.map(dataToFormat, (d, k) => ({
      title: UNITS.ESTADOS_FORMATTED[k],
      quantity: d.cantidad,
      percentage: d.porcentaje,
    }));

  return (
    <Card
      title="Estado productivo"
      description="Conocé en qué estado se encuentran cada una de tus unidades."
      type="secondary"
      style={{ height }}
    >
      <DashboardList
        loading={isLoading}
        dataSource={data && formatData(data)}
      />
    </Card>
  );
};

export default withAPI(UnitsStatesCard);
