import React from 'react';
import { useQuery } from 'react-query';
import { withAPI } from 'modules/api';
import { Col, Row } from 'antd';
import { usePaginationWithFilters } from 'modules/core/hooks';
import { GENERAL, PRODUCTIVA } from 'modules/core/constants';
import { Card, Charts } from 'modules/core/components';
import FilterSider, {
  UnitFilter,
  getRecordFilters,
} from 'modules/core/components/Filter';

const HEIGHT_ROW_1 = 250;
const HEIGHT_ROW_2 = 550;

const PartoAnalytics = ({ api, isActive }) => {
  const { filters } = usePaginationWithFilters();
  const { data: partoAbortos, isLoading: isLoadingPartoAbortos } = useQuery(
    ['Analytics::fetchPartoAbortos', { params: filters.get() }],
    api.fetchPartoAbortos,
    { enabled: isActive }
  );
  const { data: partoIEP, isLoading: isLoadingPartoIEP } = useQuery(
    ['Analytics::fetchPartoIEP', { params: filters.get() }],
    api.fetchPartoIEP,
    { enabled: isActive }
  );
  const { data: partoPesoNac, isLoading: isLoadingPartoPesoNac } = useQuery(
    ['Analytics::fetchPartoPesoNac', { params: filters.get() }],
    api.fetchPartoPesoNac,
    { enabled: isActive }
  );
  const { data: partoByTipo, isLoading: isLoadingPartoByTipo } = useQuery(
    ['Analytics::fetchPartoByTipo', { params: filters.get() }],
    api.fetchPartoByTipo,
    { enabled: isActive }
  );
  const { data: partoEvolucion, isLoading: isLoadingPartoEvolucion } = useQuery(
    ['Analytics::fetchPartoEvolucion', { params: filters.get() }],
    api.fetchPartoEvolucion,
    { enabled: isActive }
  );

  return (
    <>
      <Row gutter={[24, 32]} className="data-row">
        <Col span={24}>
          <FilterSider
            showDrawer
            showCurrentYear
            showGlobalSearch={false}
            filters={[UnitFilter, ...getRecordFilters(PRODUCTIVA.PARTO)]}
            onChange={filters.set}
          />
        </Col>
        <Col span={8}>
          <Charts.KPI
            height={HEIGHT_ROW_1}
            loading={isLoadingPartoAbortos}
            title={partoAbortos?.titulo}
            value={GENERAL.roundPercentage(partoAbortos?.data)}
            tooltip={partoAbortos?.calculo}
            stylesKPI={{ value: { fontSize: 60 } }}
          />
        </Col>
        <Col span={8}>
          <Charts.KPI
            height={HEIGHT_ROW_1}
            loading={isLoadingPartoIEP}
            title={partoIEP?.titulo}
            value={partoIEP?.data}
            tooltip={partoIEP?.calculo}
            stylesKPI={{ value: { fontSize: 60 } }}
          />
        </Col>
        <Col span={8}>
          <Charts.KPI
            height={HEIGHT_ROW_1}
            loading={isLoadingPartoPesoNac}
            title={partoPesoNac?.titulo}
            value={partoPesoNac?.data}
            tooltip={partoPesoNac?.calculo}
            stylesKPI={{ value: { fontSize: 60 } }}
          />
        </Col>
      </Row>
      <Row gutter={[24, 32]} className="data-row">
        <Col span={12}>
          <Card
            type="secondary"
            loading={isLoadingPartoByTipo}
            title={partoByTipo?.titulo}
            tooltip={partoByTipo?.calculo}
            style={{ height: HEIGHT_ROW_2 }}
          >
            <Charts.Pie
              data={GENERAL.formatPieChartData(partoByTipo?.data)}
              innerRadius={0.5}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card
            type="secondary"
            loading={isLoadingPartoEvolucion}
            title={partoEvolucion?.titulo}
            tooltip={partoEvolucion?.calculo}
            style={{ height: HEIGHT_ROW_2 }}
          >
            <Charts.Bar
              data={partoEvolucion?.data}
              indexBy="label"
              keys={['value']}
              legends={[]}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default withAPI(PartoAnalytics);
