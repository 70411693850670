import React from 'react';
import { Form, Input, Row, Col } from 'antd';

const ProductosForm = () => {
  return (
    <Row gutter={8}>
      <Col span={12}>
        <Form.Item
          label="Nombre"
          name="nombre"
          rules={[{ required: true, message: 'Ingrese nombre' }]}
        >
          <Input placeholder="Ingrese nombre" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Marca"
          name="marca"
          rules={[{ required: true, message: 'Ingrese marca' }]}
        >
          <Input placeholder="Ingrese marca" />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default ProductosForm;
