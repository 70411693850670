import React from 'react';
import { useQuery } from 'react-query';
import { withAPI } from 'modules/api';
import { GENERAL, MASTERS, SANITARIA } from 'modules/core/constants';
import { SanitariaInsumo } from '../components';
import VacunasForm from './VacunasForm';

const Vacunas = ({ api }) => {
  const inputType = SANITARIA.NOEVENT_TYPES.VACUNA;
  const masterName = {
    CAPITALIZE: 'Vacuna',
    LOWER: 'vacuna',
  };

  const { data: sindromes, isLoading: isLoadingSindromes } = useQuery(
    [
      'Sanitaria::fetchSindromes',
      { masterType: SANITARIA.NOEVENT_TYPES.SINDROME },
    ],
    api.fetchMasters
  );

  const sindromeOptions = MASTERS.generateOptions(sindromes?.results);

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      sorter: true,
    },
    {
      title: 'Sindrome que previene',
      dataIndex: 'sindrome',
      sorter: true,
      render: (value) => GENERAL.optionToLabel(sindromeOptions, value),
    },
    {
      title: 'Stock',
      dataIndex: 'cantidad_actual',
      sorter: true,
    },
    {
      title: 'Obligatoriedad',
      dataIndex: 'obligatoriedad',
      sorter: true,
      render: (value) => (value ? 'Si' : 'No'),
    },
  ];

  return (
    <SanitariaInsumo
      inputType={inputType}
      masterName={masterName}
      columns={columns}
      loading={isLoadingSindromes}
      showEdit={true}
      renderForm={() => (
        <VacunasForm
          sindrome={{ options: sindromeOptions, isLoading: isLoadingSindromes }}
        />
      )}
    />
  );
};

export default withAPI(Vacunas);
