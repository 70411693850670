import { ESTABLISHMENT, MANAGEMENT, UNITS } from 'modules/core/constants';

const menuOptions = [
  {
    key: 'home',
    title: 'INICIO',
    url: `/`,
    exact: true,
  },
  {
    key: 'establishment',
    title: 'ESTABLECIMIENTO',
    url: `/${ESTABLISHMENT.NAME_SPACE}`,
    exact: false,
  },
  {
    key: 'units',
    title: 'UNIDADES',
    url: `/${UNITS.NAME_SPACE}`,
    exact: false,
  },
  {
    key: 'management',
    title: 'GESTIÓN',
    url: `/${MANAGEMENT.NAME_SPACE}`,
    exact: false,
  },
  {
    key: 'analytics',
    title: 'ANALÍTICAS',
    url: `/analiticas`,
    exact: false,
  },
];

export default menuOptions;
