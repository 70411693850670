import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { SectionTitle } from './UnitInfoReportPDF';
import { UNITS } from 'modules/core/constants';

const styles = StyleSheet.create({
  unitTree: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: '#f0f0f0',
    borderWidth: 1,
    paddingVertical: 10,
  },
  columnsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '60%',
    // fontFamily: 'Raleway',
  },
  leaf: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
    width: 100,
    fontWeight: 500,
    fontSize: 11,
    borderRadius: 10,
    marginVertical: 5,
  },
  leafBovino: {
    color: '#126234', // --primary-color
    backgroundColor: '#edf8ed', // --primary-color-light
  },
  leafOvino: {
    color: '#0060b5', // --primary-color
    backgroundColor: '#dcedf5', // --primary-color-light
  },
  subvalue: {
    fontSize: 10,
    fontWeight: 400,
    color: '#575757',
  },
});

const UnitTreeReportPDF = ({ data, userSpecies }) => {
  const leafStyles = [
    styles.leaf,
    userSpecies === UNITS.OVINO ? styles.leafOvino : styles.leafBovino,
  ];
  return (
    <>
      <SectionTitle title="Árbol genealógico" userSpecies={userSpecies} />
      {data && (
        <View style={styles.unitTree}>
          <View style={styles.columnsContainer}>
            <View>
              <View style={leafStyles}>
                <Text>{data.unit.value}</Text>
                <Text style={styles.subvalue}>{data.unit.subvalue}</Text>
              </View>
            </View>

            <View>
              <View style={leafStyles}>
                <Text>{data.parent0.value}</Text>
                <Text style={styles.subvalue}>{data.parent0.subvalue}</Text>
              </View>
              <View style={leafStyles}>
                <Text>{data.parent1.value}</Text>
                <Text style={styles.subvalue}>{data.parent1.subvalue}</Text>
              </View>
            </View>

            <View>
              <View>
                <View style={leafStyles}>
                  <Text>{data.gparent00.value}</Text>
                  <Text style={styles.subvalue}>{data.gparent00.subvalue}</Text>
                </View>
                <View style={leafStyles}>
                  <Text>{data.gparent01.value}</Text>
                  <Text style={styles.subvalue}>{data.gparent01.subvalue}</Text>
                </View>
              </View>
              <View>
                <View style={leafStyles}>
                  <Text>{data.gparent10.value}</Text>
                  <Text style={styles.subvalue}>{data.gparent10.subvalue}</Text>
                </View>
                <View style={leafStyles}>
                  <Text>{data.gparent11.value}</Text>
                  <Text style={styles.subvalue}>{data.gparent11.subvalue}</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      )}
    </>
  );
};

export default UnitTreeReportPDF;
