import React, { useCallback, useEffect } from 'react';
import { useMutation } from 'react-query';
import { Col, Form, Modal, Row, Input } from 'antd';
import { KeyOutlined } from '@ant-design/icons';
import { withAPI } from 'modules/api';
import { displayInfoModal } from 'modules/core/components';
import './UserModalResetPw.scss';

const UserModalResetPw = ({ api, visible, close, success, user }) => {
  const { mutate: resetPw, isLoading: resettingPw } = useMutation(
    api.resetPasswordUser
  );
  const [form] = Form.useForm();

  const clearForm = useCallback(() => {
    form.resetFields();
  }, [form]);
  useEffect(clearForm, [clearForm, visible]);

  const confirmPasswordValidator = (_, value) => {
    return value && value !== form.getFieldValue('new_password')
      ? Promise.reject(new Error('Las contraseñas no coinciden'))
      : Promise.resolve();
  };

  const onResetPw = () => {
    form
      .validateFields()
      .then((values) => {
        const onSuccess = () => {
          success();
          displayInfoModal({
            description: `Se reseteó la contraseña del usuario ${user.first_name}.`,
            showCheck: true,
          });
        };
        resetPw(
          { userId: user.id, new_password: values.new_password },
          { onSuccess }
        );
      })
      .catch(() => {});
  };

  return (
    <Modal
      title={
        <>
          <KeyOutlined /> {`Resetear contraseña`}
        </>
      }
      forceRender
      className="user-reset-modal"
      cancelText={'CANCELAR'}
      okText={'Resetear'}
      confirmLoading={resettingPw}
      open={visible}
      onCancel={close}
      onOk={onResetPw}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={user}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="Nueva contraseña"
              name="new_password"
              rules={[{ required: true, message: 'Ingrese contraseña' }]}
            >
              <Input className="user-reset-form-input" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Confirmar nueva contraseña"
              name="new_password_confirm"
              dependencies={['new_password']}
              rules={[
                { required: true, message: 'Confirme nueva contraseña' },
                { validator: confirmPasswordValidator },
              ]}
            >
              <Input className="user-reset-form-input" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default withAPI(UserModalResetPw);
