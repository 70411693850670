import { ECONOMICA } from 'modules/core/constants';

const GestionEconomicaAPI = (API) => {
  API.fetchEconomicaMasters = function ({ queryKey }) {
    const [, { masterType, params }] = queryKey;
    const qp = API.makeQueryString(params);
    let url = `/api/core/gestioneconomica/maestros`;
    url += `/${masterType}/?${qp}`;
    return fetch(url, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  // ++ DASHBOARD ++
  API.fetchEconomicaDashboardTable = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`/api/core/gestioneconomica/analytics/tabla/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchEconomicaDashboardEgresosChart = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`/api/core/gestioneconomica/analytics/egresos/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchEconomicaDashboardIngresosChart = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`/api/core/gestioneconomica/analytics/ingresos/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchEconomicaDashboardAnualChart = function ({ queryKey }) {
    return fetch(`/api/core/gestioneconomica/analytics/anual/`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchDivisaActual = function ({ queryKey }) {
    return fetch(`/api/core/gestioneconomica/maestros/divisas/divisa-actual/`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.editDivisaActual = function ({ currency }) {
    return fetch(`/api/core/gestioneconomica/maestros/divisas/divisa-actual/`, {
      method: 'POST',
      headers: API.getAuthHeaders(),
      body: JSON.stringify({ currency }),
    }).then(API.handleResponse);
  };

  // ++ Events ++
  API.fetchEconomicaEventsRecords = function ({ queryKey }) {
    const [, { recordType, params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(
      `/api/core/gestioneconomica/transacciones/${recordType}/?${qp}`,
      {
        method: 'GET',
        headers: API.getAuthHeaders(),
      }
    ).then(API.handleResponse);
  };

  API.fetchEconomicaEventsKPI = function ({ queryKey }) {
    const [, { recordType, params }] = queryKey;
    const qp = API.makeQueryString(params);
    const eventTypeKpi = ECONOMICA.EVENT_TYPES_KPIS[recordType];

    return fetch(
      `/api/core/gestioneconomica/analytics/kpis-${eventTypeKpi}/?${qp}`,
      {
        method: 'GET',
        headers: API.getAuthHeaders(),
      }
    ).then(API.handleResponse);
  };

  API.addEconomicaEvent = function ({ body = {}, eventType }) {
    return fetch(`/api/core/gestioneconomica/transacciones/${eventType}/`, {
      method: 'POST',
      headers: API.getAuthHeaders(),
      body: JSON.stringify(body),
    }).then(API.handleResponse);
  };

  API.deleteEconomicaEvent = function ({ eventType, eventId }) {
    return fetch(
      `/api/core/gestioneconomica/transacciones/${eventType}/${eventId}/`,
      {
        method: 'DELETE',
        headers: API.getAuthHeaders(),
      }
    ).then(API.handleResponse);
  };

  API.deleteMultipleEconomicaEvent = function ({ eventType, event_ids }) {
    return fetch(
      `/api/core/gestioneconomica/transacciones/${eventType}/delete-multiple/`,
      {
        method: 'POST',
        headers: API.getAuthHeaders(),
        body: JSON.stringify({ event_ids }),
      }
    ).then(API.handleResponse);
  };

  API.fetchCompraInsumosAdquisiciones = function ({ queryKey }) {
    const [, { eventType, params }] = queryKey;
    const qp = API.makeQueryString({ compra: 'true', ...params });
    const insumo = ECONOMICA.getInsumoFromEventType(eventType);
    const modulo = ECONOMICA.getModuloByInsumo(insumo);

    return fetch(`/api/core/${modulo}/insumos/adquisicion-${insumo}/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.uploadEconomicaEvent = function ({ eventType, inputFile }) {
    return fetch(
      `/api/core/gestioneconomica/transacciones/${eventType}/excel-upload/`,
      {
        method: 'POST',
        headers: API.getAuthFormHeaders(),
        body: inputFile,
      }
    ).then(API.handleBlobOnerror);
  };

  API.downloadEconomicaTemplate = function ({ eventType }) {
    return fetch(
      `/api/core/gestioneconomica/transacciones/${eventType}/template/`,
      {
        method: 'GET',
        headers: API.getAuthHeaders(),
      }
    ).then(API.handleBlob);
  };

  API.fieldsExportEconomicaEvent = function ({ queryKey }) {
    const [, { eventType }] = queryKey;
    return fetch(
      `/api/core/gestioneconomica/transacciones/${eventType}/export-fields/`,
      {
        method: 'GET',
        headers: API.getAuthHeaders(),
      }
    ).then(API.handleResponse);
  };

  API.exportEconomicaEvent = function ({ eventType, fields, params = null }) {
    const qp = API.makeQueryString(params);
    return fetch(
      `/api/core/gestioneconomica/transacciones/${eventType}/excel-download/?${qp}`,
      {
        method: 'POST',
        headers: API.getAuthHeaders(),
        body: JSON.stringify({ fields }),
      }
    ).then(API.handleBlob);
  };

  return API;
};

export default GestionEconomicaAPI;
