import React from 'react';
import { Space, Spin } from 'antd';
import Card from '../Card/Card';

import './KPI.scss';

const ContentKPI = ({
  description,
  descriptionComponent: Description,
  stylesKPI = { description: {}, value: {}, subvalue: {} },
  value,
  subvalue,
  className = '',
  align = 'center',
  loading,
}) => {
  const renderDescription = () => {
    if (!description && !Description) return null;
    if (Description) {
      return <Description />;
    } else {
      return (
        <span className="finca-kpi-description" style={stylesKPI.description}>
          {description}
        </span>
      );
    }
  };

  if (loading) return <Spin />;

  return (
    <Space
      direction="vertical"
      align={align}
      className={`finca-kpi-content ${className}`}
      data-align={align}
    >
      <div>
        <span className="finca-kpi-value" style={stylesKPI.value}>
          {value}
        </span>
        <span className="finca-kpi-subvalue" style={stylesKPI.subvalue}>
          {subvalue}
        </span>
      </div>
      {renderDescription()}
    </Space>
  );
};

const KPI = ({
  height,
  title,
  withoutCard = false,
  cardClassname = '',
  justifyContent,
  tooltip,
  align = 'center',
  ...restProps
}) => {
  if (withoutCard) return <ContentKPI align={align} {...restProps} />;

  return (
    <Card
      title={title}
      style={{ height, justifyContent }}
      type="secondary"
      tooltip={tooltip}
    >
      <div className={`finca-kpi-card ${cardClassname}`} data-align={align}>
        <ContentKPI align={align} {...restProps} />
      </div>
    </Card>
  );
};

export default KPI;
