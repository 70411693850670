import React from 'react';
import { Form, Row, Col, DatePicker, InputNumber } from 'antd';
import { GENERAL } from 'modules/core/constants';
import { withAPI } from 'modules/api';

const { Item } = Form;
const { RangePicker } = DatePicker;

const PARAMS = {
  FECHA_EVENTO: `${GENERAL.FILTER_RANGE}fecha`,
  MONTO_FROM: 'from_monto',
  MONTO_TO: 'to_monto',
};

const tags = {
  [PARAMS.FECHA_EVENTO]: 'Fecha transacción',
  [PARAMS.MONTO_FROM]: 'Valor desde',
  [PARAMS.MONTO_TO]: 'Valor hasta',
};

const FormFields = () => {
  return (
    <Row gutter={8}>
      <Col span={24}>
        <Item label={tags[PARAMS.FECHA_EVENTO]} name={PARAMS.FECHA_EVENTO}>
          <RangePicker style={{ width: '100%' }} />
        </Item>
      </Col>
      <Col span={12}>
        <Item label={tags[PARAMS.MONTO_FROM]} name={PARAMS.MONTO_FROM}>
          <InputNumber style={{ width: '100%' }} placeholder="Ingresar monto" />
        </Item>
      </Col>
      <Col span={12}>
        <Item label={tags[PARAMS.MONTO_TO]} name={PARAMS.MONTO_TO}>
          <InputNumber style={{ width: '100%' }} placeholder="Ingresar monto" />
        </Item>
      </Col>
    </Row>
  );
};

const filterComponent = {
  tags,
  FormFields: withAPI(FormFields),
};

export default filterComponent;
