import React from 'react';
import { useQuery } from 'react-query';
import { withAPI } from 'modules/api';
import { Col, Row } from 'antd';
import { usePaginationWithFilters } from 'modules/core/hooks';
import { GENERAL, PRODUCTIVA } from 'modules/core/constants';
import { Card, Charts } from 'modules/core/components';
import FilterSider, {
  UnitFilter,
  getRecordFilters,
} from 'modules/core/components/Filter';

const HEIGHT_ROW_1 = 250;
const HEIGHT_ROW_2 = 550;

const PreniezAnalytics = ({ api, isActive }) => {
  const { filters } = usePaginationWithFilters();
  const { data: preniezPorcentaje, isLoading: isLoadingPreniezPorcentaje } =
    useQuery(
      ['Analytics::fetchPreniezPorcentaje', { params: filters.get() }],
      api.fetchPreniezPorcentaje,
      { enabled: isActive }
    );
  const { data: preniezTasa, isLoading: isLoadingPreniezTasa } = useQuery(
    ['Analytics::fetchPreniezTasa', { params: filters.get() }],
    api.fetchPreniezTasa,
    { enabled: isActive }
  );
  const { data: preniezByResultado, isLoading: isLoadingPreniezByResultado } =
    useQuery(
      ['Analytics::fetchPreniezByResultado', { params: filters.get() }],
      api.fetchPreniezByResultado,
      { enabled: isActive }
    );
  const { data: preniezEvolucion, isLoading: isLoadingPreniezEvolucion } =
    useQuery(
      ['Analytics::fetchPreniezEvolucion', { params: filters.get() }],
      api.fetchPreniezEvolucion,
      { enabled: isActive }
    );

  return (
    <>
      <Row gutter={[24, 32]} className="data-row">
        <Col span={24}>
          <FilterSider
            showDrawer
            showCurrentYear
            showGlobalSearch={false}
            filters={[UnitFilter, ...getRecordFilters(PRODUCTIVA.PRENIEZ)]}
            onChange={filters.set}
          />
        </Col>
        <Col span={12}>
          <Charts.KPI
            height={HEIGHT_ROW_1}
            loading={isLoadingPreniezPorcentaje}
            title={preniezPorcentaje?.titulo}
            value={GENERAL.roundPercentage(preniezPorcentaje?.data)}
            tooltip={preniezPorcentaje?.calculo}
            stylesKPI={{ value: { fontSize: 60 } }}
          />
        </Col>
        <Col span={12}>
          <Charts.KPI
            height={HEIGHT_ROW_1}
            loading={isLoadingPreniezTasa}
            title={preniezTasa?.titulo}
            value={GENERAL.roundPercentage(preniezTasa?.data)}
            tooltip={preniezTasa?.calculo}
            stylesKPI={{ value: { fontSize: 60 } }}
          />
        </Col>
      </Row>
      <Row gutter={[24, 32]} className="data-row">
        <Col span={12}>
          <Card
            type="secondary"
            loading={isLoadingPreniezByResultado}
            title={preniezByResultado?.titulo}
            tooltip={preniezByResultado?.calculo}
            style={{ height: HEIGHT_ROW_2 }}
          >
            <Charts.Pie
              data={GENERAL.formatPieChartData(preniezByResultado?.data)}
              innerRadius={0.5}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card
            type="secondary"
            loading={isLoadingPreniezEvolucion}
            title={preniezEvolucion?.titulo}
            tooltip={preniezEvolucion?.calculo}
            style={{ height: HEIGHT_ROW_2 }}
          >
            <Charts.Bar
              data={preniezEvolucion?.data}
              indexBy="label"
              keys={['Preñadas', 'Vacias']}
              defaultTooltip={true}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default withAPI(PreniezAnalytics);
