import React from 'react';
import { Badge, Typography } from 'antd';

const { Text } = Typography;

const BadgeList = ({
  data = [],
  onClick = () => {},
  trimBadgeText = true,
  renderBadgeText = () => {},
  renderBadgeColor = () => {},
}) => {
  return (
    <ul
      style={{
        margin: 0,
        padding: 0,
        listStyle: 'none',
      }}
    >
      {data.map((el) => {
        const badgeText = renderBadgeText(el);

        return (
          <li
            style={{ display: 'flex' }}
            key={el?.id}
            onClick={() => onClick(el)}
            title={badgeText}
          >
            <Badge style={{ marginRight: 8 }} color={renderBadgeColor(el)} />
            <Text ellipsis={trimBadgeText ? { tooltip: badgeText } : false}>
              {badgeText}
            </Text>
          </li>
        );
      })}
    </ul>
  );
};

export default BadgeList;
