import { GENERAL, NUTRICIONAL } from 'modules/core/constants';

const defaultColumns = [
  {
    title: 'Fecha',
    dataIndex: 'fecha',
    sorter: true,
    render: (d) => GENERAL.dateToFront(d),
  },
  {
    title: 'RP',
    dataIndex: 'unidad',
    sorter: true,
  },
];

const actionsColumn = (renderActions) => [
  {
    title: 'Acciones',
    align: 'right ',
    render: (_, record) => renderActions?.(record),
  },
];

const COLUMNS = {};

COLUMNS[NUTRICIONAL.DIETA] = (actions, resume) =>
  defaultColumns
    .concat([
      {
        title: 'Disponibilidad de pasto',
        dataIndex: 'disponibilidad_pasto',
        sorter: true,
        render: (r) => r[0].toUpperCase() + r.slice(1).toLowerCase(),
      },
      {
        title: 'Suplemento',
        dataIndex: 'suplemento',
        sorter: true,
      },
      {
        title: 'Cantidad suplemento',
        dataIndex: 'cantidad_suplemento',
        sorter: true,
      },
      {
        title: 'Peso inicial',
        dataIndex: 'peso_inicio',
        sorter: true,
      },
      {
        title: 'Peso final',
        dataIndex: 'peso_fin',
        sorter: true,
      },
      {
        title: 'Ganancia diaria',
        dataIndex: 'ganancia_diaria',
        sorter: true,
        render: (g) => Math.round(g * 100) / 100,
      },
      {
        title: 'Estado',
        dataIndex: 'estado',
      },
      {
        align: 'right',
        render: (_, d) => (d.fecha_fin ? null : resume(d)),
      },
    ])
    .concat(actionsColumn(actions));

export { COLUMNS };
