import ImgNovillo from 'assets/units/bovinos/novillo.png';
import ImgTernera from 'assets/units/bovinos/ternera.png';
import ImgTernero from 'assets/units/bovinos/ternero.png';
import ImgToro from 'assets/units/bovinos/toro.png';
import ImgVaca from 'assets/units/bovinos/vaca.png';
import ImgVaquillona from 'assets/units/bovinos/vaquillona.png';
import ImgCarnero from 'assets/units/ovinos/carnero.png';
import GENERAL from './general';

const NAME_SPACE = 'unidades';

const BOVINO = 'bovino';
const OVINO = 'ovino';
const ESPECIES = { BOVINO, OVINO };
const ESPECIES_FORMATTED = {
  [BOVINO]: 'Bovino',
  [OVINO]: 'Ovino',
};
const ESPECIES_LIST = Object.values(ESPECIES);

const HEMBRAS = 'hembra';
const MACHOS = 'macho';
const UNIDAD = 'unidad';
const TYPES = { HEMBRAS, MACHOS };
const TYPES_LIST = Object.values(TYPES);
const TYPES_FORMATTED = {
  [HEMBRAS]: 'Hembra',
  [MACHOS]: 'Macho',
};

const STOCK = {
  ACTIVAS: 'activas',
  INACTIVAS: 'inactivas',
};

const NO_APTA = 'no_apta_reproduccion';
const APTA = 'apta_reproduccion';
const ASIGNADA = 'asignada';
const SERVICIO = 'servicio';
const SERVICIOCOLECTIVO = 'servicio_colectivo';
const TRANSFERENCIAEMBRIONARIA = 'transferencia_embrionaria';
const LACTANTE = 'lactante';
const PRENIADA = 'preniada';
const ESTADOS = {
  NO_APTA,
  APTA,
  ASIGNADA,
  SERVICIO,
  SERVICIOCOLECTIVO,
  TRANSFERENCIAEMBRIONARIA,
  LACTANTE,
  PRENIADA,
};
const ESTADOS_LIST = Object.values(ESTADOS);
const ESTADOS_FORMATTED = {
  [NO_APTA]: 'No apta reproducción',
  [APTA]: 'Apta reproducción',
  [ASIGNADA]: 'Asignada',
  [LACTANTE]: 'Lactante',
  [PRENIADA]: 'Preñada',
  [SERVICIO]: 'En servicio',
  [SERVICIOCOLECTIVO]: 'En servicio colectivo',
  [TRANSFERENCIAEMBRIONARIA]: 'En transferencia embrionaria',
};

// ++ BOVINO ++
const TERNERA = 'ternera';
const RECRIAH = 'recria_hembra';
const VAQUILLONA = 'vaquillona';
const VACA = 'vaca';
const TERNERO = 'ternero';
const RECRIAM = 'recria_macho';
const NOVILLO = 'novillo';
const TORO_REP = 'toro_reproductor';
const TORO_DESC = 'toro_descarte';

const BOVINO_CATEGORIES = {
  [HEMBRAS]: { TERNERA, RECRIAH, VAQUILLONA, VACA },
  [MACHOS]: { TERNERO, RECRIAM, NOVILLO, TORO_REP, TORO_DESC },
};
const BOVINO_CATEGORIES_LIST = {
  [HEMBRAS]: Object.values(BOVINO_CATEGORIES[HEMBRAS]),
  [MACHOS]: Object.values(BOVINO_CATEGORIES[MACHOS]),
};

const BOVINO_CATEGORIES_FORMATTED = {
  [TERNERA]: 'Ternera',
  [RECRIAH]: 'Recría Hembra',
  [VAQUILLONA]: 'Vaquillona',
  [VACA]: 'Vaca',
  [TERNERO]: 'Ternero',
  [RECRIAM]: 'Recría Macho',
  [NOVILLO]: 'Novillo',
  [TORO_REP]: 'Toro Reproductor',
  [TORO_DESC]: 'Toro de Descarte',
};

const BOVINO_IMAGES = {
  [TERNERA]: ImgTernera,
  [RECRIAH]: ImgTernera,
  [VAQUILLONA]: ImgVaquillona,
  [VACA]: ImgVaca,
  [TERNERO]: ImgTernero,
  [RECRIAM]: ImgTernero,
  [NOVILLO]: ImgNovillo,
  [TORO_REP]: ImgToro,
  [TORO_DESC]: ImgToro,
};
// ++ FIN BOVINO ++

// ++ OVINO ++
const BORREGA = 'borrega';
const CORDERA = 'cordera';
const OVEJA = 'oveja';
const BORREGO = 'borrego';
const CORDERO = 'cordero';
const CARNERO = 'carnero';
const CARNERO_DESCARTE = 'carnero_descarte';
const CAPON = 'capon';

const OVINO_CATEGORIES = {
  [HEMBRAS]: { CORDERA, BORREGA, OVEJA },
  [MACHOS]: { CORDERO, BORREGO, CARNERO, CARNERO_DESCARTE, CAPON },
};
const OVINO_CATEGORIES_LIST = {
  [HEMBRAS]: Object.values(OVINO_CATEGORIES[HEMBRAS]),
  [MACHOS]: Object.values(OVINO_CATEGORIES[MACHOS]),
};
const OVINO_CATEGORIES_FORMATTED = {
  [CORDERA]: 'Cordera',
  [BORREGA]: 'Borrega',
  [OVEJA]: 'Oveja',
  [CORDERO]: 'Cordero',
  [BORREGO]: 'Borrego',
  [CARNERO]: 'Carnero',
  [CARNERO_DESCARTE]: 'Carnero de descarte',
  [CAPON]: 'Capón',
};

const OVINO_IMAGES = {
  [CORDERA]: ImgCarnero,
  [BORREGA]: ImgCarnero,
  [OVEJA]: ImgCarnero,
  [CORDERO]: ImgCarnero,
  [BORREGO]: ImgCarnero,
  [CARNERO]: ImgCarnero,
  [CARNERO_DESCARTE]: ImgCarnero,
  [CAPON]: ImgCarnero,
};
// ++ FIN OVINO ++

const CATEGORIES_LIST = {
  [BOVINO]: BOVINO_CATEGORIES_LIST,
  [OVINO]: OVINO_CATEGORIES_LIST,
};

const getType = (species, category) => {
  if (CATEGORIES_LIST[species][HEMBRAS].includes(category)) return HEMBRAS;
  if (CATEGORIES_LIST[species][MACHOS].includes(category)) return MACHOS;
  return undefined;
};

const CATEGORIES_FORMATTED = {
  ...BOVINO_CATEGORIES_FORMATTED,
  ...OVINO_CATEGORIES_FORMATTED,
};

const CRIA = {
  [BOVINO]: {
    [HEMBRAS]: [TERNERA],
    [MACHOS]: [TERNERO],
  },
  [OVINO]: {
    [HEMBRAS]: [BORREGA],
    [MACHOS]: [BORREGO],
  },
};

const SELECCION_HEMBRAS = {
  [BOVINO]: [VAQUILLONA, VACA],
  [OVINO]: [OVEJA],
};

const SELECCION_MACHOS = {
  [BOVINO]: [NOVILLO, TORO_REP, TORO_DESC],
  [OVINO]: [CAPON, CARNERO, CARNERO_DESCARTE],
};

const IMAGES = {
  ...BOVINO_IMAGES,
  ...OVINO_IMAGES,
};

const unitOptionsByType = (species, optionsList = CATEGORIES_LIST) => {
  return [
    {
      label: TYPES_FORMATTED[HEMBRAS],
      options: GENERAL.arrayToOptions(
        optionsList[species][HEMBRAS],
        (t) => CATEGORIES_FORMATTED[t]
      ),
    },
    {
      label: TYPES_FORMATTED[MACHOS],
      options: GENERAL.arrayToOptions(
        optionsList[species][MACHOS],
        (t) => CATEGORIES_FORMATTED[t]
      ),
    },
  ];
};

const formatUnit = (unit = {}, masters = {}) => {
  const renderSimple = (field) => unit[field] || '-';
  const renderPeso = (field) => `${renderSimple(field)} kg`;
  const renderBool = (field) => (unit[field] ? 'Sí' : 'No');
  const renderDate = (field) => GENERAL.dateToFront(unit[field]) || '-';
  const renderMaster = (field) => {
    let fieldNameCleanead;
    if (field === 'propietario') {
      // Use the same master
      fieldNameCleanead = 'criador';
    } else {
      // This is for ubicaciongeografica vs ubicacion_geografica
      fieldNameCleanead = field.replace('_', '');
    }
    return (
      GENERAL.optionToLabel(masters[fieldNameCleanead], unit[field]) || '-'
    );
  };
  const renderHeaderSubtitle = () => {
    return `${unit?.apodo ? `${renderSimple('apodo')} · ` : ''}${
      CATEGORIES_FORMATTED[unit?.categoria]
    }`;
  };
  const renderHeaderPill2 = () => {
    const CE = unit?.datoadicional.find(
      (el) => el.label === 'Circunferencia Escrotal'
    )?.value;
    if (unit?.sexo === HEMBRAS)
      return ESTADOS_FORMATTED[unit?.estado]?.toUpperCase() || '-';
    return `CE ${CE || '-'}`;
  };

  const header = {
    image: IMAGES[unit.categoria],
    title: renderSimple('rp'),
    subtitle: renderHeaderSubtitle(),
    pill1: renderMaster('raza')?.toUpperCase(),
    pill2: renderHeaderPill2(),
  };

  const onlyFemaleInfo = [
    { title: 'Hijos', value: renderSimple('hijos') },
    { title: 'Lactante', value: renderBool('lactante') },
    { title: 'Cantidad de partos', value: renderSimple('cantidad_partos') },
    { title: 'Días de abierta', value: renderSimple('dias_abierta') },
    { title: 'CUT', value: renderBool('cut') },
  ];

  const info = [
    // ++ col1 ++
    [
      [
        // row1
        { title: 'ID electrónico', value: renderSimple('id_electronico') },
        { title: 'Nro. trazabilidad', value: renderSimple('nro_trazabilidad') },
        { title: 'HBA', value: renderSimple('hba') },
        {
          title: 'Identificación asociación',
          value: renderSimple('id_asociacion'),
        },
        { title: 'Nombre', value: renderSimple('nombre') },
      ],
      [
        // row2
        { title: 'Ubicación', value: renderMaster('ubicacion_geografica') },
        { title: 'Rodeo', value: renderMaster('rodeo') },
        { title: 'Origen', value: renderMaster('origen') },
        { title: 'Criador', value: renderMaster('criador') },
        { title: 'Propietario', value: renderMaster('propietario') },
      ],
      [
        // row3
        { title: 'Raza', value: renderMaster('raza') },
        { title: 'Registro', value: renderMaster('registro') },
        { title: 'Color', value: renderMaster('color') },
      ],
    ],
    // ++ col2 ++
    [
      [
        // row1
        { title: 'RP Madre', value: renderSimple('rp_madre') },
        {
          title: 'RP Padre',
          value: `${renderSimple('rp_padre')} ${
            unit?.apodo_padre ? renderSimple('apodo_padre') : ''
          }`,
        },
        {
          title: 'RP Abuelo Materno',
          value: renderSimple('rp_apodo_abuelo_materno'),
        },
        {
          title: 'RP Abuelo Paterno',
          value: renderSimple('rp_apodo_abuelo_paterno'),
        },
      ],
      [
        // row2:
        { title: 'Cantidad de hijos', value: renderSimple('cantidad_hijos') },
        ...(unit?.sexo === HEMBRAS ? onlyFemaleInfo : []),
        { title: 'Objetivo', value: renderMaster('objetivo') },
        { title: 'Calificación', value: renderSimple('calificacion') },
      ],
    ],
    // ++ col3 ++
    [
      [
        // row1
        { title: 'Peso actual', value: renderSimple('peso_actual') },
        { title: 'Peso al nacer', value: renderPeso('peso_nacimiento') },
        { title: 'Peso al destete', value: renderPeso('peso_destete') },
        { title: 'Peso a los 18 meses', value: renderPeso('peso_18_meses') },
        {
          title: 'Condición corporal',
          value: renderSimple('condicion_corporal'),
        },
      ],
      [
        // row2
        { title: 'Fecha de nacimiento', value: renderDate('fecha_nacimiento') },
        { title: 'Edad', value: `${renderSimple('edad')} años` },
        { title: 'Fecha de alta', value: renderDate('created_at') },
      ],
    ],
  ];

  return { header, info };
};

const formatUnitTree = (data) => {
  if (!data) return null;
  return {
    unit: {
      value: data.value.title || '-',
      subvalue: data.value.items[0].text || '',
    },
    parent0: {
      value: data.children[0]?.value.title || '-',
      subvalue: data.children[0]?.value.items[0].text || '',
    },
    gparent00: {
      value: data.children[0]?.children[0]?.value.title || '-',
      subvalue: data.children[0]?.children[0]?.value.items[0].text || '',
    },
    gparent01: {
      value: data.children[0]?.children[1]?.value.title || '-',
      subvalue: data.children[0]?.children[1]?.value.items[0].text || '',
    },
    parent1: {
      value: data.children[1]?.value.title || '-',
      subvalue: data.children[1]?.value.items[0].text || '',
    },
    gparent10: {
      value: data.children[1]?.children[0]?.value.title || '-',
      subvalue: data.children[1]?.children[0]?.value.items[0].text || '',
    },
    gparent11: {
      value: data.children[1]?.children[1]?.value.title || '-',
      subvalue: data.children[1]?.children[1]?.value.items[0].text || '',
    },
  };
};

const isFromType = (unitType, type) =>
  Array.isArray(unitType) ? unitType.includes(type) : unitType === type;

const ACTIONS = {
  DETAIL: 'unit_action_detail',
  EDIT: 'unit_action_edit',
  REMOVE: 'unit_action_remove',
  DELETE: 'unit_action_delete',
  RECORDS: 'unit_action_records',
};

const UNITS = {
  NAME_SPACE,
  BOVINO,
  OVINO,
  ESPECIES,
  ESPECIES_FORMATTED,
  ESPECIES_LIST,
  MACHOS,
  HEMBRAS,
  UNIDAD,
  TYPES,
  TYPES_LIST,
  TYPES_FORMATTED,
  STOCK,
  ESTADOS,
  ESTADOS_LIST,
  ESTADOS_FORMATTED,
  CATEGORIES_LIST,
  CATEGORIES_FORMATTED,
  CRIA,
  SELECCION_HEMBRAS,
  SELECCION_MACHOS,
  ACTIONS,
  getType,
  unitOptionsByType,
  formatUnit,
  formatUnitTree,
  isFromType,
};

export default UNITS;
