import React from 'react';
import { View, StyleSheet, Image } from '@react-pdf/renderer';
import logoFooter from 'assets/report/logo-footer.png';
import { UNITS } from 'modules/core/constants';

const styles = StyleSheet.create({
  footer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    padding: 5,
  },
  footerBovino: {
    backgroundColor: '#126234', // --primary-color
  },
  footerOvino: {
    backgroundColor: '#0060b5', // --primary-color
  },
  footerImage: {
    width: 67,
    height: 10,
  },
});

const PageFooter = ({ fixed = true, userSpecies }) => {
  return (
    <View
      style={[
        styles.footer,
        userSpecies === UNITS.OVINO ? styles.footerOvino : styles.footerBovino,
      ]}
      fixed={fixed}
    >
      <Image src={logoFooter} style={styles.footerImage} />
    </View>
  );
};

export default PageFooter;
