import React from 'react';
import { ResponsiveTreeMap } from '@nivo/treemap';

const TreeMapChart = ({
  data = [],
  identity,
  value,
  keys = [],
  indexBy = '',
  colors = { scheme: 'greens' },
  valueFormat = '>-.2f',
  ...restProps
}) => {
  return (
    <ResponsiveTreeMap
      data={data}
      colors={colors}
      identity={identity}
      value={value}
      valueFormat={valueFormat}
      margin={{ top: 10, bottom: 40, right: 10, left: 10 }}
      labelSkipSize={12}
      labelTextColor={{
        from: 'color',
        modifiers: [['darker', 1.2]],
      }}
      parentLabelPosition="left"
      parentLabelTextColor={{
        from: 'color',
        modifiers: [['darker', 2]],
      }}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 0.1]],
      }}
      {...restProps}
    />
  );
};

export default TreeMapChart;
