import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { withAPI } from 'modules/api';
import { Col, Row, Table, DatePicker } from 'antd';
import { GENERAL } from 'modules/core/constants';
import { Card, DashboardList } from 'modules/core/components';

import './RendimientoAnalytics.scss';

const { RangePicker } = DatePicker;
const HEIGHT_ROW_1 = 350;
const HEIGHT_ROW_2 = 500;

const BAJAS = 'bajas';

const RendimientoAnalytics = ({ api, isActive }) => {
  const [selectedDates, setSelectedDates] = useState(null);
  const {
    data: resumenProductivoKPI,
    isLoading: isLoadingResumenProductivoKPI,
  } = useQuery(
    ['Analytics::fetchResumenProductivoKPI', { params: { ...selectedDates } }],
    api.fetchResumenProductivoKPI,
    { enabled: isActive }
  );
  const { data: mermaProduccionKPI, isLoading: isLoadingMermaProduccionKPI } =
    useQuery(
      ['Analytics::fetchMermaProduccionKPI', { params: { ...selectedDates } }],
      api.fetchMermaProduccionKPI,
      { enabled: isActive }
    );
  const { data: resumenStockKPI, isLoading: isLoadingResumenStockKPI } =
    useQuery(
      ['Analytics::fetchResumenStockKPI', { params: { ...selectedDates } }],
      api.fetchResumenStockKPI,
      { enabled: isActive }
    );
  const { data: produccionKgKPI, isLoading: isLoadingProduccionKgKPI } =
    useQuery(
      ['Analytics::fetchProduccionKgKPI', { params: { ...selectedDates } }],
      api.fetchProduccionKgKPI,
      { enabled: isActive }
    );

  const valueProps = { align: 'right', width: 200 };
  const renderValueCell = (v, r, type) => {
    switch (r.nombre) {
      case 'TOTAL':
        return r[`${type}_total`];
      case 'STOCK FINAL':
        return type === BAJAS && r.stock_final;
      default:
        return v;
    }
  };
  const columns = [
    { dataIndex: 'nombre', width: '50%' },
    {
      title: 'Altas',
      align: 'center',
      children: [
        { title: 'Hembras', dataIndex: 'altas_hembra', ...valueProps },
        {
          title: 'Machos',
          dataIndex: 'altas_macho',
          ...valueProps,
          render: (v, r) => renderValueCell(v, r, 'altas'),
        },
      ],
    },
    {
      title: 'Bajas',
      children: [
        { title: 'Hembras', dataIndex: 'bajas_hembra', ...valueProps },
        {
          title: 'Machos',
          dataIndex: 'bajas_macho',
          ...valueProps,
          render: (v, r) => renderValueCell(v, r, BAJAS),
        },
      ],
    },
  ];

  const onChangeDates = (dates) => {
    if (dates) {
      setSelectedDates({
        from_fecha: GENERAL.dateToBack(dates[0]),
        to_fecha: GENERAL.dateToBack(dates[1]),
      });
    } else setSelectedDates(null);
  };

  return (
    <div className="rendimiento-analytics">
      <Row gutter={[24, 32]} className="data-row">
        <Col span={24}>
          <RangePicker onChange={onChangeDates} />
        </Col>
      </Row>
      <Row gutter={[24, 32]} className="data-row">
        <Col span={12}>
          <Card
            title={resumenProductivoKPI?.titulo}
            loading={isLoadingResumenProductivoKPI}
            type="secondary"
            style={{ height: HEIGHT_ROW_1 }}
          >
            <DashboardList dataSource={resumenProductivoKPI?.data} />
          </Card>
        </Col>
        <Col span={12}>
          <Card
            title={mermaProduccionKPI?.titulo}
            loading={isLoadingMermaProduccionKPI}
            type="secondary"
            style={{ height: HEIGHT_ROW_1 }}
          >
            <DashboardList dataSource={mermaProduccionKPI?.data} />
          </Card>
        </Col>
      </Row>
      <Row gutter={[24, 32]} className="data-row">
        <Col span={12}>
          <Card
            title={produccionKgKPI?.titulo}
            loading={isLoadingProduccionKgKPI}
            type="secondary"
            style={{ height: HEIGHT_ROW_2 }}
          >
            <DashboardList dataSource={produccionKgKPI?.data} />
          </Card>
        </Col>
        <Col span={12}>
          <Card
            type="secondary"
            title={resumenStockKPI?.titulo}
            style={{ height: HEIGHT_ROW_2 }}
          >
            <Table
              rowKey="id"
              columns={columns}
              dataSource={resumenStockKPI?.data}
              bordered
              loading={isLoadingResumenStockKPI}
              size="middle"
              pagination={false}
              rowClassName={(record) =>
                record?.is_total || record?.is_title ? 'totalizer-row' : ''
              }
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default withAPI(RendimientoAnalytics);
