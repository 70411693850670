import { GENERAL, PRODUCTIVA } from 'modules/core/constants';

const defaultColumns = [];

const actionsColumn = (renderActions) => [
  {
    title: 'Acciones',
    align: 'right ',
    render: (_, record) => renderActions?.(record),
  },
];

const COLUMNS = {};

COLUMNS[PRODUCTIVA.EMBRION] = (actions) =>
  defaultColumns
    .concat([
      {
        title: 'Fecha',
        dataIndex: 'fecha',
        sorter: true,
        render: (d) => GENERAL.dateToFront(d),
      },
      {
        title: 'Cantidad',
        dataIndex: 'cantidad',
        sorter: true,
      },
      {
        title: 'Macho',
        dataIndex: ['embrion', 'macho'],
        key: 'embrion__macho',
        sorter: true,
      },
      {
        title: 'Hembra',
        dataIndex: ['embrion', 'hembra'],
        key: 'embrion__hembra',
        sorter: true,
      },
      {
        title: 'Tipo',
        dataIndex: 'tipo_embrion_value',
      },
      {
        title: 'Técnica de obtención',
        dataIndex: 'tecnica_obtencion_value',
      },
      {
        title: 'Responsable',
        dataIndex: 'responsable_value',
        key: 'responsable__nombre',
        sorter: true,
      },
    ])
    .concat(actionsColumn(actions));

COLUMNS[PRODUCTIVA.SEMEN] = (actions) =>
  defaultColumns
    .concat([
      {
        title: 'Fecha',
        dataIndex: 'fecha',
        sorter: true,
        render: (d) => GENERAL.dateToFront(d),
      },
      {
        title: 'Cantidad',
        dataIndex: 'cantidad',
        sorter: true,
      },
      {
        title: 'Macho',
        dataIndex: ['semen', 'macho'],
        key: 'semen__macho',
        sorter: true,
      },
    ])
    .concat(actionsColumn(actions));

export { COLUMNS };
