import React from 'react';
import { Form, Row, Col, Collapse, InputNumber, DatePicker } from 'antd';
import { GENERAL } from 'modules/core/constants';
import { withAPI } from 'modules/api';

const { Panel } = Collapse;
const { Item } = Form;
const { RangePicker } = DatePicker;

const PARAMS = {
  FECHA_ADQUISICION: `${GENERAL.FILTER_RANGE}fecha_adquisicion`,
  ADQUISICION_CANTIDAD_FROM: 'from_cantidad_adquisicion',
  ADQUISICION_CANTIDAD_TO: 'to_cantidad_adquisicion',
};

const tags = {
  [PARAMS.FECHA_ADQUISICION]: 'Fecha adquisición',
  [PARAMS.ADQUISICION_CANTIDAD_FROM]: 'Cantidad desde',
  [PARAMS.ADQUISICION_CANTIDAD_TO]: 'Cantidada hasta',
};

const FormFields = () => {
  return (
    <Row gutter={[0]}>
      <Col span={24}>
        <Collapse ghost>
          <Panel header="Adquisición" key="adquisicion">
            <Row gutter={8}>
              <Col span={24}>
                <Item
                  label={tags[PARAMS.FECHA_ADQUISICION]}
                  name={PARAMS.FECHA_ADQUISICION}
                >
                  <RangePicker style={{ width: '100%' }} />
                </Item>
              </Col>
              <Col span={12}>
                <Item
                  label={tags[PARAMS.ADQUISICION_CANTIDAD_FROM]}
                  name={PARAMS.ADQUISICION_CANTIDAD_FROM}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    placeholder="Ingresar cantidad"
                  />
                </Item>
              </Col>
              <Col span={12}>
                <Item
                  label={tags[PARAMS.ADQUISICION_CANTIDAD_TO]}
                  name={PARAMS.ADQUISICION_CANTIDAD_TO}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    placeholder="Ingresar cantidad"
                  />
                </Item>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </Col>
    </Row>
  );
};

const filterComponent = {
  tags,
  FormFields: withAPI(FormFields),
};

export default filterComponent;
