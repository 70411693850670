import React from 'react';
import { Card } from 'modules/core/components';
import './AdminCards.scss';

const AdminCardContent = ({ quantity = '-', description = '-' }) => {
  const ws = description.split(' ');
  const lw = ws.pop();
  return (
    <div className="admin-card-text">
      <div className="admin-card-quantity">{quantity}</div>
      <div className="admin-card-description">
        {ws} <span className="admin-card-description-strong">{lw}</span>
      </div>
    </div>
  );
};

const AdminCards = ({ data: cards }) => {
  return (
    <div className="admin-cards-container">
      {cards?.map((c) => (
        <Card className="admin-card" key={c.titulo}>
          <AdminCardContent quantity={c.cantidad} description={c.titulo} />
        </Card>
      ))}
    </div>
  );
};

export default AdminCards;
