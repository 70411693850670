import React from 'react';
import _ from 'lodash';
import { Carousel, Col, Row, Spin } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Card, Charts } from 'modules/core/components';
import { UNITS } from 'modules/core/constants';
import './UnitsCards.scss';

const CARD_HEIGHT = 110;

const UnitsCards = ({ data, loading = false }) => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={8}>
        <Charts.KPI
          height={CARD_HEIGHT}
          align="start"
          description="Unidades"
          value={data?.total}
          loading={loading}
        />
      </Col>
      <Col span={8}>
        <Card style={{ height: CARD_HEIGHT }}>
          <UnitsCardCarousel cards={data?.origenes} loading={loading} />
        </Card>
      </Col>
      <Col span={8}>
        <Card style={{ height: CARD_HEIGHT }}>
          <UnitsCardCarousel
            cards={
              data &&
              _.mapKeys(data.categorias, (_, d) => [
                UNITS.CATEGORIES_FORMATTED[d],
              ])
            }
            loading={loading}
          />
        </Card>
      </Col>
    </Row>
  );
};

const UnitsCardCarousel = ({ cards, loading }) => {
  if (loading) return <Spin />;

  return (
    <Carousel
      className="units-card-carousel"
      slidesToShow={1}
      slidesToScroll={1}
      initialSlide={0}
      infinite={true}
      dots={false}
      arrows={true}
      nextArrow={<CarouselArrow icon={RightOutlined} />}
      prevArrow={<CarouselArrow icon={LeftOutlined} />}
    >
      {_.isEmpty(cards) ? (
        <Charts.KPI
          align="start"
          description="Unidades"
          value="-"
          withoutCard
        />
      ) : (
        _.map(cards, (q, d) => (
          <Charts.KPI
            key={d}
            className="unit-carousel-kpi"
            align="start"
            description={`Unidades ${d}`}
            value={q?.cantidad ?? q}
            loading={loading}
            withoutCard
          />
        ))
      )}
    </Carousel>
  );
};

const CarouselArrow = ({ icon: Icon, className, onClick }) => {
  return (
    <Icon
      className={`${className} units-card-carousel-arrow`}
      onClick={onClick}
    />
  );
};

export default UnitsCards;
