import React from 'react';
import { Drawer } from 'antd';
import { FileAddOutlined, EditOutlined } from '@ant-design/icons';
import { ModalTitle } from 'modules/core/components';
import EstablishmentFormAdd from './EstablishmentFormAdd/EstablishmentFormAdd';
import './EstablishmentDrawerAdd.scss';

const title = (editing) => {
  return editing ? (
    <ModalTitle icon={<EditOutlined />} title="Modificar establecimiento" />
  ) : (
    <ModalTitle icon={<FileAddOutlined />} title="Agregar establecimiento" />
  );
};

const EstablishmentDrawerAdd = ({
  editEst = null,
  isEdit = false,
  open,
  close,
  success,
}) => {
  return (
    <Drawer
      title={title(isEdit)}
      forceRender
      className="est-add-drawer"
      placement="right"
      width="40%"
      onClose={close}
      open={open}
      closable={false}
    >
      <div className="est-add-form-wrapper">
        <EstablishmentFormAdd
          editEst={editEst}
          onCancel={close}
          onSuccess={success}
        />
      </div>
    </Drawer>
  );
};

export default EstablishmentDrawerAdd;
