import { MASTERS } from 'modules/core/constants';

const GestionSanitariaAPI = (API) => {
  API.fetchSanitariaMasters = function ({ queryKey }) {
    const [, { masterType, params }] = queryKey;
    const isEnum = (mType) =>
      [
        MASTERS.SANITARIA_TYPES.FORMA_ADMINISTRACION,
        MASTERS.SANITARIA_TYPES.RESULTADO_ANALISIS,
        MASTERS.SANITARIA_TYPES.ANOMALIA,
      ].includes(mType);
    const qp = API.makeQueryString(params);
    let url = `/api/core/gestionsanitaria/maestros`;
    url += isEnum(masterType) ? '/enum' : '';
    url += `/${masterType}/?${qp}`;
    return fetch(url, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.addSanitariaMaster = function ({ body = {}, masterType }) {
    return fetch(`/api/core/gestionsanitaria/maestros/${masterType}/`, {
      method: 'POST',
      headers: API.getAuthHeaders(),
      body: JSON.stringify(body),
    }).then(API.handleResponse);
  };

  API.editSanitariaMaster = function ({ id, body = {}, masterType }) {
    return fetch(`/api/core/gestionsanitaria/maestros/${masterType}/${id}/`, {
      method: 'PATCH',
      headers: API.getAuthHeaders(),
      body: JSON.stringify(body),
    }).then(API.handleResponse);
  };

  API.deleteSanitariaMaster = function ({ id, masterType }) {
    return fetch(`/api/core/gestionsanitaria/maestros/${masterType}/${id}/`, {
      method: 'DELETE',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.addSanitariaEvent = function ({ body = {}, eventType }) {
    return fetch(`/api/core/gestionsanitaria/eventos/${eventType}/`, {
      method: 'POST',
      headers: API.getAuthHeaders(),
      body: JSON.stringify(body),
    }).then(API.handleResponse);
  };

  API.editSanitariaEvent = function ({ id, body = {}, eventType }) {
    return fetch(`/api/core/gestionsanitaria/eventos/${eventType}/${id}/`, {
      method: 'PATCH',
      headers: API.getAuthHeaders(),
      body: JSON.stringify(body),
    }).then(API.handleResponse);
  };

  API.deleteSanitariaEvent = function ({ eventType, eventId }) {
    return fetch(
      `/api/core/gestionsanitaria/eventos/${eventType}/${eventId}/`,
      {
        method: 'DELETE',
        headers: API.getAuthHeaders(),
      }
    ).then(API.handleResponse);
  };

  API.deleteMultipleSanitariaEvent = function ({ eventType, event_ids }) {
    return fetch(
      `/api/core/gestionsanitaria/eventos/${eventType}/delete-multiple/`,
      {
        method: 'POST',
        headers: API.getAuthHeaders(),
        body: JSON.stringify({ event_ids }),
      }
    ).then(API.handleResponse);
  };

  API.fetchSanitariaEventsRecords = function ({ queryKey }) {
    const [, { recordType, params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`/api/core/gestionsanitaria/eventos/${recordType}/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };
  API.fetchSanitariaRecordsKPI = function ({ queryKey }) {
    const [, { recordType, params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(
      `/api/core/gestionsanitaria/analytics/kpis-${recordType}/?${qp}`,
      {
        method: 'GET',
        headers: API.getAuthHeaders(),
      }
    ).then(API.handleResponse);
  };

  API.uploadSanitariaEvent = function ({ eventType, inputFile }) {
    return fetch(
      `/api/core/gestionsanitaria/eventos/${eventType}/excel-upload/`,
      {
        method: 'POST',
        headers: API.getAuthFormHeaders(),
        body: inputFile,
      }
    ).then(API.handleBlobOnerror);
  };
  API.exportFieldsSanitariaEvent = function ({ queryKey }) {
    const [, { eventType }] = queryKey;
    return fetch(
      `/api/core/gestionsanitaria/eventos/${eventType}/export-fields/`,
      {
        method: 'GET',
        headers: API.getAuthHeaders(),
      }
    ).then(API.handleResponse);
  };
  API.exportSanitariaEvent = function ({ eventType, fields, params = null }) {
    const qp = API.makeQueryString(params);
    return fetch(
      `/api/core/gestionsanitaria/eventos/${eventType}/excel-download/?${qp}`,
      {
        method: 'POST',
        headers: API.getAuthHeaders(),
        body: JSON.stringify({ fields }),
      }
    ).then(API.handleBlob);
  };

  // ++ INSUMOS ++
  API.fetchSanitariaInsumo = function ({ queryKey }) {
    const [, { inputType, params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`/api/core/gestionsanitaria/insumos/${inputType}/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.addSanitariaInsumo = function ({ body, inputType }) {
    return fetch(`/api/core/gestionsanitaria/insumos/${inputType}/`, {
      method: 'POST',
      headers: API.getAuthHeaders(),
      body: JSON.stringify(body),
    }).then(API.handleResponse);
  };

  API.editSanitariaInsumo = function ({ id, body, inputType }) {
    return fetch(`/api/core/gestionsanitaria/insumos/${inputType}/${id}/`, {
      method: 'PATCH',
      headers: API.getAuthHeaders(),
      body: JSON.stringify(body),
    }).then(API.handleResponse);
  };

  API.deleteSanitariaInsumo = function ({ id, inputType }) {
    return fetch(`/api/core/gestionsanitaria/insumos/${inputType}/${id}/`, {
      method: 'DELETE',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.decrementSanitariaInsumo = function ({ id, inputType, amount }) {
    return fetch(
      `/api/core/gestionsanitaria/insumos/${inputType}/${id}/decrement/?cantidad=${amount}`,
      {
        method: 'POST',
        headers: API.getAuthHeaders(),
      }
    ).then(API.handleResponse);
  };

  API.fetchSanitariaAdquisicion = function ({ queryKey }) {
    const [, { inputType, params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(
      `/api/core/gestionsanitaria/insumos/adquisicion-${inputType}/?${qp}`,
      {
        method: 'GET',
        headers: API.getAuthHeaders(),
      }
    ).then(API.handleResponse);
  };

  API.addSanitariaAdquisicion = function ({ body, inputType }) {
    return fetch(
      `/api/core/gestionsanitaria/insumos/adquisicion-${inputType}/`,
      {
        method: 'POST',
        headers: API.getAuthHeaders(),
        body: JSON.stringify(body),
      }
    ).then(API.handleResponse);
  };

  API.editSanitariaAdquisicion = function ({ id, body, inputType }) {
    return fetch(
      `/api/core/gestionsanitaria/insumos/adquisicion-${inputType}/${id}/`,
      {
        method: 'PATCH',
        headers: API.getAuthHeaders(),
        body: JSON.stringify(body),
      }
    ).then(API.handleResponse);
  };

  API.deleteSanitariaAdquisicion = function ({ id, inputType }) {
    return fetch(
      `/api/core/gestionsanitaria/insumos/adquisicion-${inputType}/${id}/`,
      {
        method: 'DELETE',
        headers: API.getAuthHeaders(),
      }
    ).then(API.handleResponse);
  };

  return API;
};

export default GestionSanitariaAPI;
