import React, { useCallback, useEffect } from 'react';
import { useMutation } from 'react-query';
import { Col, Form, Modal, Row, Input } from 'antd';
import { UserAddOutlined, EditOutlined } from '@ant-design/icons';
import { withAPI } from 'modules/api';
import { displayInfoModal } from 'modules/core/components';
import './UserModalAdd.scss';

const UserModalAdd = ({ api, est, visible, close, success, user }) => {
  const { mutate: addUser, isLoading: addingUser } = useMutation(
    api.createUser
  );
  const { mutate: editUser, isLoading: editingUser } = useMutation(
    api.editUser
  );
  const [form] = Form.useForm();

  const clearForm = useCallback(() => {
    form.resetFields();
  }, [form]);
  useEffect(clearForm, [clearForm, visible]);

  const onAddUser = () => {
    form.validateFields().then((newUser) => {
      const onSuccess = () => {
        success();
        displayInfoModal({
          description: `Se agregó el usuario ${newUser.first_name} al establecimiento ${est.name}.`,
          showCheck: true,
        });
      };
      addUser({ user: { ...newUser, establecimiento: est.id } }, { onSuccess });
    });
  };

  const onEditUser = () => {
    form.validateFields().then((editedUser) => {
      const onSuccess = () => {
        success();
        displayInfoModal({
          description: `Se editó el usuario ${editedUser.first_name} al establecimiento ${est.name}.`,
          showCheck: true,
        });
      };
      editUser({ userId: user.id, user: editedUser }, { onSuccess });
    });
  };

  return (
    <Modal
      title={
        user ? (
          <>
            <EditOutlined /> {`Modificar usuario`}
          </>
        ) : (
          <>
            <UserAddOutlined /> {`Agregar usuario`}
          </>
        )
      }
      forceRender
      className="user-add-modal"
      cancelText="CANCELAR"
      okText={user ? 'MODIFICAR' : 'AGREGAR'}
      confirmLoading={addingUser || editingUser}
      open={visible}
      onCancel={close}
      onOk={user ? onEditUser : onAddUser}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={user}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="Nombre"
              name="first_name"
              rules={[{ required: true, message: 'Ingrese nombre' }]}
            >
              <Input className="user-add-form-input" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="E-Mail"
              name="email"
              rules={[{ required: true, message: 'Ingrese e-mail' }]}
            >
              <Input className="user-add-form-input" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default withAPI(UserModalAdd);
