import React from 'react';
import { List } from 'antd';
import { GENERAL } from 'modules/core/constants';

import './DashboardList.scss';

const DashboardList = ({
  dataSource = [],
  loading = false,
  headerValues = [],
  ...restProps
}) => {
  const renderListHeader = () => (
    <div className="dashboard-core-list-header">
      {headerValues.map((h, k) => (
        <span className="header" key={k}>
          {h}
        </span>
      ))}
    </div>
  );

  return (
    <>
      {headerValues.length > 0 && renderListHeader()}
      <List
        className="dashboard-core-list"
        dataSource={dataSource}
        loading={loading}
        {...restProps}
        renderItem={({ title, quantity, percentage, extra = null }) => {
          const isTotal = title?.toLocaleLowerCase() === 'total';
          const isTotalClassName = isTotal ? 'total-row' : '';

          return (
            <List.Item>
              <span className={`dashboard-core-title ${isTotalClassName}`}>
                {title}
              </span>
              <span className={`dashboard-core-quantity ${isTotalClassName}`}>
                {quantity}
              </span>
              {percentage !== undefined && (
                <span className={`percentage-text ${isTotalClassName}`}>
                  {isTotal ? '' : GENERAL.roundPercentage(percentage)}
                </span>
              )}
              {extra !== null && (
                <span className={`dashboard-core-extra ${isTotalClassName}`}>
                  {extra}
                </span>
              )}
            </List.Item>
          );
        }}
      />
    </>
  );
};

export default DashboardList;
