import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { Row, Col, Button, message as antMessage } from 'antd';
import { withAPI } from 'modules/api';
import { ScreenHeader, Table } from 'modules/core/components';
import { GENERAL } from 'modules/core/constants';

const Notifications = ({ api }) => {
  const { data, isLoading, refetch } = useQuery(
    ['Notifications::fetchNotifications'],
    api.fetchNotifications,
    {
      refetchInterval: 600000, // refetch interval 10 Min
    }
  );

  const { mutate: markAsReadNotification } = useMutation(
    api.markAsReadNotification
  );

  const onMarkAsRead = (id) => {
    const onError = ({ message, description }) => {
      if (message === '400') antMessage.error(description, 5);
    };
    markAsReadNotification({ id }, { onSuccess: refetch, onError });
  };

  const columns = [
    {
      dataIndex: 'fecha',
      title: 'Fecha y hora',
      render: (d, r) => {
        const value = GENERAL.dateToFront(d, true);
        return !r.leida ? <b>{value}</b> : value;
      },
    },
    {
      dataIndex: 'contenido',
      title: 'Descripción',
      width: '75%',
      render: (v, r) => (!r.leida ? <b>{v}</b> : v),
    },
    {
      dataIndex: 'action',
      title: 'Acción',
      render: (_, r) =>
        !r.leida && (
          <Button type="primary" onClick={() => onMarkAsRead(r?.id)}>
            Marcar como leida
          </Button>
        ),
    },
  ];

  return (
    <Row style={{ padding: '3%' }} gutter={[0, 24]}>
      <Col span={24}>
        <ScreenHeader
          title="Notificaciones"
          description="Visualizá las notificaciones relacionadas a tu establecimiento"
        />
      </Col>
      <Col span={24}>
        <Table
          rowKey="id"
          dataSource={data?.results}
          loading={isLoading}
          columns={columns}
        />
      </Col>
    </Row>
  );
};

export default withAPI(Notifications);
