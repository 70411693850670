import React from 'react';
import _ from 'lodash';
import { useQuery } from 'react-query';
import { withAPI } from 'modules/api';
import { Row, Col } from 'antd';
import { UNITS } from 'modules/core/constants';
import { Card, DashboardList } from 'modules/core/components';

const UnitsCategoryCard = ({ api }) => {
  const { data: categoriasMale, isLoading: isLoadingMales } = useQuery(
    ['Units::fetchKPIUnits', { params: { sexo: UNITS.MACHOS } }],
    api.fetchKPIUnits
  );
  const { data: categoriasFemale, isLoading: isLoadingFemales } = useQuery(
    ['Units::fetchKPIUnits', { params: { sexo: UNITS.HEMBRAS } }],
    api.fetchKPIUnits
  );

  const formatData = (dataToFormat) => {
    let categories = _.map(dataToFormat.categorias, (catInfo, catName) => {
      return {
        title: UNITS.CATEGORIES_FORMATTED[catName],
        quantity: catInfo.cantidad,
        percentage: catInfo.porcentaje,
      };
    });
    categories.push({ title: 'Total', quantity: dataToFormat.total });
    return categories;
  };

  return (
    <Card
      title="Cantidad de unidades por categoría"
      description="Total de unidades hembras y machos disponibles por categoría."
      type="secondary"
    >
      <Row gutter={24} style={{ marginTop: 20 }}>
        <Col span={12}>
          <DashboardList
            loading={isLoadingFemales}
            dataSource={categoriasFemale && formatData(categoriasFemale)}
          />
        </Col>
        <Col span={12}>
          <DashboardList
            loading={isLoadingMales}
            dataSource={categoriasMale && formatData(categoriasMale)}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default withAPI(UnitsCategoryCard);
