import { ECONOMICA, GENERAL } from 'modules/core/constants';

const defaultColumns = [
  {
    title: 'Fecha',
    dataIndex: 'fecha',
    sorter: true,
    render: (d) => GENERAL.dateToFront(d),
  },
];

const compraVentaColumns = [
  {
    title: 'RP',
    dataIndex: 'rp',
    key: 'unidad__rp',
    sorter: true,
  },
];

const insumoColumns = [
  {
    title: 'Adquisición',
    dataIndex: 'insumo_adquisicion',
  },
];

const economicaColumns = [
  {
    title: 'Monto',
    dataIndex: 'monto',
    sorter: true,
  },
  {
    title: 'Divisa',
    dataIndex: 'divisa_value',
  },
  {
    title: 'Gestión',
    dataIndex: 'tipo_gestion_value',
    key: 'tipo_gestion',
    sorter: true,
  },
];

const actionsColumn = (renderActions) => [
  {
    title: 'Acciones',
    align: 'right ',
    render: (_, record) => renderActions?.(record),
  },
];

const COLUMNS = {};

const generateCompraVentaColumns = (actions, { criador, peso } = {}) =>
  defaultColumns
    .concat(compraVentaColumns)
    .concat(economicaColumns)
    .concat([
      {
        title: 'Número de comprobante',
        dataIndex: 'numero_comprobante',
        key: 'numero_comprobante',
        sorter: true,
      },
      {
        title: criador,
        dataIndex: 'criador_value',
        key: 'unidad__criador__nombre',
        sorter: true,
      },
      {
        title: peso,
        dataIndex: 'peso',
        key: 'peso',
      },
    ])
    .concat(actionsColumn(actions));

COLUMNS[ECONOMICA.INGRESOS_VENTA_UNIDAD] = (actions) =>
  generateCompraVentaColumns(actions, {
    criador: 'Comprador',
    peso: 'Peso final',
  });

COLUMNS[ECONOMICA.EGRESO_COMPRA_UNIDAD] = (actions) =>
  generateCompraVentaColumns(actions, {
    criador: 'Vendedor',
    peso: 'Peso inicial',
  });

ECONOMICA.EVENT_EGRESO_COMPRA_INSUMOS.EVENT_TYPES.map((el) => {
  return (COLUMNS[el] = (actions) =>
    defaultColumns
      .concat(insumoColumns)
      .concat(economicaColumns)
      .concat(actionsColumn(actions)));
});

export { COLUMNS };
