import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import './LeftMenu.scss';

// options: Array[Object: { title: String, url: title, exact: Boolean }]
const LeftMenu = ({ options }) => {
  return (
    <ul className="left-menu-list">
      {options.map((item, index) => {
        const { title, url, exact } = item;
        return (
          <li key={index} className="left-menu-list-item">
            <NavLink
              key={index}
              to={url}
              exact={exact}
              activeClassName="selected"
            >
              {title}
            </NavLink>
          </li>
        );
      })}
    </ul>
  );
};

export default withRouter(LeftMenu);
