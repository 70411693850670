import React from 'react';
import { Form, Row, Col, Select } from 'antd';

const EmbrionForm = ({ males = {}, females = {} }) => {
  return (
    <Row gutter={8}>
      <Col span={12}>
        <Form.Item
          label="RP Madre"
          name={'hembra'}
          rules={[{ required: true, message: 'Ingrese RP Madre' }]}
        >
          <Select
            showSearch
            allowClear
            style={{ width: '100%' }}
            optionFilterProp="label"
            placeholder="Seleccionar RP Madre"
            loading={females?.isLoading}
            options={females?.options}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="RP Padres"
          name={'macho'}
          rules={[{ required: true, message: 'Ingrese RP Padre' }]}
        >
          <Select
            showSearch
            allowClear
            style={{ width: '100%' }}
            optionFilterProp="label"
            placeholder="Seleccionar RP Padre"
            loading={males?.isLoading}
            options={males?.options}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default EmbrionForm;
