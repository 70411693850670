import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { withAPI } from 'modules/api';
import { saveAs } from 'file-saver';
import {
  EllipsisOutlined,
  EyeOutlined,
  DownloadOutlined,
  HistoryOutlined,
} from '@ant-design/icons';
import { Button, Col, message, Row } from 'antd';
import { usePaginationWithFilters } from 'modules/core/hooks';
import { GENERAL, UNITS } from 'modules/core/constants';
import {
  Container,
  DropdownMenu,
  ExportFieldsModal,
  Table,
} from 'modules/core/components';
import FilterSider, { UnitFilter } from 'modules/core/components/Filter';
import {
  UnitDrawerRecordHistory,
  UnitDrawerShow,
} from 'modules/units/components';

const ConsolidadoAnalytics = ({ api, isActive }) => {
  const [selectedItem, setSelectedItem] = useState({});
  const [unitDrawerVisible, setUnitDrawerVisible] = useState(false);
  const [unitHistoryVisible, setUnitHistoryVisible] = useState(false);
  const [viewExport, setViewExport] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const { pagination, filters, sorter, params } = usePaginationWithFilters();
  const {
    data: unitsConsolidado,
    isLoading: isLoadingConsolidado,
    refetch,
  } = useQuery(
    ['Analytics::fetchUnitConsolidado', { params }],
    api.fetchUnitConsolidado,
    { enabled: isActive }
  );
  const { data: availableFields } = useQuery(
    ['Analytics::fieldsExportUnitConsolidado', { unitType: UNITS.HEMBRAS }],
    api.fieldsExportUnitConsolidado,
    { enabled: isActive && viewExport }
  );

  const { mutate: exportUnitConsolidado } = useMutation(
    api.exportUnitConsolidado
  );
  const onExportUnitConsolidado = (fields) => {
    setIsExporting(true);
    exportUnitConsolidado(
      { fields, params: filters.get() },
      {
        onSuccess: (response) => {
          saveAs(response, `Analíticas unidades - Consolidado.xlsx`);
          setIsExporting(false);
        },
        onError: ({ message: error }) => {
          if (error === '500') {
            message.error('Hubo un error al conectarse al servidor.');
          }
          setIsExporting(false);
        },
      }
    );
  };

  const actionsHandler = ({ item, action }) => {
    setSelectedItem(item);
    if (action === GENERAL.ACTIONS.VER_UNIDAD) {
      setUnitDrawerVisible(true);
    }
    if (action === GENERAL.ACTIONS.VER_HISTORIAL) {
      setUnitHistoryVisible(true);
    }
  };

  const columns = [
    { title: 'RP', dataIndex: 'rp', sorter: true, fixed: true },
    { title: 'ID Electrónico', dataIndex: 'id_electronico', sorter: true },
    { title: 'Categoría', dataIndex: 'categoria', sorter: true },
    { title: 'Estado', dataIndex: 'estado', sorter: true },
    {
      title: 'Fecha nacimiento',
      dataIndex: 'fecha_nacimiento',
      sorter: true,
      render: (d) => GENERAL.dateToFront(d),
    },
    {
      title: 'Fecha servicio',
      dataIndex: 'servicio_fecha',
      sorter: true,
      render: (d) => GENERAL.dateToFront(d),
    },
    { title: 'Tipo de servicio', dataIndex: 'servicio_tipo', sorter: true },
    { title: 'Macho de servicio', dataIndex: 'servicio_macho', sorter: true },
    {
      title: 'Fecha preñez',
      dataIndex: 'prenez_fecha',
      sorter: true,
      render: (d) => GENERAL.dateToFront(d),
    },
    {
      title: 'Resultado de preñez',
      dataIndex: 'prenez_resultado',
      sorter: true,
    },
    { title: 'Tipo de embrión', dataIndex: 'prenez_tipo', sorter: true },
    {
      title: 'Fecha parto',
      dataIndex: 'parto_fecha',
      sorter: true,
      render: (d) => GENERAL.dateToFront(d),
    },
    { title: 'Tipo de parto', dataIndex: 'parto_tipo', sorter: true },
    { title: 'RP última cría', dataIndex: 'parto_hijos', sorter: true },
    {
      title: 'Acciones',
      align: 'right ',
      render: (_, unit) => (
        <DropdownMenu
          menu={[
            {
              title: 'Ver detalle',
              icon: <EyeOutlined />,
              onClick: () =>
                actionsHandler({
                  item: unit,
                  action: GENERAL.ACTIONS.VER_UNIDAD,
                }),
            },

            {
              title: 'Ver historial',
              icon: <HistoryOutlined />,
              onClick: () =>
                actionsHandler({
                  item: unit,
                  action: GENERAL.ACTIONS.VER_HISTORIAL,
                }),
            },
          ]}
        />
      ),
    },
  ];

  return (
    <>
      <Row gutter={[24, 32]} className="data-row">
        <Col span={24}>
          <Container>
            <FilterSider
              showDrawer
              showCurrentYear
              showGlobalSearch={false}
              filters={[UnitFilter]}
              onChange={filters.set}
            />
            <DropdownMenu
              menu={[
                {
                  title: 'Exportar a Excel',
                  icon: <DownloadOutlined />,
                  onClick: () => setViewExport(true),
                },
              ]}
            >
              <Button icon={<EllipsisOutlined />} />
            </DropdownMenu>
          </Container>
        </Col>
        <Col span={24}>
          <Table
            rowKey="rp"
            dataSource={unitsConsolidado?.data}
            columns={columns}
            loading={isLoadingConsolidado || isExporting}
            showTotal={true}
            pagination={{ ...pagination.get(), total: unitsConsolidado?.count }}
            scroll={{ x: 1600 }}
            onChange={(p, f, s) => {
              pagination.set(p);
              sorter.set(s);
            }}
          />
          <UnitDrawerShow
            visible={unitDrawerVisible}
            unit={selectedItem}
            close={() => {
              setUnitDrawerVisible(false);
              setSelectedItem({});
            }}
          />
          <UnitDrawerRecordHistory
            visible={unitHistoryVisible}
            unitId={selectedItem?.id}
            success={refetch}
            close={() => {
              setUnitHistoryVisible(false);
              setSelectedItem({});
            }}
          />
          <ExportFieldsModal
            visible={viewExport}
            fields={availableFields}
            loading={isExporting}
            onSuccess={onExportUnitConsolidado}
            close={() => setViewExport(false)}
          />
        </Col>
      </Row>
    </>
  );
};

export default withAPI(ConsolidadoAnalytics);
