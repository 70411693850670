import React from 'react';
import { withAPI } from 'modules/api';
import { MASTERS } from 'modules/core/constants';
import { ManagementConfig } from 'modules/management/components';

const ObjetivosTratamiento = ({ api }) => {
  const masterType = MASTERS.SANITARIA_TYPES.OBJETIVO_TRATAMIENTO;
  const masterName = {
    CAPITALIZE: 'Objetivo de tratamiento',
    LOWER: 'objetivo de tratamiento',
    PLURAL: 'Objetivos de tratamiento',
  };

  return <ManagementConfig masterType={masterType} masterName={masterName} />;
};

export default withAPI(ObjetivosTratamiento);
