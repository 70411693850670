import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'antd';
import { withAPI } from 'modules/api';
import { EventsRecordHistory } from 'modules/core/components';
import { useEventsRecordHistory } from 'modules/core/hooks';
import { COLUMNS } from './utils/recordHistoryColumns';
import { NUTRICIONAL } from 'modules/core/constants';
import FinishDietaModal from './utils/FinishDietaModal';

const RecordHistory = ({ api }) => {
  const { eventType } = useParams();
  const recordState = useEventsRecordHistory({
    type: eventType,
    fetchFns: {
      fetchRecords: api.fetchNutricionalEventsRecords,
      fetchRecordsKPI: api.fetchNutricionalRecordsKPI,
      undoMultipleEvents: api.deleteMultipleNutricionalEvent,
      undoEvent: api.deleteNutricionalEvent,
    },
  });

  // Custom render (Atm only for Diet event)
  const [dieta, setDieta] = useState(false);
  const customColumnRender = (d) => {
    if (eventType === NUTRICIONAL.DIETA)
      return <Button onClick={() => setDieta(d)}>Finalizar</Button>;
    return null;
  };

  return (
    <>
      <EventsRecordHistory
        userSpecies={api.userSpecies}
        state={recordState}
        columns={COLUMNS}
        unitTypes={NUTRICIONAL.EVENT_UNIT_TYPES[eventType]}
        customColumnRender={customColumnRender}
      />
      {eventType === NUTRICIONAL.DIETA && (
        <FinishDietaModal
          visible={dieta}
          dieta={dieta}
          success={recordState.refetch}
          close={() => setDieta(false)}
        />
      )}
    </>
  );
};

export default withAPI(RecordHistory);
