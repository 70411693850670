import React from 'react';
import { Table } from 'modules/core/components';

const defaultColumns = (renderActions) => {
  const columns = [];
  columns.push(
    {
      title: 'RP Madre',
      dataIndex: 'hembra',
      key: 'hembra__rp',
      sorter: true,
    },
    {
      title: 'Padre',
      dataIndex: 'macho',
      key: 'macho__rp',
      sorter: true,
    },
    {
      title: 'Abuelo materno',
      dataIndex: 'abuelo_materno',
    },
    {
      title: 'Abuelo paterno',
      dataIndex: 'abuelo_paterno',
    },
    {
      title: 'Cantidad actual',
      dataIndex: 'cantidad_actual',
      sorter: true,
    },
    {
      title: 'Acciones',
      align: 'right ',
      render: (_, el) => renderActions?.(el),
    }
  );
  return columns;
};

const EmbrionList = ({ data = null, actions, ...props }) => {
  return (
    <Table
      rowClassName="embrion-list-row"
      rowKey="id"
      dataSource={data && data.results}
      columns={defaultColumns(actions)}
      {...props}
    />
  );
};

export default EmbrionList;
