import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, message, Spin } from 'antd';
import { useMutation } from 'react-query';
import { withAPI } from 'modules/api';
import { GENERAL } from 'modules/core/constants';
import './UnitObservations.scss';

const { TextArea } = Input;

const ConfirmButtons = ({ cancel, confirm }) => (
  <>
    <Button
      type="link"
      className="observation-del-btn-confirm"
      size="small"
      danger
      onClick={confirm}
    >
      Confirmar
    </Button>
    <Button
      type="link"
      className="observation-del-btn-cancel"
      size="small"
      onClick={cancel}
    >
      Cancelar
    </Button>
  </>
);
const DeleteButton = ({ onDelete }) => (
  <Button
    type="link"
    className="observation-del-btn-delete"
    size="small"
    onClick={onDelete}
  >
    Borrar
  </Button>
);
const UnitObservation = ({ data, onDelete }) => {
  const title = GENERAL.dateToFront(data.fecha, true);
  const [deleting, setDeleting] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const delObservation = () => {
    setDeleting(true);
    onDelete().then(() => setDeleting(false));
  };

  return (
    <Spin spinning={deleting}>
      <div className="observation-main">
        <div className="observation-delete-buttons">
          {confirm ? (
            <ConfirmButtons
              cancel={() => setConfirm(false)}
              confirm={delObservation}
            />
          ) : (
            <DeleteButton onDelete={() => setConfirm(true)} />
          )}
        </div>
        <div className="observation-title">{title} hs</div>
        <div className="observation-body">{data?.comentario}</div>
      </div>
    </Spin>
  );
};

const UnitAddObservationBox = ({ onAdd }) => {
  const newObservationRef = useRef(null);
  const [newObservation, setNewObservation] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [adding, setAdding] = useState(false);
  useEffect(() => {
    isEditing && newObservationRef.current.focus();
    isEditing &&
      newObservationRef.current.resizableTextArea.textArea.scrollIntoView({
        behavior: 'smooth',
      });
  }, [isEditing]);
  const addObservation = () => {
    setAdding(true);
    onAdd(newObservation).then(() => {
      setAdding(false);
      setIsEditing(false);
    });
  };
  const cancelAddObservation = () => {
    setNewObservation(null);
    setIsEditing(false);
  };
  if (!isEditing)
    return (
      <Button
        className="add-obs-button"
        type="link"
        size="small"
        onClick={() => setIsEditing(true)}
      >
        Agregar
      </Button>
    );
  return (
    <div className="observation-add">
      <Spin wrapperClassName="observation-container" spinning={adding}>
        <div className="unit-title">Nueva observación</div>
        <TextArea
          ref={newObservationRef}
          className="observation-content"
          autoSize={{ minRows: 3, maxRows: 5 }}
          onChange={(e) => setNewObservation(e.target.value)}
        />
        <div className="observation-add-buttons">
          <Button
            className="observation-add-btn-cancel"
            type="link"
            size="small"
            onClick={cancelAddObservation}
          >
            Cancelar
          </Button>
          <Button
            className="observation-add-btn-save"
            type="link"
            size="small"
            onClick={addObservation}
          >
            Guardar
          </Button>
        </div>
      </Spin>
    </div>
  );
};

const UnitObservations = ({ api, unitId, obs, refetch }) => {
  const { mutateAsync: deleteObs } = useMutation(api.deleteObs);
  const { mutateAsync: addObs } = useMutation(api.addObs);
  const onDelete = (id) => deleteObs({ obsId: id }, { onSuccess: refetch });
  const onAdd = (obs) =>
    addObs(
      { unitId, obs },
      {
        onSuccess: refetch,
        onError: () => {
          message.error('Error al agregar observación');
        },
      }
    );
  return (
    <div className="unit-obs">
      {obs.map((o) => (
        <UnitObservation key={o.id} data={o} onDelete={() => onDelete(o.id)} />
      ))}
      <UnitAddObservationBox onAdd={onAdd} />
    </div>
  );
};

export default withAPI(UnitObservations);
