import React from 'react';
import { ESTABLISHMENT } from 'modules/core/constants';
import { BadgeList } from 'modules/core/components';

const PlanificacionBadgeList = ({
  data = [],
  emptyText = '',
  ...restProps
}) => {
  if (data.length <= 0) {
    return <span>{emptyText}</span>;
  }
  return (
    <BadgeList
      {...restProps}
      data={data}
      renderBadgeText={(el) =>
        `${el?.evento_value} - ${el?.concepto_value} - ${el?.objetivo_value}`.toUpperCase()
      }
      renderBadgeColor={(el) =>
        ESTABLISHMENT.PLANIFICACION_CALENDAR_BADGES[el?.gestion]
      }
    />
  );
};

export default PlanificacionBadgeList;
