import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import {
  Row,
  Col,
  Form,
  DatePicker,
  message as antMessage,
  Select,
  Input,
} from 'antd';
import { withAPI } from 'modules/api';
import { usePaginationWithFilters } from 'modules/core/hooks';
import { displayInfoModal } from 'modules/core/components';
import FilterSider, { UnitFilter } from 'modules/core/components/Filter';
import {
  UnitDrawerRecordHistory,
  UnitDrawerShow,
} from 'modules/units/components';
import {
  SANITARIA,
  MANAGEMENT,
  GENERAL,
  UNITS,
  MASTERS,
} from 'modules/core/constants';
import {
  EventsHeader,
  EventsTableActions,
} from 'modules/management/components';
import ControlEpidemiologicoTable from './ControlEpidemiologicoTable/ControlEpidemiologicoTable';

const { Item: FormItem } = Form;
const EVENT_ACTIONS_INITIAL_VALUES = { item: null, action: null };

const ControlEpidemiologico = ({ api }) => {
  const eventType = SANITARIA.CONTROL_EPIDEMIOLOGICO;
  let history = useHistory();
  let location = useLocation();
  const { pagination, filters, sorter, params } = usePaginationWithFilters();
  const [actionInProgress, setActionInProgress] = useState(false);
  const [selectedItem, setSelectedItem] = useState(
    EVENT_ACTIONS_INITIAL_VALUES
  );
  const [selectedRows, setSelectedRows] = useState({ keys: [], rows: [] });
  const [form] = Form.useForm();

  const {
    data: unitsControlEpidemiologico,
    isLoading,
    refetch,
  } = useQuery(
    ['Sanitaria::fetchByEvent', { eventType: SANITARIA.GENERIC_EVENT, params }],
    api.fetchByEvent
  );

  const { data: sindromes } = useQuery(
    [
      'Sanitaria::SindromesMaster',
      { masterType: MASTERS.SANITARIA_TYPES.SINDROME },
    ],
    api.fetchMasters
  );
  const sindromesOptions = MASTERS.generateOptions(sindromes?.results);

  const { data: options } = useQuery(
    [
      'Masters::ReponsableResultado',
      {
        masterTypes: [
          MASTERS.PRODUCTIVA_TYPES.RESPONSABLE,
          MASTERS.SANITARIA_TYPES.RESULTADO_ANALISIS,
        ],
      },
    ],
    api.fetchMastersFormOptions
  );

  const { data: productos } = useQuery(
    ['Sanitaria::ProductosMaster', { inputType: SANITARIA.PRODUCTO }],
    api.fetchInsumo
  );
  const productosOptions = MASTERS.generateOptions(productos?.results);

  const { mutate: controlEpidemiologico, isLoading: registering } = useMutation(
    api.addSanitariaEvent
  );

  const emptySelection = _.isEmpty(selectedRows.keys);
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRows({ keys: selectedRowKeys, rows: selectedRows });
  };
  const rowSelection = {
    selectedRowKeys: selectedRows.keys,
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
  };

  const onAction = () => {
    setActionInProgress(true);
  };
  const rollbackAction = () => {
    setSelectedRows({ keys: [], rows: [] });
    form.resetFields();
    setActionInProgress(false);
  };

  const onActionApply = () => {
    form
      .validateFields()
      .then((values) => {
        const controlEpidemiologicoData = {
          fecha: GENERAL.dateToBack(values.date),
          producto: values.product,
          resultado: values.result,
          sindrome: values.syndrome,
          laboratorio: values.lab,
          ...(values.responsible && { responsable: values.responsible }),
          unidad: selectedRows.keys.map((el) => ({
            unidad_id: el,
            cantidad_aplicada: values.stock_amount[el]
              ? values.stock_amount[el]
              : 0,
          })),
        };
        const onSuccess = () =>
          displayInfoModal({
            description:
              'Se realizó el control epidemiológico con éxito.\n Continuá tu gestión en Finca.',
            showCheck: true,
            onOk: () => history.push(location.pathname),
          });
        const onError = ({ message, description }) => {
          if (message === '400') antMessage.error(description, 5);
        };
        controlEpidemiologico(
          { eventType, body: controlEpidemiologicoData },
          { onSuccess, onError }
        );
      })
      .catch(() => {});
  };

  const renderHeader = () => (
    <Col span={24}>
      <EventsHeader
        title="Control epidemiológico"
        description={
          !actionInProgress
            ? 'Unidades aptas para realizar control epidemiológico'
            : `${selectedRows.rows.length} unidades seleccionadas`
        }
        continueProps={{ onClick: onAction, disabled: emptySelection }}
        registerProps={{ onClick: onActionApply, loading: registering }}
        eventMenuProps={{
          menuType: MANAGEMENT.SANITARIA,
          unitType: UNITS.UNIDAD,
          eventType: eventType,
          onUpload: refetch,
          exportParams: filters.get(),
        }}
        rollbackAction={rollbackAction}
        showRegister={actionInProgress}
      />
    </Col>
  );

  const renderTable = () => {
    return (
      <>
        <Col xs={6} xxl={4}>
          <FilterSider
            filters={[UnitFilter]}
            filtersProps={{ unitType: [UNITS.HEMBRAS, UNITS.MACHOS] }}
            onChange={filters.set}
          />
        </Col>
        <Col xs={18} xxl={20}>
          <ControlEpidemiologicoTable
            data={unitsControlEpidemiologico?.results}
            rowSelection={rowSelection}
            loading={isLoading}
            actions={(item) => (
              <EventsTableActions item={item} onAction={setSelectedItem} />
            )}
            showTotal={true}
            pagination={{
              ...pagination.get(),
              total: unitsControlEpidemiologico?.count,
            }}
            onChange={(p, f, s) => {
              pagination.set(p);
              sorter.set(s);
            }}
          />
          <UnitDrawerShow
            visible={selectedItem?.action === GENERAL.ACTIONS.VER_UNIDAD}
            unit={selectedItem.item}
            close={() => setSelectedItem(EVENT_ACTIONS_INITIAL_VALUES)}
          />
          <UnitDrawerRecordHistory
            visible={selectedItem?.action === GENERAL.ACTIONS.VER_HISTORIAL}
            unitId={selectedItem.item?.id}
            success={refetch}
            close={() => setSelectedItem(EVENT_ACTIONS_INITIAL_VALUES)}
          />
        </Col>
      </>
    );
  };

  const renderForm = () => (
    <Col span={24}>
      <Form form={form} layout="vertical" requiredMark={false}>
        <Row gutter={8}>
          <Col span={4}>
            <FormItem
              label="Fecha"
              name="date"
              rules={[{ required: true, message: 'Ingrese fecha' }]}
            >
              <DatePicker
                style={{ width: '100%' }}
                format={GENERAL.DATE_FORMAT.FRONT}
              />
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem
              label="Sindrome"
              name="syndrome"
              rules={[{ required: true, message: 'Seleccione sindrome' }]}
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Selecione sindrome..."
                options={sindromesOptions}
              />
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem
              label="Resultado"
              name="result"
              rules={[{ required: true, message: 'Ingrese resultado' }]}
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Selecione resultado..."
                options={options?.[MASTERS.SANITARIA_TYPES.RESULTADO_ANALISIS]}
              />
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem
              label="Responsable"
              name="responsible"
              rules={[{ required: false, message: 'Seleccione responsable' }]}
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Selecione responsable..."
                options={options?.[MASTERS.PRODUCTIVA_TYPES.RESPONSABLE]}
              />
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem
              label="Producto"
              name="product"
              rules={[{ required: false, message: 'Seleccione producto' }]}
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Selecione producto..."
                options={productosOptions}
              />
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem
              label="Laboratorio"
              name="lab"
              rules={[{ required: false, message: 'Ingrese laboratorio' }]}
            >
              <Input
                style={{ width: '100%' }}
                placeholder="Ingrese laboratorio..."
              />
            </FormItem>
          </Col>
          <Col span={24}>
            <ControlEpidemiologicoTable
              loading={isLoading}
              data={selectedRows.rows}
              form={form}
              pagination={false}
            />
          </Col>
        </Row>
      </Form>
    </Col>
  );

  return (
    <Row gutter={[16, 16]}>
      {renderHeader()}
      {!actionInProgress && renderTable()}
      {actionInProgress && renderForm()}
    </Row>
  );
};

export default withAPI(ControlEpidemiologico);
