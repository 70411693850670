import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { withAPI } from 'modules/api';
import { CalendarOutlined } from '@ant-design/icons';
import { Spin, Button, Drawer, Space, Divider } from 'antd';
import { ESTABLISHMENT } from 'modules/core/constants';
import { Card } from 'modules/core/components';
import PlanificacionBadgeList from 'modules/establishment/Planificacion/PlanificacionBadgeList';
import ShowMoreButton from '../ShowMoreButton/ShowMoreButton';
import TitleHeaderCard from '../TitleHeaderCard/TitleHeaderCard';
import SeeMoreDrawerTitle from '../SeeMoreDrawerTitle/SeeMoreDrawerTitle';

import './PlanificacionCard.scss';

const PlanificacionCard = ({ api, height = 160 }) => {
  let history = useHistory();
  const [showMore, setShowMore] = useState(false);
  const { data: planificaciones, isLoading } = useQuery(
    ['Home::fetchPlanificacionesEvents'],
    api.fetchPlanificacionesEvents
  );

  const renderDrawerList = ({ title, data = [], extra }) => {
    return (
      <Space direction="vertical" style={{ width: '100%' }}>
        <SeeMoreDrawerTitle title={title} extra={extra} />
        <PlanificacionBadgeList
          data={data}
          emptyText="No hay eventos disponibles"
        />
      </Space>
    );
  };

  if (isLoading) return <Spin />;

  return (
    <>
      <Card className="dashboard-planificacion-card" style={{ height }}>
        <TitleHeaderCard title="Eventos en curso" />
        <PlanificacionBadgeList
          data={planificaciones?.current_events.slice(0, 3)}
          emptyText="No hay eventos para el día de hoy"
        />
        <ShowMoreButton onClick={() => setShowMore(true)} />
      </Card>
      <Drawer
        title="Eventos"
        onClose={() => setShowMore(false)}
        open={showMore}
        closable={false}
        width={700}
      >
        {renderDrawerList({
          title: 'En curso',
          data: planificaciones?.current_events,
        })}
        <Divider />
        {renderDrawerList({
          title: 'En los próximos 30 días',
          data: planificaciones?.next_events,
          extra: (
            <Button
              type="primary"
              icon={<CalendarOutlined />}
              onClick={() =>
                history.push(
                  `/${ESTABLISHMENT.NAME_SPACE}/${ESTABLISHMENT.PLANIFICACION}`
                )
              }
            >
              Ir a planificación
            </Button>
          ),
        })}
      </Drawer>
    </>
  );
};

export default withAPI(PlanificacionCard);
