import React from 'react';
import { useQuery, useMutation } from 'react-query';
import { withAPI } from 'modules/api';
import _ from 'lodash';
import { Row, Col, Button, Form, Spin, message } from 'antd';
import { Card, ScreenHeader } from 'modules/core/components';
import { LicenseCard, ProfileForm, UploadLogoCard } from './components';

import './Profile.scss';

const SectionTitle = ({ title }) => (
  <Row>
    <Col span={24} className="my-profile-subtitle">
      {title}
    </Col>
  </Row>
);

const Profile = ({ api }) => {
  const [form] = Form.useForm();
  const {
    data: userInfo,
    isLoading: isLoadingUserInfo,
    refetch: rUser,
  } = useQuery(['Profile::UserInfo', { id: api.getUserId() }], api.fetchUser);
  const {
    data: userBarn,
    isLoading: isLoadingUserBarn,
    refetch: rBarn,
  } = useQuery(['Profile::UserBarn'], api.fetchUserBarn);
  const refetch = () => {
    rUser();
    rBarn();
  };

  const { mutate: editUser, isLoading: isEditingUserInfo } = useMutation(
    api.editUser,
    {
      onError: () =>
        message.error('Hubo un problema al editar el nombre de usuario', 5),
    }
  );

  const { mutate: editUserBarn, isLoading: isEditingUserBarn } = useMutation(
    api.editUserBarn,
    {
      onError: () =>
        message.error(
          'Hubo un problema al editar la información del establecimiento',
          5
        ),
    }
  );

  const onSave = () => {
    form
      .validateFields()
      .then((values) =>
        Promise.all([
          editUser({
            userId: api.getUserId(),
            user: { first_name: values.first_name },
          }),
          editUserBarn({
            id: userBarn[0].id,
            data: _.omit(values, ['first_name', 'password']),
          }),
        ])
      )
      .then(() => {
        refetch();
        message.success('El perfil fue editado con éxito', 5);
      })
      .catch(() => {});
  };

  if (isLoadingUserInfo || isLoadingUserBarn) return <Spin spinning />;
  return (
    <Row gutter={[0, 24]} className="profile-container">
      <Col span={24}>
        <ScreenHeader
          title="Mi perfil"
          description="Gestioná tu información para mejorar tu experiencia en Finca."
        />
      </Col>
      <Col span={24} className="profile-content">
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <Card className="profile-form">
              <ProfileForm
                form={form}
                user={userInfo}
                barn={userBarn}
                titleComponent={SectionTitle}
              />
              <Button
                className="profile-action-button"
                type="primary"
                onClick={onSave}
                loading={isEditingUserBarn || isEditingUserInfo}
              >
                GUARDAR
              </Button>
            </Card>
          </Col>
          <Col span={24}>
            <UploadLogoCard barnId={userBarn[0]?.id} />
          </Col>
          <Col span={24}>
            <LicenseCard />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default withAPI(Profile);
