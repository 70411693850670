import React from 'react';
import { Space, Tooltip, Spin } from 'antd';
import { InfoCircleOutlined, RightOutlined } from '@ant-design/icons';
import DropdownMenu from '../DropdownMenu/DropdownMenu';

import './Card.scss';

const Card = ({
  title = null,
  description = null,
  quantity = null,
  className = '',
  children,
  moreActions = [],
  onClick = null,
  showGoToIcon = false,
  type = 'primary',
  tooltip = null,
  loading = false,
  ...restProps
}) => {
  const showTop = title !== null || description !== null || quantity !== null;
  const renderHeader = () => (
    <div className="finca-card-header">
      {title !== null && (
        <div className="finca-card-font finca-card-title" data-type={type}>
          {title}
        </div>
      )}
      {moreActions.length > 0 && (
        <DropdownMenu title="Más acciones" menu={moreActions} />
      )}
    </div>
  );

  const renderQuantity = () => (
    <div className="finca-card-font finca-card-quantity">{quantity}</div>
  );

  const renderDescription = () => (
    <div className="finca-card-font finca-card-description">{description}</div>
  );

  const renderTooltip = () => (
    <div className="finca-card-tooltip">
      <Tooltip title={tooltip}>
        <InfoCircleOutlined />
      </Tooltip>
    </div>
  );

  return (
    <div
      className={`finca-card ${className}`}
      onClick={onClick}
      {...restProps}
      data-hoverable={onClick !== null}
      data-withicon={showGoToIcon}
    >
      {showTop && (
        <Space direction="vertical" style={{ width: '100%' }}>
          {renderHeader()}
          {quantity !== null && renderQuantity()}
          {description !== null && renderDescription()}
        </Space>
      )}
      {tooltip !== null && renderTooltip()}
      {loading && <Spin />}
      {children}
      {showGoToIcon && <RightOutlined className="go-to-icon" />}
    </div>
  );
};

export default Card;
