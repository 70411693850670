import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Row, Col, List } from 'antd';
import { Card } from 'modules/core/components';
import { withAPI } from 'modules/api';
import { MANAGEMENT, PRODUCTIVA } from 'modules/core/constants';
import _ from 'lodash';

const ServicioDashboard = ({ api }) => {
  let history = useHistory();

  const { data } = useQuery(['Units::fetchKPIEvents'], api.fetchKPIEvents);
  const formatData =
    data &&
    _.map(PRODUCTIVA.SERVICIOS.EVENT_DASHBOARD, (card, event) => ({
      ...card,
      quantity: data[event] ?? 0,
    }));

  return (
    <Row gutter={[16, 16]}>
      <Col span={18} offset={3}>
        <List
          dataSource={formatData}
          grid={{ gutter: 24, xs: 1, sm: 2, md: 2, lg: 2, xl: 3, xxl: 3 }}
          renderItem={({ title, quantity, description, url }) => (
            <List.Item>
              <Card
                title={title}
                quantity={quantity}
                description={description}
                showGoToIcon={true}
                onClick={() =>
                  history.push(
                    `/${MANAGEMENT.NAME_SPACE}/${PRODUCTIVA.NAME_SPACE}/${PRODUCTIVA.SERVICIOS.NAME_SPACE}/${url}`
                  )
                }
              />
            </List.Item>
          )}
        />
      </Col>
    </Row>
  );
};

export default withAPI(ServicioDashboard);
