import React, { useState } from 'react';
import { useQuery } from 'react-query';
import moment from 'moment';
import { withAPI } from 'modules/api';
import { Button, Calendar, Col, Row, Spin } from 'antd';
import { ScreenHeader } from 'modules/core/components';
import PlanificacionBadgeList from './PlanificacionBadgeList';
import PlanificacionModal from './PlanificacionModal';

import './Planificacion.scss';

const initialModalInfo = { visible: false, planificacion: {} };
const FORMAT_YYYY_MM = 'YYYY-MM';
const FORMAT_YYYY_MM_DD = 'YYYY-MM-DD';

const Planificacion = ({ api }) => {
  const [modalInfo, setModalInfo] = useState(initialModalInfo);
  const [calendarYearMonth, setCalendarYearMonth] = useState(
    moment().format(FORMAT_YYYY_MM)
  );

  const {
    data: planificaciones,
    isLoading,
    refetch: rCalendar,
  } = useQuery(
    [
      'Sanitaria::fetchPlanificacionesCalendar',
      { params: { mes: calendarYearMonth } },
    ],
    api.fetchPlanificacionesCalendar
  );

  const handleClickEvent = (planificacion) => {
    setModalInfo({ visible: true, planificacion: planificacion });
  };

  const onPanelChange = (value) => {
    setCalendarYearMonth(value.format(FORMAT_YYYY_MM));
  };

  const renderEventList = (value) => {
    const planificacionList =
      (planificaciones && planificaciones[value.format(FORMAT_YYYY_MM_DD)]) ||
      [];

    return (
      <PlanificacionBadgeList
        data={planificacionList}
        trimBadgeText={false}
        onClick={(el) => handleClickEvent(el)}
      />
    );
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <ScreenHeader
          title="Planificación"
          rightActions={
            <Button
              type="primary"
              onClick={() => setModalInfo({ visible: true, planificacion: {} })}
            >
              AGREGAR PLANIFICACIÓN
            </Button>
          }
        />
      </Col>
      <Col span={24}>
        <Spin spinning={isLoading}>
          <Calendar
            onPanelChange={onPanelChange}
            dateCellRender={renderEventList}
          />
        </Spin>
      </Col>
      <PlanificacionModal
        {...modalInfo}
        onSuccess={rCalendar}
        onCancel={() => setModalInfo(initialModalInfo)}
      />
    </Row>
  );
};

export default withAPI(Planificacion);
