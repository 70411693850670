import React from 'react';
import { Drawer } from 'antd';
import { FileAddOutlined } from '@ant-design/icons';
import { ModalTitle, UnitsAddForm } from 'modules/core/components';

const PartoDrawer = ({ initialValues, visible, success, cancel }) => {
  return (
    <Drawer
      title={
        <ModalTitle
          icon={<FileAddOutlined />}
          title={`Registrar crías - ${initialValues?.current ?? '-'} / ${
            initialValues?.total ?? '-'
          }`}
        />
      }
      forceRender
      className="parto-add-drawer"
      placement="right"
      onClose={cancel}
      open={visible}
      width="40%"
      closable={false}
    >
      <UnitsAddForm
        visible={visible}
        initialValuesParto={initialValues}
        onSuccess={success}
        onCancel={cancel}
      />
    </Drawer>
  );
};

export default PartoDrawer;
