import React from 'react';
import { useQuery } from 'react-query';
import { withAPI } from 'modules/api';
import { Form, Col, Select } from 'antd';
import { GENERAL, NUTRICIONAL } from 'modules/core/constants';

const SuplementoAdquisicionesForm = ({ api, visible }) => {
  const { data: suplementos, isLoading: isLoadingOptions } = useQuery(
    ['Nutricional::Suplemento', { inputType: NUTRICIONAL.SUPLEMENTO }],
    api.fetchInsumo,
    { enabled: visible }
  );
  const suplementosOptions = GENERAL.objsToOptions(suplementos?.results, {
    value: 'nombre',
    label: 'nombre',
  });

  return (
    <>
      <Col span={24}>
        <Form.Item
          label="Suplemento"
          name="nombre"
          rules={[{ required: true, message: 'Ingrese suplemento' }]}
        >
          <Select
            showSearch
            allowClear
            style={{ width: '100%' }}
            optionFilterProp="label"
            placeholder="Seleccionar suplemento"
            loading={isLoadingOptions}
            options={suplementosOptions}
          />
        </Form.Item>
      </Col>
    </>
  );
};

export default withAPI(SuplementoAdquisicionesForm);
