import BarChart from './BarChart';
import KPI from './KPI';
import LineChart from './LineChart';
import PieChart from './PieChart';
import TreeMapChart from './TreeMapChart';

const Charts = {
  Bar: BarChart,
  KPI,
  Line: LineChart,
  Pie: PieChart,
  TreeMap: TreeMapChart,
};

export default Charts;
