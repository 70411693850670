import React from 'react';
import { useParams } from 'react-router-dom';
import { withAPI } from 'modules/api';
import { EventsRecordHistory } from 'modules/core/components';
import { useEventsRecordHistory } from 'modules/core/hooks';
import { COLUMNS } from './utils/recordHistoryColumns';
import { ECONOMICA } from 'modules/core/constants';

const RecordHistory = ({ api }) => {
  const { eventType } = useParams();
  const recordState = useEventsRecordHistory({
    type: eventType,
    fetchFns: {
      fetchRecords: api.fetchEconomicaEventsRecords,
      fetchRecordsKPI: api.fetchEconomicaEventsKPI,
      undoMultipleEvents: api.deleteMultipleEconomicaEvent,
      undoEvent: api.deleteEconomicaEvent,
    },
  });

  return (
    <EventsRecordHistory
      userSpecies={api.userSpecies}
      state={recordState}
      columns={COLUMNS}
      unitTypes={ECONOMICA.EVENT_UNIT_TYPES[eventType]}
    />
  );
};

export default withAPI(RecordHistory);
