import React from 'react';
import { Carousel, Row, Col, Avatar } from 'antd';
import { Card, DropdownMenu } from 'modules/core/components';
import {
  LeftCircleFilled,
  RightCircleFilled,
  UserOutlined,
} from '@ant-design/icons';
import './UserCarousel.scss';

const UserCard = ({ user, actions }) => (
  <Card className="user-card">
    <DropdownMenu className="user-actions" menu={actions(user)} />
    <Row>
      <Col span={24} className="user-header">
        <Avatar className="user-avatar" icon={<UserOutlined />} />
        <div className="user-name">{user.first_name}</div>
      </Col>
      <Col span={24} className="user-email">
        {user.email}
      </Col>
    </Row>
  </Card>
);

const CarouselArrowPrev = ({ className, onClick }) => (
  <LeftCircleFilled
    className={`${className} user-carousel-arrow`}
    onClick={onClick}
  />
);

const CarouselArrowNext = ({ className, onClick }) => (
  <RightCircleFilled
    className={`${className} user-carousel-arrow`}
    onClick={onClick}
  />
);

const UserCarousel = ({ data: kpis, actions }) => {
  if (kpis && kpis.length > 0) {
    return (
      <Carousel
        className="user-carousel"
        slidesToShow={5}
        slidesToScroll={5}
        initialSlide={0}
        infinite={false}
        dots={false}
        arrows={true}
        nextArrow={<CarouselArrowNext />}
        prevArrow={<CarouselArrowPrev />}
        responsive={[
          {
            breakpoint: 1350,
            settings: { slidesToShow: 4, slidesToScroll: 4 },
          },
          {
            breakpoint: 1100,
            settings: { slidesToShow: 3, slidesToScroll: 3 },
          },
          {
            breakpoint: 850,
            settings: { slidesToShow: 2, slidesToScroll: 2 },
          },
        ]}
      >
        {kpis.map((user) => (
          <UserCard key={user.id} user={user} actions={actions} />
        ))}
      </Carousel>
    );
  }
  return null;
};

export default UserCarousel;
