import React from 'react';
import { Drawer } from 'antd';
import { FileAddOutlined, EditOutlined } from '@ant-design/icons';
import { ModalTitle, UnitsAddForm } from 'modules/core/components';
import './UnitDrawerAdd.scss';

const title = (editing) => {
  return editing ? (
    <ModalTitle icon={<EditOutlined />} title="Modificar unidad" />
  ) : (
    <ModalTitle icon={<FileAddOutlined />} title="Agregar unidad" />
  );
};

const UnitDrawerAdd = ({
  initialValues = null,
  visible,
  formAction,
  close,
  success,
}) => {
  return (
    <Drawer
      title={title(!!initialValues)}
      forceRender
      className="units-add-drawer"
      placement="right"
      width="40%"
      onClose={close}
      open={visible}
      closable={false}
    >
      <div className="units-add-form-wrapper">
        <UnitsAddForm
          visible={visible}
          formAction={formAction}
          initialValues={initialValues}
          onCancel={close}
          onSuccess={success}
        />
      </div>
    </Drawer>
  );
};

export default UnitDrawerAdd;
