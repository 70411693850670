import React from 'react';
import { Space } from 'antd';
import RollbackButton from '../RollbackButton/RollbackButton';
import Title from '../Title/Title';
import './ScreenHeader.scss';

const ScreenHeader = ({
  title = '',
  onRollback = null,
  description = null,
  rightActions = null,
}) => (
  <div className="finca-screen-header">
    <Space className="finca-screen-header-container" direction="vertical">
      {onRollback && <RollbackButton onRollback={onRollback} />}
      <div className="finca-screen-body">
        <Title level={1} text={title} />
        <Space>{rightActions}</Space>
      </div>
      {description && <div>{description}</div>}
    </Space>
    <div className="finca-screen-divider" />
  </div>
);

export default ScreenHeader;
