import React from 'react';
import { useQuery } from 'react-query';
import { withAPI } from 'modules/api';
import { Form, Col, Select } from 'antd';
import { GENERAL, PRODUCTIVA } from 'modules/core/constants';

const SemenAdquisicionesForm = ({ api, visible }) => {
  const { data: semen, isLoading: isLoadingOptions } = useQuery(
    ['Productiva::Semen', { inputType: PRODUCTIVA.SEMEN }],
    api.fetchInsumo,
    { enabled: visible }
  );
  const semenOptions = GENERAL.objsToOptions(semen?.results, {
    value: 'id',
    label: 'macho',
  });

  return (
    <>
      <Col span={24}>
        <Form.Item
          label="Semen"
          name="id"
          rules={[{ required: true, message: 'Ingrese semen' }]}
        >
          <Select
            showSearch
            allowClear
            style={{ width: '100%' }}
            optionFilterProp="label"
            placeholder="Seleccionar semen"
            loading={isLoadingOptions}
            options={semenOptions}
          />
        </Form.Item>
      </Col>
    </>
  );
};

export default withAPI(SemenAdquisicionesForm);
