import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { MANAGEMENT, ECONOMICA } from 'modules/core/constants';
import RecordHistory from '../../RecordHistory/RecordHistory';
import CompraInsumosDashboard from './CompraInsumosDashboard/CompraInsumosDashboard';
import CompraInsumosEvent from './CompraInsumosEvent/CompraInsumosEvent';

const BASE = `/${MANAGEMENT.NAME_SPACE}/${MANAGEMENT.ECONOMICA}`;
const eventTypes = ECONOMICA.EVENT_EGRESO_COMPRA_INSUMOS.EVENT_TYPES.join('|');

const Routes = () => {
  return (
    <Switch>
      <Route
        exact
        path={`${BASE}/${ECONOMICA.EGRESO_COMPRA_INSUMOS}`}
        component={CompraInsumosDashboard}
      />
      <Route
        exact
        path={`${BASE}/:eventType(${eventTypes})`}
        component={CompraInsumosEvent}
      />
      <Route
        exact
        path={`${BASE}/:eventType(${eventTypes})/${MANAGEMENT.HISTORIAL_NAME_SPACE}`}
        component={RecordHistory}
      />
      <Redirect to={`${BASE}/${ECONOMICA.EGRESO_COMPRA_INSUMOS}`} />
    </Switch>
  );
};

export default Routes;
