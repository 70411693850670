import React from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'antd';
import { EyeOutlined, UndoOutlined, HistoryOutlined } from '@ant-design/icons';
import { withAPI } from 'modules/api';
import {
  ScreenHeader,
  Table,
  DropdownMenu,
  AlertWithAction,
  Charts,
} from 'modules/core/components';
import FilterSider, {
  getRecordFilters,
  UnitFilter,
} from 'modules/core/components/Filter';
import {
  UnitDrawerRecordHistory,
  UnitDrawerShow,
} from 'modules/units/components';
import { GENERAL, UNITS } from 'modules/core/constants';
import RecordsMenu from './RecordsMenu/RecordsMenu';

const EventsRecordHistory = ({
  userSpecies,
  state,
  columns,
  unitTypes,
  withoutExportFields = false,
  customColumnRender = null,
}) => {
  let history = useHistory();
  const RecordFilters = getRecordFilters(state.type);

  const renderActions = ({ unidad_id: unitId, id: eventId, categoria }) => {
    const type = UNITS.getType(userSpecies, categoria);
    return (
      <DropdownMenu
        menu={_.compact([
          unitId && {
            title: 'Detalle',
            icon: <EyeOutlined />,
            onClick: () => state.setDetail({ id: unitId, type }),
          },
          {
            title: 'Deshacer',
            icon: <UndoOutlined />,
            onClick: () => state.onUndoEvent(eventId),
          },
          unitId && {
            title: 'Historial',
            icon: <HistoryOutlined />,
            onClick: () => state.setHistory({ id: unitId, type }),
          },
        ])}
      />
    );
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <ScreenHeader
          title="Historial"
          onRollback={() => history.goBack()}
          rightActions={
            <RecordsMenu
              disabled={state.undoingMultipleEvents}
              recordType={state.type}
              exportParams={state.filters.get()}
              onUpload={state.refetch}
              onUndo={state.setUndoingMultipleEvents}
              withoutExportFields={withoutExportFields}
            />
          }
        />
      </Col>

      <Col span={24}>
        <Row gutter={[16, 16]}>
          {_.map(state.kpis, ({ titulo, cantidad, porcentaje }, i) => (
            <Col span={6} key={i}>
              <Charts.KPI
                align="start"
                description={titulo}
                value={_.isNumber(cantidad) ? _.round(cantidad, 2) : '-'}
                subvalue={
                  _.isNumber(porcentaje)
                    ? GENERAL.roundPercentage(porcentaje, 2)
                    : ''
                }
              />
            </Col>
          ))}
        </Row>
      </Col>
      <Col span={6}>
        <FilterSider
          inline
          showDrawer
          showCurrentYear
          onChange={state.filters.set}
          filters={_.compact([unitTypes && UnitFilter, ...RecordFilters])}
          filtersProps={{ unitType: unitTypes }}
        />
      </Col>
      {state.undoingMultipleEvents && (
        <Col span={24}>
          <AlertWithAction
            message="Seleccione los eventos que desea deshacer y presione Confirmar."
            loading={state.isUndoingMultipleEvents}
            onCancel={state.clearSelection}
            onConfirm={() => state.confirmSelection(state.onUndoMultipleEvents)}
            disabled={state.rowSelection?.selectedRowKeys?.length <= 0}
          />
        </Col>
      )}
      <Col span={24}>
        <Table
          rowKey="id"
          dataSource={state.historial?.results}
          columns={columns[state.type](renderActions, customColumnRender)}
          showTotal={true}
          loading={state.isLoading}
          rowSelection={state.rowSelection}
          pagination={{
            ...state.pagination.get(),
            total: state.historial?.count,
          }}
          onChange={(p, f, s) => {
            state.pagination.set(p);
            state.sorter.set(s);
          }}
        />
      </Col>
      <UnitDrawerShow
        visible={!!state.detail}
        unit={state.detail}
        close={() => state.setDetail(null)}
      />
      <UnitDrawerRecordHistory
        visible={!!state.history}
        unitId={state.history?.id}
        success={state.refetch}
        close={() => state.setHistory(null)}
      />
    </Row>
  );
};

export default withAPI(EventsRecordHistory);
