import React, { useRef } from 'react';
import { useQuery } from 'react-query';
import { withAPI } from 'modules/api';
import { Spin } from 'antd';
import Tree from 'react-d3-tree';

import './UnitTreeGraph.scss';

const UnitTreeGraph = ({ api, unitId }) => {
  const treeContainer = useRef(null);

  const { data: unitFamilyTree, isLoading } = useQuery(
    ['Unit::fetchUnitFamilyTree', { unitId }],
    api.fetchUnitFamilyTree,
    { enabled: !!unitId }
  );

  const renderCustomNode = ({ nodeDatum }) => {
    return (
      <>
        <circle r={10} className="unit-tree-node-circle"></circle>
        <g className="unit-tree-node-group">
          <text className="unit-tree-node-label" y="40">
            {nodeDatum?.value?.title}
          </text>
          <text className="unit-tree-node-description" y="60">
            {nodeDatum?.value?.items[0]?.text}
          </text>
        </g>
      </>
    );
  };

  const containerDimensions = treeContainer?.current?.getBoundingClientRect();
  const hasDimensions =
    containerDimensions?.height && containerDimensions?.height;

  const treeData = {
    data: unitFamilyTree ?? {},
    hasInteractiveNodes: false,
    collapsible: false,
    dimensions: {
      width: containerDimensions?.width,
      height: containerDimensions?.height,
    },
    nodeSize: { x: 200, y: 100 },
    scaleExtent: { max: 2, min: 0.5 },
    separation: { nonSiblings: 1, siblings: 1 },
    translate: {
      x: containerDimensions?.width / 3,
      y: containerDimensions?.height / 2,
    },
    renderCustomNodeElement: (rd3tProps) => renderCustomNode(rd3tProps),
  };

  return (
    <div ref={treeContainer} className="unit-tree-graph">
      {!hasDimensions && isLoading && <Spin spinning={true} />}
      {hasDimensions && !isLoading && <Tree {...treeData} />}
    </div>
  );
};

export default withAPI(UnitTreeGraph);
