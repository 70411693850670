import { UNITS } from 'modules/core/constants';

const GestionProductivaAPI = (API) => {
  API.fetchKPIEvents = function ({ queryKey }) {
    return fetch('/api/core/unidades/analytics/kpis-eventos/', {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchByEvent = function ({ queryKey }) {
    const [, { eventType, unitType = null, params }] = queryKey;
    const qp = API.makeQueryString({ evento: eventType, ...params });
    return fetch(`/api/core/unidades/${unitType?.concat('/') ?? ''}?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  // It's used as useQuery
  API.fetchAsignacionesByUnits = function ({ queryKey }) {
    const [, { unitsIds }] = queryKey;
    return fetch(`/api/core/unidades/${UNITS.HEMBRAS}/asignadas/`, {
      method: 'POST',
      headers: API.getAuthHeaders(),
      body: JSON.stringify({ unidades_ids: unitsIds }),
    }).then(API.handleResponse);
  };

  API.applyProductivaEvent = function ({ units, eventType, params = '' }) {
    const qp = API.makeQueryString(params);
    return fetch(`/api/core/eventos/${eventType}/?${qp}`, {
      method: 'POST',
      headers: API.getAuthHeaders(),
      body: JSON.stringify(units),
    }).then(API.handleResponse);
  };

  API.undoProductivaEvent = function ({ eventType, eventId }) {
    return fetch(`/api/core/eventos/${eventType}/${eventId}/`, {
      method: 'DELETE',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.undoMultipleProductivaEvents = function ({ eventType, event_ids }) {
    return fetch(`/api/core/eventos/${eventType}/delete-multiple/`, {
      method: 'POST',
      headers: API.getAuthHeaders(),
      body: JSON.stringify({ event_ids }),
    }).then(API.handleResponse);
  };

  API.uploadProductivaEvent = function ({ eventType, inputFile }) {
    return fetch(`/api/core/eventos/${eventType}/excel-upload/`, {
      method: 'POST',
      headers: API.getAuthFormHeaders(),
      body: inputFile,
    }).then(API.handleBlobOnerror);
  };

  API.fieldsExportProductivaEvent = function ({ queryKey }) {
    const [, { eventType }] = queryKey;
    return fetch(`/api/core/eventos/${eventType}/export-fields/`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.exportProductivaEvent = function ({
    eventType,
    fields,
    withoutExportFields = false,
    params = null,
  }) {
    const qp = API.makeQueryString(params);
    const fetchOp = withoutExportFields
      ? { method: 'GET' }
      : { method: 'POST', body: JSON.stringify({ fields }) };

    return fetch(`/api/core/eventos/${eventType}/excel-download/?${qp}`, {
      headers: API.getAuthHeaders(),
      ...fetchOp,
    }).then(API.handleBlob);
  };

  // ++ RECORDS ++
  API.fetchProductivaRecords = function ({ queryKey }) {
    const [, { recordType, params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`/api/core/eventos/${recordType}/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchProductivaRecordsKPI = function ({ queryKey }) {
    const [, { recordType, params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`/api/core/eventos/analytics/kpis-${recordType}/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  // ++ INSUMOS ++
  API.fetchProductivaInsumo = function ({ queryKey }) {
    const [, { inputType, params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`/api/core/eventos/insumos/${inputType}/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchProductivaInsumoDisponible = function ({ queryKey }) {
    const [, { inputType }] = queryKey;
    return fetch(`/api/core/eventos/insumos/${inputType}/disponibles/`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchKPIProductivaInsumo = function ({ queryKey }) {
    const [, { inputType, params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(
      `/api/core/eventos/insumos/analytics/kpis-${inputType}/?${qp}`,
      {
        method: 'GET',
        headers: API.getAuthHeaders(),
      }
    ).then(API.handleResponse);
  };

  API.decrementProductivaInsumo = function ({ id, inputType, amount }) {
    return fetch(
      `/api/core/eventos/insumos/${inputType}/${id}/decrement/?cantidad=${amount}`,
      {
        method: 'POST',
        headers: API.getAuthHeaders(),
      }
    ).then(API.handleResponse);
  };

  API.addProductivaInsumo = function ({ body, inputType }) {
    return fetch(`/api/core/eventos/insumos/${inputType}/`, {
      method: 'POST',
      headers: API.getAuthHeaders(),
      body: JSON.stringify(body),
    }).then(API.handleResponse);
  };

  API.editProductivaInsumo = function ({ id, body, inputType }) {
    return fetch(`/api/core/eventos/insumos/${inputType}/${id}/`, {
      method: 'PATCH',
      headers: API.getAuthHeaders(),
      body: JSON.stringify(body),
    }).then(API.handleResponse);
  };

  API.deleteProductivaInsumo = function ({ id, inputType }) {
    return fetch(`/api/core/eventos/insumos/${inputType}/${id}/`, {
      method: 'DELETE',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchProductivaAdquisicion = function ({ queryKey }) {
    const [, { inputType, params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`/api/core/eventos/insumos/adquisicion-${inputType}/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.addProductivaAdquisicion = function ({ body, inputType }) {
    return fetch(`/api/core/eventos/insumos/adquisicion-${inputType}/`, {
      method: 'POST',
      headers: API.getAuthHeaders(),
      body: JSON.stringify(body),
    }).then(API.handleResponse);
  };

  API.editProductivaAdquisicion = function ({ id, body, inputType }) {
    return fetch(`/api/core/eventos/insumos/adquisicion-${inputType}/${id}/`, {
      method: 'PATCH',
      headers: API.getAuthHeaders(),
      body: JSON.stringify(body),
    }).then(API.handleResponse);
  };

  API.deleteProductivaAdquisicion = function ({ id, inputType }) {
    return fetch(`/api/core/eventos/insumos/adquisicion-${inputType}/${id}/`, {
      method: 'DELETE',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  return API;
};

export default GestionProductivaAPI;
