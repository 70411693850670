import React from 'react';
import { Form, Input, Row, Col, InputNumber } from 'antd';

const SuplementosForm = () => {
  return (
    <Row gutter={8}>
      <Col span={12}>
        <Form.Item
          label="Nombre"
          name="nombre"
          rules={[{ required: true, message: 'Ingrese nombre' }]}
        >
          <Input placeholder="Ingrese nombre" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Materia seca (%)"
          name="materia_seca"
          rules={[
            { required: true, message: 'Ingrese porcentaje de materia seca' },
          ]}
        >
          <InputNumber
            min={0}
            style={{ width: '100%' }}
            placeholder="Ingrese porcentaje"
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default SuplementosForm;
