import React from 'react';
import { useQuery } from 'react-query';
import { Spin, Table } from 'antd';
import { GENERAL } from 'modules/core/constants';

const EconomicTable = ({ currency = '', fetch, params = {} }) => {
  const { data, isLoading } = useQuery(
    ['Economica::fetchEconomicaDashboardDataTable', { params }],
    fetch
  );

  const valueProps = { align: 'right', width: 200 };
  const amountRender = {
    render: (v, r) => {
      if (r?.is_title) return '';
      return v !== null ? `${v} ${currency}` : '-';
    },
  };
  const percentageRender = {
    render: (v, r) => {
      if (r?.is_title) return '';
      return GENERAL.roundPercentage(v, 2);
    },
  };
  const columns = [
    {
      dataIndex: 'nombre',
    },
    {
      title: 'Ingresos',
      align: 'center',
      children: [
        {
          dataIndex: 'ingreso_monto',
          ...valueProps,
          ...amountRender,
        },
        {
          dataIndex: 'ingreso_porcentaje',
          ...valueProps,
          ...percentageRender,
        },
      ],
    },

    {
      title: 'Egresos',
      children: [
        {
          dataIndex: 'egreso_monto',
          ...valueProps,
          ...amountRender,
        },
        {
          dataIndex: 'egreso_porcentaje',
          ...valueProps,
          ...percentageRender,
        },
      ],
    },
  ];
  const renderTableFooter = () => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <b>{data?.total.nombre}</b>
      <b>
        {data?.total.monto} {currency}
      </b>
    </div>
  );

  const formatData = (tableData) => {
    const dataOperativa = [
      { id: 'operativos', nombre: 'Operativos', is_title: true },
      ...tableData?.operativo.map((d) => ({
        ...d,
        id: `op_${d.id}`,
      })),
    ];
    const dataNoOperativa = [
      { id: 'no_operativos', nombre: 'No operativos', is_title: true },
      ...tableData?.no_operativo.map((d) => ({
        ...d,
        id: `no_op_${d.id}`,
      })),
    ];

    return [...dataOperativa, ...dataNoOperativa];
  };

  if (isLoading) return <Spin />;

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={formatData(data?.table)}
      bordered
      loading={isLoading}
      size="middle"
      pagination={false}
      footer={renderTableFooter}
      rowClassName={(record) =>
        record?.is_total || record?.is_title ? 'totalizer-row' : ''
      }
    />
  );
};

export default EconomicTable;
