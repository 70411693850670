import React from 'react';
import { Table as TableAnt } from 'antd';

import './Table.scss';

const Table = ({ className, pagination, showTotal = false, ...props }) => {
  return (
    <TableAnt
      {...props}
      className={`finca-table ${className}`}
      pagination={
        pagination !== false && {
          showSizeChanger: true,
          position: ['bottomCenter'],
          defaultPageSize: 50,
          pageSizeOptions: [50, 100, 200, 300],
          ...pagination,
        }
      }
      footer={() =>
        showTotal && pagination?.total ? `- ${pagination.total} items -` : ''
      }
    />
  );
};

export default Table;
