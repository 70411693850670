import {
  PRODUCTIVA as mgmtPRODUCTIVA,
  SANITARIA as mgmtSANITARIA,
  NUTRICIONAL as mgmtNUTRICIONAL,
  ECONOMICA as mgmtECONOMICA,
} from '.';

const NAME_SPACE = 'gestion';

const HISTORIAL_NAME_SPACE = 'historial';
const ADQUISICIONES_NAME_SPACE = 'adquisiciones';

const PRODUCTIVA = mgmtPRODUCTIVA.NAME_SPACE;
const SANITARIA = mgmtSANITARIA.NAME_SPACE;
const NUTRICIONAL = mgmtNUTRICIONAL.NAME_SPACE;
const ECONOMICA = mgmtECONOMICA.NAME_SPACE;

const MANAGEMENT_TYPES = [PRODUCTIVA, SANITARIA];

const MANAGEMENT_FORMATTED = {
  [PRODUCTIVA]: 'Productiva',
  [SANITARIA]: 'Sanitaria',
  [NUTRICIONAL]: 'Nutricional',
  [ECONOMICA]: 'Económica',
};

const MANAGEMENT = {
  NAME_SPACE,
  HISTORIAL_NAME_SPACE,
  ADQUISICIONES_NAME_SPACE,
  PRODUCTIVA,
  SANITARIA,
  NUTRICIONAL,
  ECONOMICA,
  MANAGEMENT_TYPES,
  MANAGEMENT_FORMATTED,
};

export default MANAGEMENT;
