import React from 'react';
import { NUTRICIONAL, PRODUCTIVA, SANITARIA } from 'modules/core/constants';
import {
  EmbrionAdquisicionesForm,
  SemenAdquisicionesForm,
} from './ProductivaAdquisicionesForms';
import {
  ProductoAdquisicionesForm,
  VacunaAdquisicionesForm,
} from './SanitariaAdquisicionesForms';
import { SuplementoAdquisicionesForm } from './NutricionalAdquisicionesForms';

const ProductivaAdquisicionesForms = {
  [PRODUCTIVA.NOEVENT_TYPES.SEMEN]: {
    fields: ['id'],
    render: (props) => <SemenAdquisicionesForm {...props} />,
  },
  [PRODUCTIVA.NOEVENT_TYPES.EMBRION]: {
    outerFields: ['tipo_embrion', 'tecnica_obtencion', 'responsable'],
    fields: ['id'],
    render: (props) => <EmbrionAdquisicionesForm {...props} />,
  },
};

const SanitariaAdquisicionesForms = {
  [SANITARIA.NOEVENT_TYPES.PRODUCTO]: {
    fields: ['nombre'],
    render: (props) => <ProductoAdquisicionesForm {...props} />,
  },
  [SANITARIA.NOEVENT_TYPES.VACUNA]: {
    fields: ['nombre'],
    render: (props) => <VacunaAdquisicionesForm {...props} />,
  },
};

const NutricionalAdquisicionesForms = {
  [NUTRICIONAL.NOEVENT_TYPES.SUPLEMENTO]: {
    fields: ['nombre'],
    render: (props) => <SuplementoAdquisicionesForm {...props} />,
  },
};

const AdquisicionesForms = {
  ...ProductivaAdquisicionesForms,
  ...SanitariaAdquisicionesForms,
  ...NutricionalAdquisicionesForms,
};

export { AdquisicionesForms };
