import React, { useState } from 'react';
import { displayInfoModal } from 'modules/core/components';
import PartoDrawer from 'modules/management/productiva/events/Parto/PartoDrawer/PartoDrawer';

const usePartoRecordState = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [info, setInfo] = useState(null);
  const next = () => setInfo((i) => ({ ...i, current: i.current + 1 }));
  const set = (parto) => {
    setInfo({
      id: parto.id,
      current: parto.hijos.length + 1,
      total: parto.cantidad_hijos,
      data: {
        origen: 1,
        raza: parto.raza_id,
        madre: parto.madre_embrion_id ?? parto.unidad_id,
        padre: parto.padre,
        fecha_nacimiento: parto.fecha,
      },
    });
    setIsVisible(true);
  };
  const show = () => setIsVisible(true);
  const hide = () => setIsVisible(false);
  return { next, set, show, hide, visible: isVisible, info };
};

const PartoRecordDrawer = ({ success, close, visible, parto, next }) => {
  const onSuccess = (success) => {
    success();
    if (parto.current === parto.total) {
      close();
      displayInfoModal({
        showCheck: true,
        description:
          'Se realizó el registro de las unidades con éxito.\n Continuá tu gestión en Finca.',
      });
    } else next();
  };
  return (
    <PartoDrawer
      initialValues={parto}
      visible={visible}
      success={() => onSuccess(success)}
      cancel={close}
    />
  );
};

export { usePartoRecordState, PartoRecordDrawer };
