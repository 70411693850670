import React from 'react';
import './Title.scss';

// TODO: Desde UX/UI van a estandarizar los titulos en algun momento.
// Como ya existia este componente lo reutilizo, deje todo lo que estaba como level =0.
// Level = 0 => Es como se venia manejando todos los titulos (verdes y bold)
// Level = 1 y 2 => Los nuevos titulos y subtitulos, a futuro level = 1 deberia ser el default.
const Title = ({ text = '', level = 0, className, style = {} }) => (
  <div style={style} className={`${className} finca-title`} data-level={level}>
    {text}
  </div>
);

export default Title;
