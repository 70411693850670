const DashboardAPI = (API) => {
  const WEATHER_API_KEY = 'bf4da4795df134c26361933ad99e2f9f';

  API.fetchWeather = function ({ queryKey }) {
    const [, { lat, lon }] = queryKey;
    return fetch(
      `https://api.openweathermap.org/data/3.0/onecall?lat=${lat}&lon=${lon}&units=metric&exclude={part}&appid=${WEATHER_API_KEY}`,
      { method: 'GET' }
    ).then(API.handleResponse);
  };

  API.fetchMarketData = function ({ queryKey }) {
    return fetch(`/api/core/gestioneconomica/marketdata/prices/`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchKPIProductivityList = function ({ queryKey }) {
    return fetch('/api/core/eventos/analytics/kpis-productividad/', {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchKPIUnitStates = function ({ queryKey }) {
    return fetch('/api/core/unidades/analytics/kpis-estados/', {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchKPIUnits = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`/api/core/unidades/analytics/kpis-unidades/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchKPISanityList = function ({ queryKey }) {
    return fetch(
      '/api/core/gestionsanitaria/analytics/kpis-indice-sanitario/',
      {
        method: 'GET',
        headers: API.getAuthHeaders(),
      }
    ).then(API.handleResponse);
  };

  // ++ Planificacion ++
  API.fetchPlanificacionesEvents = function ({ queryKey }) {
    return fetch(`/api/core/eventos/planificacion/events_info/`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchPlanificacionesCalendar = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`/api/core/eventos/planificacion/calendar/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.addPlanificacion = function ({ body = {} }) {
    return fetch(`/api/core/eventos/planificacion/`, {
      method: 'POST',
      headers: API.getAuthHeaders(),
      body: JSON.stringify(body),
    }).then(API.handleResponse);
  };

  API.editPlanificacion = function ({ id, body = {} }) {
    return fetch(`/api/core/eventos/planificacion/${id}/`, {
      method: 'PATCH',
      headers: API.getAuthHeaders(),
      body: JSON.stringify(body),
    }).then(API.handleResponse);
  };

  API.deletePlanificacion = function ({ id }) {
    return fetch(`/api/core/eventos/planificacion/${id}/`, {
      method: 'DELETE',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchBajasMotivo = function ({ queryKey }) {
    return fetch(`/api/core/eventos/analytics/bajas-por-motivo/`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchSuperficieKPIs = function ({ queryKey }) {
    return fetch(`/api/core/unidades/analytics/kpis-superficie/`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchEventosKPIs = function ({ queryKey }) {
    return fetch(`/api/core/unidades/analytics/kpis-eventos-dashboard/`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchNotifications = function ({ queryKey }) {
    return fetch(`/api/core/notificaciones/`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.markAsReadNotification = function ({ id }) {
    return fetch(`/api/core/notificaciones/${id}/mark-as-readed/`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };
  return API;
};

export default DashboardAPI;
