import React from 'react';
import { useQuery } from 'react-query';
import { withAPI } from 'modules/api';
import { Space, Spin } from 'antd';
import { GENERAL } from 'modules/core/constants';
import { Card } from 'modules/core/components';
import TitleHeaderCard from '../TitleHeaderCard/TitleHeaderCard';

import './SuperficieCard.scss';

const SuperficieCard = ({ api, height = 160 }) => {
  const { data, isLoading } = useQuery(
    ['Home::fetchSuperficieKPIs'],
    api.fetchSuperficieKPIs
  );

  const renderKPI = ({ title, value }) => (
    <Space direction="vertical" align="center">
      <span className="superficie-value">{value}</span>
      <span className="superficie-title">{title}</span>
    </Space>
  );

  if (isLoading) return <Spin />;

  return (
    <Card className="superficie-card" style={{ height }}>
      <TitleHeaderCard title="Mi establecimiento" />
      <div className="superficie-card-body">
        {renderKPI({
          title: 'Superficie ganadera',
          value: GENERAL.numToFront(data?.superficie_ganadera, 2, 'ha'),
        })}
        {renderKPI({
          title: 'Unidades',
          value: data?.cantidad_unidades ? data?.cantidad_unidades : 0,
        })}
        {renderKPI({
          title: 'Carga animal',
          value: GENERAL.numToFront(data?.carga_animal, 2),
        })}
      </div>
    </Card>
  );
};

export default withAPI(SuperficieCard);
