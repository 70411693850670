import React from 'react';
import { useQuery } from 'react-query';
import { withAPI } from 'modules/api';
import { Form, Col, Select } from 'antd';
import { GENERAL, SANITARIA } from 'modules/core/constants';

const VacunaAdquisicionesForm = ({ api, visible }) => {
  const { data: vacunas, isLoading: isLoadingOptions } = useQuery(
    ['Sanitaria::Vacunas', { inputType: SANITARIA.VACUNA }],
    api.fetchInsumo,
    { enabled: visible }
  );
  const vacunasOptions = GENERAL.objsToOptions(vacunas?.results, {
    value: 'nombre',
    label: 'nombre',
  });

  return (
    <>
      <Col span={24}>
        <Form.Item
          label="Vacuna"
          name="nombre"
          rules={[{ required: true, message: 'Ingrese vacuna' }]}
        >
          <Select
            showSearch
            allowClear
            style={{ width: '100%' }}
            optionFilterProp="label"
            placeholder="Seleccionar vacuna"
            loading={isLoadingOptions}
            options={vacunasOptions}
          />
        </Form.Item>
      </Col>
    </>
  );
};

export default withAPI(VacunaAdquisicionesForm);
