import React from 'react';
import { useQuery } from 'react-query';
import { withAPI } from 'modules/api';
import { Form, Col, Select } from 'antd';
import { GENERAL, SANITARIA } from 'modules/core/constants';

const ProductoAdquisicionesForm = ({ api, visible }) => {
  const { data: productos, isLoading: isLoadingOptions } = useQuery(
    ['Sanitaria::Productos', { inputType: SANITARIA.PRODUCTO }],
    api.fetchInsumo,
    { enabled: visible }
  );
  const productosOptions = GENERAL.objsToOptions(productos?.results, {
    value: 'nombre',
    label: 'nombre',
  });

  return (
    <>
      <Col span={24}>
        <Form.Item
          label="Producto"
          name="nombre"
          rules={[{ required: true, message: 'Ingrese producto' }]}
        >
          <Select
            showSearch
            allowClear
            style={{ width: '100%' }}
            optionFilterProp="label"
            placeholder="Seleccionar producto"
            loading={isLoadingOptions}
            options={productosOptions}
          />
        </Form.Item>
      </Col>
    </>
  );
};

export default withAPI(ProductoAdquisicionesForm);
