import React from 'react';
import { useQuery } from 'react-query';
import { withAPI } from 'modules/api';
import _ from 'lodash';
import { Modal, Spin } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { MASTERS, UNITS } from 'modules/core/constants';
import { ModalTitle } from 'modules/core/components';
import UnitReportPDF from './UnitReportPDF';

const UnitReportPDFModal = ({ api, visible, unit, close }) => {
  const unitId = unit?.id;
  const unitType = unit?.sexo || unit?.type;
  const isEnabled = !!(unitId && visible);
  const { data: unitInfo, isLoading: isLoadingUnitInfo } = useQuery(
    ['UnitDrawer::fetchUnit', { unitId, unitType, params: { bajas: true } }],
    api.fetchUnit,
    { enabled: isEnabled }
  );
  const { data: unitRecords, isLoading: isLoadingUnitRecords } = useQuery(
    ['Unit::fetchUnitEventsRecords', { unitId }],
    api.fetchUnitEventsRecords,
    { enabled: isEnabled }
  );
  const { data: unitFamilyTree, isLoading: isLoadingUnitFamilyTree } = useQuery(
    ['Unit::fetchUnitFamilyTree', { unitId }],
    api.fetchUnitFamilyTree,
    { enabled: isEnabled }
  );

  const { data: masters, isLoading: isLoadingMasters } = useQuery(
    [
      'Masters::Bulk',
      {
        masterTypes: [
          MASTERS.PRODUCTIVA_TYPES.ORIGEN,
          MASTERS.PRODUCTIVA_TYPES.RAZA,
          MASTERS.PRODUCTIVA_TYPES.REGISTRO,
          MASTERS.PRODUCTIVA_TYPES.COLOR,
          MASTERS.PRODUCTIVA_TYPES.UBICACION,
          MASTERS.PRODUCTIVA_TYPES.CRIADOR,
          MASTERS.PRODUCTIVA_TYPES.RODEO,
          MASTERS.PRODUCTIVA_TYPES.OBJETIVO_UNIDAD,
        ],
      },
    ],
    api.fetchMastersFormOptions,
    { enabled: isEnabled, staleTime: 10000 }
  );

  const isLoading =
    isLoadingUnitInfo ||
    isLoadingUnitRecords ||
    isLoadingUnitFamilyTree ||
    isLoadingMasters;

  return (
    <Modal
      title={
        <ModalTitle icon={<FilePdfOutlined />} title="Exportar ficha en PDF" />
      }
      forceRender
      cancelText="Cerrar"
      open={visible}
      onCancel={close}
      footer={null}
    >
      <Spin spinning={isLoading} />
      {isEnabled && !isLoading && (
        <PDFDownloadLink
          fileName="Ficha de unidad.pdf"
          document={
            <UnitReportPDF
              userSpecies={api.userSpecies}
              header={UNITS.formatUnit(unitInfo, masters).header}
              info={UNITS.formatUnit(unitInfo, masters).info}
              infoExtra={{
                datosAdicionales: _.chunk(unitInfo.datoadicional, 3),
                deps: _.chunk(unitInfo.dep, 4),
              }}
              records={unitRecords?.eventos}
              familyTree={UNITS.formatUnitTree(unitFamilyTree)}
              observations={unitInfo?.comentarios}
            />
          }
        >
          {({ blob, url, loading, error }) =>
            loading
              ? 'Preparando la ficha de unidad...'
              : 'Descargar ficha de unidad'
          }
        </PDFDownloadLink>
      )}
    </Modal>
  );
};

export default withAPI(UnitReportPDFModal);
