import React, { useCallback, useEffect } from 'react';
import { useMutation } from 'react-query';
import { Col, Form, Modal, Row, DatePicker } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { withAPI } from 'modules/api';
import { GENERAL } from 'modules/core/constants';
import { displayInfoModal, ModalTitle } from 'modules/core/components';
import './AdquisicionesModalEdit.scss';
import moment from 'moment';

const AdquisicionesModalEdit = ({
  api,
  adquisicion,
  inputType,
  visible,
  close,
  success,
}) => {
  const { mutate: editAdquisicion, isLoading: editing } = useMutation(
    api.editAdquisicion
  );
  const [form] = Form.useForm();

  const clearForm = useCallback(() => {
    form.resetFields();
  }, [form]);
  useEffect(clearForm, [clearForm, visible]);

  const onSubmitAdquisicion = () => {
    form
      .validateFields()
      .then(({ fecha }) => {
        const editedAdquisicion = { fecha: GENERAL.dateToBack(fecha) };
        const onSuccess = () => {
          success();
          displayInfoModal({
            description: `Se modificó la adquisición con éxito.`,
            showCheck: true,
          });
        };
        editAdquisicion(
          { id: adquisicion.id, body: editedAdquisicion, inputType },
          { onSuccess }
        );
      })
      .catch(() => {});
  };

  return (
    <Modal
      title={
        <ModalTitle icon={<EditOutlined />} title="Modificar adquisición" />
      }
      forceRender
      className="adquisicion-add-modal"
      cancelText="CANCELAR"
      okText="MODIFICAR"
      confirmLoading={editing}
      open={visible}
      onCancel={close}
      onOk={onSubmitAdquisicion}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={{
          ...adquisicion,
          fecha: adquisicion?.fecha && moment(adquisicion.fecha),
        }}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="Fecha de adquisición"
              name="fecha"
              rules={[{ required: true, message: 'Ingrese fecha' }]}
            >
              <DatePicker
                style={{ width: '100%' }}
                format={GENERAL.DATE_FORMAT.FRONT}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default withAPI(AdquisicionesModalEdit);
