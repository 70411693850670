const NAME_SPACE = 'establecimiento';

const PLANIFICACION = 'planificacion';
const TAREAS = 'tareas';
const RODEOS = 'rodeo';
const CRIADORES = 'criador';
const UBICACIONES = 'ubicacion';
const RESPONSABLES = 'responsables';
const ENTITIES = { RODEOS, CRIADORES, UBICACIONES, RESPONSABLES };
const ENTITIES_LIST = Object.values(ENTITIES);

const MOVIMIENTOS_NAME_SPACE = 'movimientos';

const MOVIMIENTOS_LIST = [RODEOS, UBICACIONES];

const PLANIFICACION_CALENDAR_BADGES = {
  productiva: 'orange',
  sanitaria: 'cyan',
  nutricional: 'green',
  economica: 'purple',
};

const TAREAS_PRIORIDADES = {
  URGENTE: 'urgente',
  ALTA: 'alta',
  MEDIA: 'media',
  BAJA: 'baja',
};

const TAREAS_PRIORIDADES_BADGES = {
  [TAREAS_PRIORIDADES.URGENTE]: 'red',
  [TAREAS_PRIORIDADES.ALTA]: 'volcano',
  [TAREAS_PRIORIDADES.MEDIA]: 'gold',
  [TAREAS_PRIORIDADES.BAJA]: 'purple',
};

const ESTABLISHMENT = {
  NAME_SPACE,
  PLANIFICACION,
  TAREAS,
  RODEOS,
  CRIADORES,
  UBICACIONES,
  RESPONSABLES,
  ENTITIES,
  ENTITIES_LIST,
  MOVIMIENTOS_NAME_SPACE,
  MOVIMIENTOS_LIST,
  PLANIFICACION_CALENDAR_BADGES,
  TAREAS_PRIORIDADES_BADGES,
};

export default ESTABLISHMENT;
