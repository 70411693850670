import React, { useState } from 'react';
import { Spin } from 'antd';
import { UndoOutlined } from '@ant-design/icons';
import { DropdownMenu } from 'modules/core/components';
import './UnitRecordCard.scss';

const UnitRecordCard = ({ event, date, data, onUndo }) => {
  const [undoing, setUndoing] = useState(false);
  const renderActions = () => (
    <DropdownMenu
      className="unit-record-actions"
      menu={[
        {
          title: 'Deshacer',
          icon: <UndoOutlined />,
          onClick: () => {
            setUndoing(true);
            onUndo()
              .then(() => setUndoing(false))
              .catch(() => setUndoing(false));
          },
        },
      ]}
    />
  );

  return (
    <div className="unit-record-card">
      <Spin spinning={undoing}>
        {onUndo && renderActions()}
        <div className="unit-record-card-header">
          <div className="unit-record-card-event">{event}</div>
          <div className="unit-record-card-date">{date}</div>
        </div>
        <div className="unit-record-card-body">{data}</div>
      </Spin>
    </div>
  );
};

export default UnitRecordCard;
