import React from 'react';
import { Spin } from 'antd';
import { ReactComponent as HouseIcon } from 'assets/home/house.svg';

import './WelcomeUser.scss';

const WelcomeUser = ({ loading = true, barnName = '', userName = '' }) => (
  <Spin spinning={loading}>
    {!loading && (
      <div className="welcome-user">
        <div className="welcome-user-company">
          <HouseIcon alt="house" className="welcome-user-icon" />
          <span>
            Establecimiento <b>{barnName}</b>
          </span>
        </div>
        <div className="welcome-user-name">
          Buen día <span>{userName}</span>
        </div>
        mantenete actualizado con las novedades de tu establecimiento.
      </div>
    )}
  </Spin>
);

export default WelcomeUser;
