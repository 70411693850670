import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { FileAddOutlined, DeleteOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';
import { withAPI } from 'modules/api';
import { Table, DropdownMenu, DeleteModal } from 'modules/core/components';
import { GENERAL } from 'modules/core/constants';
import UnitsSyncModal from './UnitsSyncModal';

const SYNC_ACTIONS_INITIAL_VALUES = { item: null, action: null };

const UnitsSync = ({ api }) => {
  const [selectedItem, setSelectedItem] = useState(SYNC_ACTIONS_INITIAL_VALUES);

  const { data, isLoading, refetch } = useQuery(
    ['Mobile::UnitsImageSync'],
    api.mobileFetchUnitsImageSync
  );

  const resetSelectedItem = () => {
    setSelectedItem(SYNC_ACTIONS_INITIAL_VALUES);
  };

  const { mutate: mobileDeleteUnitsImageSync, isLoading: isDeleting } =
    useMutation(api.mobileDeleteUnitsImageSync);

  const handleDelete = ({ onSuccess, onError }) => {
    mobileDeleteUnitsImageSync(
      { fileId: selectedItem.item.id },
      { onSuccess, onError }
    );
  };

  const columns = [
    {
      dataIndex: 'created_on',
      title: 'Fecha de carga',
      render: (d) => GENERAL.dateToFront(d, true),
    },
    {
      dataIndex: 'user',
      title: 'Usuario',
    },
    {
      dataIndex: 'image_file',
      title: 'Imagen',
      render: (v) => (
        <a href={v} target="_blank" rel="noreferrer">
          Ver imagen
        </a>
      ),
    },
    {
      dataIndex: 'xls_file',
      title: 'Archivo generado',
      render: (v) =>
        v ? (
          <a href={v} target="_blank" rel="noreferrer">
            Descargar archivo
          </a>
        ) : (
          'No'
        ),
    },
    {
      dataIndex: 'file_uploaded',
      title: 'Unidades cargadas',
      render: (v) => (v ? 'Si' : 'No'),
    },
    {
      title: 'Acciones',
      align: 'right ',
      render: (_t, item) => (
        <DropdownMenu
          menu={[
            {
              title: 'Carga de unidades',
              icon: <FileAddOutlined />,
              disabled: item.file_uploaded,
              onClick: () =>
                setSelectedItem({ item, action: GENERAL.ACTIONS.MODIFICAR }),
            },
            {
              title: 'Eliminar',
              icon: <DeleteOutlined />,
              onClick: () =>
                setSelectedItem({ item, action: GENERAL.ACTIONS.ELIMINAR }),
            },
          ]}
        />
      ),
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Table
          rowKey="id"
          loading={isLoading}
          columns={columns}
          dataSource={data}
          rowClassName={(record) =>
            (!record.xls_file || !record.file_uploaded) && 'bold'
          }
        />
      </Col>
      <UnitsSyncModal
        open={selectedItem.action === GENERAL.ACTIONS.MODIFICAR}
        item={selectedItem.item}
        onCancel={resetSelectedItem}
        refetch={refetch}
      />
      <DeleteModal
        title="Eliminar imagen"
        isDeleting={isDeleting}
        visible={selectedItem.action === GENERAL.ACTIONS.ELIMINAR}
        onCancel={resetSelectedItem}
        onConfirm={handleDelete}
        onSuccess={() => {
          refetch();
          resetSelectedItem();
        }}
        onSuccessInfo={{
          description: 'Imagen eliminada con éxito.',
          showCheck: true,
        }}
      />
    </Row>
  );
};

export default withAPI(UnitsSync);
