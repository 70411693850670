import React from 'react';
import { Row, Col, Input, Space } from 'antd';
import { Table, Title } from 'modules/core/components';
import { GENERAL, UNITS } from 'modules/core/constants';

const { Search } = Input;

const defaultColumns = (sorter = false) => [
  {
    title: 'RP',
    dataIndex: 'rp',
    sorter,
  },
  {
    title: 'ID Electrónico',
    dataIndex: 'id_electronico',
    sorter,
  },
  {
    title: 'RP Madre',
    dataIndex: 'rp_madre',
    key: 'madre__rp',
    sorter,
  },
  {
    title: 'Padre',
    dataIndex: 'rp_padre',
    key: 'padre__rp',
    sorter,
    render: (_, r) => `${r.rp_padre ?? ''} ${r.apodo_padre ?? ''}`,
  },
  {
    title: 'Nacimiento',
    dataIndex: 'fecha_nacimiento',
    sorter,
    render: (d) => GENERAL.dateToFront(d),
  },
  {
    title: 'Categoría',
    dataIndex: 'categoria',
    render: (c) => UNITS.CATEGORIES_FORMATTED[c],
  },
];

const femalesColumns = () => [
  {
    title: 'RP',
    dataIndex: 'rp',
    sorter: (a, b) => a.rp.localeCompare(b.rp),
  },
  {
    title: 'Nacimiento',
    dataIndex: 'fecha_nacimiento',
    render: (d) => GENERAL.dateToFront(d),
  },
  {
    title: 'Categoría',
    dataIndex: 'categoria',
    render: (c) => UNITS.CATEGORIES_FORMATTED[c],
  },
];

const malesColumns = (registros) => [
  {
    title: 'RP',
    dataIndex: 'rp',
    sorter: (a, b) => a.rp.localeCompare(b.rp),
  },
  {
    title: 'Apodo',
    dataIndex: 'apodo',
  },
  {
    title: 'Registro',
    dataIndex: 'registro',
    render: (v) => GENERAL.optionToLabel(registros, v),
  },
];

const actionsColumn = (renderActions) => [
  {
    title: 'Acciones',
    align: 'right ',
    render: (_, record) => renderActions?.(record),
  },
];

const ServicioColectivoTable = ({
  data,
  form = null,
  rowKey = 'id',
  loading = false,
  registros = null,
  razas = null,
  maleUnits,
  maleSelection,
  maleLoading,
  maleRefetch,
  malePagination,
  malePaginationSet,
  actions = null,
  ...props
}) => {
  if (form) {
    return (
      <Row gutter={[48, 0]}>
        <Col span={16}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Title
              level={0}
              text="Hembras seleccionadas para registrar el servicio"
            />
            <Table
              key={'females'}
              rowKey={rowKey}
              rowClassName="table-row"
              columns={femalesColumns(registros)}
              dataSource={data}
              {...props}
            />
          </Space>
        </Col>
        <Col span={8}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Title level={0} text="Machos para dar servicio" />
              <Search
                style={{ width: '40%' }}
                placeholder="Buscar"
                allowClear
                onSearch={(v) => maleRefetch(v || undefined)}
              />
            </div>
            <Table
              key={'males'}
              rowKey={rowKey}
              rowClassName="table-row"
              columns={malesColumns(registros)}
              dataSource={maleUnits?.results}
              rowSelection={maleSelection}
              loading={maleLoading}
              pagination={malePagination}
              onChange={malePaginationSet}
            />
          </Space>
        </Col>
      </Row>
    );
  }
  return (
    <Table
      rowKey={rowKey}
      rowClassName="table-row"
      columns={defaultColumns(true).concat(actionsColumn(actions))}
      dataSource={data}
      loading={loading}
      {...props}
    />
  );
};

export default ServicioColectivoTable;
