import React from 'react';
import { useQuery } from 'react-query';
import { withAPI } from 'modules/api';
import moment from 'moment';
import { Row, Col, Spin } from 'antd';
import { Card } from 'modules/core/components';

import './LicenseCard.scss';

const LicenseCard = ({ api }) => {
  const {
    data: license,
    isLoading,
    error,
  } = useQuery(['Home::UserLicense'], api.fetchUserLicense);

  if (isLoading) return <Spin spinning={isLoading} />;

  if (error)
    return (
      <div className="license-card">Error al obtener datos de licencia</div>
    );

  const renderLienceInfo = ({ label, value }) => {
    return (
      <Col span={12}>
        <div>{label}</div>
        <div className="license-info">{value}</div>
      </Col>
    );
  };

  return (
    <Card title="Licencia" className="license-card">
      <Row className="license-info-line" justify="space-between">
        {renderLienceInfo({ label: 'Nombre', value: license.name ?? '-' })}
        {renderLienceInfo({
          label: 'Días restantes',
          value: license.remaining_days ?? '-',
        })}
      </Row>
      <Row className="license-info-line" justify="space-between">
        {renderLienceInfo({
          label: 'Fecha de inicio',
          value: license.start_date
            ? moment(license.start_date).format('DD/MM/YYYY')
            : '-',
        })}
        {renderLienceInfo({
          label: 'Fecha de fin',
          value: license.end_date
            ? moment(license.end_date).format('DD/MM/YYYY')
            : '-',
        })}
      </Row>
    </Card>
  );
};

export default withAPI(LicenseCard);
