import React from 'react';
import { useQuery } from 'react-query';
import { withAPI } from 'modules/api';
import { Col, Row } from 'antd';
import { usePaginationWithFilters } from 'modules/core/hooks';
import { GENERAL, UNITS } from 'modules/core/constants';
import { Card, Charts } from 'modules/core/components';
import FilterSider, { UnitFilter } from 'modules/core/components/Filter';

const HEIGHT_ROW_1 = 400;

const StockAnalytics = ({ api, isActive }) => {
  const { filters } = usePaginationWithFilters();
  const { data: stockActivas, isLoading: stockActivasLoading } = useQuery(
    ['Analytics::fetchUnitStockActivas', { params: filters.get() }],
    api.fetchUnitStockActivas,
    { enabled: isActive }
  );
  const { data: stockByCategoria, isLoading: stockByCategoriaLoading } =
    useQuery(
      ['Analytics::fetchUnitStockByCategoria', { params: filters.get() }],
      api.fetchUnitStockByCategoria,
      { enabled: isActive }
    );
  const { data: hembrasByEstado, isLoading: hembrasByEstadoLoading } = useQuery(
    ['Analytics::fetchUnitStockHembrasByEstado', { params: filters.get() }],
    api.fetchUnitStockHembrasByEstado,
    { enabled: isActive }
  );
  const { data: stockBajas, isLoading: stockBajasLoading } = useQuery(
    ['Analytics::fetchUnitStockBajas', { params: filters.get() }],
    api.fetchUnitStockBajas,
    { enabled: isActive }
  );
  const { data: bajasByMotivo, isLoading: bajasByMotivoLoading } = useQuery(
    ['Analytics::fetchUnitStockBajasByMotivo', { params: filters.get() }],
    api.fetchUnitStockBajasByMotivo,
    { enabled: isActive }
  );
  const { data: stockEvolucion, isLoading: stockEvolucionLoading } = useQuery(
    ['Analytics::fetchUnitStockEvolucion', { params: filters.get() }],
    api.fetchUnitStockEvolucion,
    { enabled: isActive, cacheTime: 0 }
  );

  return (
    <>
      <Row gutter={[24, 32]} className="data-row">
        <Col span={24}>
          <FilterSider
            showDrawer
            showCurrentYear
            showGlobalSearch={false}
            filters={[UnitFilter]}
            filtersProps={{ unitType: [UNITS.HEMBRAS, UNITS.MACHOS] }}
            onChange={filters.set}
          />
        </Col>
        <Col span={4}>
          <Charts.KPI
            height={HEIGHT_ROW_1}
            loading={stockActivasLoading}
            title={stockActivas?.titulo}
            value={stockActivas?.data}
            tooltip={stockActivas?.calculo}
            stylesKPI={{ value: { fontSize: 60 } }}
          />
        </Col>
        <Col span={10}>
          <Card
            type="secondary"
            loading={stockByCategoriaLoading}
            title={stockByCategoria?.titulo}
            tooltip={stockByCategoria?.calculo}
            style={{ height: HEIGHT_ROW_1 }}
          >
            <Charts.Pie
              data={GENERAL.formatPieChartData(stockByCategoria?.data)}
              innerRadius={0.5}
            />
          </Card>
        </Col>
        <Col span={10}>
          <Card
            type="secondary"
            loading={hembrasByEstadoLoading}
            title={hembrasByEstado?.titulo}
            tooltip={hembrasByEstado?.calculo}
            style={{ height: HEIGHT_ROW_1 }}
          >
            <Charts.Bar
              data={hembrasByEstado?.data}
              indexBy="label"
              keys={['value']}
              legends={[]}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={[24, 32]} className="data-row">
        <Col span={12}>
          <Charts.KPI
            height={HEIGHT_ROW_1}
            loading={stockBajasLoading}
            title={stockBajas?.titulo}
            value={stockBajas?.data}
            tooltip={stockBajas?.calculo}
            stylesKPI={{ value: { fontSize: 60 } }}
          />
        </Col>
        <Col span={12}>
          <Card
            type="secondary"
            loading={bajasByMotivoLoading}
            title={bajasByMotivo?.titulo}
            tooltip={bajasByMotivo?.calculo}
            style={{ height: HEIGHT_ROW_1 }}
          >
            <Charts.Pie
              data={GENERAL.formatPieChartData(bajasByMotivo?.data)}
              innerRadius={0.5}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={[24, 32]} className="data-row">
        <Col span={24}>
          <Card
            type="secondary"
            loading={stockEvolucionLoading}
            title={stockEvolucion?.titulo}
            tooltip={stockEvolucion?.calculo}
            style={{ height: HEIGHT_ROW_1 }}
          >
            <Charts.Line
              data={stockEvolucion?.data}
              colors={['#74c476', '#f47560']}
              axisLeft={{ legend: 'Unidades' }}
              yFormat=" >-.0f"
              enablePoints={false}
              enableGridX={false}
              axisBottom={{
                tickValues: GENERAL.generateTickValues(
                  stockEvolucion?.data[0]?.data
                ),
              }}
              legends={[]}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default withAPI(StockAnalytics);
