import EventsCard from './EventsCard';
import UnitsBajasByMotivoCard from './UnitsBajasByMotivoCard';
import UnitsCategoryCard from './UnitsCategoryCard';
import UnitsStatesCard from './UnitsStatesCard';

const ManagementListCards = {
  Events: EventsCard,
  UnitsBajasByMotivo: UnitsBajasByMotivoCard,
  UnitsCategory: UnitsCategoryCard,
  UnitsStates: UnitsStatesCard,
};

export default ManagementListCards;
