import React from 'react';
import { Form, Col, Select } from 'antd';
import { GENERAL, MASTERS, UNITS } from 'modules/core/constants';
import { withAPI } from 'modules/api';
import { useQuery } from 'react-query';

const { Item } = Form;
const PARAMS = {
  UBICACION_PREVIA: 'ubicacion_previa',
  RODEO_PREVIO: 'rodeo_previo',
  ESTADIO_PREVIO: 'estado_previo',
};

const tags = {
  [PARAMS.UBICACION_PREVIA]: 'Ubicacion previa',
  [PARAMS.RODEO_PREVIO]: 'Rodeo previo',
  [PARAMS.ESTADIO_PREVIO]: 'Estado previo',
};

const FormFields = ({ api, unitType = UNITS.HEMBRAS }) => {
  const queryOptions = { staleTime: 100000 };
  const { data: options, isLoading: isLoadingOptions } = useQuery(
    [
      'MAESTROS::fetchMastersAsFormOptions',
      {
        masterTypes: [
          MASTERS.PRODUCTIVA_TYPES.UBICACION,
          MASTERS.PRODUCTIVA_TYPES.RODEO,
        ],
      },
    ],
    api.fetchMastersFormOptions,
    queryOptions
  );

  return (
    <>
      <Col span={24}>
        <Item
          label={tags[PARAMS.UBICACION_PREVIA]}
          name={PARAMS.UBICACION_PREVIA}
        >
          <Select
            placeholder="Seleccionar valor"
            allowClear
            labelInValue={true}
            dropdownMatchSelectWidth={200}
            loading={isLoadingOptions}
            options={options?.[MASTERS.PRODUCTIVA_TYPES.UBICACION]}
          />
        </Item>
      </Col>
      <Col span={24}>
        <Item label={tags[PARAMS.RODEO_PREVIO]} name={PARAMS.RODEO_PREVIO}>
          <Select
            placeholder="Seleccionar valor"
            allowClear
            labelInValue={true}
            dropdownMatchSelectWidth={200}
            loading={isLoadingOptions}
            options={options?.[MASTERS.PRODUCTIVA_TYPES.RODEO]}
          />
        </Item>
      </Col>
      <Col span={24}>
        <Item label={tags[PARAMS.ESTADIO_PREVIO]} name={PARAMS.ESTADIO_PREVIO}>
          <Select
            placeholder="Seleccionar valor"
            mode="multiple"
            showArrow
            allowClear
            labelInValue={true}
            dropdownMatchSelectWidth={200}
            disabled={!UNITS.isFromType(unitType, UNITS.HEMBRAS)}
            options={GENERAL.arrayToOptions(
              UNITS.ESTADOS_LIST,
              (s) => UNITS.ESTADOS_FORMATTED[s]
            )}
          />
        </Item>
      </Col>
    </>
  );
};

const filterComponent = {
  tags,
  FormFields: withAPI(FormFields),
};

export default filterComponent;
