import React from 'react';
import { useQuery } from 'react-query';
import { withAPI } from 'modules/api';
import _ from 'lodash';
import { Col, Spin } from 'antd';
import { GENERAL } from 'modules/core/constants';
import { Charts } from 'modules/core/components';

import './ManagementKPIs.scss';

const ManagementKPIs = ({ api, height = 200 }) => {
  const { data, isLoading } = useQuery(
    ['Home::fetchEventosKPIs'],
    api.fetchEventosKPIs
  );

  const renderKPI = ({ title, value }) => {
    return (
      <Col xs={12} lg={4}>
        <Charts.KPI height={height} description={title} value={value} />
      </Col>
    );
  };

  if (isLoading) return <Spin />;

  return (
    <>
      {renderKPI({
        title: '% de Preñez',
        value: GENERAL.roundPercentage(data?.porcentaje_prenez),
      })}
      {renderKPI({
        title: '% de Abortos',
        value: GENERAL.roundPercentage(data?.porcentaje_abortos),
      })}
      {renderKPI({
        title: 'Promedio IEP',
        value: _.round(data?.promedio_iep, 2),
      })}
      {renderKPI({
        title: 'Peso promedio al nacimiento',
        value: _.round(data?.peso_promedio_nacimiento, 2),
      })}
      {renderKPI({
        title: 'Peso promedio al destete',
        value: _.round(data?.peso_promedio_destete, 2),
      })}
    </>
  );
};

export default withAPI(ManagementKPIs);
