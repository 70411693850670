import React from 'react';
import { Form, Input, Row, Col } from 'antd';

const ResponsablesForm = () => {
  return (
    <Row>
      <Col span={24}>
        <Form.Item
          label="Nombre responsable"
          name="nombre"
          rules={[{ required: true, message: 'Ingrese nombre responsable' }]}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default ResponsablesForm;
