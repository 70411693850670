import React from 'react';
import { Button } from 'antd';
import { LeftCircleFilled } from '@ant-design/icons';

const RollbackButton = ({ onRollback, label = 'Volver' }) => (
  <Button
    style={{ padding: 0 }}
    type="link"
    onClick={onRollback}
    icon={<LeftCircleFilled style={{ fontSize: 20 }} />}
  >
    <span style={{ color: '#575757' }}>{label}</span>
  </Button>
);

export default RollbackButton;
