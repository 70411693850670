import React from 'react';
import { DropdownMenu } from 'modules/core/components';
import { EyeOutlined, HistoryOutlined } from '@ant-design/icons';
import { GENERAL } from 'modules/core/constants';

const EventsTableActions = ({ item, onAction }) => {
  return (
    <DropdownMenu
      menu={[
        {
          title: 'Detalle',
          icon: <EyeOutlined />,
          onClick: () =>
            onAction({
              item,
              action: GENERAL.ACTIONS.VER_UNIDAD,
            }),
        },
        {
          title: 'Ver historial',
          icon: <HistoryOutlined />,
          onClick: () =>
            onAction({
              item,
              action: GENERAL.ACTIONS.VER_HISTORIAL,
            }),
        },
      ]}
    />
  );
};

export default EventsTableActions;
