import React from 'react';
import { Table } from 'modules/core/components';
import { ECONOMICA, GENERAL } from 'modules/core/constants';

const defaultColumns = [
  {
    title: 'Fecha',
    dataIndex: 'fecha',
    sorter: true,
    render: (d) => GENERAL.dateToFront(d),
  },
  {
    title: 'Cantidad',
    dataIndex: 'cantidad',
    sorter: true,
  },
];

const COLUMNS = {};

COLUMNS[ECONOMICA.EVENT_EGRESO_COMPRA_INSUMOS.SEMEN] = [
  {
    title: 'Macho',
    dataIndex: ['semen', 'macho'],
    key: 'semen__macho',
    sorter: true,
  },
];

COLUMNS[ECONOMICA.EVENT_EGRESO_COMPRA_INSUMOS.EMBRION] = [
  {
    title: 'Macho',
    dataIndex: ['embrion', 'macho'],
    key: 'embrion__macho',
    sorter: true,
  },
  {
    title: 'Hembra',
    dataIndex: ['embrion', 'hembra'],
    key: 'embrion__hembra',
    sorter: true,
  },
];

COLUMNS[ECONOMICA.EVENT_EGRESO_COMPRA_INSUMOS.VACUNA] = [
  {
    title: 'Nombre',
    dataIndex: ['vacuna', 'nombre'],
    key: 'vacuna__nombre',
    sorter: true,
  },
];

COLUMNS[ECONOMICA.EVENT_EGRESO_COMPRA_INSUMOS.PRODUCTO] = [
  {
    title: 'Nombre',
    dataIndex: ['producto', 'nombre'],
    key: 'producto__nombre',
    sorter: true,
  },
  {
    title: 'Marca',
    dataIndex: ['producto', 'marca'],
    key: 'producto__marca',
    sorter: true,
  },
];

COLUMNS[ECONOMICA.EVENT_EGRESO_COMPRA_INSUMOS.SUPLEMENTO] = [
  {
    title: 'Nombre',
    dataIndex: ['suplemento', 'nombre'],
    key: 'suplemento__nombre',
    sorter: true,
  },
  {
    title: 'Materia seca (%)',
    dataIndex: ['suplemento', 'materia_seca'],
    key: 'suplemento__materia_seca',
    sorter: true,
  },
];

const CompraInsumoTable = ({
  data,
  rowKey = 'id',
  loading = false,
  eventType,
  ...props
}) => {
  const columns = [...defaultColumns, ...COLUMNS[eventType]];

  return (
    <Table
      rowKey={rowKey}
      rowClassName="table-row"
      dataSource={data}
      columns={columns}
      loading={loading}
      {...props}
    />
  );
};

export default CompraInsumoTable;
