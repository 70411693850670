import React, { useState } from 'react';
import { Tabs } from 'antd';
import { UNITS } from 'modules/core/constants';
import { ScreenHeader } from 'modules/core/components';
import StockAnalytics from './StockAnalytics/StockAnalytics';
import UnitRankingAnalytics from './UnitRankingAnalytics/UnitRankingAnalytics';
import ConsolidadoAnalytics from './ConsolidadoAnalytics/ConsolidadoAnalytics';
import EstablecimientoAnalytics from './EstablecimientoAnalytics/EstablecimientoAnalytics';

const ANALYTICS_UNITS = {
  STOCK: 'stock',
  RANKING_FEMALES: 'ranking_females',
  RANKING_MALES: 'ranking_males',
  CONSOLIDADO: 'consolidado',
  ESTABLECIMIENTO: 'establecimiento',
};

const UnidadesAnalytics = () => {
  const [activeTab, setActiveTab] = useState(ANALYTICS_UNITS.STOCK);
  const items = [
    {
      key: ANALYTICS_UNITS.STOCK,
      label: 'Stock',
      children: (
        <StockAnalytics isActive={activeTab === ANALYTICS_UNITS.STOCK} />
      ),
    },
    {
      key: ANALYTICS_UNITS.RANKING_FEMALES,
      label: 'Top hembras',
      children: (
        <UnitRankingAnalytics
          isActive={activeTab === ANALYTICS_UNITS.RANKING_FEMALES}
          unitType={UNITS.HEMBRAS}
        />
      ),
    },
    {
      key: ANALYTICS_UNITS.RANKING_MALES,
      label: 'Top machos',
      children: (
        <UnitRankingAnalytics
          isActive={activeTab === ANALYTICS_UNITS.RANKING_MALES}
          unitType={UNITS.MACHOS}
        />
      ),
    },
    {
      key: ANALYTICS_UNITS.CONSOLIDADO,
      label: 'Consolidado',
      children: (
        <ConsolidadoAnalytics
          isActive={activeTab === ANALYTICS_UNITS.CONSOLIDADO}
        />
      ),
    },
    {
      key: ANALYTICS_UNITS.ESTABLECIMIENTO,
      label: 'Establecimiento',
      children: (
        <EstablecimientoAnalytics
          isActive={activeTab === ANALYTICS_UNITS.ESTABLECIMIENTO}
        />
      ),
    },
  ];

  return (
    <div>
      <ScreenHeader title="Analíticas unidades" />
      <Tabs items={items} onChange={setActiveTab} />
    </div>
  );
};

export default UnidadesAnalytics;
