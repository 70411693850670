import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { withAPI } from 'modules/api';
import { usePaginationWithFilters } from 'modules/core/hooks';
import {
  EditOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
  UndoOutlined,
} from '@ant-design/icons';
import { Button, Col, Row, Tabs, Tag, message as antMessage } from 'antd';
import { GENERAL, MASTERS } from 'modules/core/constants';
import {
  DeleteModal,
  DropdownMenu,
  ScreenHeader,
  Table,
} from 'modules/core/components';
import TareaModal from './TareaModal';

const TASK_MODAL_INITIAL_VALUES = {
  open: false,
  action: GENERAL.ACTIONS.CREAR,
};

const TAREAS_PENDIENTES = 'tareas-pendientes';
const TAREAS_REALIZADAS = 'tareas-realizadas';

const Tareas = ({ api }) => {
  const [activeTab, setActiveTab] = useState(TAREAS_PENDIENTES);
  const { pagination, sorter, params } = usePaginationWithFilters();
  const [taskModal, setTaskModal] = useState(TASK_MODAL_INITIAL_VALUES);
  const [selectedItem, setSelectedItem] = useState({});

  const {
    data: tareasRealizadas,
    isLoading: loadingTareasRealizadas,
    refetch: refetchTareasRealizadas,
  } = useQuery(
    [
      `Establecimientos::fetchTareasRealizadas`,
      { params: { ...params, realizado: true } },
    ],
    api.fetchTareas,
    { enabled: activeTab === TAREAS_REALIZADAS }
  );

  const {
    data: tareasPendientes,
    isLoading: loadingTareasPendientes,
    refetch: refetchTareasPendientes,
  } = useQuery(
    [
      `Establecimientos::fetchTareasPendientes`,
      { params: { ...params, realizado: false } },
    ],
    api.fetchTareas,
    { enabled: activeTab === TAREAS_PENDIENTES }
  );

  const { mutate: deleteTarea, isLoading: isDeleting } = useMutation(
    api.deleteTarea
  );
  const handleDelete = ({ onSuccess, onError }) => {
    deleteTarea(selectedItem.id, { onSuccess, onError });
  };

  const actionsHandler = ({ item = null, action }) => {
    setTaskModal({ open: true, action });
    setSelectedItem(item);
  };
  const onCancel = () => {
    setTaskModal(TASK_MODAL_INITIAL_VALUES);
    setSelectedItem(null);
  };

  const { mutate: changeTaskStatus } = useMutation(api.setTareaRealizada);
  const onChangeTaskStatus = (taskId, successMsg = '') => {
    const onSuccess = () => {
      antMessage.success(successMsg);
      refetchTareasRealizadas();
      refetchTareasPendientes();
    };
    const onError = ({ message, description }) => {
      if (message === '400') antMessage.error(description, 5);
    };
    changeTaskStatus(taskId, { onSuccess, onError });
  };

  const actionsMenuPendientes = (task) => {
    return [
      {
        title: 'Modificar',
        icon: <EditOutlined />,
        onClick: () =>
          actionsHandler({
            item: task,
            action: GENERAL.ACTIONS.MODIFICAR,
          }),
      },
      {
        title: 'Realizada',
        icon: <CheckCircleOutlined />,
        onClick: () => onChangeTaskStatus(task?.id, 'Tarea completada'),
      },
      {
        title: 'Eliminar',
        icon: <DeleteOutlined />,
        onClick: () =>
          actionsHandler({
            item: task,
            action: GENERAL.ACTIONS.ELIMINAR,
          }),
      },
    ];
  };

  const actionsMenuRealizadas = (task) => {
    return [
      {
        title: 'Deshacer',
        icon: <UndoOutlined />,
        onClick: () => onChangeTaskStatus(task?.id, 'Tarea revertida'),
      },
    ];
  };

  const items = [
    {
      key: 'tareas-pendientes',
      label: 'Pendientes',
      children: (
        <TareasTable
          dataSource={tareasPendientes}
          loading={loadingTareasPendientes}
          actionsMenu={actionsMenuPendientes}
          pagination={pagination}
          sorter={sorter}
        />
      ),
    },
    {
      key: 'tareas-realizadas',
      label: 'Realizadas',
      children: (
        <TareasTable
          dataSource={tareasRealizadas}
          loading={loadingTareasRealizadas}
          pagination={pagination}
          sorter={sorter}
          actionsMenu={actionsMenuRealizadas}
        />
      ),
    },
  ];
  const onChangeTab = (key) => {
    setActiveTab(key);
    pagination.set({ ...pagination.get(), current: 1 });
    sorter.set(null);
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <ScreenHeader
          title="Tareas"
          rightActions={
            <Button
              type="primary"
              onClick={() =>
                actionsHandler({
                  action: GENERAL.ACTIONS.CREAR,
                })
              }
            >
              AGREGAR TAREA
            </Button>
          }
        />
      </Col>
      <Col span={24}>
        <Tabs items={items} onChange={onChangeTab} />
      </Col>
      <TareaModal
        visible={
          taskModal.open &&
          [GENERAL.ACTIONS.CREAR, GENERAL.ACTIONS.MODIFICAR].includes(
            taskModal.action
          )
        }
        initialValues={selectedItem}
        onCancel={onCancel}
        onConfirm={handleDelete}
        onSuccess={refetchTareasPendientes}
      />
      <DeleteModal
        title="Eliminar tarea"
        isDeleting={isDeleting}
        visible={
          taskModal.open && taskModal.action === GENERAL.ACTIONS.ELIMINAR
        }
        onCancel={onCancel}
        onConfirm={handleDelete}
        onSuccess={() => {
          onCancel();
          refetchTareasPendientes();
        }}
        onSuccessInfo={{
          description: 'La tarea se eliminó con éxito.',
          showCheck: true,
        }}
      />
    </Row>
  );
};

const TareasTable = ({
  dataSource = [],
  loading,
  pagination,
  sorter,
  actionsMenu = () => [],
}) => {
  const columns = [
    {
      title: 'Fecha creación',
      dataIndex: 'fecha_creacion',
      sorter: true,
      render: (d) => GENERAL.dateToFront(d),
    },
    {
      title: 'Fecha límite',
      dataIndex: 'fecha_limite',
      sorter: true,
      render: (d) => GENERAL.dateToFront(d),
    },

    {
      title: 'Descripción',
      dataIndex: 'descripcion',
      sorter: true,
      ellipsis: true,
      width: '60%',
    },
    {
      title: 'Responsable',
      dataIndex: 'responsable_value',
      key: 'responsable__nombre',
      sorter: true,
    },
    {
      title: 'Prioridad',
      dataIndex: 'prioridad_value',
      key: 'prioridad_order',
      sorter: true,
      render: (v) => (
        <Tag color={MASTERS.PRIORIDADES_BADGES[v?.toLocaleLowerCase()]}>
          {v}
        </Tag>
      ),
    },
    {
      title: 'Acciones',
      align: 'right ',
      render: (_, task) => <DropdownMenu menu={actionsMenu(task)} />,
    },
  ];

  return (
    <Table
      rowKey="id"
      dataSource={dataSource?.results}
      columns={columns}
      loading={loading}
      showTotal={true}
      pagination={{ ...pagination.get(), total: dataSource?.count }}
      onChange={(p, f, s) => {
        pagination.set(p);
        sorter.set(s);
      }}
    />
  );
};

export default withAPI(Tareas);
