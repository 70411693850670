import React from 'react';
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import {
  GENERAL,
  NUTRICIONAL,
  PRODUCTIVA,
  SANITARIA,
  UNITS,
} from 'modules/core/constants';

const EVENTS_FORMATTED = {
  ...PRODUCTIVA.EVENT_FORMATTED,
  ...SANITARIA.EVENT_FORMATTED,
  ...NUTRICIONAL.EVENT_FORMATTED,
};

const styles = StyleSheet.create({
  list: {
    paddingHorizontal: 12,
    paddingTop: 15,
  },
  card: {
    // fontFamily: 'Raleway',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 8,
    borderColor: '#f0f0f0',
    borderWidth: 1,
    marginVertical: 4,
  },
  cardBovino: {
    color: '#054e2d', // --primary-color-dark
  },
  cardOvino: {
    color: '#00478f', // --primary-color-dark
  },
  cardText: {
    fontWeight: 500,
    fontSize: 10,
    width: '45%',
  },
  cardExtra: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '50%',
  },
  cardDescription: {
    fontSize: 8,
  },
  cardDate: {
    // fontFamily: 'Roboto',
    fontSize: 8,
    color: '#575757',
  },
});

const RecordCard = ({ fecha, info, tipo_evento, userSpecies }) => {
  return (
    <View
      style={[
        styles.card,
        userSpecies === UNITS.OVINO ? styles.cardOvino : styles.cardBovino,
      ]}
      wrap={false}
    >
      <Text style={styles.cardText}>{EVENTS_FORMATTED[tipo_evento]}</Text>
      <View style={styles.cardExtra}>
        <Text style={styles.cardDescription}>{info}</Text>
        <Text style={styles.cardDate}>{fecha}</Text>
      </View>
    </View>
  );
};

const ObservationCard = ({ fecha, comentario, userSpecies }) => {
  return (
    <View
      style={[
        styles.card,
        userSpecies === UNITS.OVINO ? styles.cardOvino : styles.cardBovino,
      ]}
    >
      <Text style={styles.cardText}>{comentario}</Text>
      <Text style={styles.cardDate}>{`${GENERAL.dateToFront(
        fecha,
        true
      )} hs`}</Text>
    </View>
  );
};

export const ListReportPDF = ({
  data = [],
  cardComponent: Card,
  userSpecies,
}) => {
  return (
    <View style={styles.list}>
      {data.map((el, ix) => (
        <Card key={ix} {...el} userSpecies={userSpecies} />
      ))}
    </View>
  );
};

export const CardsListReportPDF = {
  Record: RecordCard,
  Observation: ObservationCard,
};
