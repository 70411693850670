import React from 'react';
import { Button, Dropdown } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';

import './DropdownMenu.scss';

const DropdownMenu = ({
  menu,
  title,
  icon = null,
  disabled,
  children,
  className = '',
  placement = 'bottomRight',
  trigger = ['click'],
  ...props
}) => {
  const iconWithStyle =
    icon &&
    React.cloneElement(icon, {
      className: 'actions-menu-button',
    });

  const items = menu.map((option, index) => {
    const { submenu, ...itemProps } = option;
    if (submenu) {
      return {
        key: itemProps?.title,
        label: itemProps?.title,
        ...itemProps,
        children: submenu.map((submenuItem) => [
          { key: submenuItem.title, label: submenuItem?.title, ...submenuItem },
        ]),
      };
    }
    return {
      key: itemProps?.title,
      label: itemProps?.title,
      ...itemProps,
    };
  });

  return (
    <Dropdown
      className={`finca-menu-dropdown ${className}`}
      overlayClassName="finca-menu-dropdown-overlay"
      disabled={disabled}
      placement={placement}
      trigger={trigger}
      {...props}
      menu={{ items }}
    >
      {children ? (
        <span
          title={title}
          data-disabled={disabled}
          className="finca-dropdown-menu-trigger"
        >
          {children}
        </span>
      ) : (
        <Button size="small" type="text">
          {icon ? (
            iconWithStyle
          ) : (
            <EllipsisOutlined className="actions-menu-button" />
          )}
        </Button>
      )}
    </Dropdown>
  );
};

export default DropdownMenu;
