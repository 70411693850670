import React from 'react';

const Container = ({
  justifyContent = 'space-between',
  alignItems = 'center',
  children,
}) => {
  return (
    <div style={{ display: 'flex', justifyContent, alignItems }}>
      {children}
    </div>
  );
};

export default Container;
