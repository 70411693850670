import React from 'react';
import { Page, View, Document, StyleSheet } from '@react-pdf/renderer';
// import { Font } from '@react-pdf/renderer';
import bovinoIconDetail from 'assets/report/bovino-icon-detail.png';
import bovinoIconHistory from 'assets/report/bovino-icon-history.png';
import bovinoIconObservations from 'assets/report/bovino-icon-observations.png';
// import RalewayRegular from 'assets/report/Raleway-Regular.ttf';
// import RalewayMedium from 'assets/report/Raleway-Medium.ttf';
// import RobotoRegular from 'assets/report/Roboto-Regular.ttf';
import {
  PageFooter,
  PageHeader,
  ListReportPDF,
  CardsListReportPDF,
  UnitHeaderReportPDF,
  UnitInfoReportPDF,
  ExtraInfoReportPDF,
  UnitTreeReportPDF,
} from './components';
import { UNITS } from 'modules/core/constants';

// Font.register({ family: 'Roboto', src: RobotoRegular });
// Font.register({
//   family: 'Raleway',
//   fonts: [{ src: RalewayRegular }, { src: RalewayMedium, fontWeight: 500 }],
// });

const styles = StyleSheet.create({
  page: {
    // fontFamily: 'Raleway',
    fontSize: 14,
    paddingBottom: 12,
  },
  pageBackgroundBovino: {
    backgroundColor: '#c8e2c9', // --secondary-color
  },
  pageBackgroundOvino: {
    backgroundColor: '#e6f4ff', // --secondary-color
  },
  section: {
    flexGrow: 1,
    paddingTop: 5,
    paddingHorizontal: 12,
    backgroundColor: '#ffffff',
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    fontSize: 12,
  },
  separator: { marginBottom: 10 },
});

const SectionWithSeparator = ({ children }) => {
  return (
    <>
      <View fixed style={styles.separator} />
      {children}
      <View fixed style={styles.separator} />
    </>
  );
};

const UnitReportPDF = ({
  userSpecies = UNITS.BOVINO,
  header,
  info,
  infoExtra: { datosAdicionales, deps },
  records,
  familyTree,
  observations,
}) => {
  const renderUnitDetail = () => {
    return (
      <>
        <PageHeader
          title="Detalle de la unidad"
          icon={bovinoIconDetail}
          userSpecies={userSpecies}
        />
        <View style={styles.section}>
          <UnitHeaderReportPDF {...header} userSpecies={userSpecies} />
          <UnitInfoReportPDF data={info} userSpecies={userSpecies} />
          <ExtraInfoReportPDF
            title="Datos adicionales"
            data={datosAdicionales}
            userSpecies={userSpecies}
          />
          <ExtraInfoReportPDF
            title="DEPs"
            data={deps}
            userSpecies={userSpecies}
          />
          <UnitTreeReportPDF data={familyTree} userSpecies={userSpecies} />
        </View>
      </>
    );
  };

  const renderHistory = () => {
    return (
      <>
        <PageHeader
          title="Historial de la unidad"
          icon={bovinoIconHistory}
          userSpecies={userSpecies}
        />
        <View style={styles.section}>
          <SectionWithSeparator>
            <ListReportPDF
              data={records}
              userSpecies={userSpecies}
              cardComponent={CardsListReportPDF.Record}
            />
          </SectionWithSeparator>
        </View>
      </>
    );
  };

  const renderObservations = () => {
    return (
      <>
        <PageHeader
          title="Observaciones"
          icon={bovinoIconObservations}
          userSpecies={userSpecies}
        />
        <View style={styles.section}>
          <SectionWithSeparator>
            <ListReportPDF
              data={observations}
              userSpecies={userSpecies}
              cardComponent={CardsListReportPDF.Observation}
            />
          </SectionWithSeparator>
        </View>
      </>
    );
  };

  const pageStyles = [
    styles.page,
    userSpecies === UNITS.OVINO
      ? styles.pageBackgroundOvino
      : styles.pageBackgroundBovino,
  ];
  return (
    <Document>
      <Page size="A4" style={pageStyles} wrap>
        {renderUnitDetail()}
        <PageFooter userSpecies={userSpecies} />
      </Page>
      <Page size="A4" style={pageStyles} wrap>
        {renderHistory()}
        <PageFooter userSpecies={userSpecies} />
      </Page>
      <Page size="A4" style={pageStyles} wrap>
        {renderObservations()}
        <PageFooter userSpecies={userSpecies} />
      </Page>
    </Document>
  );
};

export default UnitReportPDF;
