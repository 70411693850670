import React from 'react';
import { useQuery, useMutation } from 'react-query';
import { withAPI } from 'modules/api';
import { Button, Spin, Upload, message } from 'antd';
import { Card } from 'modules/core/components';

import './UploadLogoCard.scss';

const fileTypes = '.jpg, .jpeg, .png';
const fileSizeMax = 200;

const UploadLogoCard = ({ api, barnId }) => {
  const { data, isLoading, refetch } = useQuery(
    ['Home::fetchBarnLogo', { barnId }],
    api.fetchBarnLogo,
    { enabled: !!barnId }
  );

  const { mutate: editBarnLogo, isLoading: isEditingLogo } = useMutation(
    api.editBarnLogo
  );

  const beforeUpload = ({ size }) => {
    const isValid = size / 1024 < fileSizeMax;
    if (!isValid)
      message.error(`El archivo tiene que ser menor a ${fileSizeMax}KB`);
    return isValid;
  };

  const onUploadLogo = ({ file }) => {
    const logoAsForm = new FormData();
    logoAsForm.append('logo', file);

    return editBarnLogo(
      { barnId, data: logoAsForm },
      {
        onSuccess: refetch,
        onError: () => message.error('No se pudo cargar el logo'),
      }
    );
  };

  return (
    <Card
      title="Logo establecimiento"
      tooltip={`Recorda que la imagen debe tener como máximo un tamaño de ${fileSizeMax}KB. Dimensiones recomendadas: 150 x 60px`}
      className="upload-logo-card"
    >
      <Spin spinning={isLoading} />
      {!isLoading && (
        <div className="upload-logo-container">
          {data.type === 'image/*' && (
            <img
              className="upload-logo-img"
              src={URL.createObjectURL(data)}
              alt="Logo"
            />
          )}
          {data.type !== 'image/*' && (
            <div className="upload-logo-placeholder">
              Aún no tienes un logo cargado.
            </div>
          )}
        </div>
      )}
      <Upload
        className="profile-action-button"
        showUploadList={false}
        accept={fileTypes}
        beforeUpload={beforeUpload}
        customRequest={onUploadLogo}
      >
        <Button
          type="primary"
          loading={isEditingLogo}
          disabled={isLoading}
          block
        >
          CARGAR LOGO
        </Button>
      </Upload>
    </Card>
  );
};

export default withAPI(UploadLogoCard);
