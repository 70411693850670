const ProfileAPI = (API) => {
  API.login = function ({ username, password }) {
    return fetch('/api/auth/users/token/', {
      method: 'POST',
      headers: API.getSimpleHeaders(),
      body: JSON.stringify({ username, password }),
    }).then(API.handleResponse);
  };

  API.editUserPassword = function ({
    password,
    password_confirm,
    old_password,
  }) {
    return fetch('/api/auth/users/confirm/', {
      method: 'POST',
      headers: API.getAuthHeaders(),
      body: JSON.stringify({ password, password_confirm, old_password }),
    }).then(API.handleResponse);
  };

  API.fetchUserLicense = function ({ queryKey }) {
    return fetch('/api/auth/licenses/kpi-licencia/', {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchGraphURL = function ({ queryKey }) {
    return fetch(`/api/auth/establecimientos/quicksight/`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  return API;
};

export default ProfileAPI;
