import React from 'react';

const OtrosEconomicaDeleteMessage = ({ monto = '' }) => {
  return (
    <div>
      Estás eliminando un movimiento de monto <b>{`${monto}`}</b>
      <br />
      ¿Está seguro que desea continuar?
    </div>
  );
};

export default OtrosEconomicaDeleteMessage;
