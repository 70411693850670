import React from 'react';
import { Modal } from 'antd';
import correcto from 'assets/correcto.svg';
import incorrecto from 'assets/incorrecto.svg';

import './InfoModal.scss';

const CorrectIcon = ({ isCorrect = true }) => {
  return isCorrect ? (
    <img
      src={correcto}
      className="finca-info-modal-content-check"
      alt="check-correcto"
    />
  ) : (
    <img
      src={incorrecto}
      className="finca-info-modal-content-mark"
      alt="check-incorrecto"
    />
  );
};

const displayInfoModal = ({
  description,
  onOk = () => {},
  onCancel = null,
  title = null,
  icon = null,
  showCheck = false,
  showCross = false,
  okText = 'Aceptar',
  cancelText = 'Cancelar',
}) => {
  const modalParams = {
    okText: okText,
    cancelText: cancelText,
    title: title,
    icon: icon,
    className: 'finca-info-modal',
    width: '30%',
    content: (
      <div className="finca-info-modal-content">
        {(showCheck || showCross) && <CorrectIcon isCorrect={!!showCheck} />}
        <div className="finca-info-modal-description">{description}</div>
      </div>
    ),
    onOk,
    onCancel,
  };
  return onCancel ? Modal.confirm(modalParams) : Modal.info(modalParams);
};

export { displayInfoModal };
