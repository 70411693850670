import React from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'antd';
import { withAPI } from 'modules/api';
import { EventsRecordHistory } from 'modules/core/components';
import { useEventsRecordHistory } from 'modules/core/hooks';
import {
  usePartoRecordState,
  PartoRecordDrawer,
} from './utils/PartosRecordHelper';
import { COLUMNS } from './utils/recordHistoryColumns';
import { PRODUCTIVA } from 'modules/core/constants';

const RecordHistory = ({ api }) => {
  const { eventType } = useParams();
  const isDatosAdicionalesOrDEPS =
    eventType === PRODUCTIVA.DATOS_ADICIONALES || eventType === PRODUCTIVA.DEPS;
  const recordState = useEventsRecordHistory({
    type: eventType,
    fetchFns: {
      fetchRecords: api.fetchProductivaRecords,
      ...(!isDatosAdicionalesOrDEPS && {
        fetchRecordsKPI: api.fetchProductivaRecordsKPI,
      }),
      undoMultipleEvents: api.undoMultipleProductivaEvents,
      undoEvent: api.undoProductivaEvent,
    },
  });

  // Custom (atm only for Parto Event)
  const partoState = usePartoRecordState();
  const customColumnRender = (p) => {
    if (eventType === PRODUCTIVA.PARTO)
      return <Button onClick={() => partoState.set(p)}>Continuar</Button>;
    return null;
  };

  return (
    <>
      <EventsRecordHistory
        userSpecies={api.userSpecies}
        state={recordState}
        columns={COLUMNS}
        unitTypes={PRODUCTIVA.EVENT_UNIT_TYPES[eventType]}
        customColumnRender={customColumnRender}
        withoutExportFields={isDatosAdicionalesOrDEPS}
      />
      {eventType === PRODUCTIVA.PARTO && (
        <PartoRecordDrawer
          success={recordState.refetch}
          visible={partoState.visible}
          next={partoState.next}
          parto={partoState.info}
          close={partoState.hide}
        />
      )}
    </>
  );
};

export default withAPI(RecordHistory);
