import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import {
  Row,
  Col,
  Form,
  DatePicker,
  message as antMessage,
  Select,
} from 'antd';
import { withAPI } from 'modules/api';
import { usePaginationWithFilters } from 'modules/core/hooks';
import { displayInfoModal } from 'modules/core/components';
import FilterSider, {
  UnitFilter,
  VacunasSinAsignarFilter,
} from 'modules/core/components/Filter';
import {
  UnitDrawerRecordHistory,
  UnitDrawerShow,
} from 'modules/units/components';
import {
  SANITARIA,
  MANAGEMENT,
  GENERAL,
  UNITS,
  MASTERS,
} from 'modules/core/constants';
import {
  EventsHeader,
  EventsTableActions,
} from 'modules/management/components';
import VacunacionTable from './VacunacionTable/VacunacionTable';

const { Item: FormItem } = Form;
const EVENT_ACTIONS_INITIAL_VALUES = { item: null, action: null };

const Vacunacion = ({ api }) => {
  const eventType = SANITARIA.VACUNACION;
  let history = useHistory();
  let location = useLocation();
  const { pagination, filters, sorter, params } = usePaginationWithFilters();
  const [actionInProgress, setActionInProgress] = useState(false);
  const [selectedItem, setSelectedItem] = useState(
    EVENT_ACTIONS_INITIAL_VALUES
  );
  const [selectedRows, setSelectedRows] = useState({ keys: [], rows: [] });
  const [form] = Form.useForm();

  const {
    data: unitsVacunacion,
    isLoading,
    refetch,
  } = useQuery(
    ['Sanitaria::fetchByEvent', { eventType: SANITARIA.GENERIC_EVENT, params }],
    api.fetchByEvent
  );

  const { data: responsables } = useQuery(
    [
      'Sanitaria::ResponsablesMaster',
      { masterType: MASTERS.PRODUCTIVA_TYPES.RESPONSABLE },
    ],
    api.fetchMasters
  );
  const responsablesOptions = MASTERS.generateOptions(responsables?.results);

  const { data: vacunas } = useQuery(
    ['Sanitaria::Vacunas', { inputType: SANITARIA.VACUNA }],
    api.fetchInsumo
  );
  const vacunasOptions = MASTERS.generateOptions(vacunas?.results);

  const { mutate: vacunar, isLoading: registering } = useMutation(
    api.addSanitariaEvent
  );

  const emptySelection = _.isEmpty(selectedRows.keys);
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRows({ keys: selectedRowKeys, rows: selectedRows });
  };
  const rowSelection = {
    selectedRowKeys: selectedRows.keys,
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
  };

  const onAction = () => {
    setActionInProgress(true);
  };
  const rollbackAction = () => {
    setSelectedRows({ keys: [], rows: [] });
    form.resetFields();
    setActionInProgress(false);
  };

  const onActionApply = () => {
    form
      .validateFields()
      .then((values) => {
        const vacunacion = {
          fecha: GENERAL.dateToBack(values.date),
          vacuna: values.vaccine,
          ...(values.responsible && { responsable: values.responsible }),
          unidad: selectedRows.keys.map((el) => ({
            unidad_id: el,
            cantidad_aplicada: values.stock_amount[el]
              ? values.stock_amount[el]
              : 0,
          })),
        };
        const onSuccess = () =>
          displayInfoModal({
            description:
              'Las unidades fueron vacunadas con éxito.\n Continuá tu gestión en Finca.',
            showCheck: true,
            onOk: () => history.push(location.pathname),
          });
        const onError = ({ message, description }) => {
          if (message === '400') antMessage.error(description, 5);
        };
        vacunar({ eventType, body: vacunacion }, { onSuccess, onError });
      })
      .catch(() => {});
  };

  const renderHeader = () => (
    <Col span={24}>
      <EventsHeader
        title="Vacunación"
        description={
          !actionInProgress
            ? 'Unidades aptas para vacunar'
            : `${selectedRows.rows.length} unidades seleccionadas para vacunar`
        }
        continueProps={{ onClick: onAction, disabled: emptySelection }}
        registerProps={{ onClick: onActionApply, loading: registering }}
        eventMenuProps={{
          menuType: MANAGEMENT.SANITARIA,
          unitType: UNITS.UNIDAD,
          eventType: eventType,
          onUpload: refetch,
          exportParams: filters.get(),
        }}
        rollbackAction={rollbackAction}
        showRegister={actionInProgress}
      />
    </Col>
  );

  const renderTable = () => {
    return (
      <>
        <Col xs={6} xxl={4}>
          <FilterSider
            filters={[UnitFilter, VacunasSinAsignarFilter]}
            filtersProps={{ unitType: [UNITS.HEMBRAS, UNITS.MACHOS] }}
            onChange={filters.set}
          />
        </Col>
        <Col xs={18} xxl={20}>
          <VacunacionTable
            data={unitsVacunacion?.results}
            rowSelection={rowSelection}
            loading={isLoading}
            actions={(item) => (
              <EventsTableActions item={item} onAction={setSelectedItem} />
            )}
            showTotal={true}
            pagination={{ ...pagination.get(), total: unitsVacunacion?.count }}
            onChange={(p, f, s) => {
              pagination.set(p);
              sorter.set(s);
            }}
          />
          <UnitDrawerShow
            visible={selectedItem?.action === GENERAL.ACTIONS.VER_UNIDAD}
            unit={selectedItem.item}
            close={() => setSelectedItem(EVENT_ACTIONS_INITIAL_VALUES)}
          />
          <UnitDrawerRecordHistory
            visible={selectedItem?.action === GENERAL.ACTIONS.VER_HISTORIAL}
            unitId={selectedItem.item?.id}
            success={refetch}
            close={() => setSelectedItem(EVENT_ACTIONS_INITIAL_VALUES)}
          />
        </Col>
      </>
    );
  };

  const renderForm = () => (
    <Col span={24}>
      <Form form={form} layout="vertical" requiredMark={false}>
        <Row gutter={8}>
          <Col span={4}>
            <FormItem
              label="Fecha de vacunación"
              name="date"
              rules={[{ required: true, message: 'Ingrese fecha' }]}
            >
              <DatePicker
                style={{ width: '100%' }}
                format={GENERAL.DATE_FORMAT.FRONT}
              />
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem
              label="Vacuna a aplicar"
              name="vaccine"
              rules={[{ required: true, message: 'Seleccione vacuna' }]}
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Selecione vacuna..."
                options={vacunasOptions}
              />
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem
              label="Responsable"
              name="responsible"
              rules={[{ required: false, message: 'Seleccione responsable' }]}
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Selecione responsable..."
                options={responsablesOptions}
              />
            </FormItem>
          </Col>
          <Col span={24}>
            <VacunacionTable
              loading={isLoading}
              data={selectedRows.rows}
              form={form}
              pagination={false}
            />
          </Col>
        </Row>
      </Form>
    </Col>
  );

  return (
    <Row gutter={[16, 16]}>
      {renderHeader()}
      {!actionInProgress && renderTable()}
      {actionInProgress && renderForm()}
    </Row>
  );
};

export default withAPI(Vacunacion);
