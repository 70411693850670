import {
  ECONOMICA,
  NUTRICIONAL,
  PRODUCTIVA,
  SANITARIA,
} from 'modules/core/constants';

const EVENTS_FORMATTED = {
  ...PRODUCTIVA.EVENT_FORMATTED,
  ...SANITARIA.EVENT_FORMATTED,
  ...NUTRICIONAL.EVENT_FORMATTED,
  ...ECONOMICA.EVENT_FORMATTED,
  ...ECONOMICA.EVENT_EGRESO_COMPRA_INSUMOS.EVENT_FORMATTED,
};

const ALL_ECONOMICA_EVENTS = [
  ...ECONOMICA.EVENT_TYPES,
  ...ECONOMICA.EVENT_EGRESO_COMPRA_INSUMOS.EVENT_TYPES,
];

export const getExportFns = (recordType, api) => {
  if (PRODUCTIVA.EVENT_TYPES.includes(recordType))
    return {
      fields: api.fieldsExportProductivaEvent,
      excel: api.exportProductivaEvent,
    };
  if (SANITARIA.EVENT_TYPES.includes(recordType))
    return {
      fields: api.exportFieldsSanitariaEvent,
      excel: api.exportSanitariaEvent,
    };
  if (NUTRICIONAL.EVENT_TYPES.includes(recordType))
    return {
      fields: api.exportFieldsNutricionalEvent,
      excel: api.exportNutricionalEvent,
    };
  if (ALL_ECONOMICA_EVENTS.includes(recordType))
    return {
      fields: api.fieldsExportEconomicaEvent,
      excel: api.exportEconomicaEvent,
    };
};

export const getFileAffix = (recordType) => EVENTS_FORMATTED[recordType];
