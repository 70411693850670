import React from 'react';
import { Alert, Button, Space } from 'antd';

const AlertWithAction = ({
  message = '',
  type = 'success',
  onCancel = () => {},
  onConfirm = () => {},
  loading = false,
  disabled = false,
}) => {
  return (
    <Alert
      message={message}
      type={type}
      action={
        <Space direction="horizontal">
          <Button size="small" type="text" danger onClick={onCancel}>
            Cancelar
          </Button>
          <Button
            size="small"
            type="primary"
            loading={loading}
            onClick={onConfirm}
            disabled={disabled}
          >
            Confirmar
          </Button>
        </Space>
      }
    />
  );
};

export default AlertWithAction;
