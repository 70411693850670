import React from 'react';
import { Form, Input, Row, Col, Select } from 'antd';
import { MASTERS } from 'modules/core/constants';

const VacunasForm = ({ sindrome = {} }) => {
  return (
    <Row gutter={8}>
      <Col span={12}>
        <Form.Item
          label="Nombre"
          name="nombre"
          rules={[{ required: true, message: 'Ingrese nombre' }]}
        >
          <Input placeholder="Ingrese nombre" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Sindrome que previene"
          name="sindrome"
          rules={[{ required: true, message: 'Ingrese sindrome' }]}
        >
          <Select
            showSearch
            allowClear
            style={{ width: '100%' }}
            optionFilterProp="label"
            placeholder="Seleccionar sindrome"
            loading={sindrome?.isLoading}
            options={sindrome?.options}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Obligatoriedad"
          name="obligatoriedad"
          rules={[{ required: true, message: 'Ingrese obligatoriedad' }]}
        >
          <Select
            style={{ width: '100%' }}
            optionFilterProp="label"
            placeholder="Seleccionar obligatoriedad"
            options={MASTERS.BOOLEAN_OPTIONS}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default VacunasForm;
