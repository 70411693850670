import React from 'react';
import { withAPI } from 'modules/api';
import { MASTERS } from 'modules/core/constants';
import { ManagementConfig } from 'modules/management/components';

const Sindromes = ({ api }) => {
  const masterType = MASTERS.SANITARIA_TYPES.SINDROME;
  const masterName = {
    CAPITALIZE: 'Sindrome',
    LOWER: 'sindrome',
    PLURAL: 'Sindromes',
  };

  return <ManagementConfig masterType={masterType} masterName={masterName} />;
};

export default withAPI(Sindromes);
