import { MANAGEMENT, UNITS } from 'modules/core/constants';

const PATH_BASE = 'analiticas';
const managementLeftMenuOptions = [
  {
    title: 'Unidades',
    url: `/${PATH_BASE}/${UNITS.NAME_SPACE}`,
    exact: false,
  },
  {
    title: 'Productiva',
    url: `/${PATH_BASE}/${MANAGEMENT.PRODUCTIVA}`,
    exact: false,
  },
  // {
  //   title: 'Sanitaria',
  //   url: `/${PATH_BASE}/${MANAGEMENT.SANITARIA}`,
  //   exact: false,
  // },
  // {
  //   title: 'Nutricional',
  //   url: `/${PATH_BASE}/${MANAGEMENT.NUTRICIONAL}`,
  //   exact: false,
  // },
  // {
  //   title: 'Económica',
  //   url: `/${PATH_BASE}/${MANAGEMENT.ECONOMICA}`,
  //   exact: false,
  // },
  // {
  //   title: 'Establecimiento',
  //   url: `/${PATH_BASE}/${ESTABLISHMENT.NAME_SPACE}`,
  //   exact: false,
  // },
];

export default managementLeftMenuOptions;
