import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { saveAs } from 'file-saver';
import { withAPI } from 'modules/api';
import { Drawer, Button, List, Empty, message as antMessage } from 'antd';
import { HistoryOutlined } from '@ant-design/icons';
import { ModalTitle } from 'modules/core/components';
import { NUTRICIONAL, PRODUCTIVA, SANITARIA } from 'modules/core/constants';
import UnitRecordCard from './UnitRecordCard/UnitRecordCard';
import './UnitDrawerRecordHistory.scss';

const EVENTS_FORMATTED = {
  ...PRODUCTIVA.EVENT_FORMATTED,
  ...SANITARIA.EVENT_FORMATTED,
  ...NUTRICIONAL.EVENT_FORMATTED,
};

const UnitDrawerRecordHistory = ({ api, unitId, visible, close, success }) => {
  const [isExporting, setIsExporting] = useState(false);
  const { mutate: exportUnitEventsRecords } = useMutation(
    api.exportUnitEventsRecords
  );
  const {
    data: records,
    isLoading: fetchingRecords,
    refetch: refetchRecords,
  } = useQuery(
    ['Unit::fetchUnitEventsRecords', { unitId }],
    api.fetchUnitEventsRecords,
    {
      enabled: !!(unitId && visible),
    }
  );

  const { mutateAsync: undoRecord } = useMutation(api.undoRecord);
  const onUndoRecord = (eventId, eventType) => {
    return undoRecord(
      { eventId, eventType },
      {
        onSuccess: () => {
          success && success();
          return refetchRecords();
        },
        onError: ({ message, description }) => {
          // TODO: Handle error better
          antMessage.error('No se pudo deshacer el evento.');
        },
      }
    );
  };

  const onExport = () => {
    setIsExporting(true);
    exportUnitEventsRecords(
      { id: unitId },
      {
        onSuccess: (response) => {
          saveAs(response, `Unidad-Historial de eventos.xlsx`);
          setIsExporting(false);
        },
        onError: ({ message: error }) => {
          if (error === '500') {
            antMessage.error('Hubo un error al conectarse al servidor.');
          }
          setIsExporting(false);
        },
      }
    );
  };

  return (
    <Drawer
      title={
        <ModalTitle icon={<HistoryOutlined />} title="Historial de la unidad" />
      }
      placement="right"
      width="40%"
      onClose={close}
      open={visible}
      closable={false}
    >
      <div className="unit-records-view">
        <div className="unit-data-container">
          {unitId && (
            <List
              loading={{ spinning: fetchingRecords, size: 'large' }}
              dataSource={records?.eventos}
              itemLayout={'vertical'}
              grid={{ gutter: 0, column: 1 }}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={'La unidad no posee eventos relacionados'}
                  />
                ),
              }}
              renderItem={(r) => (
                <List.Item>
                  <UnitRecordCard
                    event={EVENTS_FORMATTED[r.tipo_evento]}
                    date={r.fecha}
                    data={r.info}
                    onUndo={() => onUndoRecord(r.id, r.tipo_evento)}
                  />
                </List.Item>
              )}
            />
          )}
        </div>
        <div className="unit-record-history-footer">
          <Button onClick={onExport} loading={isExporting}>
            EXPORTAR
          </Button>
          <Button type="primary" onClick={close}>
            ACEPTAR
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default withAPI(UnitDrawerRecordHistory);
