import _ from 'lodash';
import { GENERAL, SANITARIA } from 'modules/core/constants';

const defaultColumns = [
  {
    title: 'Fecha',
    dataIndex: 'fecha',
    sorter: true,
    render: (d) => GENERAL.dateToFront(d),
  },
  {
    title: 'RP',
    dataIndex: 'unidad',
    sorter: true,
  },
];

const actionsColumn = (renderActions) => [
  {
    title: 'Acciones',
    align: 'right ',
    render: (_, record) => renderActions?.(record),
  },
];

const COLUMNS = {};

COLUMNS[SANITARIA.VACUNACION] = (actions) =>
  defaultColumns
    .concat([
      {
        title: 'Vacuna aplicada',
        dataIndex: 'vacuna',
        sorter: true,
      },
      {
        title: 'Responsable',
        dataIndex: 'responsable_value',
        key: 'responsable__nombre',
        sorter: true,
      },
    ])
    .concat(actionsColumn(actions));

COLUMNS[SANITARIA.CONTROL_EPIDEMIOLOGICO] = (actions) =>
  defaultColumns
    .concat([
      {
        title: 'Sindrome',
        dataIndex: 'sindrome_value',
        key: 'sindrome__nombre',
        sorter: true,
      },
      {
        title: 'Resultado',
        dataIndex: 'resultado',
        sorter: true,
        render: (v) => _.startCase(v),
      },
      {
        title: 'Responsable',
        dataIndex: 'responsable_value',
        key: 'responsable__nombre',
        sorter: true,
      },
      {
        title: 'Producto',
        dataIndex: 'producto_value',
        key: 'producto__nombre',
        sorter: true,
      },
      {
        title: 'Laboratorio',
        dataIndex: 'laboratorio',
        sorter: true,
      },
    ])
    .concat(actionsColumn(actions));

COLUMNS[SANITARIA.TRATAMIENTO_COLECTIVO] = (actions) =>
  defaultColumns
    .concat([
      {
        title: 'Producto',
        dataIndex: 'producto_value',
        key: 'producto__nombre',
        sorter: true,
      },
      {
        title: 'Objetivo',
        dataIndex: 'objetivo_value',
        key: 'objetivo__nombre',
        sorter: true,
        render: (v) => _.startCase(v),
      },
      {
        title: 'Responsable',
        dataIndex: 'responsable_value',
        key: 'responsable__nombre',
        sorter: true,
      },
      {
        title: 'Tiempo',
        dataIndex: 'tiempo',
        sorter: true,
      },
    ])
    .concat(actionsColumn(actions));

COLUMNS[SANITARIA.SANIDAD_INDIVIDUAL] = (actions) =>
  defaultColumns
    .concat([
      {
        title: 'Anomalía',
        dataIndex: 'anomalia',
        sorter: true,
        render: (v) => _.startCase(v),
      },
      {
        title: 'Observación presuntiva',
        dataIndex: 'observacion_presuntiva',
        sorter: true,
      },
      {
        title: 'Análisis complementarios',
        dataIndex: 'analisis_complementarios',
        sorter: true,
      },
      {
        title: 'Tratamiento',
        dataIndex: 'tratamiento',
        sorter: true,
      },
      {
        title: 'Responsable',
        dataIndex: 'responsable_value',
        key: 'responsable__nombre',
        sorter: true,
      },
      {
        title: 'Sindrome',
        dataIndex: 'sindrome_value',
        key: 'sindrome__nombre',
        sorter: true,
      },
    ])
    .concat(actionsColumn(actions));

export { COLUMNS };
