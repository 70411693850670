import React from 'react';
import { MASTERS } from 'modules/core/constants';
import { EstablishmentMasters } from 'modules/establishment/establishmentMasters/components';
import RodeoForm from './RodeoForm';

const Rodeos = () => {
  const masterType = MASTERS.PRODUCTIVA_TYPES.RODEO;
  const masterName = {
    CAPITALIZE: 'Rodeo',
    LOWER: 'rodeo',
    PLURAL: 'Rodeos',
  };

  return (
    <EstablishmentMasters
      masterType={masterType}
      masterName={masterName}
      renderForm={(props) => <RodeoForm {...props} />}
    />
  );
};

export default Rodeos;
