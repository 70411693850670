import React from 'react';
import { MASTERS } from 'modules/core/constants';
import { EstablishmentMastersDeactivated } from 'modules/establishment/establishmentMasters/components';

const RodeosDeactivatedList = () => {
  return (
    <EstablishmentMastersDeactivated
      screenTitle="Rodeos desactivados"
      masterType={MASTERS.PRODUCTIVA_TYPES.RODEO}
    />
  );
};

export default RodeosDeactivatedList;
