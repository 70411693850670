import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { saveAs } from 'file-saver';
import { withAPI } from 'modules/api';
import { NUTRICIONAL } from 'modules/core/constants';
import { Button, message } from 'antd';
import {
  EllipsisOutlined,
  CloudDownloadOutlined,
  DownloadOutlined,
  FileAddOutlined,
} from '@ant-design/icons';
import {
  DropdownMenu,
  ExportFieldsModal,
  UploadModal,
} from 'modules/core/components';

const NutricionalEventsMenu = ({
  api,
  eventType,
  unitType,
  exportParams,
  onUpload,
}) => {
  const fileAffix = NUTRICIONAL.EVENT_FORMATTED[eventType];
  const [viewExport, setViewExport] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [viewUpload, setViewUpload] = useState(false);
  const { mutate: exportEvent } = useMutation(api.exportEventUnits);
  const { mutate: downloadTemplate } = useMutation(api.downloadEventTemplate);

  const { data: availableFields } = useQuery(
    ['Events::fieldsExportEventUnits', { unitType }],
    api.fieldsExportUnits,
    { enabled: viewExport }
  );

  const onExportEvents = ({ _nogroup: fields }) => {
    setIsExporting(true);
    exportEvent(
      {
        eventType: NUTRICIONAL.GENERIC_EVENT,
        unitType,
        fields,
        params: exportParams,
      },
      {
        onSuccess: (response) => {
          saveAs(response, `${fileAffix}-Exportados.xlsx`);
          setIsExporting(false);
          setViewExport(false);
        },
        onError: ({ message: error }) => {
          if (error === '500') {
            message.error('Hubo un error al conectarse al servidor.');
          }
          setIsExporting(false);
        },
      }
    );
  };

  const onDownloadTemplate = () => {
    downloadTemplate(
      { eventType, params: exportParams },
      {
        onSuccess: (response) =>
          saveAs(response, `${fileAffix}-Plantilla.xlsx`),
      }
    );
  };

  return (
    <>
      <DropdownMenu
        menu={[
          {
            title: 'Plantilla para alta',
            icon: <CloudDownloadOutlined />,
            onClick: () => onDownloadTemplate(),
          },
          {
            title: 'Alta masiva',
            icon: <FileAddOutlined />,
            onClick: () => setViewUpload(true),
          },
          {
            title: 'Exportar a Excel',
            icon: <DownloadOutlined />,
            onClick: () => setViewExport(true),
          },
        ]}
      >
        <Button icon={<EllipsisOutlined />} />
      </DropdownMenu>
      <ExportFieldsModal
        visible={viewExport}
        fields={availableFields}
        loading={isExporting}
        onSuccess={onExportEvents}
        close={() => setViewExport(false)}
      />
      <UploadModal
        title={fileAffix}
        apiFn={api.uploadNutricionalEvent}
        params={{ eventType }}
        visible={viewUpload}
        close={() => setViewUpload(false)}
        onUpload={onUpload}
      />
    </>
  );
};

export default withAPI(NutricionalEventsMenu);
