import { MASTERS } from 'modules/core/constants';

const GestionNutricionalAPI = (API) => {
  API.fetchNutricionalMasters = function ({ queryKey }) {
    const [, { masterType, params }] = queryKey;
    const isEnum = (mType) =>
      [MASTERS.NUTRICIONAL_TYPES.DISPONIBILIDAD_PASTO].includes(mType);
    const qp = API.makeQueryString(params);
    let url = `/api/core/gestionnutricional/maestros`;
    url += isEnum(masterType) ? '/enum' : '';
    url += `/${masterType}/?${qp}`;
    return fetch(url, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.addNutricionalMaster = function ({ body = {}, masterType }) {
    return fetch(`/api/core/gestionnutricional/maestros/${masterType}/`, {
      method: 'POST',
      headers: API.getAuthHeaders(),
      body: JSON.stringify(body),
    }).then(API.handleResponse);
  };

  API.editNutricionalMaster = function ({ id, body = {}, masterType }) {
    return fetch(`/api/core/gestionnutricional/maestros/${masterType}/${id}/`, {
      method: 'PATCH',
      headers: API.getAuthHeaders(),
      body: JSON.stringify(body),
    }).then(API.handleResponse);
  };

  API.deleteNutricionalMaster = function ({ id, masterType }) {
    return fetch(`/api/core/gestionnutricional/maestros/${masterType}/${id}/`, {
      method: 'DELETE',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.addNutricionalEvent = function ({ body = {}, eventType }) {
    return fetch(`/api/core/gestionnutricional/eventos/${eventType}/`, {
      method: 'POST',
      headers: API.getAuthHeaders(),
      body: JSON.stringify(body),
    }).then(API.handleResponse);
  };

  API.deleteNutricionalEvent = function ({ eventType, eventId }) {
    return fetch(
      `/api/core/gestionnutricional/eventos/${eventType}/${eventId}/`,
      {
        method: 'DELETE',
        headers: API.getAuthHeaders(),
      }
    ).then(API.handleResponse);
  };

  API.deleteMultipleNutricionalEvent = function ({ eventType, event_ids }) {
    return fetch(
      `/api/core/gestionnutricional/eventos/${eventType}/delete-multiple/`,
      {
        method: 'POST',
        headers: API.getAuthHeaders(),
        body: JSON.stringify({ event_ids }),
      }
    ).then(API.handleResponse);
  };

  API.finishDieta = function ({ id, dieta }) {
    return fetch(
      `/api/core/gestionnutricional/eventos/dieta/${id}/finalizar/`,
      {
        method: 'POST',
        headers: API.getAuthHeaders(),
        body: JSON.stringify(dieta),
      }
    ).then(API.handleResponse);
  };

  API.exportFieldsNutricionalEvent = function ({ queryKey }) {
    const [, { eventType }] = queryKey;
    return fetch(
      `/api/core/gestionnutricional/eventos/${eventType}/export-fields/`,
      {
        method: 'GET',
        headers: API.getAuthHeaders(),
      }
    ).then(API.handleResponse);
  };
  API.exportNutricionalEvent = function ({ eventType, fields, params = null }) {
    const qp = API.makeQueryString(params);
    return fetch(
      `/api/core/gestionnutricional/eventos/${eventType}/excel-download/?${qp}`,
      {
        method: 'POST',
        headers: API.getAuthHeaders(),
        body: JSON.stringify({ fields }),
      }
    ).then(API.handleBlob);
  };

  API.fetchNutricionalEventsRecords = function ({ queryKey }) {
    const [, { recordType, params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`/api/core/gestionnutricional/eventos/${recordType}/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchNutricionalRecordsKPI = function ({ queryKey }) {
    const [, { recordType, params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(
      `/api/core/gestionnutricional/analytics/kpis-${recordType}/?${qp}`,
      {
        method: 'GET',
        headers: API.getAuthHeaders(),
      }
    ).then(API.handleResponse);
  };

  API.uploadNutricionalEvent = function ({
    eventType,
    inputFile,
    params = {},
  }) {
    const qp = API.makeQueryString(params);
    return fetch(
      `/api/core/gestionnutricional/eventos/${eventType}/excel-upload/?${qp}`,
      {
        method: 'POST',
        headers: API.getAuthFormHeaders(),
        body: inputFile,
      }
    ).then(API.handleBlobOnerror);
  };

  // ++ INSUMOS ++
  API.fetchNutricionalInsumo = function ({ queryKey }) {
    const [, { inputType, params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`/api/core/gestionnutricional/insumos/${inputType}/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.addNutricionalInsumo = function ({ body, inputType }) {
    return fetch(`/api/core/gestionnutricional/insumos/${inputType}/`, {
      method: 'POST',
      headers: API.getAuthHeaders(),
      body: JSON.stringify(body),
    }).then(API.handleResponse);
  };

  API.editNutricionalInsumo = function ({ id, body, inputType }) {
    return fetch(`/api/core/gestionnutricional/insumos/${inputType}/${id}/`, {
      method: 'PATCH',
      headers: API.getAuthHeaders(),
      body: JSON.stringify(body),
    }).then(API.handleResponse);
  };

  API.deleteNutricionalInsumo = function ({ id, inputType }) {
    return fetch(`/api/core/gestionnutricional/insumos/${inputType}/${id}/`, {
      method: 'DELETE',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.decrementNutricionalInsumo = function ({ id, inputType, amount }) {
    return fetch(
      `/api/core/gestionnutricional/insumos/${inputType}/${id}/decrement/?cantidad=${amount}`,
      {
        method: 'POST',
        headers: API.getAuthHeaders(),
      }
    ).then(API.handleResponse);
  };

  API.fetchNutricionalAdquisicion = function ({ queryKey }) {
    const [, { inputType, params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(
      `/api/core/gestionnutricional/insumos/adquisicion-${inputType}/?${qp}`,
      {
        method: 'GET',
        headers: API.getAuthHeaders(),
      }
    ).then(API.handleResponse);
  };

  API.addNutricionalAdquisicion = function ({ body, inputType }) {
    return fetch(
      `/api/core/gestionnutricional/insumos/adquisicion-${inputType}/`,
      {
        method: 'POST',
        headers: API.getAuthHeaders(),
        body: JSON.stringify(body),
      }
    ).then(API.handleResponse);
  };

  API.editNutricionalAdquisicion = function ({ id, body, inputType }) {
    return fetch(
      `/api/core/gestionnutricional/insumos/adquisicion-${inputType}/${id}/`,
      {
        method: 'PATCH',
        headers: API.getAuthHeaders(),
        body: JSON.stringify(body),
      }
    ).then(API.handleResponse);
  };

  API.deleteNutricionalAdquisicion = function ({ id, inputType }) {
    return fetch(
      `/api/core/gestionnutricional/insumos/adquisicion-${inputType}/${id}/`,
      {
        method: 'DELETE',
        headers: API.getAuthHeaders(),
      }
    ).then(API.handleResponse);
  };

  return API;
};

export default GestionNutricionalAPI;
