const AdminAPI = (API) => {
  API.fetchVersion = function ({ queryKey }) {
    return fetch('/api/auth/versions/', {
      method: 'GET',
      headers: API.getSimpleHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchKPIAdmin = function ({ queryKey }) {
    return fetch('/api/auth/establecimientos-admin/kpis/', {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchEstablishments = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`/api/auth/establecimientos-admin/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchEstablishment = function ({ queryKey }) {
    const [, { estId }] = queryKey;
    return fetch(`/api/auth/establecimientos-admin/${estId}/`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.createEstablishment = function ({ est }) {
    return fetch(`/api/auth/establecimientos-admin/`, {
      method: 'POST',
      headers: API.getAuthHeaders(),
      body: JSON.stringify(est),
    }).then(API.handleResponse);
  };

  API.editEstablishment = function ({ est, estId }) {
    return fetch(`/api/auth/establecimientos-admin/${estId}/`, {
      method: 'PATCH',
      headers: API.getAuthHeaders(),
      body: JSON.stringify(est),
    }).then(API.handleResponse);
  };

  API.resetEstablishment = function ({ estId }) {
    return fetch(`/api/auth/establecimientos-admin/${estId}/reset-tenant/`, {
      method: 'POST',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.deleteEstablishment = function ({ estId }) {
    return fetch(`/api/auth/establecimientos-admin/${estId}/`, {
      method: 'DELETE',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.createUser = function ({ user }) {
    return fetch(`/api/auth/users/`, {
      method: 'POST',
      headers: API.getAuthHeaders(),
      body: JSON.stringify(user),
    }).then(API.handleResponse);
  };

  API.fetchUser = function ({ queryKey }) {
    const [, { id }] = queryKey;
    return fetch(`/api/auth/users/${id}/`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.editUser = function ({ userId, user }) {
    return fetch(`/api/auth/users/${userId}/`, {
      method: 'PATCH',
      headers: API.getAuthHeaders(),
      body: JSON.stringify(user),
    }).then(API.handleResponse);
  };

  API.resetPasswordUser = function ({ userId, new_password }) {
    return fetch(`/api/auth/users/${userId}/change-password/`, {
      method: 'POST',
      headers: API.getAuthHeaders(),
      body: JSON.stringify({ new_password }),
    }).then(API.handleResponse);
  };

  API.deleteUser = function ({ userId }) {
    return fetch(`/api/auth/users/${userId}/`, {
      method: 'DELETE',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchUserBarn = function ({ queryKey }) {
    return fetch('/api/auth/establecimientos/', {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.editUserBarn = function ({ id, data = {} }) {
    return fetch(`/api/auth/establecimientos/${id}/`, {
      method: 'PATCH',
      headers: API.getAuthHeaders(),
      body: JSON.stringify(data),
    }).then(API.handleResponse);
  };

  API.fetchBarnLogo = function ({ queryKey }) {
    const [, { barnId }] = queryKey;
    return fetch(`/api/auth/establecimientos/${barnId}/logo/`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleBlob);
  };

  API.editBarnLogo = function ({ barnId, data = {} }) {
    return fetch(`/api/auth/establecimientos/${barnId}/logo-upload/`, {
      method: 'POST',
      headers: API.getAuthFormHeaders(),
      body: data,
    }).then(API.handleBlob);
  };

  API.fetchLicenseTypes = function ({ queryKey }) {
    return fetch(`/api/auth/license-types/`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.createLicense = function ({ license }) {
    return fetch(`/api/auth/licenses/`, {
      method: 'POST',
      headers: API.getAuthHeaders(),
      body: JSON.stringify(license),
    }).then(API.handleResponse);
  };

  API.deleteLicense = function ({ licenseId }) {
    return fetch(`/api/auth/licenses/${licenseId}/`, {
      method: 'DELETE',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  return API;
};

export default AdminAPI;
