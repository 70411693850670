import React from 'react';
import { Table } from 'modules/core/components';
import { GENERAL, UNITS } from 'modules/core/constants';

const defaultColumns = (sorter = false) => [
  {
    title: 'RP',
    dataIndex: 'rp',
    sorter,
  },
  {
    title: 'ID Electrónico',
    dataIndex: 'id_electronico',
    sorter,
  },
  {
    title: 'Nacimiento',
    dataIndex: 'fecha_nacimiento',
    sorter,
    render: (d) => GENERAL.dateToFront(d),
  },
  {
    title: 'Categoría',
    dataIndex: 'categoria',
    render: (c) => UNITS.CATEGORIES_FORMATTED[c],
  },
  {
    title: 'Estado',
    dataIndex: 'estado',
    render: (s) => UNITS.ESTADOS_FORMATTED[s],
  },
  {
    title: 'RP Madre',
    dataIndex: 'rp_madre',
    key: 'madre__rp',
    sorter,
  },
  {
    title: 'RP Padre',
    dataIndex: 'rp_padre',
    key: 'padre__rp',
    sorter,
  },
];

const actionsColumn = (renderActions) => [
  {
    title: 'Acciones',
    align: 'right ',
    render: (_, record) => renderActions?.(record),
  },
];

const SanidadIndividualTable = ({
  data,
  rowKey = 'id',
  loading = false,
  actions = null,
  ...props
}) => {
  let columns = actions
    ? defaultColumns(true).concat(actionsColumn(actions))
    : defaultColumns();
  return (
    <Table
      rowKey={rowKey}
      rowClassName="table-row"
      dataSource={data}
      columns={columns}
      loading={loading}
      {...props}
    />
  );
};

export default SanidadIndividualTable;
