import { GENERAL, NUTRICIONAL } from 'modules/core/constants';

const defaultColumns = [];

const actionsColumn = (renderActions) => [
  {
    title: 'Acciones',
    align: 'right ',
    render: (_, record) => renderActions?.(record),
  },
];

const COLUMNS = {};

COLUMNS[NUTRICIONAL.SUPLEMENTO] = (actions) =>
  defaultColumns
    .concat([
      {
        title: 'Fecha',
        dataIndex: 'fecha',
        sorter: true,
        render: (d) => GENERAL.dateToFront(d),
      },
      {
        title: 'Cantidad',
        dataIndex: 'cantidad',
        sorter: true,
      },
      {
        title: 'Nombre',
        dataIndex: ['suplemento', 'nombre'],
        key: 'suplemento__nombre',
        sorter: true,
      },
      {
        title: 'Materia seca (%)',
        dataIndex: ['suplemento', 'materia_seca'],
        key: 'suplemento__materia_seca',
        sorter: true,
      },
    ])
    .concat(actionsColumn(actions));

export { COLUMNS };
