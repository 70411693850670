import React, { useState } from 'react';
import _ from 'lodash';
import { Form, Row, Col, Collapse, Select } from 'antd';
import { GENERAL, MASTERS } from 'modules/core/constants';
import { withAPI } from 'modules/api';
import { useQuery } from 'react-query';
import DatoAdicionalInput from 'modules/management/productiva/events/DatosAdicionales/DatosAdicionalesTable/DatoAdicionalInput';
import { default as CommonFilter } from './CommonFilter';

const { Panel } = Collapse;
const { Item } = Form;

const PARAMS = {
  DATO_ADICIONAL: 'dato_adicional',
  VALUE: 'valor',
  VALUE_FROM: 'from_valor',
  VALUE_TO: 'to_valor',
  BAJA: 'baja',
};

const tags = {
  [PARAMS.DATO_ADICIONAL]: 'Dato adicional',
  [PARAMS.VALUE]: 'Valor',
  [PARAMS.VALUE_FROM]: 'Valor desde',
  [PARAMS.VALUE_TO]: 'Valor hasta',
  [PARAMS.BAJA]: 'Estado de unidad',
  ...CommonFilter.tags,
};

const FormFields = ({ api, form, ...props }) => {
  const [datoAdicionalType, setDatoAdicionalType] = useState(null);
  const queryOptions = { staleTime: 100000 };
  const { data: options, isLoading: isLoadingOptions } = useQuery(
    [
      'MAESTROS::fetchMastersAsFormOptions',
      {
        masterTypes: [
          MASTERS.PRODUCTIVA_TYPES.DATOS_ADICIONALES,
          MASTERS.PRODUCTIVA_TYPES.CUERNO,
          MASTERS.PRODUCTIVA_TYPES.DENTICION,
        ],
      },
    ],
    api.fetchMastersFormOptions,
    queryOptions
  );

  const showValueInput = [
    ...MASTERS.DATOS_ADICIONALES_COMPONENT.SELECT,
    ...MASTERS.DATOS_ADICIONALES_COMPONENT.INPUT,
  ].includes(datoAdicionalType);

  const onChangeDatoAdicionalType = (o) => {
    form.setFieldsValue({
      [PARAMS.VALUE]: undefined,
      [PARAMS.VALUE_TO]: undefined,
      [PARAMS.VALUE_TO]: undefined,
    });
    setDatoAdicionalType(o?.value);
  };

  const renderValueInput = () => {
    return (
      showValueInput && (
        <Col span={24}>
          <Item label={tags[PARAMS.VALUE]} name={PARAMS.VALUE}>
            <DatoAdicionalInput
              datoAdicionalType={datoAdicionalType}
              disabled={!datoAdicionalType}
              selectOptions={_.omit(options, [
                MASTERS.PRODUCTIVA_TYPES.DATOS_ADICIONALES,
              ])}
            />
          </Item>
        </Col>
      )
    );
  };

  const renderValueFromToInput = () => {
    return (
      !showValueInput && (
        <>
          <Col span={12}>
            <Item label={tags[PARAMS.VALUE_FROM]} name={PARAMS.VALUE_FROM}>
              <DatoAdicionalInput
                datoAdicionalType={datoAdicionalType}
                disabled={!datoAdicionalType}
                selectOptions={_.omit(options, [
                  MASTERS.PRODUCTIVA_TYPES.DATOS_ADICIONALES,
                ])}
              />
            </Item>
          </Col>
          <Col span={12}>
            <Item label={tags[PARAMS.VALUE_TO]} name={PARAMS.VALUE_TO}>
              <DatoAdicionalInput
                datoAdicionalType={datoAdicionalType}
                disabled={!datoAdicionalType}
                selectOptions={_.omit(options, [
                  MASTERS.PRODUCTIVA_TYPES.DATOS_ADICIONALES,
                ])}
              />
            </Item>
          </Col>
        </>
      )
    );
  };

  return (
    <Row gutter={[0]}>
      <Col span={24}>
        <Collapse ghost>
          <Panel header="Datos adicionales" key="datos_adicionales">
            <Row gutter={8}>
              <Col span={24}>
                <Item
                  label={tags[PARAMS.DATO_ADICIONAL]}
                  name={PARAMS.DATO_ADICIONAL}
                >
                  <Select
                    placeholder="Seleccionar valor"
                    allowClear
                    labelInValue={true}
                    dropdownMatchSelectWidth={200}
                    loading={isLoadingOptions}
                    onChange={onChangeDatoAdicionalType}
                    options={
                      options?.[MASTERS.PRODUCTIVA_TYPES.DATOS_ADICIONALES]
                    }
                  />
                </Item>
              </Col>
              {renderValueInput()}
              {renderValueFromToInput()}
              <Col span={24}>
                <Form.Item label={tags[PARAMS.BAJA]} name={PARAMS.BAJA}>
                  <Select
                    placeholder="Seleccionar valor"
                    allowClear
                    labelInValue={true}
                    dropdownMatchSelectWidth={200}
                    options={GENERAL.optionsBoolToStr(MASTERS.BAJA_OPTIONS)}
                  />
                </Form.Item>
              </Col>
              <CommonFilter.FormFields {...{ form, ...props }} />
            </Row>
          </Panel>
        </Collapse>
      </Col>
    </Row>
  );
};

const filterComponent = {
  tags,
  FormFields: withAPI(FormFields),
};

export default filterComponent;
