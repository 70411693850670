import { Space } from 'antd';
import React from 'react';
import './ModalTitle.scss';

const ModalTitle = ({ icon = null, title }) => (
  <div className="finca-modal-title">
    <Space>
      {icon}
      {title}
    </Space>
  </div>
);

export default ModalTitle;
