import React from 'react';
import {
  Form,
  Row,
  Col,
  Collapse,
  DatePicker,
  AutoComplete,
  Select,
} from 'antd';
import { GENERAL, UNITS, MASTERS } from 'modules/core/constants';
import { withAPI } from 'modules/api';
import { useQuery } from 'react-query';
import { default as CommonFilter } from './CommonFilter';
const { Panel } = Collapse;
const { Item } = Form;
const { RangePicker } = DatePicker;

const PARAMS = {
  FECHA_EVENTO: `${GENERAL.FILTER_RANGE}fecha_evento`,
  MACHO_RP: 'rp_macho',
  MACHO_APODO: 'apodo_macho',
  BAJA: 'baja',
};

const tags = {
  [PARAMS.FECHA_EVENTO]: 'Fecha servicio',
  [PARAMS.MACHO_RP]: 'Macho',
  [PARAMS.MACHO_APODO]: 'Apodo macho',
  [PARAMS.BAJA]: 'Estado de unidad',
  ...CommonFilter.tags,
};

const FormFields = ({ api, ...props }) => {
  const queryOptions = { staleTime: 100000 };
  const { data: males, isLoading: isLoadingMales } = useQuery(
    ['Units::males', { params: { sexo: UNITS.MACHOS } }],
    api.fetchUnits,
    queryOptions
  );

  return (
    <Row gutter={[0]}>
      <Col span={24}>
        <Collapse ghost>
          <Panel header="Servicio" key="servicio">
            <Row gutter={8}>
              <Col span={24}>
                <Item
                  label={tags[PARAMS.FECHA_EVENTO]}
                  name={PARAMS.FECHA_EVENTO}
                >
                  <RangePicker style={{ width: '100%' }} />
                </Item>
              </Col>
              <Col span={24}>
                <Form.Item label={tags[PARAMS.MACHO_RP]} name={PARAMS.MACHO_RP}>
                  <AutoComplete
                    placeholder="Ingresar valor"
                    allowClear
                    filterOption={true}
                    loading={isLoadingMales}
                    options={GENERAL.toRPOptions(males?.results)}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={tags[PARAMS.MACHO_APODO]}
                  name={PARAMS.MACHO_APODO}
                >
                  <AutoComplete
                    placeholder="Ingresar valor"
                    allowClear
                    filterOption={true}
                    loading={isLoadingMales}
                    options={GENERAL.toApodoOptions(males?.results)}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label={tags[PARAMS.BAJA]} name={PARAMS.BAJA}>
                  <Select
                    placeholder="Seleccionar valor"
                    allowClear
                    labelInValue={true}
                    dropdownMatchSelectWidth={200}
                    options={GENERAL.optionsBoolToStr(MASTERS.BAJA_OPTIONS)}
                  />
                </Form.Item>
              </Col>
              <CommonFilter.FormFields {...props} />
            </Row>
          </Panel>
        </Collapse>
      </Col>
    </Row>
  );
};

const filterComponent = {
  tags,
  FormFields: withAPI(FormFields),
};

export default filterComponent;
