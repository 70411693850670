import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { withAPI } from 'modules/api';
import { MASTERS } from 'modules/core/constants';
import { Form, Input, Row, Col, InputNumber, Checkbox, Select } from 'antd';

const UbicacionesForm = ({ api, visible, form, initialValues }) => {
  const [isAgrupador, setIsAgrupador] = useState(false);

  useEffect(() => {
    if (visible) {
      setIsAgrupador({ target: { checked: initialValues.is_padre } });
    }
  }, [visible, initialValues.is_padre]);

  const { data: options, isLoading: isLoadingOptions } = useQuery(
    [
      'MAESTROS::fetchMastersAsFormOptions',
      {
        masterTypes: [MASTERS.PRODUCTIVA_TYPES.UBICACION],
        params: { is_padre: true },
      },
    ],
    api.fetchMastersFormOptions,
    { enabled: visible }
  );

  const onChangeIsAgrupador = (e) => {
    setIsAgrupador(e);
    form.setFieldsValue({
      ubicacion_padre: null,
      superficie_ganadera: null,
    });
  };

  return (
    <Row gutter={8}>
      <Col span={16}>
        <Form.Item
          label="Nombre de la ubicación geográfica"
          name="nombre"
          rules={[{ required: true, message: 'Ingrese nombre de ubicación' }]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label=" " valuePropName="checked" name="is_padre">
          <Checkbox checked={isAgrupador} onChange={onChangeIsAgrupador}>
            Es agrupador
          </Checkbox>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Ubicación agrupadora" name="ubicacion_padre">
          <Select
            allowClear
            loading={isLoadingOptions}
            options={options?.[MASTERS.PRODUCTIVA_TYPES.UBICACION].filter(
              (o) => o.value !== initialValues?.id
            )}
            disabled={isAgrupador.target?.checked}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Superficie ganadera (ha)"
          name="superficie_ganadera"
          rules={[
            {
              required: !isAgrupador.target?.checked,
              message: 'Ingrese cantidad de hectáreas',
            },
          ]}
        >
          <InputNumber
            style={{ width: '100%' }}
            min={0}
            disabled={isAgrupador.target?.checked}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item label="Descripción de la ubicación" name="descripcion">
          <Input.TextArea rows={2} />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default withAPI(UbicacionesForm);
