import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { withAPI } from 'modules/api';
import { OrderedListOutlined } from '@ant-design/icons';
import { Spin, Drawer, Button, Space } from 'antd';
import { ESTABLISHMENT, MASTERS } from 'modules/core/constants';
import { BadgeList, Card } from 'modules/core/components';
import ShowMoreButton from '../ShowMoreButton/ShowMoreButton';
import TitleHeaderCard from '../TitleHeaderCard/TitleHeaderCard';
import SeeMoreDrawerTitle from '../SeeMoreDrawerTitle/SeeMoreDrawerTitle';
import moment from 'moment';

const sortPrioridad = (a, b) =>
  MASTERS.PRIORIDADES_LIST.indexOf(a) - MASTERS.PRIORIDADES_LIST.indexOf(b);

const sortDates = (a, b) => moment(a).format('X') - moment(b).format('X');

const sortTareas = (tasks) =>
  tasks
    .slice()
    .sort(
      (a, b) =>
        sortPrioridad(a.prioridad, b.prioridad) ||
        sortDates(a.fecha_limite, b.fecha_limite)
    );

const TareasCard = ({ api, height = 160 }) => {
  let history = useHistory();
  const [showMore, setShowMore] = useState(false);
  const { data, isLoading } = useQuery(
    [
      'Establecimientos::fetchTareasPendientes',
      { params: { realizado: false } },
    ],
    api.fetchTareas,
    { select: (d) => sortTareas(d.results) }
  );

  if (isLoading) return <Spin />;

  return (
    <>
      <Card
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height,
        }}
      >
        <TitleHeaderCard title="Tareas pendientes" />
        <BadgeList
          data={data.slice(0, 3)}
          emptyText="No hay tareas pendientes"
          renderBadgeText={(el) => el?.descripcion}
          renderBadgeColor={(el) =>
            MASTERS.PRIORIDADES_BADGES[el?.prioridad.toLocaleLowerCase()]
          }
        />
        <ShowMoreButton onClick={() => setShowMore(true)} />
      </Card>
      <Drawer
        title="Tareas pendientes"
        onClose={() => setShowMore(false)}
        open={showMore}
        closable={false}
        width={700}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <SeeMoreDrawerTitle
            title="Ordenadas por prioridad"
            extra={
              <Button
                type="primary"
                icon={<OrderedListOutlined />}
                onClick={() =>
                  history.push(
                    `/${ESTABLISHMENT.NAME_SPACE}/${ESTABLISHMENT.TAREAS}`
                  )
                }
              >
                Ir a Tareas
              </Button>
            }
          />
          <BadgeList
            data={data}
            emptyText="No hay tareas pendientes"
            renderBadgeText={(el) => el?.descripcion}
            renderBadgeColor={(el) =>
              MASTERS.PRIORIDADES_BADGES[el?.prioridad.toLocaleLowerCase()]
            }
          />
        </Space>
      </Drawer>
    </>
  );
};

export default withAPI(TareasCard);
