import React from 'react';
import { useQuery } from 'react-query';
import { withAPI } from 'modules/api';
import { Row, Col, Tabs } from 'antd';
import {
  HomeDashEconomico,
  HomeDashProductivo,
  PlanificacionCard,
  SuperficieCard,
  TareasCard,
  TopBarInfo,
  WelcomeUser,
} from './components';

import './Home.scss';

const HEADER_CARD_HEIGHT = 160;

const Home = ({ api }) => {
  const {
    data: userInfo,
    isLoading: isLoadingUserInfo,
    error: errorUserInfo,
  } = useQuery(['Profile::UserInfo', { id: api.getUserId() }], api.fetchUser);
  const {
    data: userBarn,
    isLoading: isLoadingUserBarn,
    error: errorUserBarn,
  } = useQuery(['Profile::UserBarn'], api.fetchUserBarn);

  const items = [
    {
      key: 'dashboard-productivo',
      label: 'Dashboard productivo',
      children: <HomeDashProductivo />,
    },
    {
      key: 'dashboard-economico',
      label: 'Dashboard económico',
      children: <HomeDashEconomico />,
    },
  ];

  const renderUserInfo = () => {
    if (errorUserInfo || errorUserBarn)
      return <div>Hubo un error al conectarse al servidor</div>;
    return (
      <WelcomeUser
        loading={isLoadingUserInfo || isLoadingUserBarn}
        barnName={userBarn?.[0]?.name}
        userName={userInfo?.first_name}
      />
    );
  };

  return (
    <div className="home-container">
      <Row className="home-header">
        <Col span={24}>
          <TopBarInfo lat={userBarn?.[0]?.lat} lon={userBarn?.[0]?.lon} />
        </Col>
        <Col span={24}>
          <Row
            className="welcome-row"
            gutter={[16, 16]}
            justify="space-between"
            align="middle"
          >
            <Col xs={24} lg={12} xl={8} xxl={12}>
              {renderUserInfo()}
            </Col>
            <Col xs={24} lg={12} xxl={8}>
              <SuperficieCard height={HEADER_CARD_HEIGHT} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={12} xl={12}>
          <TareasCard height={HEADER_CARD_HEIGHT} />
        </Col>
        <Col xs={24} lg={12}>
          <PlanificacionCard height={HEADER_CARD_HEIGHT} />
        </Col>
      </Row>

      <Tabs items={items} />
    </div>
  );
};

export default withAPI(Home);
