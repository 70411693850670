import React from 'react';
import { Form, Row, Col, Select } from 'antd';

const SemenForm = ({ males = {} }) => {
  return (
    <Row gutter={8}>
      <Col span={24}>
        <Form.Item
          label="RP"
          name="macho"
          rules={[{ required: true, message: 'Ingrese RP' }]}
        >
          <Select
            showSearch
            allowClear
            style={{ width: '100%' }}
            optionFilterProp="label"
            placeholder="Seleccionar RP"
            loading={males?.isLoading}
            options={males?.options}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default SemenForm;
