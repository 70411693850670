import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { UNITS } from 'modules/core/constants';

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 15,
    paddingTop: 12,
    paddingLeft: 12,
    fontSize: 16,
    fontWeight: 500,
  },
  headerBovino: {
    color: '#054e2d', // --primary-color-dark
  },
  headerOvino: {
    color: '#00478f', // --primary-color-dark
  },
  headerIcon: {
    width: 24,
    height: 24,
    marginRight: 8,
  },
});

const PageHeader = ({ icon, title, userSpecies }) => {
  return (
    <View
      style={[
        styles.header,
        userSpecies === UNITS.OVINO ? styles.headerOvino : styles.headerBovino,
      ]}
    >
      <Image src={icon} style={styles.headerIcon} />
      <Text>{title}</Text>
    </View>
  );
};

export default PageHeader;
