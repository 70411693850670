import React from 'react';
import { InputNumber, Form } from 'antd';
import { Table } from 'modules/core/components';
import { GENERAL, UNITS } from 'modules/core/constants';

const defaultColumns = (sorter = false) => [
  {
    title: 'RP',
    dataIndex: 'rp',
    sorter,
  },
  {
    title: 'ID Electrónico',
    dataIndex: 'id_electronico',
    sorter,
  },
  {
    title: 'Nacimiento',
    dataIndex: 'fecha_nacimiento',
    sorter,
    render: (d) => GENERAL.dateToFront(d),
  },
  {
    title: 'Categoría',
    dataIndex: 'categoria',
    render: (c) => UNITS.CATEGORIES_FORMATTED[c],
  },
  {
    title: 'Estado',
    dataIndex: 'estado',
    render: (s) => UNITS.ESTADOS_FORMATTED[s],
  },
  {
    title: 'RP Madre',
    dataIndex: 'rp_madre',
    key: 'madre__rp',
    sorter,
  },
  {
    title: 'RP Padre',
    dataIndex: 'rp_padre',
    key: 'padre__rp',
    sorter,
  },
];

const stockFormColumns = () => [
  {
    title: 'Decrementar stock',
    dataIndex: 'stock_amount',
    width: 300,
    render: (value, row) => (
      <Form.Item
        name={[`stock_amount`, `${row.id}`]}
        style={{ marginBottom: 0 }}
      >
        <InputNumber
          style={{ width: '100%' }}
          defaultValue={0}
          min={0}
          placeholder="Indicar cantidad"
        />
      </Form.Item>
    ),
  },
];

const actionsColumn = (renderActions) => [
  {
    title: 'Acciones',
    align: 'right ',
    render: (_, record) => renderActions?.(record),
  },
];

const ControlEpidemiologicoTable = ({
  data,
  rowKey = 'id',
  loading = false,
  actions = null,
  form = null,
  ...props
}) => {
  let columns = defaultColumns(true).concat(actionsColumn(actions));

  if (form) {
    columns = defaultColumns().concat(stockFormColumns());
  }

  return (
    <Table
      rowKey={rowKey}
      rowClassName="table-row"
      dataSource={data}
      columns={columns}
      loading={loading}
      {...props}
    />
  );
};

export default ControlEpidemiologicoTable;
