import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import _ from 'lodash';
import { withAPI } from 'modules/api';
import { Col, Row } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';
import { usePaginationWithFilters } from 'modules/core/hooks';
import { GENERAL } from 'modules/core/constants';
import { DeleteModal, DropdownMenu, Table } from 'modules/core/components';
import FilterSider from 'modules/core/components/Filter';
import {
  InsumosHeader,
  InsumosModalDecrement,
} from 'modules/management/components';
import InsumoModal from './InsumoModal';

const Insumo = ({
  api,
  inputType,
  masterName,
  columns = [],
  loading = false,
  showEdit = false,
  renderForm = null,
}) => {
  const { pagination, filters, sorter, params } = usePaginationWithFilters();
  const [addEditModalVisible, setAddEditModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [decrementarModalVisible, setDecrementarModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const {
    data,
    isLoading: isLoadingData,
    refetch,
  } = useQuery(
    [`${masterName.CAPITALIZE}::fetchMaster`, { inputType, params }],
    api.fetchInsumo
  );

  const { mutate: deleteInsumo, isLoading: isDeleting } = useMutation(
    api.deleteInsumo
  );

  const actionsHandler = ({ item, action }) => {
    setSelectedItem(item);
    if (action === GENERAL.ACTIONS.MODIFICAR) {
      setAddEditModalVisible(true);
    }
    if (action === GENERAL.ACTIONS.DECREMENTAR) {
      setDecrementarModalVisible(true);
    }
    if (action === GENERAL.ACTIONS.ELIMINAR) {
      setDeleteModalVisible(true);
    }
  };

  const handleDelete = ({ onSuccess, onError }) => {
    deleteInsumo({ id: selectedItem.id, inputType }, { onSuccess, onError });
  };

  const handleCancelDelete = () => {
    setDeleteModalVisible(false);
    setSelectedItem({});
  };

  const handleCancelDecrementar = () => {
    setDecrementarModalVisible(false);
    setSelectedItem({});
  };

  const tableColumns = [
    ...columns,
    {
      title: 'Acciones',
      dataIndex: 'id',
      align: 'right ',
      render: (id, item) => (
        <DropdownMenu
          menu={_.compact([
            showEdit && {
              title: 'Modificar',
              icon: <EditOutlined />,
              onClick: () =>
                actionsHandler({ item, action: GENERAL.ACTIONS.MODIFICAR }),
            },
            {
              title: 'Decrementar',
              icon: <CaretDownOutlined />,
              onClick: () =>
                actionsHandler({ item, action: GENERAL.ACTIONS.DECREMENTAR }),
            },
            {
              title: 'Eliminar',
              icon: <DeleteOutlined />,
              onClick: () =>
                actionsHandler({ item, action: GENERAL.ACTIONS.ELIMINAR }),
            },
          ])}
        />
      ),
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <InsumosHeader
          title={`${masterName.CAPITALIZE}s`}
          onClick={() => setAddEditModalVisible(true)}
        />
      </Col>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col span={4}>
            <FilterSider onChange={filters.set} />
          </Col>
          <Col span={24}>
            <Table
              rowKey="id"
              dataSource={data && data?.results}
              columns={tableColumns}
              loading={isLoadingData || loading}
              showTotal={true}
              pagination={{ ...pagination.get(), total: data?.count }}
              onChange={(p, f, s) => {
                pagination.set(p);
                sorter.set(s);
              }}
            />
          </Col>
          <InsumoModal
            visible={addEditModalVisible}
            initialValues={selectedItem}
            inputType={inputType}
            masterName={masterName}
            onSuccess={refetch}
            onCancel={() => {
              setAddEditModalVisible(false);
              setSelectedItem({});
            }}
            renderForm={renderForm}
          />
          <InsumosModalDecrement
            id={selectedItem.id}
            inputType={inputType}
            visible={decrementarModalVisible}
            onSuccess={() => {
              refetch();
              handleCancelDecrementar();
            }}
            onCancel={handleCancelDecrementar}
          />
          <DeleteModal
            title={`Eliminar ${masterName.LOWER}`}
            bodyText={<b>{selectedItem?.nombre}</b>}
            isDeleting={isDeleting}
            visible={deleteModalVisible}
            onCancel={handleCancelDelete}
            onConfirm={handleDelete}
            onSuccess={() => {
              refetch();
              handleCancelDelete();
            }}
            onSuccessInfo={{
              description: `${masterName.CAPITALIZE} eliminada con éxito.`,
              showCheck: true,
            }}
          />{' '}
        </Row>
      </Col>
    </Row>
  );
};

export default withAPI(Insumo);
