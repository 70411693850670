import React from 'react';
import { List } from 'antd';
import { Card } from 'modules/core/components';
import './InsumosCards.scss';

const InsumosCardContent = ({ quantity = '-', description = '-' }) => (
  <div className="insumos-card-text">
    <div className="insumos-card-quantity">{quantity}</div>
    <div className="insumos-card-description">
      <span className="insumos-card-description-strong">{description}</span>
    </div>
  </div>
);

const InsumosCards = ({ data: cards }) => {
  return (
    <div className="insumos-cards-container">
      <List
        dataSource={cards}
        itemLayout="vertical"
        split={false}
        loading={!cards}
        renderItem={({ cantidad, titulo }) => (
          <List.Item>
            <Card className="insumos-card">
              <InsumosCardContent quantity={cantidad} description={titulo} />
            </Card>
          </List.Item>
        )}
      />
    </div>
  );
};
export default InsumosCards;
