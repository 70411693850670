import React from 'react';
import { Select, Form } from 'antd';
import { Table } from 'modules/core/components';
import { GENERAL, UNITS } from 'modules/core/constants';

const { Item: FormItem } = Form;

const defaultColumns = (registros, sorter = false) => [
  {
    title: 'RP',
    dataIndex: 'rp',
    sorter,
  },
  {
    title: 'ID Electrónico',
    dataIndex: 'id_electronico',
    sorter,
  },
  {
    title: 'Registro',
    dataIndex: 'registro',
    sorter,
    render: (v) => GENERAL.optionToLabel(registros, v),
  },
  {
    title: 'Categoría',
    dataIndex: 'categoria',
    render: (c) => UNITS.CATEGORIES_FORMATTED[c],
  },
  {
    title: 'Peso actual',
    dataIndex: 'peso_actual',
    sorter,
    render: (w) => w && `${w} kg`,
  },
  {
    title: 'Apodo padre',
    dataIndex: 'apodo_padre',
    key: 'padre__apodo',
    sorter,
  },
  {
    title: 'Apodo abuelo materno',
    dataIndex: 'apodo_abuelo_materno',
  },
];
const actionsColumn = (renderActions) => [
  {
    title: 'Acciones',
    align: 'right ',
    render: (_, record) => renderActions?.(record),
  },
];

const seleccionFormColumns = (userSpecies) => [
  {
    title: 'Categoría',
    dataIndex: 'nueva_categoria',
    render: (value, row) => (
      <FormItem
        initialValue={value}
        name={[`newCategories`, `${row.id}`]}
        style={{ marginBottom: 0 }}
        rules={[
          {
            required: true,
            message: 'Seleccione el estado',
          },
        ]}
      >
        <Select
          style={{ width: '100%', minWidth: 200 }}
          placeholder="Seleccionar"
          options={GENERAL.arrayToOptions(
            UNITS.SELECCION_MACHOS[userSpecies].filter(
              (el) => el !== row?.categoria
            ),
            (i) => UNITS.CATEGORIES_FORMATTED[i]
          )}
        />
      </FormItem>
    ),
  },
];

const SeleccionMachosTable = ({
  data,
  form = null,
  rowKey = 'id',
  loading = false,
  registros = null,
  actions = null,
  userSpecies,
  ...props
}) => {
  let columns = defaultColumns(registros, true).concat(actionsColumn(actions));
  if (form)
    columns = defaultColumns(registros).concat(
      seleccionFormColumns(userSpecies)
    );
  return (
    <Table
      rowKey={rowKey}
      rowClassName="table-row"
      dataSource={data}
      columns={columns}
      loading={loading}
      {...props}
    />
  );
};

export default SeleccionMachosTable;
