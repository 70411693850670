import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, List } from 'antd';
import { Card, ScreenHeader, Title } from 'modules/core/components';

import './ManagementDashboard.scss';

const ManagementDashboard = ({
  title = '',
  loading = false,
  events = [],
  noEvents = [],
}) => {
  let history = useHistory();

  const renderSectionTitle = (text = '') => (
    <Title className="section-title" text={text} level={2} />
  );

  const renderEvents = () => (
    <Col span={24}>
      <Col span={24}>{renderSectionTitle('Eventos')}</Col>
      <Col span={24}>
        <List
          dataSource={events}
          grid={{
            gutter: 24,
            xs: 1,
            sm: 2,
            md: 4,
            lg: 4,
            xl: 4,
            xxl: 4,
          }}
          loading={loading}
          renderItem={({ title, quantity, description, url }) => (
            <List.Item>
              <Card
                className="event-card"
                onClick={() => history.push(url)}
                title={title}
                quantity={quantity}
                description={description}
                showGoToIcon={true}
              />
            </List.Item>
          )}
        />
      </Col>
    </Col>
  );

  const renderNoEvents = () => (
    <Col span={24}>
      <Row gutter={[48, 16]}>
        {noEvents.map((noEvent) => (
          <Col key={noEvent.title} span={8}>
            {renderSectionTitle(noEvent.title)}
            {noEvent.data.map(({ title, url }) => (
              <Card
                key={title}
                title={title}
                onClick={() => history.push(url)}
                showGoToIcon={true}
              />
            ))}
          </Col>
        ))}
      </Row>
    </Col>
  );

  return (
    <Row gutter={[16, 16]} className="management-dashboard">
      <Col span={24}>
        <ScreenHeader title={title} />
      </Col>
      {renderEvents()}
      {renderNoEvents()}
    </Row>
  );
};

export default ManagementDashboard;
