import React from 'react';
import { withAPI } from 'modules/api';
import { useQuery } from 'react-query';
import { MASTERS } from 'modules/core/constants';
import { Form, Col, Select } from 'antd';
import { ManagementConfig } from 'modules/management/components';

const MotivosBajaForm = ({ api }) => {
  const { data: options, isLoading } = useQuery(
    [
      'Gestion::fetchCategoriasMotivosBaja',
      { masterTypes: [MASTERS.PRODUCTIVA_TYPES.CATEGORIA_MOTIVO_BAJA] },
    ],
    api.fetchMastersFormOptions
  );

  return (
    <Col span={24}>
      <Form.Item
        label="Categoria"
        name="categoria"
        rules={[{ required: true, message: 'Ingrese categoria' }]}
      >
        <Select
          showSearch
          allowClear
          style={{ width: '100%' }}
          optionFilterProp="label"
          placeholder="Seleccionar categoria"
          loading={isLoading}
          options={options?.[MASTERS.PRODUCTIVA_TYPES.CATEGORIA_MOTIVO_BAJA]}
        />
      </Form.Item>
    </Col>
  );
};

const MotivosBajaFormApi = withAPI(MotivosBajaForm);

const MotivosBaja = ({ api }) => {
  const masterType = MASTERS.PRODUCTIVA_TYPES.MOTIVO;
  const masterName = {
    CAPITALIZE: 'Motivo de baja',
    LOWER: 'motivo de baja',
    PLURAL: 'Motivos de baja',
  };

  return (
    <ManagementConfig
      masterType={masterType}
      masterName={masterName}
      groupBy="categoria"
      renderForm={() => <MotivosBajaFormApi />}
    />
  );
};

export default withAPI(MotivosBaja);
