import React, { useCallback, useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { withAPI } from 'modules/api';
import { GENERAL, MASTERS } from 'modules/core/constants';
import {
  Modal,
  Form,
  Input,
  message as antMessage,
  Row,
  Col,
  Select,
  DatePicker,
} from 'antd';
import { FileAddOutlined } from '@ant-design/icons';
import { displayInfoModal, ModalTitle } from 'modules/core/components';

const OtrosEconomicaModalAdd = ({
  api,
  title = '',
  eventType,
  visible,
  onSuccess,
  close,
}) => {
  const [form] = Form.useForm();
  const { mutate: add, isLoading: addingOtroMovimiento } = useMutation(
    api.addEconomicaEvent
  );
  const { data: options, isLoading: isLoadingOptions } = useQuery(
    [
      'MAESTROS::fetchMastersAsFormOptions',
      {
        masterTypes: [MASTERS.ECONOMICA_TYPES.TIPO_GESTION],
      },
    ],
    api.fetchMastersFormOptions
  );

  const clearForm = useCallback(() => {
    form.resetFields();
  }, [form]);
  useEffect(clearForm, [clearForm, visible]);

  const onCancel = () => {
    clearForm();
    close();
  };
  const clearAndSuccess = () => {
    clearForm();
    onSuccess();
  };

  const onConfirm = () => {
    form
      .validateFields()
      .then((values) => {
        const data = {
          fecha: GENERAL.dateToBack(values.fecha),
          is_operativo: values.is_operativo,
          tipo_gestion: values.tipo_gestion,
          monto: values.monto,
          descripcion: values.descripcion,
        };
        const handleOnSuccess = () => {
          clearAndSuccess();
          displayInfoModal({
            description: `Movimiento (${title}) agregado con éxito.`,
            showCheck: true,
          });
        };
        const handleOnError = ({ message, description }) => {
          if (message === '400') antMessage.error(description);
        };
        add(
          { eventType, body: data },
          { onSuccess: handleOnSuccess, onError: handleOnError }
        );
      })
      .catch(() => {});
  };
  return (
    <Modal
      title={
        <ModalTitle icon={<FileAddOutlined />} title={`Agregar ${title}`} />
      }
      forceRender
      cancelText="CANCELAR"
      okText="AGREGAR"
      confirmLoading={addingOtroMovimiento}
      open={visible}
      onCancel={onCancel}
      onOk={onConfirm}
    >
      <Form form={form} layout="vertical" requiredMark={false}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Fecha"
              name="fecha"
              rules={[{ required: true, message: 'Ingrese fecha' }]}
            >
              <DatePicker
                style={{ width: '100%' }}
                format={GENERAL.DATE_FORMAT.FRONT}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Tipo"
              name="is_operativo"
              rules={[{ required: true, message: 'Ingrese un tipo' }]}
            >
              <Select
                placeholder="Seleccionar"
                style={{ width: '100%' }}
                loading={isLoadingOptions}
                options={[
                  { value: true, label: 'Operativo' },
                  { value: false, label: 'No operativo' },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Gestión"
              name="tipo_gestion"
              rules={[{ required: true, message: 'Ingrese una gestión' }]}
            >
              <Select
                placeholder="Seleccionar"
                style={{ width: '100%' }}
                loading={isLoadingOptions}
                options={options?.[MASTERS.ECONOMICA_TYPES.TIPO_GESTION]}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Monto"
              name="monto"
              rules={[
                {
                  required: true,
                  type: 'string',
                  pattern: /^[0-9?]*\.?[0-9]*$/,
                  message: 'Ingrese monto',
                },
              ]}
            >
              <Input placeholder="Ingrese monto" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Descripción"
              name="descripcion"
              rules={[{ required: true, message: 'Ingrese descripción' }]}
            >
              <Input.TextArea rows={2} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default withAPI(OtrosEconomicaModalAdd);
