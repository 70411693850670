import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withSubMenuLayout } from 'modules/core/layouts';
import { ESTABLISHMENT, GENERAL } from 'modules/core/constants';
import {
  Ubicaciones,
  UbicacionesDeactivatedList,
  Rodeos,
  RodeosDeactivatedList,
  Responsables,
  Criadores,
} from './establishmentMasters';
import Planificacion from './Planificacion/Planificacion';
import Tareas from './Tareas/Tareas';
import establishmentLeftMenuOptions from './establishmentLeftMenuOptions';

const withSubLayout = withSubMenuLayout(establishmentLeftMenuOptions);

const Routes = () => {
  return (
    <Switch>
      <Route
        exact
        path={`/${ESTABLISHMENT.NAME_SPACE}/${ESTABLISHMENT.PLANIFICACION}`}
        component={withSubLayout(Planificacion)}
      />
      <Route
        exact
        path={`/${ESTABLISHMENT.NAME_SPACE}/${ESTABLISHMENT.CRIADORES}`}
        component={withSubLayout(Criadores)}
      />
      <Route
        exact
        path={`/${ESTABLISHMENT.NAME_SPACE}/${ESTABLISHMENT.RESPONSABLES}`}
        component={withSubLayout(Responsables)}
      />
      <Route
        exact
        path={`/${ESTABLISHMENT.NAME_SPACE}/${ESTABLISHMENT.RODEOS}`}
        component={withSubLayout(Rodeos)}
      />
      <Route
        exact
        path={`/${ESTABLISHMENT.NAME_SPACE}/${ESTABLISHMENT.RODEOS}/${GENERAL.DEACTIVE}`}
        component={withSubLayout(RodeosDeactivatedList)}
      />
      <Route
        exact
        path={`/${ESTABLISHMENT.NAME_SPACE}/${ESTABLISHMENT.UBICACIONES}`}
        component={withSubLayout(Ubicaciones)}
      />
      <Route
        exact
        path={`/${ESTABLISHMENT.NAME_SPACE}/${ESTABLISHMENT.UBICACIONES}/${GENERAL.DEACTIVE}`}
        component={withSubLayout(UbicacionesDeactivatedList)}
      />
      <Route
        exact
        path={`/${ESTABLISHMENT.NAME_SPACE}/${ESTABLISHMENT.TAREAS}`}
        component={withSubLayout(Tareas)}
      />
      <Redirect
        to={`/${ESTABLISHMENT.NAME_SPACE}/${ESTABLISHMENT.PLANIFICACION}`}
      />
    </Switch>
  );
};

export default Routes;
