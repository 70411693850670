import { NUTRICIONAL, PRODUCTIVA, SANITARIA, UNITS } from '.';

const NAME_SPACE = 'economica';

const INGRESOS = 'ingresos';
const EGRESOS = 'egresos';

// EVENTS
const INGRESOS_VENTA_UNIDAD = 'ventaunidad';
const INGRESOS_OTROS = 'ingreso';
const EGRESO_COMPRA_UNIDAD = 'compraunidad';
const EGRESO_OTROS = 'egreso';
const EGRESO_COMPRA_INSUMOS = 'comprainsumo';

const EVENT_TYPES = [
  INGRESOS_VENTA_UNIDAD,
  INGRESOS_OTROS,
  EGRESO_COMPRA_UNIDAD,
  EGRESO_OTROS,
];

const EVENT_TYPES_KPIS = {
  [INGRESOS_VENTA_UNIDAD]: 'venta-unidades',
  [INGRESOS_OTROS]: 'otros-ingresos',
  [EGRESO_COMPRA_UNIDAD]: 'compra-unidades',
  [EGRESO_OTROS]: 'otros-egresos',
};

const EVENT_FORMATTED = {
  [INGRESOS]: 'Ingresos',
  [EGRESOS]: 'Egresos',
  [INGRESOS_VENTA_UNIDAD]: 'Venta de unidades',
  [INGRESOS_OTROS]: 'Otros ingresos',
  [EGRESO_COMPRA_UNIDAD]: 'Compra de unidades',
  [EGRESO_COMPRA_INSUMOS]: 'Compra de insumos',
  [EGRESO_OTROS]: 'Otros egresos',
};

const EVENT_DASHBOARD_INGRESOS = {
  [INGRESOS_VENTA_UNIDAD]: {
    title: EVENT_FORMATTED[INGRESOS_VENTA_UNIDAD],
    url: INGRESOS_VENTA_UNIDAD,
  },
  [INGRESOS_OTROS]: {
    title: EVENT_FORMATTED[INGRESOS_OTROS],
    url: INGRESOS_OTROS,
  },
};

const EVENT_DASHBOARD_EGRESOS = {
  [EGRESO_COMPRA_UNIDAD]: {
    title: EVENT_FORMATTED[EGRESO_COMPRA_UNIDAD],
    url: EGRESO_COMPRA_UNIDAD,
  },
  [EGRESO_COMPRA_INSUMOS]: {
    title: EVENT_FORMATTED[EGRESO_COMPRA_INSUMOS],
    url: EGRESO_COMPRA_INSUMOS,
  },
  [EGRESO_OTROS]: {
    title: EVENT_FORMATTED[EGRESO_OTROS],
    url: EGRESO_OTROS,
  },
};

const EVENT_DASHBOARD = {
  [INGRESOS]: {
    title: EVENT_FORMATTED[INGRESOS],
    items: EVENT_DASHBOARD_INGRESOS,
  },
  [EGRESOS]: {
    title: EVENT_FORMATTED[EGRESOS],
    items: EVENT_DASHBOARD_EGRESOS,
  },
};

// Para los filtros del historial
const EVENT_UNIT_TYPES = {
  [INGRESOS_VENTA_UNIDAD]: [UNITS.HEMBRAS, UNITS.MACHOS],
  [EGRESO_COMPRA_UNIDAD]: [UNITS.HEMBRAS, UNITS.MACHOS],
};

// Event type compra insumos
const SEPARATOR = '/';

const getInsumoFromEventType = (eventType) => {
  return eventType.split(SEPARATOR)[1];
};

const SEMEN = EGRESO_COMPRA_INSUMOS + SEPARATOR + PRODUCTIVA.SEMEN;
const EMBRION = EGRESO_COMPRA_INSUMOS + SEPARATOR + PRODUCTIVA.EMBRION;
const VACUNA = EGRESO_COMPRA_INSUMOS + SEPARATOR + SANITARIA.VACUNA;
const PRODUCTO = EGRESO_COMPRA_INSUMOS + SEPARATOR + SANITARIA.PRODUCTO;
const SUPLEMENTO = EGRESO_COMPRA_INSUMOS + SEPARATOR + NUTRICIONAL.SUPLEMENTO;

const EGRESO_COMPRA_INSUMOS_EVENT_TYPES = {
  SEMEN,
  EMBRION,
  VACUNA,
  PRODUCTO,
  SUPLEMENTO,
};

const AUX_NOEVENT_FORMATTED = {
  ...PRODUCTIVA.NOEVENT_FORMATTED,
  ...SANITARIA.NOEVENT_FORMATTED,
  ...NUTRICIONAL.NOEVENT_FORMATTED,
};
const EGRESO_COMPRA_INSUMOS_EVENT_FORMATTED = Object.values(
  EGRESO_COMPRA_INSUMOS_EVENT_TYPES
).reduce(
  (accum, el) => ({
    ...accum,
    [el]: `${EVENT_FORMATTED[EGRESO_COMPRA_INSUMOS]} (${
      AUX_NOEVENT_FORMATTED[getInsumoFromEventType(el)]
    })`,
  }),
  {}
);

const EVENT_EGRESO_COMPRA_INSUMOS = {
  ...EGRESO_COMPRA_INSUMOS_EVENT_TYPES,
  EVENT_TYPES: Object.values(EGRESO_COMPRA_INSUMOS_EVENT_TYPES),
  EVENT_FORMATTED: EGRESO_COMPRA_INSUMOS_EVENT_FORMATTED,
  EVENT_DASHBOARD: {
    [PRODUCTIVA.NAME_SPACE]: {
      title: 'Insumos productivos',
      children: PRODUCTIVA.NOEVENTS_DASHBOARD_INSUMOS,
    },
    [SANITARIA.NAME_SPACE]: {
      title: 'Insumos sanitarios',
      children: SANITARIA.NOEVENTS_DASHBOARD_INSUMOS,
    },
    [NUTRICIONAL.NAME_SPACE]: {
      title: 'Insumos nutricionales',
      children: NUTRICIONAL.NOEVENTS_DASHBOARD_INSUMOS,
    },
  },
};

const getModuloByInsumo = (insumo) => {
  if (PRODUCTIVA.NOEVENT_TYPES_LIST.includes(insumo)) {
    return PRODUCTIVA.GENERIC_EVENT;
  }
  if (SANITARIA.NOEVENT_TYPES_LIST.includes(insumo)) {
    return SANITARIA.GENERIC_EVENT;
  }
  if (NUTRICIONAL.NOEVENT_TYPES_LIST.includes(insumo)) {
    return NUTRICIONAL.GENERIC_EVENT;
  }
};

const ECONOMICA = {
  NAME_SPACE,
  INGRESOS,
  INGRESOS_VENTA_UNIDAD,
  INGRESOS_OTROS,
  EGRESOS,
  EGRESO_COMPRA_UNIDAD,
  EGRESO_COMPRA_INSUMOS,
  EGRESO_OTROS,
  EVENT_TYPES,
  EVENT_TYPES_KPIS,
  EVENT_FORMATTED,
  EVENT_DASHBOARD,
  EVENT_UNIT_TYPES,
  EVENT_EGRESO_COMPRA_INSUMOS,
  getInsumoFromEventType,
  getModuloByInsumo,
};

export default ECONOMICA;
