import { useHistory, useRouteMatch } from 'react-router-dom';
import { EllipsisOutlined, DisconnectOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { GENERAL } from 'modules/core/constants';
import { DropdownMenu, ScreenHeader } from 'modules/core/components';

const EstablishmentHeader = ({
  screenTitle,
  addTitle,
  onAdd,
  hideExtraActions = false,
}) => {
  const history = useHistory();
  const match = useRouteMatch();

  return (
    <>
      <ScreenHeader
        title={screenTitle}
        rightActions={
          <>
            <Button type="primary" onClick={onAdd}>
              {addTitle}
            </Button>
            {!hideExtraActions && (
              <DropdownMenu
                menu={[
                  {
                    title: 'Ver desactivados',
                    icon: <DisconnectOutlined />,
                    onClick: () =>
                      history.push(`${match.url}/${GENERAL.DEACTIVE}`),
                  },
                ]}
              >
                <Button icon={<EllipsisOutlined />} />
              </DropdownMenu>
            )}
          </>
        }
      />
    </>
  );
};

export default EstablishmentHeader;
