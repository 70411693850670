import React from 'react';
import { Table } from 'modules/core/components';
import { UNITS, GENERAL } from 'modules/core/constants';

const columns = (renderActions = null, unitType) => {
  const columns = [];
  columns.push(
    {
      title: 'RP',
      dataIndex: 'rp',
      sorter: true,
    },
    {
      title: 'ID Electrónico',
      dataIndex: 'id_electronico',
      sorter: true,
    },
    {
      title: 'Nacimiento',
      dataIndex: 'fecha_nacimiento',
      sorter: true,
      render: (d) => GENERAL.dateToFront(d),
    },
    {
      title: 'Categoría',
      dataIndex: 'categoria',
      render: (c) => UNITS.CATEGORIES_FORMATTED[c],
    },
    { title: 'Apodo', dataIndex: 'apodo', sorter: true },
    {
      title: 'Estado',
      dataIndex: 'estado',
      render: (s) => UNITS.ESTADOS_FORMATTED[s],
    },
    {
      title: 'RP Madre',
      dataIndex: 'rp_madre',
      key: 'madre__rp',
      sorter: true,
    },
    {
      title: 'RP Padre',
      dataIndex: 'rp_padre',
      key: 'padre__rp',
      sorter: true,
      render: (rp, unit) => `${rp ?? ''} ${unit.apodo_padre ?? ''}`,
    }
  );

  if (renderActions) {
    columns.push({
      title: 'Acciones',
      align: 'right ',
      render: (_, unit) => renderActions?.(unit),
    });
  }
  return columns;
};

const UnitsList = ({ data = [], actions, unitType, ...props }) => {
  return (
    <Table
      rowClassName="units-list-row"
      rowKey="id"
      dataSource={data}
      columns={columns(actions, unitType)}
      {...props}
    />
  );
};

export default UnitsList;
