const NAME_SPACE = 'roles';

const ADMIN_USER = 0; // This user doesn't exist in backend, is roleId=1 and is_superuser=true
const FINCA_USER = 1;

const ROLES = { ADMIN_USER, FINCA_USER };

const ROLES_LIST = Object.values(ROLES);

const setRole = ({ roleId, isSuperuser }) => {
  if (isSuperuser) {
    return ADMIN_USER;
  } else {
    if (roleId === FINCA_USER) {
      return FINCA_USER;
    } else {
      return null;
    }
  }
};

const USERS = {
  NAME_SPACE,
  ADMIN_USER,
  FINCA_USER,
  ROLES,
  ROLES_LIST,
  setRole,
};

export default USERS;
