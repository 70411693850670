import React from 'react';
import { GENERAL, MASTERS } from 'modules/core/constants';
import { EstablishmentMasters } from 'modules/establishment/establishmentMasters/components';
import ResponsablesForm from './ResponsablesForm';

const Responsables = () => {
  const masterType = MASTERS.PRODUCTIVA_TYPES.RESPONSABLE;
  const masterName = {
    CAPITALIZE: 'Responsable',
    LOWER: 'responsable',
    PLURAL: 'Responsables',
  };

  return (
    <EstablishmentMasters
      masterType={masterType}
      masterName={masterName}
      renderForm={(props) => <ResponsablesForm {...props} />}
      hideHeaderExtraActions={true}
      allowSinAsignar={true}
      actions={[GENERAL.ACTIONS.MODIFICAR, GENERAL.ACTIONS.ELIMINAR]}
    />
  );
};

export default Responsables;
