import { Button } from 'antd';
import React from 'react';

import './ShowMoreButton.scss';

const ShowMoreButton = ({ onClick = () => {} }) => {
  return (
    <Button className="home-show-more-button" type="text" onClick={onClick}>
      Ver más
    </Button>
  );
};

export default ShowMoreButton;
