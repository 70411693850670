import React, { useState } from 'react';
import _ from 'lodash';
import { Modal, Checkbox, Spin } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { withAPI } from 'modules/api';
import { ECONOMICA } from 'modules/core/constants';
import ModalTitle from '../ModalTitle/ModalTitle';

import './ExportFieldsModal.scss';

const ExportFieldsModal = ({ fields, visible, onSuccess, close, loading }) => {
  const [selected, setSelected] = useState({});
  const [allInderterminated, setAllIndeterminated] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const fieldsGroups = _.reduce(
    fields?.fields,
    (a, v, i) =>
      _.isObject(v)
        ? { ...a, [i]: _.map(v, (label, value) => ({ label, value })) }
        : {
            ...a,
            _nogroup: (a._nogroup ?? []).concat([{ label: v, value: i }]),
          },
    {}
  );
  const onSelect = (group, values) => {
    const newSelected = { ...selected, [group]: values };
    setSelected(newSelected);
    const allSelected = _.every(fieldsGroups, (fields, group) => {
      return fields.length === newSelected[group]?.length;
    });
    const oneSelected = _.some(newSelected, (fields) => fields.length > 0);
    setAllIndeterminated(!allSelected && oneSelected);
    setAllSelected(allSelected);
  };
  const onSelectAll = ({ target: { checked } }) => {
    const allSelection = _.reduce(
      fieldsGroups,
      (a, fields, group) => {
        return { ...a, [group]: checked ? _.map(fields, (f) => f.value) : [] };
      },
      {}
    );
    setSelected(allSelection);
    setAllIndeterminated(false);
    setAllSelected(checked);
  };

  const renderGroupTitle = (group) => {
    if (group === '_nogroup') return '';
    if (ECONOMICA.EVENT_FORMATTED[group])
      return ECONOMICA.EVENT_FORMATTED[group];

    return group;
  };

  const canExport = _.map(selected, (s) => s.length > 0).includes(true);

  return (
    <Modal
      title={
        <ModalTitle icon={<DownloadOutlined />} title="Exportar a Excel" />
      }
      width={'45%'}
      className="export-fields-modal"
      cancelText="CANCELAR"
      okText="EXPORTAR"
      open={visible}
      onCancel={close}
      onOk={() => onSuccess(selected)}
      confirmLoading={loading}
      destroyOnClose={true}
      okButtonProps={{ disabled: !(fields && canExport) }}
    >
      <Spin spinning={!fields}>
        <div className="export-fields-modal-body">
          <Checkbox
            onChange={onSelectAll}
            indeterminate={allInderterminated}
            checked={allSelected}
          >
            Seleccionar todos
          </Checkbox>
          {_.map(fieldsGroups, (fields, group) => (
            <div className="export-fields-group" key={group}>
              <span>{renderGroupTitle(group)}</span>
              <Checkbox.Group
                options={fields}
                value={selected[group]}
                onChange={(values) => onSelect(group, values)}
              />
            </div>
          ))}
        </div>
      </Spin>
    </Modal>
  );
};

export default withAPI(ExportFieldsModal);
