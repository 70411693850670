import React from 'react';
import { useMutation } from 'react-query';
import { withAPI } from 'modules/api';
import { DeleteModal, displayInfoModal } from 'modules/core/components';

const UnitModalDelete = ({ api, unit, visible, close, success }) => {
  const { mutate: deleteUnit, isLoading: isDeleting } = useMutation(
    api.deleteUnit
  );

  const handleDelete = ({ onSuccess, onError }) => {
    deleteUnit(
      { unitId: unit.id },
      {
        onSuccess,
        onError: ({ message: error }) => {
          if (error === '400')
            displayInfoModal({
              description: `La unidad ${unit.rp} no puede ser eliminada porque posee eventos o hijos relacionados.`,
              showCross: true,
              onOk: close,
            });
        },
      }
    );
  };

  return (
    <DeleteModal
      title="Eliminar unidad"
      isDeleting={isDeleting}
      visible={visible}
      onCancel={close}
      onConfirm={handleDelete}
      onSuccess={() => {
        success();
        close();
      }}
      onSuccessInfo={{
        description: `La unidad ${unit?.rp} ${
          unit?.nombre ?? ''
        } se eliminó de tu listado de unidades.`,
        showCheck: true,
      }}
    >
      <div>
        Estás eliminando a{' '}
        <b>
          {unit?.rp} {unit?.nombre ?? ''}
        </b>
        , de tu listado de unidades.
        <br />
        ¿Confirmás que esta unidad ya no será parte de las unidades de tu
        establecimiento?
      </div>
    </DeleteModal>
  );
};

export default withAPI(UnitModalDelete);
