import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { withAPI } from 'modules/api';
import { usePaginationWithFilters } from 'modules/core/hooks';
import {
  EyeOutlined,
  EditOutlined,
  HistoryOutlined,
  DeleteOutlined,
  FilePdfOutlined,
} from '@ant-design/icons';
import { Row, Col, message as antMessage, Button, Space } from 'antd';
import { GENERAL, UNITS } from 'modules/core/constants';
import {
  DropdownMenu,
  AlertWithAction,
  Container,
} from 'modules/core/components';
import FilterSider, { UnitFilter } from 'modules/core/components/Filter';
import {
  UnitDrawerAdd,
  UnitDrawerRecordHistory,
  UnitDrawerShow,
  UnitModalDelete,
  UnitReportPDFModal,
  UnitsCards,
  UnitsList,
  UnitsMenu,
} from 'modules/units/components';

const UNIT_ACTIONS_INITIAL_VALUES = { item: null, action: null };

const Stock = ({ api, stockType = UNITS.STOCK.ACTIVAS }) => {
  const [selectedItem, setSelectedItem] = useState(UNIT_ACTIONS_INITIAL_VALUES);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showDeleteMultipleUnit, setShowDeleteMultipleUnit] = useState(null);
  const { pagination, filters, sorter, params } = usePaginationWithFilters();

  // baja=True -> inactivas
  // baja=False -> activas
  const {
    data: unitsKpis,
    isLoading: isLoadingKpis,
    refetch: rKpis,
  } = useQuery(
    [
      'Units::fetchKPIUnits',
      { params: { ...filters.get(), baja: stockType !== UNITS.STOCK.ACTIVAS } },
    ],
    api.fetchKPIUnits
  );

  const {
    data: units,
    isLoading: isLoadingUnits,
    refetch: rUnits,
  } = useQuery(
    [
      'Units::fetchUnits',
      { params: { ...params, baja: stockType !== UNITS.STOCK.ACTIVAS } },
    ],
    api.fetchUnits
  );

  const refetch = () => {
    rKpis();
    rUnits();
  };

  const rowSelection = showDeleteMultipleUnit
    ? {
        selectedRowKeys,
        onChange: setSelectedRowKeys,
        preserveSelectedRowKeys: true,
      }
    : null;

  const { mutate: deleteMultipleUnits, isLoading: isDeletingMultiple } =
    useMutation(api.deleteMultipleUnits);

  const onCancelDeleteMultipleUnits = () => {
    setShowDeleteMultipleUnit(false);
    setSelectedRowKeys([]);
  };

  const onConfirmDeleteMultipleUnits = () => {
    deleteMultipleUnits(
      { unitsId: selectedRowKeys },
      {
        onSuccess: () => {
          antMessage.success('Unidades eliminadas con éxito.');
          onCancelDeleteMultipleUnits();
          refetch();
        },
        onError: () => {
          antMessage.error('Algunas unidades no pudieron ser eliminadas.');
        },
      }
    );
  };

  const resetSelectedItem = () => setSelectedItem(UNIT_ACTIONS_INITIAL_VALUES);

  const renderUnitMenu = () => {
    if (stockType === UNITS.STOCK.INACTIVAS) return null;
    return (
      <Space>
        <Button
          type="primary"
          onClick={() =>
            setSelectedItem({ item: null, action: GENERAL.ACTIONS.CREAR })
          }
        >
          AGREGAR UNIDAD
        </Button>
        <UnitsMenu
          exportParams={{
            baja: stockType !== UNITS.STOCK.ACTIVAS,
            ...filters.get(),
          }}
          onUpload={refetch}
          onDeleteMultiple={() => setShowDeleteMultipleUnit(true)}
        />
      </Space>
    );
  };

  const renderActions = (item) => {
    const actionsHandler = (action) => setSelectedItem({ item, action });

    return (
      <DropdownMenu
        menu={[
          {
            title: 'Ver detalle',
            icon: <EyeOutlined />,
            onClick: () => actionsHandler(GENERAL.ACTIONS.VER_UNIDAD),
          },
          {
            title: 'Exportar ficha en PDF',
            icon: <FilePdfOutlined />,
            onClick: () => actionsHandler(GENERAL.ACTIONS.EXPORTAR),
          },
          {
            title: 'Modificar',
            icon: <EditOutlined />,
            onClick: () => actionsHandler(GENERAL.ACTIONS.MODIFICAR),
          },
          {
            title: 'Ver historial',
            icon: <HistoryOutlined />,
            onClick: () => actionsHandler(GENERAL.ACTIONS.VER_HISTORIAL),
          },
          {
            title: 'Eliminar',
            icon: <DeleteOutlined />,
            onClick: () => actionsHandler(GENERAL.ACTIONS.ELIMINAR),
          },
        ]}
      />
    );
  };

  return (
    <Row gutter={[16, 16]}>
      <Col xs={6} xxl={4}>
        <FilterSider
          filters={[UnitFilter]}
          filtersProps={{ unitType: [UNITS.HEMBRAS, UNITS.MACHOS] }}
          onChange={filters.set}
        />
      </Col>
      <Col xs={18} xxl={20}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Container justifyContent="flex-end">{renderUnitMenu()}</Container>
          </Col>
          <Col span={24}>
            <UnitsCards data={unitsKpis} loading={isLoadingKpis} />
          </Col>
          {showDeleteMultipleUnit && (
            <Col span={24}>
              <AlertWithAction
                message="Seleccione las unidades que desea eliminar y presione Confirmar."
                loading={isDeletingMultiple}
                onCancel={onCancelDeleteMultipleUnits}
                onConfirm={onConfirmDeleteMultipleUnits}
                disabled={selectedRowKeys?.length <= 0}
              />
            </Col>
          )}
          <Col span={24}>
            <UnitsList
              data={units?.results}
              actions={renderActions}
              loading={isLoadingUnits}
              showTotal={true}
              pagination={{ ...pagination.get(), total: units?.count }}
              onChange={(p, f, s) => {
                pagination.set(p);
                sorter.set(s);
              }}
              rowSelection={rowSelection}
            />
          </Col>
        </Row>
      </Col>
      <UnitDrawerShow
        visible={selectedItem.action === GENERAL.ACTIONS.VER_UNIDAD}
        unit={selectedItem.item}
        edit={(unit) =>
          setSelectedItem(() => ({
            item: unit,
            action: GENERAL.ACTIONS.MODIFICAR,
          }))
        }
        close={resetSelectedItem}
      />
      <UnitDrawerAdd
        visible={[GENERAL.ACTIONS.CREAR, GENERAL.ACTIONS.MODIFICAR].includes(
          selectedItem.action
        )}
        formAction={selectedItem.action}
        initialValues={selectedItem.item}
        success={() => {
          resetSelectedItem();
          refetch();
        }}
        close={resetSelectedItem}
      />
      <UnitDrawerRecordHistory
        visible={selectedItem.action === GENERAL.ACTIONS.VER_HISTORIAL}
        unitId={selectedItem.item?.id}
        success={refetch}
        close={resetSelectedItem}
      />
      <UnitReportPDFModal
        unit={selectedItem.item}
        visible={selectedItem.action === GENERAL.ACTIONS.EXPORTAR}
        close={resetSelectedItem}
      />
      <UnitModalDelete
        unit={selectedItem.item}
        visible={selectedItem.action === GENERAL.ACTIONS.ELIMINAR}
        success={() => {
          resetSelectedItem();
          refetch();
        }}
        close={resetSelectedItem}
      />
    </Row>
  );
};

export default withAPI(Stock);
