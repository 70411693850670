import React, { useCallback, useEffect } from 'react';
import { useMutation } from 'react-query';
import _ from 'lodash';
import { withAPI } from 'modules/api';
import {
  Modal,
  Form,
  message as antMessage,
  Row,
  Col,
  Select,
  DatePicker,
  Input,
} from 'antd';
import { FileAddOutlined, EditOutlined } from '@ant-design/icons';
import { ModalTitle } from 'modules/core/components';
import { GENERAL, MASTERS } from 'modules/core/constants';
import { useQuery } from 'react-query';
import moment from 'moment';

const TareaModal = ({
  api,
  visible,
  onSuccess,
  onCancel,
  initialValues = {},
}) => {
  const [form] = Form.useForm();
  const { mutate: add, isLoading: isAdding } = useMutation(api.addTarea);
  const { mutate: edit, isLoading: isEditing } = useMutation(api.editTarea);

  const hasInitialValues = !_.isEmpty(initialValues);

  const { data: responsables, isLoading: isLoadingOptions } = useQuery(
    [
      'Servicio::ResponsablesMaster',
      { masterType: MASTERS.PRODUCTIVA_TYPES.RESPONSABLE },
    ],
    api.fetchMasters
  );
  const responsablesOptions = MASTERS.generateOptions(responsables?.results);

  const clearForm = useCallback(() => {
    form.resetFields();
  }, [form]);
  useEffect(clearForm, [clearForm, visible]);

  const handleOnCancel = () => {
    clearForm();
    onCancel();
  };

  const onConfirm = () => {
    form
      .validateFields()
      .then((values) => {
        const task = {
          ...values,
          responsable: values.responsable || null,
          fecha_creacion: GENERAL.dateToBack(values.fecha_creacion),
          fecha_limite: GENERAL.dateToBack(values.fecha_limite),
        };
        const afterCreatingOrEditing = () => {
          onSuccess();
          handleOnCancel();
        };
        const onError = ({ message, description }) => {
          if (message === '400') antMessage.error(description);
        };

        if (hasInitialValues) {
          afterCreatingOrEditing();
          edit(
            { task, taskId: initialValues.id },
            { onSuccess: afterCreatingOrEditing, onError }
          );
        } else {
          afterCreatingOrEditing();
          add(task, {
            onSuccess: () => afterCreatingOrEditing({ isAdding: true }),
            onError,
          });
        }
      })
      .catch(() => {});
  };

  return (
    <Modal
      title={
        hasInitialValues ? (
          <ModalTitle icon={<EditOutlined />} title="Editar tarea" />
        ) : (
          <ModalTitle icon={<FileAddOutlined />} title="Agregar tarea" />
        )
      }
      forceRender
      cancelText="CANCELAR"
      okText={hasInitialValues ? 'EDITAR' : 'AGREGAR'}
      confirmLoading={isAdding || isEditing}
      open={visible}
      onCancel={handleOnCancel}
      onOk={onConfirm}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={{
          fecha_creacion: moment(),
          ...(hasInitialValues && {
            ...initialValues,
            fecha_creacion: moment(initialValues?.fecha_creacion),
            fecha_limite: initialValues?.fecha_limite
              ? moment(initialValues?.fecha_limite)
              : null,
          }),
        }}
      >
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item label="Fecha creación" name="fecha_creacion">
              <DatePicker
                style={{ width: '100%' }}
                format={GENERAL.DATE_FORMAT.FRONT}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Fecha límite" name="fecha_limite">
              <DatePicker
                style={{ width: '100%' }}
                format={GENERAL.DATE_FORMAT.FRONT}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Responsable" name="responsable">
              <Select
                showSearch
                allowClear
                style={{ width: '100%' }}
                optionFilterProp="label"
                placeholder="Seleccionar Responsable"
                loading={isLoadingOptions}
                options={responsablesOptions}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Prioridad"
              name="prioridad"
              rules={[{ required: true, message: 'Ingrese priodidad' }]}
            >
              <Select
                style={{ width: '100%' }}
                optionFilterProp="label"
                placeholder="Seleccionar prioridad"
                options={MASTERS.PRIORIDADES_OPTIONS}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Descripción"
              name="descripcion"
              rules={[{ required: true, message: 'Ingrese descripción' }]}
            >
              <Input.TextArea rows={2} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default withAPI(TareaModal);
