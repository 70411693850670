import React from 'react';
import { withAPI } from 'modules/api';
import { useQuery } from 'react-query';
import { MANAGEMENT, PRODUCTIVA, UNITS } from 'modules/core/constants';
import { ManagementDashboard } from 'modules/management/components';
import _ from 'lodash';

const BASE_URL = `/${MANAGEMENT.NAME_SPACE}/${MANAGEMENT.PRODUCTIVA}`;

const ProductivaDashboard = ({ api }) => {
  const { data, isLoading } = useQuery(
    ['Units::fetchKPIEvents'],
    api.fetchKPIEvents
  );

  const eventDashboard =
    api.userSpecies === UNITS.OVINO
      ? PRODUCTIVA.OVINO_EVENT_DASHBOARD
      : PRODUCTIVA.BOVINO_EVENT_DASHBOARD;
  const events =
    data &&
    _.map(eventDashboard, (card, event) => ({
      ...card,
      quantity: data[event] ?? 0,
      url: `${BASE_URL}/${card.url}`,
    }));

  return (
    <ManagementDashboard
      title="Gestión productiva"
      loading={isLoading}
      events={events}
      noEvents={[
        {
          title: 'Insumos',
          data: _.map(PRODUCTIVA.NOEVENTS_DASHBOARD_INSUMOS, (el) => ({
            ...el,
            url: `${BASE_URL}/${el.url}`,
          })),
        },
        {
          title: 'Configuración',
          data: _.map(PRODUCTIVA.NOEVENTS_DASHBOARD_CONFIG, (el) => ({
            ...el,
            url: `${BASE_URL}/${el.url}`,
          })),
        },
      ]}
    />
  );
};

export default withAPI(ProductivaDashboard);
