const menuOptions = [
  {
    key: 'home',
    title: 'ADMINISTRACIÓN',
    url: `/admin`,
    exact: true,
  },
];

export default menuOptions;
