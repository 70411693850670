import React, { useState } from 'react';
import { Row, Col, Tabs } from 'antd';
import { UNITS } from 'modules/core/constants';
import { ScreenHeader } from 'modules/core/components';
import Stock from './Stock';

const StockHome = ({ api }) => {
  const [activeTab, setActiveTab] = useState(UNITS.STOCK.ACTIVAS);

  const items = [
    {
      key: UNITS.STOCK.ACTIVAS,
      label: 'Activas',
      children: activeTab === UNITS.STOCK.ACTIVAS && (
        <Stock stockType={UNITS.STOCK.ACTIVAS} />
      ),
    },
    {
      key: UNITS.STOCK.INACTIVAS,
      label: 'Inactivas',
      children: activeTab === UNITS.STOCK.INACTIVAS && (
        <Stock stockType={UNITS.STOCK.INACTIVAS} />
      ),
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <ScreenHeader title="Stock" />
      </Col>
      <Col span={24}>
        <Tabs items={items} onChange={setActiveTab} />
      </Col>
    </Row>
  );
};

export default StockHome;
