import React from 'react';
import { Form, Button, Input } from 'antd';

import './LoginForm.scss';

const LoginForm = ({ onSubmit, loading }) => {
  return (
    <Form
      className="login-form"
      layout="vertical"
      requiredMark={false}
      onFinish={onSubmit}
    >
      <Form.Item
        name="username"
        label="E-Mail"
        rules={[{ required: true, message: 'Ingrese su email' }]}
      >
        <Input className="login-form-input" size="large" />
      </Form.Item>
      <Form.Item
        name="password"
        label="Contraseña"
        rules={[{ required: true, message: 'Ingrese su contraseña' }]}
      >
        <Input className="login-form-input" size="large" type="password" />
      </Form.Item>
      <Form.Item className="login-form-button-container">
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          loading={loading}
        >
          INICIAR SESIÓN
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginForm;
