const UnidadesAPI = (API) => {
  API.fetchUnit = function ({ queryKey }) {
    const [, { unitId, unitType, params }] = queryKey;
    const type = unitType || 'unidad';
    const qp = API.makeQueryString(params);
    return fetch(`/api/core/unidades/${type}/${unitId}/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchUnits = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(`/api/core/unidades/unidad/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.createUnit = function ({ unitType, unit }) {
    return fetch(`/api/core/unidades/${unitType}/`, {
      method: 'POST',
      headers: API.getAuthHeaders(),
      body: JSON.stringify(unit),
    }).then(API.handleResponse);
  };

  API.editUnit = function ({ unit, unitId }) {
    return fetch(`/api/core/unidades/unidad/${unitId}/`, {
      method: 'PATCH',
      headers: API.getAuthHeaders(),
      body: JSON.stringify(unit),
    }).then(API.handleResponse);
  };

  API.deleteUnit = function ({ unitId }) {
    return fetch(`/api/core/unidades/unidad/${unitId}/`, {
      method: 'DELETE',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.deleteMultipleUnits = function ({ unitsId }) {
    return fetch(`/api/core/unidades/unidad/delete-multiple/`, {
      method: 'POST',
      headers: API.getAuthHeaders(),
      body: JSON.stringify({ unidades_ids: unitsId }),
    }).then(API.handleResponse);
  };

  API.addObs = function ({ unitId, obs }) {
    const date = new Date();
    return fetch(`/api/core/unidades/comentarios/`, {
      method: 'POST',
      headers: API.getAuthHeaders(),
      body: JSON.stringify({ unidad: unitId, fecha: date, comentario: obs }),
    }).then(API.handleResponse);
  };

  API.deleteObs = function ({ obsId }) {
    return fetch(`/api/core/unidades/comentarios/${obsId}/`, {
      method: 'DELETE',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchUnitFamilyTree = function ({ queryKey }) {
    const [, { unitId }] = queryKey;
    return fetch(`/api/core/unidades/unidad/${unitId}/genealogia/`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.downloadUnitsTemplate = function ({ params }) {
    const qp = API.makeQueryString(params);
    return fetch(`/api/core/unidades/unidad/template/?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleBlob);
  };

  API.uploadUnits = function ({ inputFile }) {
    return fetch(`/api/core/unidades/unidad/excel-upload/`, {
      method: 'POST',
      headers: API.getAuthFormHeaders(),
      body: inputFile,
    }).then(API.handleBlobOnerror);
  };

  API.uploadUpdatedUnits = function ({ inputFile }) {
    return fetch(`/api/core/unidades/unidad/excel-upload-update/`, {
      method: 'POST',
      headers: API.getAuthFormHeaders(),
      body: inputFile,
    }).then(API.handleBlobOnerror);
  };

  API.exportUnits = function ({ fields, params = null }) {
    const qp = API.makeQueryString(params);
    return fetch(`/api/core/unidades/unidad/excel-download/?${qp}`, {
      method: 'POST',
      headers: API.getAuthHeaders(),
      body: JSON.stringify({ fields }),
    }).then(API.handleBlob);
  };

  API.fieldsExportUnits = function ({ queryKey }) {
    const [, { unitType }] = queryKey;
    return fetch(`/api/core/unidades/${unitType}/export-fields/`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.fetchUnitEventsRecords = function ({ queryKey }) {
    const [, { unitId }] = queryKey;
    return fetch(`/api/core/unidades/unidad/${unitId}/events-history/`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.exportUnitEventsRecords = function ({ id }) {
    return fetch(`/api/core/unidades/unidad/${id}/events-history-download/`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleBlob);
  };

  API.downloadEventTemplate = function ({
    eventType,
    unitType,
    params = null,
  }) {
    const qp = API.makeQueryString({ evento: eventType, ...params });
    let url = '/api/core/unidades/';
    url += unitType?.concat('/') ?? '';
    url += 'template-evento/';
    return fetch(`${url}?${qp}`, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleBlob);
  };

  API.exportEventUnits = function ({
    eventType,
    unitType,
    fields,
    params = null,
  }) {
    const qp = API.makeQueryString({ evento: eventType, ...params });
    return fetch(`/api/core/unidades/${unitType}/excel-download/?${qp}`, {
      method: 'POST',
      headers: API.getAuthHeaders(),
      body: JSON.stringify({ fields }),
    }).then(API.handleBlob);
  };

  return API;
};

export default UnidadesAPI;
