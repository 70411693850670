import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { MANAGEMENT, SANITARIA } from 'modules/core/constants';
import SanitariaDashboard from './SanitariaDashboard/SanitariaDashboard';
import {
  ControlEpidemiologico,
  SanidadIndividual,
  TratamientoColectivo,
  Vacunacion,
  RecordHistory,
} from './events';
import {
  ObjetivosTratamiento,
  Productos,
  Sindromes,
  Vacunas,
} from './noevents';
import { Adquisiciones } from '../components';

const BASE = `/${MANAGEMENT.NAME_SPACE}/${MANAGEMENT.SANITARIA}`;
const eventRecordTypes = SANITARIA.EVENT_TYPES.join('|');
const noEventTypes = SANITARIA.NOEVENT_TYPES_LIST.join('|');

const Routes = () => {
  return (
    <Switch>
      <Route exact path={BASE} component={SanitariaDashboard} />
      <Route
        exact
        path={`${BASE}/${SANITARIA.VACUNACION}`}
        component={Vacunacion}
      />
      <Route
        exact
        path={`${BASE}/${SANITARIA.CONTROL_EPIDEMIOLOGICO}`}
        component={ControlEpidemiologico}
      />
      <Route
        exact
        path={`${BASE}/${SANITARIA.TRATAMIENTO_COLECTIVO}`}
        component={TratamientoColectivo}
      />
      <Route
        exact
        path={`${BASE}/${SANITARIA.SANIDAD_INDIVIDUAL}`}
        component={SanidadIndividual}
      />
      <Route
        exact
        path={`${BASE}/:eventType(${eventRecordTypes})/${MANAGEMENT.HISTORIAL_NAME_SPACE}`}
        component={RecordHistory}
      />
      <Route
        exact
        path={`${BASE}/${SANITARIA.SINDROME}`}
        component={Sindromes}
      />
      <Route
        exact
        path={`${BASE}/${SANITARIA.OBJTIVOS_TRATAMIENTO}`}
        component={ObjetivosTratamiento}
      />
      <Route exact path={`${BASE}/${SANITARIA.VACUNA}`} component={Vacunas} />
      <Route
        exact
        path={`${BASE}/${SANITARIA.PRODUCTO}`}
        component={Productos}
      />
      <Route
        exact
        path={`${BASE}/:noEventType(${noEventTypes})/${MANAGEMENT.ADQUISICIONES_NAME_SPACE}`}
        component={Adquisiciones}
      />
      <Redirect to={BASE} />
    </Switch>
  );
};

export default Routes;
