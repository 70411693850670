import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import {
  Row,
  Col,
  Form,
  DatePicker,
  message as antMessage,
  Select,
} from 'antd';
import { withAPI } from 'modules/api';
import { usePaginationWithFilters } from 'modules/core/hooks';
import { displayInfoModal } from 'modules/core/components';
import FilterSider, { UnitFilter } from 'modules/core/components/Filter';
import {
  UnitDrawerRecordHistory,
  UnitDrawerShow,
} from 'modules/units/components';
import {
  MANAGEMENT,
  PRODUCTIVA,
  GENERAL,
  MASTERS,
  UNITS,
} from 'modules/core/constants';
import {
  EventsHeader,
  EventsTableActions,
} from 'modules/management/components';
import TransferenciaEmbrionariaTable from './TransferenciaEmbrionariaTable/TransferenciaEmbrionariaTable';

const { Item: FormItem } = Form;
const EVENT_ACTIONS_INITIAL_VALUES = { item: null, action: null };

const TransferenciaEmbrionaria = ({ api }) => {
  const eventType = PRODUCTIVA.TRANSFERENCIAEMBRIONARIA;
  let history = useHistory();
  let location = useLocation();
  const { pagination, filters, sorter, params } = usePaginationWithFilters();
  const [actionInProgress, setActionInProgress] = useState(false);
  const [selectedItem, setSelectedItem] = useState(
    EVENT_ACTIONS_INITIAL_VALUES
  );
  const [selectedRows, setSelectedRows] = useState({ keys: [], rows: [] });
  const [form] = Form.useForm();

  const { data: options } = useQuery(
    [
      'Masters::Servicio',
      {
        masterTypes: [
          MASTERS.PRODUCTIVA_TYPES.REGISTRO,
          MASTERS.PRODUCTIVA_TYPES.RESPONSABLE,
        ],
      },
    ],
    api.fetchMastersFormOptions
  );
  const registros = options?.[MASTERS.PRODUCTIVA_TYPES.REGISTRO];

  const responsablesOptions = options?.[MASTERS.PRODUCTIVA_TYPES.RESPONSABLE];

  const {
    data: unitsTransferencia,
    isLoading,
    refetch,
  } = useQuery(
    [
      'Servicio::fetchByEventFemales',
      { eventType, unitType: UNITS.HEMBRAS, params },
    ],
    api.fetchByEvent
  );

  const { data: embriones } = useQuery(
    [
      'TrasnferenciaEmbrionaria::fetchEmbriones',
      { inputType: PRODUCTIVA.EMBRION },
    ],
    api.fetchProductivaInsumoDisponible,
    { enabled: actionInProgress }
  );

  const { mutate: aplicarServicio, isLoading: registering } = useMutation(
    api.applyProductivaEvent
  );

  const emptySelection = _.isEmpty(selectedRows.keys);
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRows({ keys: selectedRowKeys, rows: selectedRows });
  };
  const rowSelection = {
    selectedRowKeys: selectedRows.keys,
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
  };

  const onAction = () => {
    setActionInProgress(true);
  };
  const rollbackAction = () => {
    setSelectedRows({ keys: [], rows: [] });
    form.resetFields();
    setActionInProgress(false);
  };
  const onActionApply = () => {
    form
      .validateFields()
      .then((values) => {
        const fecha = GENERAL.dateToBack(values.date);
        const unidades = _.map(values.selectedEmbriones, (embrion, id) => ({
          fecha: fecha,
          tipo: values.tipo,
          ...(values.responsible && { responsable: values.responsible }),
          unidad: id,
          embrion: embrion,
        }));
        const onSuccess = () =>
          displayInfoModal({
            description:
              'Se realizó la transferencia embrionaria con éxito.\n Continuá tu gestión en Finca.',
            showCheck: true,
            onOk: () => history.push(location.pathname),
          });
        const onError = ({ message, description }) => {
          if (message === '400') antMessage.error(description, 5);
        };
        aplicarServicio({ eventType, units: unidades }, { onSuccess, onError });
      })
      .catch(() => {});
  };

  const renderHeader = () => (
    <Col span={24}>
      <EventsHeader
        title="Transferencia embrionaria"
        description={
          !actionInProgress
            ? 'Unidades aptas como receptoras'
            : 'Para realizar la implantación del embrión en la hembra, completá con la fecha del evento y seleccioná el embrión'
        }
        continueProps={{ onClick: onAction, disabled: emptySelection }}
        registerProps={{ onClick: onActionApply, loading: registering }}
        eventMenuProps={{
          menuType: MANAGEMENT.PRODUCTIVA,
          unitType: UNITS.HEMBRAS,
          eventType: eventType,
          onUpload: refetch,
          exportParams: filters.get(),
        }}
        rollbackAction={rollbackAction}
        showRegister={actionInProgress}
      />
    </Col>
  );

  const renderTable = () => (
    <>
      <Col xs={6} xxl={4}>
        <FilterSider filters={[UnitFilter]} onChange={filters.set} />
      </Col>
      <Col xs={18} xxl={20}>
        <TransferenciaEmbrionariaTable
          data={unitsTransferencia?.results}
          registros={registros}
          rowSelection={rowSelection}
          loading={isLoading}
          actions={(item) => (
            <EventsTableActions item={item} onAction={setSelectedItem} />
          )}
          showTotal={true}
          pagination={{ ...pagination.get(), total: unitsTransferencia?.count }}
          onChange={(p, f, s) => {
            pagination.set(p);
            sorter.set(s);
          }}
        />
        <UnitDrawerShow
          visible={selectedItem?.action === GENERAL.ACTIONS.VER_UNIDAD}
          unit={selectedItem.item}
          close={() => setSelectedItem(EVENT_ACTIONS_INITIAL_VALUES)}
        />
        <UnitDrawerRecordHistory
          visible={selectedItem?.action === GENERAL.ACTIONS.VER_HISTORIAL}
          unitId={selectedItem.item?.id}
          success={refetch}
          close={() => setSelectedItem(EVENT_ACTIONS_INITIAL_VALUES)}
        />
      </Col>
    </>
  );

  const renderForm = () => (
    <Col span={24}>
      <Form form={form} layout="vertical" requiredMark={false}>
        <Row gutter={8}>
          <Col span={4}>
            <FormItem
              label="Fecha"
              name="date"
              rules={[{ required: true, message: 'Ingrese fecha' }]}
            >
              <DatePicker
                style={{ width: '100%' }}
                format={GENERAL.DATE_FORMAT.FRONT}
              />
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem
              label="Tipo de transferencia"
              name="tipo"
              rules={[{ required: true, message: 'Ingrese tipo' }]}
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Seleccionar"
                options={
                  PRODUCTIVA.OPTIONS[PRODUCTIVA.TRANSFERENCIAEMBRIONARIA]
                }
              />
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem
              label="Responsable"
              name="responsible"
              rules={[{ required: false, message: 'Seleccione responsable' }]}
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Selecione responsable..."
                options={responsablesOptions}
              />
            </FormItem>
          </Col>
          <Col span={24}>
            <TransferenciaEmbrionariaTable
              loading={isLoading}
              data={selectedRows.rows}
              embriones={embriones?.results}
              registros={registros}
              form={form}
              pagination={false}
            />
          </Col>
        </Row>
      </Form>
    </Col>
  );

  return (
    <Row gutter={[16, 16]}>
      {renderHeader()}
      {!actionInProgress && renderTable()}
      {actionInProgress && renderForm()}
    </Row>
  );
};

export default withAPI(TransferenciaEmbrionaria);
