import React from 'react';
import { withAPI } from 'modules/api';
import { SANITARIA } from 'modules/core/constants';
import { SanitariaInsumo } from '../components';
import ProductosForm from './ProductosForm';

const Productos = ({ api }) => {
  const inputType = SANITARIA.NOEVENT_TYPES.PRODUCTO;
  const masterName = {
    CAPITALIZE: 'Producto',
    LOWER: 'producto',
  };

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      sorter: true,
    },
    {
      title: 'Marca',
      dataIndex: 'marca',
      sorter: true,
    },
    {
      title: 'Stock',
      dataIndex: 'cantidad_actual',
      sorter: true,
    },
  ];

  return (
    <SanitariaInsumo
      inputType={inputType}
      masterName={masterName}
      columns={columns}
      showEdit={true}
      renderForm={() => <ProductosForm />}
    />
  );
};

export default withAPI(Productos);
