import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import _ from 'lodash';

const LineChart = ({
  data = [],
  xScale = {},
  yScale = {},
  colors = { scheme: 'nivo' },
  yFormat = '>-.2f',
  axisTop = null,
  axisRight = null,
  axisBottom = {},
  axisLeft = {},
  yMaxValue = null,
  yMinValue = null,
  ...restProps
}) => {
  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 20, bottom: 120, right: 100, left: 50 }}
      xScale={{ type: 'point', ...xScale }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        ...yScale,
      }}
      colors={colors}
      yFormat={yFormat}
      axisTop={axisTop}
      axisRight={axisRight}
      axisBottom={{
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -45,
        legendPosition: 'middle',
        legendOffset: 36,
        ...axisBottom,
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendPosition: 'middle',
        legendOffset: -45,
        format: yFormat,
        ...axisLeft,
      }}
      pointSize={10}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      useMesh={true}
      legends={[
        {
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: 'left-to-right',
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: 'circle',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      markers={_.compact([
        yMaxValue && {
          axis: 'y',
          value: yMaxValue,
          legend: `Max (${yMaxValue})`,
          legendPosition: 'bottom-right',
          lineStyle: {
            strokeDasharray: 5,
          },
        },
        yMinValue && {
          axis: 'y',
          value: yMinValue,
          legend: `Min (${yMinValue})`,
          legendPosition: 'bottom-right',
          lineStyle: {
            strokeDasharray: 5,
          },
        },
      ])}
      {...restProps}
    />
  );
};

export default LineChart;
