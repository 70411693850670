import React, { useState } from 'react';
import { Tabs } from 'antd';
import { PRODUCTIVA } from 'modules/core/constants';
import { ScreenHeader } from 'modules/core/components';
import DesteteAnalytics from './DesteteAnalytics/DesteteAnalytics';
import PreServicioAnalytics from './PreServicioAnalytics/PreServicioAnalytics';
import PreniezAnalytics from './PreniezAnalytics/PreniezAnalytics';
import PartoAnalytics from './PartoAnalytics/PartoAnalytics';
import PesajeAnalytics from './PesajeAnalytics/PesajeAnalytics';
import RendimientoAnalytics from './RendimientoAnalytics/RendimientoAnalytics';

export const ProductivaAnalytics = () => {
  const [activeTab, setActiveTab] = useState(PRODUCTIVA.DESTETE);

  const items = [
    {
      key: PRODUCTIVA.DESTETE,
      label: PRODUCTIVA.EVENT_FORMATTED[PRODUCTIVA.DESTETE],
      children: (
        <DesteteAnalytics isActive={activeTab === PRODUCTIVA.DESTETE} />
      ),
    },
    {
      key: PRODUCTIVA.SERVICIO,
      label: `Pre-Servicio / Servicio`,
      children: (
        <PreServicioAnalytics isActive={activeTab === PRODUCTIVA.SERVICIO} />
      ),
    },
    {
      key: PRODUCTIVA.PRENIEZ,
      label: PRODUCTIVA.EVENT_FORMATTED[PRODUCTIVA.PRENIEZ],
      children: (
        <PreniezAnalytics isActive={activeTab === PRODUCTIVA.PRENIEZ} />
      ),
    },
    {
      key: PRODUCTIVA.PARTO,
      label: PRODUCTIVA.EVENT_FORMATTED[PRODUCTIVA.PARTO],
      children: <PartoAnalytics isActive={activeTab === PRODUCTIVA.PARTO} />,
    },
    {
      key: PRODUCTIVA.PESAJE,
      label: PRODUCTIVA.EVENT_FORMATTED[PRODUCTIVA.PESAJE],
      children: <PesajeAnalytics isActive={activeTab === PRODUCTIVA.PESAJE} />,
    },
    {
      key: 'rendimiento',
      label: 'Rendimiento',
      children: <RendimientoAnalytics isActive={activeTab === 'rendimiento'} />,
    },
  ];

  return (
    <div>
      <ScreenHeader title="Analíticas productivas" />
      <Tabs items={items} onChange={setActiveTab} />
    </div>
  );
};

export default ProductivaAnalytics;
