import React from 'react';
import { View, StyleSheet, Text, Image } from '@react-pdf/renderer';
import { UNITS } from 'modules/core/constants';

const styles = StyleSheet.create({
  sectionTitle: {
    fontWeight: 500,
    marginBottom: 4,
  },
  sectionTitleBovino: {
    color: '#054e2d', // --primary-color-dark
  },
  sectionTitleOvino: {
    color: '#00478f', // --primary-color-dark
  },
  header: { flexDirection: 'row' },
  headerImg: {
    width: 80,
    height: 80,
  },
  headerInfo: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    // fontFamily: 'Raleway',
  },
  headerPills: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 4,
  },
  headerPill: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: 10,
    paddingVertical: 2,
    paddingHorizontal: 4,
    fontSize: 8,
    color: '#fcfffc',
    marginRight: 4,
  },
  headerPillBovino: {
    backgroundColor: '#126234', // --primary-color
  },
  headerPillOvino: {
    backgroundColor: '#0060b5', // --primary-color
  },
  unitInfo: {
    flexDirection: 'row',
    marginBottom: 8,
    paddingTop: 10,
    paddingHorizontal: 10,
    borderColor: '#f0f0f0',
    borderWidth: 1,
  },
  unitInfoColumn: {
    flexDirection: 'column',
    width: '33%',
    marginRight: 8,
  },
  unitInfoRow: {
    marginBottom: 8,
  },
  info: { flexDirection: 'row' },
  infoTitle: {
    fontWeight: 500,
    fontSize: 10,
  },
  infoTitleBovino: {
    color: '#054e2d', // --primary-color-dark
  },
  infoTitleOvino: {
    color: '#00478f', // --primary-color
  },
  infoValue: {
    // fontFamily: 'Roboto',
    color: '#575757',
    fontSize: 10,
  },
});

export const SectionTitle = ({ title, userSpecies }) => {
  return (
    <Text
      style={[
        styles.sectionTitle,
        userSpecies === UNITS.OVINO
          ? styles.sectionTitleOvino
          : styles.sectionTitleBovino,
      ]}
    >
      {title}
    </Text>
  );
};

const UnitInfoValue = ({ title, value, userSpecies }) => {
  return (
    <View style={styles.info}>
      <Text
        style={[
          styles.infoTitle,
          userSpecies === UNITS.OVINO
            ? styles.infoTitleOvino
            : styles.infoTitleBovino,
        ]}
      >
        {`${title}: `}
        <Text style={styles.infoValue}>{value}</Text>
      </Text>
    </View>
  );
};

export const UnitHeaderReportPDF = ({
  image,
  title,
  subtitle,
  pill1,
  pill2,
  userSpecies,
}) => {
  const headerPillStyle = [
    styles.headerPill,
    userSpecies === UNITS.OVINO
      ? styles.headerPillOvino
      : styles.headerPillBovino,
  ];
  return (
    <View style={styles.header} wrap={false}>
      <Image src={image} style={styles.headerImg} />
      <View style={styles.headerInfo}>
        <SectionTitle title={title} userSpecies={userSpecies} />
        <Text style={styles.infoValue}>{subtitle}</Text>
        <View style={styles.headerPills}>
          <View style={headerPillStyle}>
            <Text>{pill1}</Text>
          </View>
          <View style={headerPillStyle}>
            <Text>{pill2}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export const UnitInfoReportPDF = ({ data, userSpecies }) => {
  return (
    <>
      <SectionTitle title="Ficha técnica" userSpecies={userSpecies} />
      <View style={styles.unitInfo}>
        {data.map((col, cix) => (
          <View key={cix} style={styles.unitInfoColumn}>
            {col.map((row, rix) => (
              <View key={`${cix}${rix}`} style={styles.unitInfoRow}>
                {row.map((el, ix) => (
                  <UnitInfoValue
                    key={ix}
                    title={el?.title}
                    value={el?.value}
                    userSpecies={userSpecies}
                  />
                ))}
              </View>
            ))}
          </View>
        ))}
      </View>
    </>
  );
};

export const ExtraInfoReportPDF = ({ title = '', data = [], userSpecies }) => {
  return (
    <View wrap={false}>
      <SectionTitle title={title} userSpecies={userSpecies} />
      <View style={styles.unitInfo}>
        {data.map((row, rix) => (
          <View key={rix} style={styles.unitInfoColumn}>
            <View style={styles.unitInfoRow}>
              {row.map((el, ix) => (
                <UnitInfoValue
                  key={ix}
                  title={el?.label}
                  value={el?.value || '-'}
                  userSpecies={userSpecies}
                />
              ))}
            </View>
          </View>
        ))}
      </View>
    </View>
  );
};
