import React from 'react';
import { SubMenuLayout, LeftMenu } from 'modules/core/layouts';

export function withSubMenuLayout(leftMenuOptions = []) {
  return function withSubLayout(ContentComponent) {
    return (props) => (
      <SubMenuLayout leftMenu={() => <LeftMenu options={leftMenuOptions} />}>
        <ContentComponent {...props} />
      </SubMenuLayout>
    );
  };
}
