import _ from 'lodash';
import moment from 'moment';

// ++ TOKEN ++
const LOCAL_TOKEN = {
  KEY: 'Finca::Token',
  set(token) {
    return localStorage.setItem(this.KEY, token);
  },
  remove() {
    return localStorage.removeItem(this.KEY);
  },
  get() {
    return localStorage.getItem(this.KEY);
  },
};

// ++ DATE ++
const DATE_FORMAT = {
  FRONT: 'DD/MM/YYYY',
  FRONT_HOUR: 'DD/MM/YYYY HH:mm',
  BACK: 'YYYY-MM-DD',
};
const dateToFront = (d, wHour = false) =>
  d && moment(d).format(wHour ? DATE_FORMAT.FRONT_HOUR : DATE_FORMAT.FRONT);
const dateToBack = (d) => d && moment(d).format(DATE_FORMAT.BACK);

// ++ GENERATE OPTIONS ++
const optionToLabel = (opts, val) => _.find(opts, ['value', val])?.label;
const valueBoolToStr = (val) => (val ? 'True' : 'False');
const optionsBoolToStr = (opts) =>
  _.map(opts, (o) => {
    return _.isBoolean(o.value)
      ? { label: o.label, value: valueBoolToStr(o.value) }
      : o;
  });
const objsToOptions = (objs, { value = 'id', label = 'name' } = {}) => {
  return objs?.map((o) => ({ value: o[value], label: o[label] ?? o[value] }));
};
const arrayToOptions = (l, format = null) => {
  return l.map((i) => ({ label: format?.(i) ?? i, value: i }));
};
const toRPOptions = (units, { value = 'rp' } = {}) =>
  _.filter(units?.map((u) => u.rp && { value: u[value], label: u.rp }));
const toApodoOptions = (units, { value = 'apodo' } = {}) =>
  _.filter(units?.map((u) => u.apodo && { value: u[value], label: u.apodo }));
const toRPApodoOptions = (units, { value = 'id' } = {}) =>
  units?.map((u) => ({
    value: u[value],
    label: u.apodo ? `${u.rp} - ${u.apodo}` : u.rp,
  }));

// ++ NUMBERS ++
const numToFront = (n, d = 0, unit = '') => {
  if (!n && n !== 0) return '-';
  const number = Number(n);
  if (isNaN(number)) return n;
  return `${number.toFixed(d)}${unit}`;
};
const dec2deg = (lon, lat) => {
  const convert = (value, max) => {
    let sign = value < 0 ? -1 : 1;
    let abs = Math.abs(Math.round(value * 1000000));
    if (abs > max * 1000000) {
      return NaN;
    }
    let dec = (abs % 1000000) / 1000000;
    let deg = Math.floor(abs / 1000000) * sign;
    let min = Math.floor(dec * 60);
    let sec = (dec - min / 60) * 3600;
    return `${deg}° ${min}' ${Math.round(sec)}"`;
  };
  const londeg = convert(lon, 180);
  const latdeg = convert(lat, 90);
  return [londeg, latdeg];
};

const roundPercentage = (value, precision = 0, hideSymbol) => {
  if (_.isNumber(value))
    return `${_.round(value * 100, precision)}${hideSymbol ? '' : '%'}`;
  return '-';
};

// ++ ACTIONS ++
const VER_UNIDAD = 'show_units';
const VER_HISTORIAL = 'show_history';
const CREAR = 'crear';
const MODIFICAR = 'edit';
const ELIMINAR = 'eliminar';
const DESACTIVAR = 'deactivate';
const DECREMENTAR = 'decrementar';
const EXPORTAR = 'export';

const ACTIONS = {
  VER_UNIDAD,
  VER_HISTORIAL,
  CREAR,
  MODIFICAR,
  ELIMINAR,
  DESACTIVAR,
  DECREMENTAR,
  EXPORTAR,
};
const ACTIONS_LIST = Object.values(ACTIONS);

// ++ ANALYTICS ++
const formatPieChartData = (data) =>
  _(data)
    .pickBy(({ value }) => _.identity(value))
    .map((d) => ({
      ...d,
      id: d.label,
      sliceLabel: roundPercentage(d.percentage),
    }))
    .value();

const generateTickValues = (data = []) => {
  const dataLength = data.length;

  if (dataLength <= 0) return [];

  let firstValue = 0;
  let middleValue = Math.floor(dataLength / 2);
  let lastValue = dataLength - 1;

  return [data[firstValue].x, data[middleValue].x, data[lastValue].x];
};

// TODO: Need to split range filters this way because of how backend handle filters with from and to.
const FILTER_RANGE = 'range/';
const formatRangeParams = (params = {}) => {
  if (_.isEmpty(params)) return {};

  return Object.keys(params).reduce((acc, v) => {
    if (v.includes(FILTER_RANGE) && _.isArray(params[v])) {
      return {
        ...acc,
        [`from_${v.split('/')[1]}`]: params[v][0],
        [`to_${v.split('/')[1]}`]: params[v][1],
      };
    }
    return { ...acc, [v]: params[v] };
  }, {});
};

const generateSliderProps = (values = []) => ({
  range: true,
  min: values[0],
  max: values[values.length - 1],
  defaultValue: values,
  marks: values.reduce((acc, v) => ({ ...acc, [v]: v }), {}),
});

const GENERAL = {
  LOCAL_TOKEN,
  DATE_FORMAT,
  dateToBack,
  dateToFront,
  optionToLabel,
  valueBoolToStr,
  optionsBoolToStr,
  objsToOptions,
  arrayToOptions,
  toRPOptions,
  toApodoOptions,
  toRPApodoOptions,
  numToFront,
  dec2deg,
  roundPercentage,
  ACTIONS,
  ACTIONS_LIST,
  formatPieChartData,
  generateTickValues,
  FILTER_RANGE,
  formatRangeParams,
  generateSliderProps,
};

export default GENERAL;
