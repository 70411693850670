import { UNITS } from '.';
const NAME_SPACE = 'nutricional';

// EVENTS
const GENERIC_EVENT = 'gestionnutricional';
const DIETA = 'dieta';

const EVENT_TYPES = [DIETA];

const EVENT_FORMATTED = {
  [DIETA]: 'Dieta',
};

const EVENT_DASHBOARD = {
  [DIETA]: {
    title: EVENT_FORMATTED[DIETA],
    description: 'unidades disponibles',
    url: DIETA,
  },
};

const EVENT_UNIT_TYPES = {
  [DIETA]: [UNITS.HEMBRAS, UNITS.MACHOS],
};

// NOEVENTS
const SUPLEMENTO = 'suplemento';

const NOEVENT_TYPES = { SUPLEMENTO };
const NOEVENT_TYPES_LIST = Object.values(NOEVENT_TYPES);

const NOEVENT_FORMATTED = {
  [SUPLEMENTO]: 'Suplementos',
};

const NOEVENTS_DASHBOARD_INSUMOS = {
  [SUPLEMENTO]: {
    title: NOEVENT_FORMATTED[SUPLEMENTO],
    url: SUPLEMENTO,
  },
};

const NOEVENTS_DASHBOARD_CONFIG = {};

const NUTRICIONAL = {
  NAME_SPACE,
  GENERIC_EVENT,
  DIETA,
  SUPLEMENTO,
  EVENT_TYPES,
  EVENT_FORMATTED,
  EVENT_DASHBOARD,
  EVENT_UNIT_TYPES,
  NOEVENT_TYPES,
  NOEVENT_TYPES_LIST,
  NOEVENT_FORMATTED,
  NOEVENTS_DASHBOARD_INSUMOS,
  NOEVENTS_DASHBOARD_CONFIG,
};

export default NUTRICIONAL;
