import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { GENERAL } from 'modules/core/constants';
import { DropdownMenu } from 'modules/core/components';
import './EstablishmentLicenseCard.scss';

const EstablishmentLicenseCard = ({ data, onDelete }) => {
  return (
    <div className="est-license-card">
      <div className="est-license-action">
        <DropdownMenu
          menu={[
            {
              title: 'Eliminar',
              icon: <DeleteOutlined />,
              onClick: onDelete,
            },
          ]}
        />
      </div>
      <div className="est-license-type">
        <div className="est-license-title">Tipo de licencia</div>
        <div className="est-license-text">{data.tipo ?? '-'}</div>
      </div>
      <div className="est-license-date">
        <div className="est-license-title">Fecha de inicio</div>
        <div className="est-license-text">
          {data.start_date ? GENERAL.dateToFront(data.start_date) : '-'}
        </div>
      </div>
      <div className="est-license-date">
        <div className="est-license-title">Fecha de fin</div>
        <div className="est-license-text">
          {data.end_date ? GENERAL.dateToFront(data.end_date) : '-'}
        </div>
      </div>
    </div>
  );
};

export default EstablishmentLicenseCard;
