import React, { useState } from 'react';
import { Row, Col, Tabs } from 'antd';
import { ScreenHeader } from 'modules/core/components';
import EventsSync from './tabs/EventsSync';
import UnitsSync from './tabs/UnitsSync/UnitsSync';

const MOBILE_SYNC_TABS = {
  EVENTS: 'events_sync',
  UNITS: 'units_sync',
};
const MobileSync = () => {
  const [activeTab, setActiveTab] = useState(MOBILE_SYNC_TABS.EVENTS);
  const items = [
    {
      key: MOBILE_SYNC_TABS.EVENTS,
      label: 'Eventos',
      children: activeTab === MOBILE_SYNC_TABS.EVENTS && <EventsSync />,
    },
    {
      key: MOBILE_SYNC_TABS.UNITS,
      label: 'Carga de unidades',
      children: activeTab === MOBILE_SYNC_TABS.UNITS && <UnitsSync />,
    },
  ];

  return (
    <div style={{ padding: '3%' }}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <ScreenHeader
            title="Sincronización Finca App"
            description="Visualizá los archivos cargados desde el aplicativo móvil"
          />
        </Col>
        <Col span={24}>
          <Tabs items={items} onChange={setActiveTab} />
        </Col>
      </Row>
    </div>
  );
};

export default MobileSync;
