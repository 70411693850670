import React from 'react';
import _ from 'lodash';
import { useQuery } from 'react-query';
import { withAPI } from 'modules/api';
import { Card, DashboardList } from 'modules/core/components';

const UnitsBajasByMotivoCard = ({ api, height = 200 }) => {
  const { data, isLoading } = useQuery(
    ['Home::fetchBajasMotivo'],
    api.fetchBajasMotivo
  );

  const formatData = (dataToFormat) =>
    _.map(dataToFormat, (d, k) => ({ title: k.toLowerCase(), quantity: d }));

  return (
    <Card
      title="Bajas por motivo"
      description="Conocé las bajas por motivo de cada una de tus unidades."
      type="secondary"
      style={{ height }}
    >
      <DashboardList
        loading={isLoading}
        dataSource={data && formatData(data)}
        style={{
          height: height - 100,
          overflowY: 'auto',
          textTransform: 'capitalize',
        }}
      />
    </Card>
  );
};

export default withAPI(UnitsBajasByMotivoCard);
