import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { withAPI } from 'modules/api';
import { GENERAL } from 'modules/core/constants';
import { DisconnectOutlined } from '@ant-design/icons';
import { Row, Col, message as antMessage } from 'antd';
import { DropdownMenu, Table, ScreenHeader } from 'modules/core/components';
import _ from 'lodash';

const EstablishmentDeactivatedList = ({
  api,
  screenTitle,
  masterType,
  extraColumns = [],
}) => {
  const history = useHistory();

  const { data, isLoading, refetch } = useQuery(
    [
      'Establishment::fetchDeactivatedList',
      { masterType, params: { activo: false } },
    ],
    api.fetchMasters
  );

  const { mutate: activateUbicacion, isLoading: isActivating } = useMutation(
    api.editMaster
  );
  const handleActivate = (item) => {
    const handleOnSuccess = () => {
      refetch();
      antMessage.success('Activado con éxito.');
    };
    const handleOnError = ({ description } = {}) => {
      antMessage.error(description);
    };
    activateUbicacion(
      { masterType, id: item.id, body: { activo: true } },
      {
        onSuccess: handleOnSuccess,
        onError: handleOnError,
      }
    );
  };

  const columns = _.compact([
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      sorter: (a, b) => a.nombre.localeCompare(b.nombre),
    },
    ...extraColumns,
    {
      title: 'Descripción',
      dataIndex: 'descripcion',
      sorter: (a, b) => a.nombre.localeCompare(b.nombre),
    },
    {
      title: 'Acciones',
      align: 'right ',
      render: (_, el) => (
        <DropdownMenu
          title="Más acciones"
          menu={[
            {
              title: 'Activar',
              icon: <DisconnectOutlined />,
              disabled: isActivating,
              onClick: () => handleActivate(el),
            },
          ]}
        />
      ),
    },
  ]);

  const rollbackAction = () => {
    history.push(history.location.pathname.replace(`/${GENERAL.DEACTIVE}`, ''));
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <ScreenHeader title={screenTitle} onRollback={rollbackAction} />
      </Col>
      <Col span={24}>
        <Table
          rowKey="id"
          loading={isLoading}
          columns={columns}
          dataSource={data?.results}
        />
      </Col>
    </Row>
  );
};

export default withAPI(EstablishmentDeactivatedList);
