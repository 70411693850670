import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { BasicTooltip } from '@nivo/tooltip';

const BarChart = ({
  data = [],
  keys = [],
  indexBy = '',
  xScale = {},
  yScale = {},
  colors = ['#c7e9c0', '#a1d99b', '#74c476', '#41ab5d', '#006d2c'],
  valueFormat = '>-.0f',
  axisTop = null,
  axisRight = null,
  axisBottom = {},
  axisLeft = {},
  defaultTooltip = false,
  ...restProps
}) => {
  const customTooltip = ({ formattedValue, indexValue, color, label }) => {
    return (
      <BasicTooltip
        id={indexValue}
        value={formattedValue}
        color={color}
        enableChip
      />
    );
  };

  return (
    <ResponsiveBar
      data={data}
      keys={keys}
      indexBy={indexBy}
      margin={{ top: 20, bottom: 120, right: 100, left: 50 }}
      padding={0.3}
      xScale={{ type: 'point', ...xScale }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        ...yScale,
      }}
      colors={colors}
      valueFormat={valueFormat}
      labelSkipWidth={12}
      labelSkipHeight={12}
      axisTop={axisTop}
      axisRight={axisRight}
      axisBottom={{
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -45,
        legendPosition: 'middle',
        legendOffset: 36,
        ...axisBottom,
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendPosition: 'middle',
        legendOffset: -40,
        ...axisLeft,
      }}
      labelTextColor={{
        from: 'color',
        modifiers: [['darker', 1.6]],
      }}
      {...(!defaultTooltip && { tooltip: customTooltip })}
      legends={[
        {
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: 'left-to-right',
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: 'circle',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      {...restProps}
    />
  );
};

export default BarChart;
