import { APIPrototype } from './APIPrototype';

import {
  AdminAPI,
  AnalyticsAPI,
  DashboardAPI,
  GeneralAPI,
  GestionEconomicaAPI,
  GestionNutricionalAPI,
  GestionProductivaAPI,
  GestionSanitariaAPI,
  MastersAPI,
  ProfileAPI,
  UnidadesAPI,
} from './modulesAPI';

let FincaAPI = Object.create(APIPrototype);

FincaAPI = GeneralAPI(
  GestionProductivaAPI(
    GestionSanitariaAPI(
      GestionNutricionalAPI(
        GestionEconomicaAPI(
          AnalyticsAPI(
            DashboardAPI(
              UnidadesAPI(MastersAPI(ProfileAPI(AdminAPI(FincaAPI))))
            )
          )
        )
      )
    )
  )
);

export { FincaAPI };
