import React from 'react';
import _ from 'lodash';
import { Table } from 'modules/core/components';
import { GENERAL } from 'modules/core/constants';

const defaultColumns = (renderActions) => [
  {
    title: 'Nombre del establecimiento',
    dataIndex: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: 'Licencia',
    dataIndex: 'license_type',
  },
  {
    title: 'Vencimiento de licencia',
    dataIndex: 'license_end_date',
    render: (d) => GENERAL.dateToFront(d),
  },
  {
    title: 'Días por vencer',
    dataIndex: 'license_remaining_days',
    render: (d) => _.isFinite(d) && (d >= 0 ? `${d} días` : 'VENCIDA'),
  },
  {
    title: 'Acciones',
    align: 'right ',
    render: (_, est) => renderActions?.(est),
  },
];

const FincaClients = ({ data = null, actions, ...props }) => {
  return (
    <Table
      rowKey="id"
      dataSource={data && data.results}
      columns={defaultColumns(actions)}
      {...props}
    />
  );
};

export default FincaClients;
