import React, { useContext, useState } from 'react';

const APIContext = React.createContext({ api: {} });

const APIProvider = ({ children, value }) => {
  // const [api, setAPI] = useState(value.api);
  const [, setAuthToken] = useState(value.api.authToken);

  // TODO: Estaria bueno que se pueda generar la api nuevamente.
  const logUser = (token) => {
    value.api.withAuthUser(token);
    setAuthToken(token);
    // const newAPI = Object.assign(api, Object.create(api));
    // newAPI.withAuthUser(token);
    // setAPI(newAPI);
  };

  return (
    <APIContext.Provider value={{ api: value.api, logUser }}>
      {children}
    </APIContext.Provider>
  );
};

const withAPI = (ComponentToWrap) => (props) => {
  const context = useContext(APIContext);
  const { api, logUser } = context;
  return <ComponentToWrap api={api} logUser={logUser} {...props} />;
};

export { APIProvider, withAPI };
