import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { Row, Col, Form, DatePicker, message as antMessage } from 'antd';
import { withAPI } from 'modules/api';
import { usePaginationWithFilters } from 'modules/core/hooks';
import { displayInfoModal } from 'modules/core/components';
import FilterSider, { UnitFilter } from 'modules/core/components/Filter';
import {
  UnitDrawerRecordHistory,
  UnitDrawerShow,
} from 'modules/units/components';
import {
  PRODUCTIVA,
  MASTERS,
  GENERAL,
  UNITS,
  MANAGEMENT,
} from 'modules/core/constants';
import {
  EventsHeader,
  EventsTableActions,
} from 'modules/management/components';
import PartoTable from './PartoTable/PartoTable';
import PartoDrawer from './PartoDrawer/PartoDrawer';

const { Item: FormItem } = Form;
const EVENT_ACTIONS_INITIAL_VALUES = { item: null, action: null };

const Parto = ({ api }) => {
  const eventType = PRODUCTIVA.PARTO;
  const history = useHistory();
  let location = useLocation();
  const { pagination, filters, sorter, params } = usePaginationWithFilters();
  const [actionInProgress, setActionInProgress] = useState(false);
  const [selectedItem, setSelectedItem] = useState(
    EVENT_ACTIONS_INITIAL_VALUES
  );
  const [selectedRows, setSelectedRows] = useState({ keys: [], rows: [] });
  const [info, setInfo] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [form] = Form.useForm();

  const { data: options, isLoading: fetchingOptions } = useQuery(
    [
      'Masters::Registro-Parto',
      {
        masterTypes: [
          MASTERS.PRODUCTIVA_TYPES.PARTO,
          MASTERS.PRODUCTIVA_TYPES.REGISTRO,
        ],
      },
    ],
    api.fetchMastersFormOptions
  );

  const {
    data: unitsParto,
    isLoading,
    refetch,
  } = useQuery(
    ['Parto::fetchByEvent', { eventType, unitType: UNITS.HEMBRAS, params }],
    api.fetchByEvent
  );
  const { mutate: parir, isLoading: registering } = useMutation(
    api.applyProductivaEvent
  );

  const emptySelection = _.isEmpty(selectedRows.keys);
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRows({ keys: selectedRowKeys, rows: selectedRows });
  };
  const rowSelection = {
    selectedRowKeys: selectedRows.keys,
    preserveSelectedRowKeys: true,
    type: 'radio',
    onChange: onSelectChange,
  };

  const onAction = () => {
    setActionInProgress(true);
  };
  const rollbackAction = () => {
    setSelectedRows({ keys: [], rows: [] });
    form.resetFields();
    setActionInProgress(false);
  };
  const onActionApply = () => {
    form
      .validateFields()
      .then((values) => {
        const unidad = [
          {
            fecha: GENERAL.dateToBack(values.date),
            unidad: selectedRows.keys[0],
            tipo: values.birthType,
            cantidad_hijos: values.childs ?? 0,
            ...(values.condicion_corporal && {
              condicion_corporal: values.condicion_corporal,
            }),
          },
        ];

        const onSuccess = ([parto]) => {
          if (parto.cantidad_hijos >= 1)
            displayInfoModal({
              description:
                'Se realizó el parto de las unidades con éxito.\n Continuá registrando sus crías en Finca.',
              showCheck: true,
              okText: 'Registrar crías',
              onOk: () => {
                setInfo({
                  id: parto.id,
                  current: 1,
                  total: parto.cantidad_hijos,
                  data: {
                    origen: 1,
                    raza: parto.raza_id,
                    madre: parto.madre_embrion_id ?? parto.unidad_id,
                    padre: parto.padre,
                    fecha_nacimiento: parto.fecha,
                  },
                });
                setDrawerVisible(true);
              },
            });
          else
            displayInfoModal({
              description:
                'Se realizó el parto de las unidades con éxito.\n Continuá tu gestión en Finca.',
              showCheck: true,
              onOk: () => history.push(location.pathname),
            });
        };
        const onError = ({ message: error, description }) => {
          if (error === '400') antMessage.error(description, 5);
        };
        parir({ eventType, units: unidad }, { onSuccess, onError });
      })
      .catch(() => {});
  };

  const renderHeader = () => (
    <Col span={24}>
      <EventsHeader
        title="Parto"
        description={
          !actionInProgress
            ? 'Unidades que están disponibles para parir'
            : 'Para determinar el parto de las unidades, completá con la fecha, tipo de parto e indicá cantidad de crías'
        }
        continueProps={{ onClick: onAction, disabled: emptySelection }}
        registerProps={{ onClick: onActionApply, loading: registering }}
        eventMenuProps={{
          menuType: MANAGEMENT.PRODUCTIVA,
          unitType: UNITS.HEMBRAS,
          eventType: eventType,
          onUpload: refetch,
          exportParams: filters.get(),
        }}
        rollbackAction={rollbackAction}
        showRegister={actionInProgress}
      />
    </Col>
  );

  const renderTable = () => (
    <>
      <Col xs={6} xxl={4}>
        <FilterSider filters={[UnitFilter]} onChange={filters.set} />
      </Col>
      <Col xs={18} xxl={20}>
        <PartoTable
          data={unitsParto?.results}
          rowSelection={rowSelection}
          loading={isLoading || fetchingOptions}
          registros={options?.[MASTERS.PRODUCTIVA_TYPES.REGISTRO]}
          actions={(item) => (
            <EventsTableActions item={item} onAction={setSelectedItem} />
          )}
          showTotal={true}
          pagination={{ ...pagination.get(), total: unitsParto?.count }}
          onChange={(p, f, s) => {
            pagination.set(p);
            sorter.set(s);
          }}
        />
        <UnitDrawerShow
          visible={selectedItem?.action === GENERAL.ACTIONS.VER_UNIDAD}
          unit={selectedItem.item}
          close={() => setSelectedItem(EVENT_ACTIONS_INITIAL_VALUES)}
        />
        <UnitDrawerRecordHistory
          visible={selectedItem?.action === GENERAL.ACTIONS.VER_HISTORIAL}
          unitId={selectedItem.item?.id}
          success={refetch}
          close={() => setSelectedItem(EVENT_ACTIONS_INITIAL_VALUES)}
        />
      </Col>
    </>
  );

  const renderForm = () => (
    <Col span={24}>
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={{ birthType: 1, childs: 1 }}
      >
        <Row gutter={8}>
          <Col span={4}>
            <FormItem
              label="Fecha"
              name="date"
              rules={[{ required: true, message: 'Ingrese fecha' }]}
            >
              <DatePicker
                style={{ width: '100%' }}
                format={GENERAL.DATE_FORMAT.FRONT}
              />
            </FormItem>
          </Col>
          <Col span={24}>
            <PartoTable
              loading={isLoading}
              data={selectedRows.rows}
              tiposParto={options?.[MASTERS.PRODUCTIVA_TYPES.PARTO]}
              registros={options?.[MASTERS.PRODUCTIVA_TYPES.REGISTRO]}
              form={form}
              pagination={false}
            />
          </Col>
        </Row>
      </Form>
    </Col>
  );

  const renderDrawer = () => {
    const onSucces = () => {
      if (info.current === info.total) {
        setDrawerVisible(false);
        displayInfoModal({
          showCheck: true,
          description:
            'Se realizó el registro de las unidades con éxito.\n Continuá tu gestión en Finca.',
          onOk: () => {
            setInfo(null);
            history.push(
              `/${MANAGEMENT.NAME_SPACE}/${MANAGEMENT.PRODUCTIVA}/${eventType}`
            );
          },
        });
      } else setInfo((i) => ({ ...i, current: i.current + 1 }));
    };
    const onCancel = () => {
      displayInfoModal({
        title: 'Cancelar registro de crías nacidas',
        okText: 'Sí, cancelar',
        cancelText: 'Continuar registro',
        description:
          'Si cancelás, deberás retomar el registro de las crías más tarde desde el historial de Parto.\n ¿Querés cancelar el registro?',
        onCancel: () => {},
        onOk: () => {
          setDrawerVisible(false);
          setInfo(null);
          history.push(
            `/${MANAGEMENT.NAME_SPACE}/${MANAGEMENT.PRODUCTIVA}/${eventType}`
          );
        },
      });
    };
    return (
      <PartoDrawer
        initialValues={info}
        visible={drawerVisible}
        success={onSucces}
        cancel={onCancel}
      />
    );
  };

  return (
    <Row gutter={[16, 16]}>
      {renderHeader()}
      {!actionInProgress && renderTable()}
      {actionInProgress && renderForm()}
      {renderDrawer()}
    </Row>
  );
};

export default withAPI(Parto);
