import React from 'react';
import { Form, Input, Row, Col } from 'antd';

const RodeoForm = () => {
  return (
    <Row>
      <Col span={24}>
        <Form.Item
          label="Nombre del rodeo"
          name="nombre"
          rules={[{ required: true, message: 'Ingrese nombre de rodeo' }]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item label="Descripción del rodeo" name="descripcion">
          <Input.TextArea rows={2} />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default RodeoForm;
