import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import {
  Row,
  Col,
  Form,
  DatePicker,
  message as antMessage,
  Select,
} from 'antd';
import { withAPI } from 'modules/api';
import { usePaginationWithFilters } from 'modules/core/hooks';
import { displayInfoModal } from 'modules/core/components';
import FilterSider, { UnitFilter } from 'modules/core/components/Filter';
import {
  UnitDrawerRecordHistory,
  UnitDrawerShow,
} from 'modules/units/components';
import {
  MANAGEMENT,
  PRODUCTIVA,
  GENERAL,
  UNITS,
  MASTERS,
} from 'modules/core/constants';
import {
  EventsHeader,
  EventsTableActions,
} from 'modules/management/components';
import PreniezTable from './PreniezTable/PreniezTable';
import { InputNumber } from 'antd/lib';

const { Item: FormItem } = Form;
const EVENT_ACTIONS_INITIAL_VALUES = { item: null, action: null };

const Preniez = ({ api }) => {
  const eventType = PRODUCTIVA.PRENIEZ;
  let history = useHistory();
  let location = useLocation();
  const { pagination, filters, sorter, params } = usePaginationWithFilters();
  const [actionInProgress, setActionInProgress] = useState(false);
  const [selectedItem, setSelectedItem] = useState(
    EVENT_ACTIONS_INITIAL_VALUES
  );
  const [selectedRows, setSelectedRows] = useState({ keys: [], rows: [] });
  const [form] = Form.useForm();

  const { data: options, isLoading: fetchingOptions } = useQuery(
    ['Masters::Registro', { masterTypes: [MASTERS.PRODUCTIVA_TYPES.REGISTRO] }],
    api.fetchMastersFormOptions
  );
  const registros = options?.[MASTERS.PRODUCTIVA_TYPES.REGISTRO];

  const {
    data: unitsPreniez,
    isLoading,
    refetch,
  } = useQuery(
    ['Preniez::fetchByEvent', { eventType, unitType: UNITS.HEMBRAS, params }],
    api.fetchByEvent
  );
  const { mutate: preniar, isLoading: registering } = useMutation(
    api.applyProductivaEvent
  );

  const emptySelection = _.isEmpty(selectedRows.keys);
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRows({ keys: selectedRowKeys, rows: selectedRows });
  };
  const rowSelection = {
    selectedRowKeys: selectedRows.keys,
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
  };

  const onAction = () => {
    setActionInProgress(true);
  };
  const rollbackAction = () => {
    setSelectedRows({ keys: [], rows: [] });
    form.resetFields();
    setActionInProgress(false);
  };
  const onActionApply = () => {
    form
      .validateFields()
      .then((values) => {
        const fecha = GENERAL.dateToBack(values.date);
        const unidades = _.map(values.embrionTypes, (type, id) => ({
          fecha: fecha,
          unidad: id,
          ok: values.estado_preniez,
          ...(values.cant_dias_prenez && {
            cant_dias_prenez: values.cant_dias_prenez,
          }),
          ...(values.estado_preniez && { tipo: type }),
          ...(values.condicion_corporal[id] && {
            condicion_corporal: values.condicion_corporal[id],
          }),
          ...(values.cut[id] && {
            cut: values.cut[id],
          }),
        }));
        const onSuccess = () =>
          displayInfoModal({
            description:
              'Se determinó el estado de preñez con éxito.\n Continuá tu gestión en Finca.',
            showCheck: true,
            onOk: () => history.push(location.pathname),
          });
        const onError = ({ message: error, description }) => {
          if (error === '400') antMessage.error(description, 5);
        };
        preniar({ eventType, units: unidades }, { onSuccess, onError });
      })
      .catch(() => {});
  };

  const renderHeader = () => (
    <Col span={24}>
      <EventsHeader
        title="Preñez"
        description={
          !actionInProgress
            ? 'Unidades para realizar preñez'
            : 'Para determinar si la unidad quedó preñada luego del servicio, completá con la fecha del tacto y cargá el estado de la misma'
        }
        continueProps={{ onClick: onAction, disabled: emptySelection }}
        registerProps={{ onClick: onActionApply, loading: registering }}
        eventMenuProps={{
          menuType: MANAGEMENT.PRODUCTIVA,
          unitType: UNITS.HEMBRAS,
          eventType: eventType,
          onUpload: refetch,
          exportParams: filters.get(),
        }}
        rollbackAction={rollbackAction}
        showRegister={actionInProgress}
      />
    </Col>
  );

  const renderTable = () => {
    return (
      <>
        <Col xs={6} xxl={4}>
          <FilterSider filters={[UnitFilter]} onChange={filters.set} />
        </Col>
        <Col xs={18} xxl={20}>
          <PreniezTable
            data={unitsPreniez?.results}
            rowSelection={rowSelection}
            loading={isLoading || fetchingOptions}
            registros={registros}
            actions={(item) => (
              <EventsTableActions item={item} onAction={setSelectedItem} />
            )}
            showTotal={true}
            pagination={{ ...pagination.get(), total: unitsPreniez?.count }}
            onChange={(p, f, s) => {
              pagination.set(p);
              sorter.set(s);
            }}
          />
          <UnitDrawerShow
            visible={selectedItem?.action === GENERAL.ACTIONS.VER_UNIDAD}
            unit={selectedItem.item}
            close={() => setSelectedItem(EVENT_ACTIONS_INITIAL_VALUES)}
          />
          <UnitDrawerRecordHistory
            visible={selectedItem?.action === GENERAL.ACTIONS.VER_HISTORIAL}
            unitId={selectedItem.item?.id}
            success={refetch}
            close={() => setSelectedItem(EVENT_ACTIONS_INITIAL_VALUES)}
          />
        </Col>
      </>
    );
  };

  const renderForm = () => (
    <Col span={24}>
      <Form form={form} layout="vertical" requiredMark={false}>
        <Row gutter={8}>
          <Col span={4}>
            <FormItem
              label="Fecha"
              name="date"
              rules={[{ required: true, message: 'Ingrese fecha' }]}
            >
              <DatePicker
                style={{ width: '100%' }}
                format={GENERAL.DATE_FORMAT.FRONT}
              />
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem
              label="Estado"
              name="estado_preniez"
              rules={[{ required: true, message: 'Seleccione estado' }]}
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Seleccionar"
                options={PRODUCTIVA.OPTIONS[PRODUCTIVA.PRENIEZ].STATUS}
              />
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem
              label="Cantidad de días de preñez"
              name="cant_dias_prenez"
            >
              <InputNumber
                style={{ width: '100%' }}
                placeholder="Ingresar valor"
              />
            </FormItem>
          </Col>
          <Col span={24}>
            <PreniezTable
              loading={isLoading}
              data={selectedRows.rows}
              form={form}
              registros={registros}
              pagination={false}
            />
          </Col>
        </Row>
      </Form>
    </Col>
  );

  return (
    <Row gutter={[16, 16]}>
      {renderHeader()}
      {!actionInProgress && renderTable()}
      {actionInProgress && renderForm()}
    </Row>
  );
};

export default withAPI(Preniez);
