import React from 'react';
import { Select, Form } from 'antd';
import { Table } from 'modules/core/components';
import { GENERAL, UNITS } from 'modules/core/constants';

const { Item: FormItem } = Form;

const embrionesToOptions = (embriones) => {
  return embriones?.map((e) => ({
    value: e.id,
    label: `${e.hembra} ${e.macho && ' | ' + e.macho}`,
  }));
};

const defaultColumns = (registros, sorter = false) => [
  {
    title: 'RP',
    dataIndex: 'rp',
    sorter,
  },
  {
    title: 'ID Electrónico',
    dataIndex: 'id_electronico',
    sorter,
  },
  {
    title: 'RP Madre',
    dataIndex: 'rp_madre',
    key: 'madre__rp',
    sorter,
  },
  {
    title: 'Apodo Padre',
    dataIndex: 'apodo_padre',
    key: 'padre__apodo',
    sorter,
  },
  {
    title: 'Nacimiento',
    dataIndex: 'fecha_nacimiento',
    sorter,
    render: (d) => GENERAL.dateToFront(d),
  },
  {
    title: 'Categoría',
    dataIndex: 'categoria',
    render: (c) => UNITS.CATEGORIES_FORMATTED[c],
  },
  {
    title: 'Registro',
    dataIndex: 'registro',
    key: 'registro__nombre',
    sorter,
    render: (v) => GENERAL.optionToLabel(registros, v),
  },
];
const actionsColumn = (renderActions) => [
  {
    title: 'Acciones',
    align: 'right ',
    render: (_, record) => renderActions?.(record),
  },
];

const transferenciaEmbrionariaFormColumns = (embriones) => [
  {
    title: 'Embriones disponibles',
    dataIndex: 'embrion',
    render: (value, row) => (
      <FormItem
        initialValue={value}
        name={[`selectedEmbriones`, `${row.id}`]}
        style={{ marginBottom: 0 }}
        rules={[
          {
            required: true,
            message: 'Seleccione el embrión a implantar',
          },
        ]}
      >
        <Select
          style={{ width: '100%', minWidth: 200 }}
          placeholder="Seleccione embrión"
          loading={!embriones}
          options={embrionesToOptions(embriones)}
        />
      </FormItem>
    ),
  },
];

const TransferenciaEmbrionariaTable = ({
  data,
  embriones = null,
  registros = null,
  form = null,
  rowKey = 'id',
  loading = false,
  actions = null,
  ...props
}) => {
  let columns = defaultColumns(registros, true).concat(actionsColumn(actions));
  if (form)
    columns = defaultColumns(registros).concat(
      transferenciaEmbrionariaFormColumns(embriones)
    );
  return (
    <Table
      rowKey={rowKey}
      rowClassName="table-row"
      dataSource={data}
      columns={columns}
      loading={loading}
      {...props}
    />
  );
};

export default TransferenciaEmbrionariaTable;
