import { MASTERS } from 'modules/core/constants';

// FIXME: Deberian estar dividas en ProductivaMaster y UnitMasters
const MastersAPI = (API) => {
  API.fetchUbicacionesOrdered = function ({ queryKey }) {
    const [, { params }] = queryKey;
    const qp = API.makeQueryString(params);
    return fetch(
      `/api/core/unidades/maestros/ubicaciongeografica/ubicacion-order?${qp}`,
      {
        method: 'GET',
        headers: API.getAuthHeaders(),
      }
    ).then(API.handleResponse);
  };

  API.fetchProductivaMasters = function ({ queryKey }) {
    const [, { masterType, id = '', params }] = queryKey;
    const qp = API.makeQueryString(params);
    const route = MASTERS.isFromUnits(masterType)
      ? `/api/core/unidades/maestros/${masterType}/${id}?${qp}`
      : `/api/core/eventos/maestros/${masterType}/${id}?${qp}`;
    return fetch(route, {
      method: 'GET',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  API.addProductivaMaster = function ({ masterType, body = {} }) {
    const route = MASTERS.isFromUnits(masterType)
      ? `/api/core/unidades/maestros/${masterType}/`
      : `/api/core/eventos/maestros/${masterType}/`;
    return fetch(route, {
      method: 'POST',
      headers: API.getAuthHeaders(),
      body: JSON.stringify(body),
    }).then(API.handleResponse);
  };

  API.editProductivaMaster = function ({ id, body = {}, masterType }) {
    const route = MASTERS.isFromUnits(masterType)
      ? `/api/core/unidades/maestros/${masterType}/${id}/`
      : `/api/core/eventos/maestros/${masterType}/${id}/`;
    return fetch(route, {
      method: 'PATCH',
      headers: API.getAuthHeaders(),
      body: JSON.stringify(body),
    }).then(API.handleResponse);
  };

  API.deleteProductivaMaster = function ({ id, masterType }) {
    const route = MASTERS.isFromUnits(masterType)
      ? `/api/core/unidades/maestros/${masterType}/${id}/`
      : `/api/core/eventos/maestros/${masterType}/${id}/`;
    return fetch(route, {
      method: 'DELETE',
      headers: API.getAuthHeaders(),
    }).then(API.handleResponse);
  };

  return API;
};

export default MastersAPI;
