import React from 'react';
import { useQuery } from 'react-query';
import _ from 'lodash';
import { Card, DashboardList } from 'modules/core/components';

const EventsCard = ({
  title = '',
  description = '',
  titleFormatter,
  fetch,
  height = 200,
}) => {
  const { data, isLoading } = useQuery([title], fetch);

  const formatData = (dataToFormat) => {
    const events = _.map(dataToFormat.current_year.values, 'titulo');
    return events.map((e, i) => {
      const current = _.find(dataToFormat.current_year.values, { titulo: e });
      const last = _.find(dataToFormat.last_year.values, { titulo: e });
      return {
        id: i,
        title: titleFormatter[e] || '-',
        quantity: current.cantidad,
        percentage: current.porcentaje,
        extra: last.cantidad,
      };
    });
  };

  return (
    <Card
      title={title}
      description={description}
      style={{ height }}
      type="secondary"
    >
      <DashboardList
        loading={isLoading}
        dataSource={data && formatData(data)}
        headerValues={[data?.current_year?.year, data?.last_year?.year]}
      />
    </Card>
  );
};

export default EventsCard;
