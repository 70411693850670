import { GENERAL, SANITARIA } from 'modules/core/constants';

const defaultColumns = [];

const actionsColumn = (renderActions) => [
  {
    title: 'Acciones',
    align: 'right ',
    render: (_, record) => renderActions?.(record),
  },
];

const COLUMNS = {};

COLUMNS[SANITARIA.VACUNA] = (actions) =>
  defaultColumns
    .concat([
      {
        title: 'Fecha',
        dataIndex: 'fecha',
        sorter: true,
        render: (d) => GENERAL.dateToFront(d),
      },
      {
        title: 'Cantidad',
        dataIndex: 'cantidad',
        sorter: true,
      },
      {
        title: 'Nombre',
        dataIndex: ['vacuna', 'nombre'],
        key: 'vacuna__nombre',
        sorter: true,
      },
    ])
    .concat(actionsColumn(actions));

COLUMNS[SANITARIA.PRODUCTO] = (actions) =>
  defaultColumns
    .concat([
      {
        title: 'Fecha',
        dataIndex: 'fecha',
        sorter: true,
        render: (d) => GENERAL.dateToFront(d),
      },
      {
        title: 'Cantidad',
        dataIndex: 'cantidad',
        sorter: true,
      },
      {
        title: 'Nombre',
        dataIndex: ['producto', 'nombre'],
        key: 'producto__nombre',
        sorter: true,
      },
      {
        title: 'Marca',
        dataIndex: ['producto', 'marca'],
        key: 'producto__marca',
        sorter: true,
      },
    ])
    .concat(actionsColumn(actions));

export { COLUMNS };
