import React from 'react';
import { useQuery } from 'react-query';
import { withAPI } from 'modules/api';
import { Col, Row } from 'antd';
import { usePaginationWithFilters } from 'modules/core/hooks';
import { GENERAL, PRODUCTIVA } from 'modules/core/constants';
import { Card, Charts } from 'modules/core/components';
import FilterSider, {
  UnitFilter,
  getRecordFilters,
} from 'modules/core/components/Filter';

const HEIGHT_ROW_1 = 250;
const HEIGHT_ROW_2 = 500;

const DesteteAnalytics = ({ api, isActive }) => {
  const { filters } = usePaginationWithFilters();

  const { data: destetePesoPromedio, isLoading: isLoadingDestetePesoPromedio } =
    useQuery(
      ['Analytics::fetchDestetePesoPromedio', { params: filters.get() }],
      api.fetchDestetePesoPromedio,
      { enabled: isActive }
    );
  const {
    data: desteteGananciaDiaria,
    isLoading: isLoadingDesteteGananciaDiaria,
  } = useQuery(
    ['Analytics::fetchDesteteGananciaDiaria', { params: filters.get() }],
    api.fetchDesteteGananciaDiaria,
    { enabled: isActive }
  );
  const { data: desteteByServicios, isLoading: isLoadingDesteteByServicios } =
    useQuery(
      ['Analytics::fetchDesteteByServicios', { params: filters.get() }],
      api.fetchDesteteByServicios,
      { enabled: isActive }
    );
  const { data: desteteByPreneces, isLoading: isLoadingDesteteByPreneces } =
    useQuery(
      ['Analytics::fetchDesteteByPreneces', { params: filters.get() }],
      api.fetchDesteteByPreneces,
      { enabled: isActive }
    );
  const { data: desteteByPartos, isLoading: isLoadingDesteteByPartos } =
    useQuery(
      ['Analytics::fetchDesteteByPartos', { params: filters.get() }],
      api.fetchDesteteByPartos,
      { enabled: isActive }
    );
  const { data: desteteEvolucion, isLoading: isLoadingDesteteEvolucion } =
    useQuery(
      ['Analytics::fetchDesteteEvolucion', { params: filters.get() }],
      api.fetchDesteteEvolucion,
      { enabled: isActive }
    );
  const { data: desteteByPeso, isLoading: isLoadingesteteByPeso } = useQuery(
    ['Analytics::fetchDesteteByPeso', { params: filters.get() }],
    api.fetchDesteteByPeso,
    { enabled: isActive }
  );

  return (
    <>
      <Row gutter={[24, 32]} className="data-row" justify="center">
        <Col span={24}>
          <FilterSider
            showDrawer
            showCurrentYear
            showGlobalSearch={false}
            filters={[UnitFilter, ...getRecordFilters(PRODUCTIVA.DESTETE)]}
            onChange={filters.set}
          />
        </Col>
        <Col span={4}>
          <Charts.KPI
            height={HEIGHT_ROW_1}
            loading={isLoadingDestetePesoPromedio}
            title={destetePesoPromedio?.titulo}
            value={destetePesoPromedio?.data}
            tooltip={destetePesoPromedio?.calculo}
            stylesKPI={{ value: { fontSize: 60 } }}
          />
        </Col>
        <Col span={4}>
          <Charts.KPI
            height={HEIGHT_ROW_1}
            loading={isLoadingDesteteGananciaDiaria}
            title={desteteGananciaDiaria?.titulo}
            value={desteteGananciaDiaria?.data}
            tooltip={desteteGananciaDiaria?.calculo}
            stylesKPI={{ value: { fontSize: 60 } }}
          />
        </Col>
        <Col span={4}>
          <Charts.KPI
            height={HEIGHT_ROW_1}
            loading={isLoadingDesteteByServicios}
            title={desteteByServicios?.titulo}
            value={GENERAL.roundPercentage(desteteByServicios?.data)}
            tooltip={desteteByServicios?.calculo}
            stylesKPI={{ value: { fontSize: 60 } }}
          />
        </Col>
        <Col span={4}>
          <Charts.KPI
            height={HEIGHT_ROW_1}
            loading={isLoadingDesteteByPreneces}
            title={desteteByPreneces?.titulo}
            value={GENERAL.roundPercentage(desteteByPreneces?.data)}
            tooltip={desteteByPreneces?.calculo}
            stylesKPI={{ value: { fontSize: 60 } }}
          />
        </Col>
        <Col span={4}>
          <Charts.KPI
            height={HEIGHT_ROW_1}
            loading={isLoadingDesteteByPartos}
            title={desteteByPartos?.titulo}
            value={GENERAL.roundPercentage(desteteByPartos?.data)}
            tooltip={desteteByPartos?.calculo}
            stylesKPI={{ value: { fontSize: 60 } }}
          />
        </Col>
      </Row>
      <Row gutter={[24, 32]}>
        <Col span={12}>
          <Card
            type="secondary"
            loading={isLoadingDesteteEvolucion}
            title={desteteEvolucion?.titulo}
            tooltip={desteteEvolucion?.calculo}
            style={{ height: HEIGHT_ROW_2 }}
          >
            <Charts.Bar
              data={desteteEvolucion?.data}
              indexBy="label"
              keys={['value']}
              enableLabel={false}
              legends={[]}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card
            type="secondary"
            loading={isLoadingesteteByPeso}
            title={desteteByPeso?.titulo}
            tooltip={desteteByPeso?.calculo}
            style={{ height: HEIGHT_ROW_2 }}
          >
            <Charts.Bar
              data={desteteByPeso?.data}
              indexBy="label"
              keys={['value']}
              enableLabel={false}
              valueFormat=">-.2f"
              legends={[]}
              axisLeft={{ legend: 'Peso promiedo' }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default withAPI(DesteteAnalytics);
