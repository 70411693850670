import _ from 'lodash';
const NAME_SPACE = 'maestros';

// PRODUCTIVA
const RAZA = 'raza';
const ORIGEN = 'origen';
const CRIADOR = 'criador';
const UBICACION = 'ubicaciongeografica';
const REGISTRO = 'registro';
const COLOR = 'color';
const RODEO = 'rodeo';
const MOTIVO = 'motivobaja';
const CLASIFICACION_DESTETE = 'objetivodestete';
const PARTO = 'tipoparto';
const DEPS = 'dep';
const DATOS_ADICIONALES = 'datoadicional';
const CUERNO = 'cuerno';
const DENTICION = 'denticion';
const DOCIBILIDAD = 'docibilidad';
const CODIGO_ADN = 'codigo_adn';
const TECNICA_OBTENCION = 'tecnica_obtencion';
const TIPO_EMBRION = 'tipo_embrion';
const RESPONSABLE = 'responsable';
const GESTIONES = 'gestiones';
const EVENTOS = 'eventos';
const CONCEPTOS = 'conceptos';
const CATEGORIA = 'categoria';
const CATEGORIA_MOTIVO_BAJA = 'categoriamotivobaja';
const OBJETIVO_UNIDAD = 'objetivo';

const PRODUCTIVA_TYPES = {
  RAZA,
  ORIGEN,
  CRIADOR,
  UBICACION,
  REGISTRO,
  COLOR,
  RODEO,
  MOTIVO,
  CLASIFICACION_DESTETE,
  PARTO,
  DEPS,
  DATOS_ADICIONALES,
  CUERNO,
  DENTICION,
  TECNICA_OBTENCION,
  TIPO_EMBRION,
  RESPONSABLE,
  GESTIONES,
  EVENTOS,
  CONCEPTOS,
  CATEGORIA,
  CATEGORIA_MOTIVO_BAJA,
  OBJETIVO_UNIDAD,
};
const PRODUCTIVA_TYPES_LIST = Object.values(PRODUCTIVA_TYPES);

// Datos Adicionales
const DOCIBILIDAD_OPTIONS = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
];

const DATOS_ADICIONALES_STATIC_OPTIONS = {
  [DOCIBILIDAD]: DOCIBILIDAD_OPTIONS,
};

const DATOS_ADICIONALES_SELECT = [CUERNO, DENTICION, DOCIBILIDAD];
const DATOS_ADICIONALES_INPUT = [CODIGO_ADN];

const DATOS_ADICIONALES_COMPONENT = {
  SELECT: DATOS_ADICIONALES_SELECT,
  INPUT: DATOS_ADICIONALES_INPUT,
};

const BOOLEAN_OPTIONS = [
  { value: true, label: 'Si' },
  { value: false, label: 'No' },
];

const BAJA_OPTIONS = [
  { value: false, label: 'Activa' },
  { value: true, label: 'Baja' },
];

const PRIORIDADES = {
  URGENTE: 'urgente',
  ALTA: 'alta',
  MEDIA: 'media',
  BAJA: 'baja',
};

const PRIORIDADES_LIST = Object.values(PRIORIDADES);

const PRIORIDADES_OPTIONS = [
  { value: PRIORIDADES.URGENTE, label: 'Urgente' },
  { value: PRIORIDADES.ALTA, label: 'Alta' },
  { value: PRIORIDADES.MEDIA, label: 'Media' },
  { value: PRIORIDADES.BAJA, label: 'Baja' },
];

const PRIORIDADES_BADGES = {
  [PRIORIDADES.URGENTE]: 'red',
  [PRIORIDADES.ALTA]: 'volcano',
  [PRIORIDADES.MEDIA]: 'gold',
  [PRIORIDADES.BAJA]: 'purple',
};

const isFromUnits = (type) =>
  [
    RAZA,
    ORIGEN,
    CRIADOR,
    UBICACION,
    REGISTRO,
    COLOR,
    RODEO,
    OBJETIVO_UNIDAD,
  ].includes(type);
const getNAValue = (options, breed) => {
  return _.get(_.find(options, { raza: breed, label: 'N/A' }), 'value');
};

// SANITARIA
const SINDROME = 'sindrome';
const FORMA_ADMINISTRACION = 'formasadministracion';
const RESULTADO_ANALISIS = 'resultadosanalisis';
const OBJETIVO_TRATAMIENTO = 'objetivotratamiento';
const ANOMALIA = 'anomalias';
const SANITARIA_TYPES = {
  SINDROME,
  FORMA_ADMINISTRACION,
  RESULTADO_ANALISIS,
  OBJETIVO_TRATAMIENTO,
  ANOMALIA,
};
const SANITARIA_TYPES_LIST = Object.values(SANITARIA_TYPES);

// NUTRICIONAL
const SUPLEMENTO = 'suplemento';
const DISPONIBILIDAD_PASTO = 'disponibilidadpasto';
const NUTRICIONAL_TYPES = {
  SUPLEMENTO,
  DISPONIBILIDAD_PASTO,
};
const NUTRICIONAL_TYPES_LIST = Object.values(NUTRICIONAL_TYPES);

// ECONOMICA
const DIVISA = 'divisas';
const TIPO_GESTION = 'tiposgestion';
const ECONOMICA_TYPES = {
  DIVISA,
  TIPO_GESTION,
};
const ECONOMICA_TYPES_LIST = Object.values(ECONOMICA_TYPES);

// UTILS
const generateOptions = (masterList) => {
  const replaceKey = { id: 'value', nombre: 'label' };
  return masterList?.map((i) => _.mapKeys(i, (_, k) => replaceKey[k] ?? k));
};

const isRodeoSinAsignar = (item, field = 'nombre') => {
  return item[field].toLowerCase() === 'sin asignar';
};

const getSinAsignarId = (rodeoList = []) => {
  return rodeoList?.find((item) => isRodeoSinAsignar(item, 'label'))?.value;
};

const MASTERS = {
  NAME_SPACE,
  PRODUCTIVA_TYPES,
  PRODUCTIVA_TYPES_LIST,
  DATOS_ADICIONALES_STATIC_OPTIONS,
  DATOS_ADICIONALES_COMPONENT,
  BOOLEAN_OPTIONS,
  BAJA_OPTIONS,
  PRIORIDADES_LIST,
  PRIORIDADES_OPTIONS,
  PRIORIDADES_BADGES,
  isFromUnits,
  getNAValue,
  SANITARIA_TYPES,
  SANITARIA_TYPES_LIST,
  NUTRICIONAL_TYPES,
  NUTRICIONAL_TYPES_LIST,
  generateOptions,
  ECONOMICA_TYPES,
  ECONOMICA_TYPES_LIST,
  isRodeoSinAsignar,
  getSinAsignarId,
};

export default MASTERS;
