import React from 'react';

import './SeeMoreDrawerTitle.scss';

const SeeMoreDrawerTitle = ({ title, extra }) => (
  <div className="see-more-drawer-container">
    <span className="see-more-drawer-title">{title}</span>
    {extra}
  </div>
);

export default SeeMoreDrawerTitle;
