import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import {
  Row,
  Col,
  Form,
  Select,
  DatePicker,
  message as antMessage,
} from 'antd';
import { withAPI } from 'modules/api';
import { usePaginationWithFilters } from 'modules/core/hooks';
import { displayInfoModal } from 'modules/core/components';
import FilterSider, { UnitFilter } from 'modules/core/components/Filter';
import {
  UnitDrawerRecordHistory,
  UnitDrawerShow,
} from 'modules/units/components';
import {
  PRODUCTIVA,
  MASTERS,
  GENERAL,
  UNITS,
  MANAGEMENT,
} from 'modules/core/constants';
import {
  EventsHeader,
  EventsTableActions,
} from 'modules/management/components';
import DesteteTable from './DesteteTable/DesteteTable';

const { Item: FormItem } = Form;
const EVENT_ACTIONS_INITIAL_VALUES = { item: null, action: null };

const Destete = ({ api }) => {
  const eventType = PRODUCTIVA.DESTETE;
  let history = useHistory();
  let location = useLocation();
  const { pagination, filters, sorter, params } = usePaginationWithFilters();
  const [actionInProgress, setActionInProgress] = useState(false);
  const [selectedItem, setSelectedItem] = useState(
    EVENT_ACTIONS_INITIAL_VALUES
  );
  const [selectedRows, setSelectedRows] = useState({ keys: [], rows: [] });
  const [form] = Form.useForm();

  const {
    data: unitsDestete,
    isLoading,
    refetch,
  } = useQuery(
    ['Destete::fetchByEvent', { eventType, params }],
    api.fetchByEvent
  );
  const { data: options } = useQuery(
    [
      'Destete::fetchOptions',
      { masterTypes: [MASTERS.PRODUCTIVA_TYPES.CLASIFICACION_DESTETE] },
    ],
    api.fetchMastersFormOptions
  );
  const { mutate: destetar, isLoading: registering } = useMutation(
    api.applyProductivaEvent
  );

  const emptySelection = _.isEmpty(selectedRows.keys);
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRows({ keys: selectedRowKeys, rows: selectedRows });
  };
  const rowSelection = {
    selectedRowKeys: selectedRows.keys,
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
  };

  const onAction = () => {
    setActionInProgress(true);
  };
  const rollbackAction = () => {
    setSelectedRows({ keys: [], rows: [] });
    form.resetFields();
    setActionInProgress(false);
  };
  const onActionApply = () => {
    form
      .validateFields()
      .then((values) => {
        const fecha = GENERAL.dateToBack(values.date);
        const unidades = _.map(values.newWeights, (weight, id) => ({
          fecha: fecha,
          objetivo: values.reason,
          unidad: id,
          peso: weight,
        }));
        const onSuccess = () =>
          displayInfoModal({
            description:
              'Las unidades fueron destetadas correctamente.\n Continuá tu gestión en Finca.',
            showCheck: true,
            onOk: () => history.push(location.pathname),
          });

        const onError = ({ message, description }) => {
          if (message === '400') antMessage.error(description, 5);
          if (message === '403') {
            return antMessage.error(
              'Ha alcanzado la cantidad máxima de Madres que permite su licencia'
            );
          }
        };
        destetar({ eventType, units: unidades }, { onSuccess, onError });
      })
      .catch(() => {});
  };

  const renderHeader = () => (
    <Col span={24}>
      <EventsHeader
        title="Destete"
        description={
          !actionInProgress
            ? 'Unidades aptas para destetar'
            : 'Para realizar el destete de las unidades, completá la fecha, el motivo y el peso del destete'
        }
        continueProps={{ onClick: onAction, disabled: emptySelection }}
        registerProps={{ onClick: onActionApply, loading: registering }}
        eventMenuProps={{
          menuType: MANAGEMENT.PRODUCTIVA,
          unitType: UNITS.UNIDAD,
          eventType: eventType,
          onUpload: refetch,
          exportParams: filters.get(),
        }}
        rollbackAction={rollbackAction}
        showRegister={actionInProgress}
      />
    </Col>
  );

  const renderTable = () => (
    <>
      <Col xs={6} xxl={4}>
        <FilterSider filters={[UnitFilter]} onChange={filters.set} />
      </Col>
      <Col xs={18} xxl={20}>
        <DesteteTable
          data={unitsDestete?.results}
          rowSelection={rowSelection}
          loading={isLoading}
          actions={(item) => (
            <EventsTableActions item={item} onAction={setSelectedItem} />
          )}
          showTotal={true}
          pagination={{ ...pagination.get(), total: unitsDestete?.count }}
          onChange={(p, f, s) => {
            pagination.set(p);
            sorter.set(s);
          }}
        />
        <UnitDrawerShow
          visible={selectedItem?.action === GENERAL.ACTIONS.VER_UNIDAD}
          unit={selectedItem?.item}
          close={() => setSelectedItem(EVENT_ACTIONS_INITIAL_VALUES)}
        />
        <UnitDrawerRecordHistory
          visible={selectedItem?.action === GENERAL.ACTIONS.VER_HISTORIAL}
          unitId={selectedItem?.item?.id}
          success={refetch}
          close={() => setSelectedItem(EVENT_ACTIONS_INITIAL_VALUES)}
        />
      </Col>
    </>
  );

  const renderForm = () => (
    <Col span={24}>
      <Form form={form} layout="vertical" requiredMark={false}>
        <Row gutter={8}>
          <Col span={4}>
            <FormItem
              label="Fecha"
              name="date"
              rules={[{ required: true, message: 'Ingrese fecha' }]}
            >
              <DatePicker
                style={{ width: '100%' }}
                format={GENERAL.DATE_FORMAT.FRONT}
              />
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem
              label="Clasificación al destete"
              name="reason"
              rules={[{ required: true, message: 'Seleccione clasificación' }]}
            >
              <Select
                placeholder="Seleccionar"
                style={{ width: '100%' }}
                options={
                  options?.[MASTERS.PRODUCTIVA_TYPES.CLASIFICACION_DESTETE]
                }
              />
            </FormItem>
          </Col>
          <Col span={24}>
            <DesteteTable
              loading={isLoading}
              data={selectedRows.rows}
              form={form}
              pagination={false}
            />
          </Col>
        </Row>
      </Form>
    </Col>
  );

  return (
    <Row gutter={[16, 16]}>
      {renderHeader()}
      {!actionInProgress && renderTable()}
      {actionInProgress && renderForm()}
    </Row>
  );
};

export default withAPI(Destete);
