import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import _ from 'lodash';
import { Row, Col, Button, message as antMessage, Spin } from 'antd';
import { UndoOutlined } from '@ant-design/icons';
import { withAPI } from 'modules/api';
import { usePaginationWithFilters } from 'modules/core/hooks';
import { ECONOMICA, GENERAL } from 'modules/core/constants';
import {
  ScreenHeader,
  DropdownMenu,
  Table,
  DeleteModal,
  AlertWithAction,
  Charts,
} from 'modules/core/components';
import FilterSider, { getRecordFilters } from 'modules/core/components/Filter';
import { EconomicalEventsMenu } from 'modules/management/components/EventsMenu';
import OtrosEconomicaDeleteMessage from './OtrosEconomicaDeleteMessage/OtrosEconomicaDeleteMessage';
import OtrosEconomicaModalAdd from './OtrosEconomicaModalAdd/OtrosEconomicaModalAdd';

const OtrosEconomica = ({ api, eventType }) => {
  const eventTitle = ECONOMICA.EVENT_FORMATTED[eventType];
  const { pagination, filters, sorter, params } = usePaginationWithFilters();
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [undoingMultipleEvents, setUndoingMultipleEvents] = useState(false);
  const [selectedRows, setSelectedRows] = useState({ keys: [] });

  const {
    data,
    isLoading,
    refetch: rEvent,
  } = useQuery(
    [
      'OtrosIngresos::fetchEconomicaEventsRecords',
      { recordType: eventType, params },
    ],
    api.fetchEconomicaEventsRecords
  );

  const {
    data: kpis,
    isLoading: isLoadingKpis,
    refetch: rKpis,
  } = useQuery(
    [
      'CompraVentaUnidad::fetchEconomicaEventsKPI',
      { recordType: eventType, params },
    ],
    api.fetchEconomicaEventsKPI
  );

  const refetch = () => {
    rEvent();
    rKpis();
  };

  const { mutate: deleteEconomicaEvent, isLoading: isDeleting } = useMutation(
    api.deleteEconomicaEvent
  );

  const {
    mutate: deleteMultipleEconomicaEvent,
    isLoading: isDeletingMultiple,
  } = useMutation(api.deleteMultipleEconomicaEvent);

  const resetState = () => {
    setAddModalVisible(false);
    setDeleteModalVisible(false);
    setSelectedItem({});
  };

  const actionsHandler = ({ item, action }) => {
    setSelectedItem(item);
    if (action === GENERAL.ACTIONS.ELIMINAR) {
      setDeleteModalVisible(true);
    }
  };

  const handleDelete = ({ onSuccess, onError }) => {
    deleteEconomicaEvent(
      { eventType, eventId: selectedItem.id },
      { onSuccess, onError }
    );
  };

  const clearSelection = () => {
    setSelectedRows({ keys: [] });
    setUndoingMultipleEvents(false);
  };

  const rowSelection = undoingMultipleEvents
    ? {
        selectedRowKeys: selectedRows.keys,
        onChange: (selectedRowKeys) =>
          setSelectedRows({ keys: selectedRowKeys }),
        preserveSelectedRowKeys: true,
      }
    : null;

  const onCofirmeMultipleDelete = () => {
    const handleOnSuccess = () => {
      refetch();
      clearSelection();
      antMessage.success('Eventos deshechos con éxito.');
    };
    const handleOnError = ({ description } = {}) => {
      antMessage.error('Algunos eventos no pudieron ser deshechos.');
    };

    deleteMultipleEconomicaEvent(
      { eventType, event_ids: selectedRows.keys },
      {
        onSuccess: handleOnSuccess,
        onError: handleOnError,
      }
    );
  };

  const columns = [
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      sorter: true,
      render: (d) => GENERAL.dateToFront(d),
    },
    {
      title: 'Monto',
      dataIndex: 'monto',
      sorter: true,
    },
    {
      title: 'Divisa',
      dataIndex: 'divisa_value',
    },
    {
      title: 'Tipo',
      dataIndex: 'is_operativo',
      key: 'is_operativo',
      sorter: true,
      render: (v) => (v ? 'Operativo' : 'No operativo'),
    },
    {
      title: 'Gestión',
      dataIndex: 'tipo_gestion_value',
      key: 'tipo_gestion',
      sorter: true,
    },
    {
      title: 'Descripción',
      dataIndex: 'descripcion',
      sorter: true,
    },
    {
      title: 'Acciones',
      align: 'right ',
      render: (_, el) => (
        <DropdownMenu
          title="Más acciones"
          menu={[
            {
              title: 'Deshacer',
              icon: <UndoOutlined />,
              onClick: () =>
                actionsHandler({ item: el, action: GENERAL.ACTIONS.ELIMINAR }),
            },
          ]}
        />
      ),
    },
  ];

  const renderHeader = () => (
    <Col span={24}>
      <ScreenHeader
        title={eventTitle}
        rightActions={
          <>
            <Button type="primary" onClick={() => setAddModalVisible(true)}>
              {`AGREGAR ${eventTitle.toUpperCase()}`}
            </Button>
            <EconomicalEventsMenu
              onUpload={refetch}
              eventType={eventType}
              exportParams={filters.get()}
              onShowUndo={() => setUndoingMultipleEvents(true)}
            />
          </>
        }
      />
    </Col>
  );

  const renderKpis = () => {
    if (isLoadingKpis) return <Spin />;

    return (
      <Col span={24}>
        <Row gutter={[16, 16]}>
          {kpis.map((k, ix) => (
            <Col span={6} key={ix}>
              <Charts.KPI
                align="start"
                description={k?.titulo}
                value={_.round(k?.cantidad, 2)}
              />
            </Col>
          ))}
        </Row>
      </Col>
    );
  };

  const renderTable = () => (
    <>
      <Col xs={6} xxl={4}>
        <FilterSider
          filters={getRecordFilters(eventType)}
          onChange={filters.set}
        />
      </Col>
      <Col xs={18} xxl={20}>
        <Row gutter={[16, 16]}>
          <Col span={24}>{renderKpis()}</Col>
          {undoingMultipleEvents && (
            <Col span={24}>
              <AlertWithAction
                message="Seleccione los eventos que desea deshacer y presione Confirmar."
                loading={isDeletingMultiple}
                onCancel={clearSelection}
                onConfirm={onCofirmeMultipleDelete}
                disabled={rowSelection?.selectedRowKeys?.length <= 0}
              />
            </Col>
          )}
          <Col span={24}>
            <Table
              rowKey="id"
              dataSource={data?.results}
              columns={columns}
              loading={isLoading}
              pagination={{ ...pagination.get(), total: data?.count }}
              rowSelection={rowSelection}
              onChange={(p, f, s) => {
                pagination.set(p);
                sorter.set(s);
              }}
            />
          </Col>
        </Row>
      </Col>
    </>
  );

  return (
    <Row gutter={[16, 16]}>
      {renderHeader()}
      {renderTable()}
      <OtrosEconomicaModalAdd
        title={eventTitle.toLowerCase()}
        visible={addModalVisible}
        eventType={eventType}
        onSuccess={() => {
          refetch();
          resetState();
        }}
        close={resetState}
      />
      <DeleteModal
        title="Eliminar movimiento"
        isDeleting={isDeleting}
        visible={deleteModalVisible}
        onCancel={resetState}
        onConfirm={handleDelete}
        onSuccess={() => {
          refetch();
          resetState();
        }}
        onSuccessInfo={{
          description: `El movimiento se eliminó con éxito.`,
          showCheck: true,
        }}
      >
        <OtrosEconomicaDeleteMessage {...selectedItem} />
      </DeleteModal>
    </Row>
  );
};

export default withAPI(OtrosEconomica);
