import React, { useState } from 'react';
import { NavLink, withRouter, useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import _ from 'lodash';
import moment from 'moment';
import { LogoutOutlined } from '@ant-design/icons';
import { Layout, Dropdown, Modal, Spin } from 'antd';
import { withAPI } from 'modules/api';
import { GENERAL, USERS } from 'modules/core/constants';
import { ModalTitle } from 'modules/core/components';
import { ReactComponent as LogoIcon } from 'assets/logo.svg';
import { ReactComponent as UserIcon } from 'assets/home/user.svg';
import menuOptions from './menuOptions';
import adminMenuOptions from './adminMenuOptions';
import NotificationButton from './NotificationButton';

import './AppLayout.scss';

const { Header, Footer, Content } = Layout;

const getNavigationOptions = (userRole) => {
  switch (userRole) {
    case USERS.ROLES.ADMIN_USER:
      return adminMenuOptions;
    case USERS.ROLES.FINCA_USER:
      return menuOptions;
    default:
      return [];
  }
};

const ProfileMenu = withAPI(({ api, logUser, showProfile }) => {
  const history = useHistory();
  const [visibleLogoutModal, setVisibleLogoutModal] = useState(false);
  const { data: profileImage, isLoading: isLoadingProfileImage } = useQuery(
    ['Profile::UserBarn'],
    api.fetchUserBarn,
    {
      select: (d) => d[0].logo_file,
    }
  );

  const items = _.compact([
    showProfile && {
      key: 'profile',
      label: 'Mi perfil',
      onClick: () => history.push(`/perfil/`),
    },
    {
      key: 'logout',
      label: 'Cerrar sesión',
      onClick: () => setVisibleLogoutModal(true),
    },
  ]);

  const renderDropdownIcon = () => {
    if (profileImage)
      return (
        <img
          className="app-header-dropdown-img"
          src={profileImage}
          alt="Logo"
        />
      );
    return <UserIcon alt="Usuario" className="app-header-icon-item" />;
  };

  if (isLoadingProfileImage) return <Spin />;

  return (
    <>
      <Dropdown
        overlayClassName="app-header-user-actions"
        trigger={['click']}
        menu={{ items }}
      >
        {renderDropdownIcon()}
      </Dropdown>
      <Modal
        title={<ModalTitle icon={<LogoutOutlined />} title="Cerrar sesión" />}
        open={visibleLogoutModal}
        cancelText="Cancelar"
        onCancel={() => setVisibleLogoutModal(false)}
        okText="Sí, cerrar sesión"
        onOk={() => {
          logUser(null);
          GENERAL.LOCAL_TOKEN.remove();
          // TODO: Si se logra mejorar el APIContext, no seria necesario realizar el push y reload para volver al theme default
          history.push('/login/');
          window.location.reload();
        }}
      >
        ¿Estás seguro de que quieres cerrar la sesión?
      </Modal>
    </>
  );
});

const AppLayout = withAPI(({ api, children, userRole }) => {
  const { data: version } = useQuery('Auth::fetchVersion', api.fetchVersion, {
    staleTime: Infinity,
  });
  const showSync = userRole !== USERS.ROLES.ADMIN_USER;
  const showNotifications = userRole !== USERS.ROLES.ADMIN_USER;
  const showProfile = userRole === USERS.ROLES.FINCA_USER;

  return (
    <Layout className="app-layout">
      <Layout className="app-content-layout">
        <Header className="app-header">
          <LogoIcon className="app-header-logo" />
          <div className="app-header-navigation">
            <ul className="navigation-list">
              {getNavigationOptions(userRole).map((item, index) => {
                const { title, url, exact } = item;
                return (
                  <li key={index} className="navigation-list-item">
                    <NavLink
                      key={index}
                      to={url}
                      exact={exact}
                      activeClassName="selected"
                    >
                      {title}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
            <NotificationButton
              showSync={showSync}
              showNotifications={showNotifications}
            />
            <ProfileMenu showProfile={showProfile} />
          </div>
        </Header>
        <Content className="app-content">{children}</Content>
        <Footer className="app-footer">
          <span className="app-footer-text">Finca {moment().year()}</span>
          <span className="app-footer-text">{version?.version ?? ''}</span>
        </Footer>
      </Layout>
    </Layout>
  );
});

export default withRouter(AppLayout);
