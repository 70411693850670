import React from 'react';
import { GENERAL, MASTERS } from 'modules/core/constants';
import { EstablishmentMasters } from '../components';
import CriadoresForm from './CriadoresForm';

const Criadores = () => {
  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      sorter: (a, b) => a.nombre.localeCompare(b.nombre),
    },
    {
      title: 'Unidades',
      dataIndex: 'cantidad_unidades',
    },
  ];

  const masterType = MASTERS.PRODUCTIVA_TYPES.CRIADOR;
  const masterName = {
    CAPITALIZE: 'Criador / Propietario',
    LOWER: 'criador / propietario',
    PLURAL: 'Criadores / Propietarios',
  };

  return (
    <EstablishmentMasters
      masterType={masterType}
      masterName={masterName}
      columns={columns}
      renderForm={(props) => <CriadoresForm {...props} />}
      hideHeaderExtraActions={true}
      allowSinAsignar={true}
      actions={[GENERAL.ACTIONS.MODIFICAR, GENERAL.ACTIONS.ELIMINAR]}
    />
  );
};

export default Criadores;
