import React, { useState, useEffect } from 'react';
import moment from 'moment';
import _ from 'lodash';
import {
  Input,
  Tag,
  Form,
  Button,
  Row,
  Col,
  Drawer,
  Space,
  Divider,
} from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import { GENERAL } from 'modules/core/constants';

import './FilterSider.scss';

const { Search } = Input;

const DEFAULT_GLOBAL_PARAM = 'search';
const DEFAULT_CURRENT_YEAR_PARAM = 'current_year';
const CURRENT_YEAR_ACTIVE = {
  active: true,
  year: moment().format('YYYY'),
  color: 'blue',
};
const CURRENT_YEAR_DISABLED = { active: false, year: null, color: 'default' };

const formatForBack = (val) => {
  if (_.isArray(val)) return val.map(formatForBack);
  if (val.label) return val.value;
  if (moment.isMoment(val)) return GENERAL.dateToBack(val);
  return val;
};

const FilterSider = ({
  onChange,
  filters,
  filtersProps,
  showGlobalSearch = true,
  showCurrentYear = false,
  showDrawer = false,
  inline = false,
}) => {
  const [form] = Form.useForm();
  const [globalParam, setGlobalParam] = useState('');
  const [params, setParams] = useState();
  const [currentYear, setCurrentYear] = useState(CURRENT_YEAR_DISABLED);
  const [disabledFilter, setDisabledFilter] = useState(true);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);

  useEffect(() => {
    let formattedParams = _.mapValues(params, formatForBack);
    if (globalParam) formattedParams[DEFAULT_GLOBAL_PARAM] = globalParam;
    if (showCurrentYear && currentYear.year)
      formattedParams[DEFAULT_CURRENT_YEAR_PARAM] = currentYear.year;
    onChange(formattedParams);
  }, [globalParam, params, currentYear, showCurrentYear, onChange]);

  const handleCurrentYear = () => {
    setCurrentYear((prevCurrentYear) =>
      prevCurrentYear.active ? CURRENT_YEAR_DISABLED : CURRENT_YEAR_ACTIVE
    );
  };

  const applyFilters = () => {
    form
      .validateFields()
      .then((values) => {
        setParams(_.pickBy(values));
        setDisabledFilter(true);
        setOpenFilterDrawer(false);
      })
      .catch(() => {});
  };

  const cleanFilters = () => {
    setParams({});
    form.resetFields();
    setDisabledFilter(true);
  };

  const renderGlobalSearch = () => {
    if (showGlobalSearch) {
      return (
        <Col span={!inline ? 24 : 12}>
          <Search
            placeholder="Buscar"
            allowClear
            enterButton
            onSearch={setGlobalParam}
          />
        </Col>
      );
    }
  };

  const renderCurrentYear = () => {
    if (showCurrentYear || showDrawer) {
      return (
        <Col span={!inline ? 24 : 6}>
          <Space size={8}>
            {showDrawer && (
              <Button
                onClick={() => setOpenFilterDrawer(true)}
                size="small"
                icon={<FilterOutlined />}
              >
                Filtrar
              </Button>
            )}
            {showCurrentYear && (
              <Tag
                color={currentYear.color}
                style={{ cursor: 'pointer' }}
                onClick={handleCurrentYear}
              >
                Año actual
              </Tag>
            )}
          </Space>
        </Col>
      );
    }
  };

  const renderFilterForm = () => {
    if (filters?.length > 0) {
      return (
        <>
          <Col span={24}>
            <Form
              className="filter-sider-form"
              form={form}
              layout="vertical"
              requiredMark={false}
              onValuesChange={() => setDisabledFilter(false)}
            >
              {filters.map(({ FormFields }, ix) => (
                <FormFields key={ix} form={form} {...filtersProps} />
              ))}
            </Form>
          </Col>

          <Col span={16}>
            <Button
              type="primary"
              onClick={applyFilters}
              disabled={disabledFilter}
              block
            >
              APLICAR
            </Button>
          </Col>
          <Col span={8}>
            <Button onClick={cleanFilters} block>
              LIMPIAR
            </Button>
          </Col>
        </>
      );
    }
  };

  const renderDrawer = () => {
    if (filters?.length > 0) {
      return (
        <Drawer
          title="Filtros"
          placement="left"
          width="30%"
          onClose={() => setOpenFilterDrawer(false)}
          open={openFilterDrawer}
        >
          <Row gutter={[8, 8]}>{renderFilterForm()}</Row>
        </Drawer>
      );
    }
  };

  const renderDivider = () => {
    if (!inline && showGlobalSearch && filters?.length > 0) {
      return (
        <Col span={24}>
          <Divider />
        </Col>
      );
    }
  };

  return (
    <Row gutter={[8, 8]} {...(inline && { align: 'middle' })}>
      {renderGlobalSearch()}
      {renderDivider()}
      {renderCurrentYear()}
      {!showDrawer && renderFilterForm()}
      {showDrawer && renderDrawer()}
    </Row>
  );
};

export default FilterSider;
