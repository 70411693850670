import React from 'react';
import { withAPI } from 'modules/api';
import { MASTERS } from 'modules/core/constants';
import { NutricionalInsumo } from '../components';
import SuplementosForm from './SuplementosForm';

const Suplementos = ({ api }) => {
  const inputType = MASTERS.NUTRICIONAL_TYPES.SUPLEMENTO;
  const masterName = {
    CAPITALIZE: 'Suplemento',
    LOWER: 'suplemento',
  };

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      sorter: true,
    },
    {
      title: 'Materia seca',
      dataIndex: 'materia_seca',
      sorter: true,
      render: (e) => (e ? `${e}%` : '-'),
    },
    {
      title: 'Stock actual',
      dataIndex: 'cantidad_actual',
      sorter: true,
    },
  ];

  return (
    <NutricionalInsumo
      inputType={inputType}
      masterName={masterName}
      columns={columns}
      renderForm={() => <SuplementosForm />}
    />
  );
};

export default withAPI(Suplementos);
