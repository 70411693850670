import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AdminHome from './AdminHome/AdminHome';
import FincaClientDetail from './FincaClientDetail/FincaClientDetail';

const Routes = () => {
  return (
    <Switch>
      <Route exact path={`/admin`} component={AdminHome} />
      <Route
        exact
        path={`/admin/establecimiento/:est`}
        component={FincaClientDetail}
      />
      <Redirect to={`/admin`} />
    </Switch>
  );
};

export default Routes;
