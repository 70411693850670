import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import _ from 'lodash';
import { Row, Col, List } from 'antd';
import { Card, RollbackButton } from 'modules/core/components';
import { ECONOMICA } from 'modules/core/constants';

const EVENT_DASHBOARD = ECONOMICA.EVENT_EGRESO_COMPRA_INSUMOS.EVENT_DASHBOARD;

const CompraInsumosDashboard = () => {
  let history = useHistory();
  const match = useRouteMatch();
  const [managementType, setManagementType] = useState(null);

  const managementOptions = _.map(EVENT_DASHBOARD, (el, ix) => ({
    key: ix,
    ...el,
  }));

  const insumoOptions = _.map(EVENT_DASHBOARD[managementType]?.children);

  const renderManagementList = () => (
    <Col span={18} offset={3}>
      <List
        dataSource={managementOptions}
        grid={{ gutter: 24, xs: 1, sm: 2, md: 2, lg: 2, xl: 3, xxl: 3 }}
        renderItem={({ title, key }) => (
          <List.Item>
            <Card
              title={title}
              showGoToIcon={true}
              onClick={() => setManagementType(key)}
            />
          </List.Item>
        )}
      />
    </Col>
  );

  const renderInsumoList = () => (
    <>
      <Col span={18} offset={3}>
        <RollbackButton onRollback={() => setManagementType(null)} />
      </Col>
      <Col span={18} offset={3}>
        <List
          dataSource={insumoOptions}
          grid={{ gutter: 24, xs: 1, sm: 2, md: 2, lg: 2, xl: 3, xxl: 3 }}
          renderItem={({ title, url }) => (
            <List.Item>
              <Card
                title={title}
                showGoToIcon={true}
                onClick={() => history.push(`${match.url}/${url}`)}
              />
            </List.Item>
          )}
        />
      </Col>
    </>
  );

  return (
    <Row gutter={[16, 16]}>
      {!managementType && renderManagementList()}
      {managementType && renderInsumoList()}
    </Row>
  );
};

export default CompraInsumosDashboard;
