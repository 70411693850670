import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from 'antd';
import { ScreenHeader } from 'modules/core/components';
import { MANAGEMENT } from 'modules/core/constants';

const InsumosHeader = ({ title = '', description = '', onClick = null }) => {
  let history = useHistory();
  let location = useLocation();

  return (
    <ScreenHeader
      title={title}
      description={description}
      rightActions={
        <>
          <Button type="primary" onClick={onClick}>
            {`AGREGAR ${title.toUpperCase()}`}
          </Button>
          <Button
            onClick={() =>
              history.push(
                `${location.pathname}/${MANAGEMENT.ADQUISICIONES_NAME_SPACE}`
              )
            }
          >
            ADQUISICIONES
          </Button>
        </>
      }
    />
  );
};

export default InsumosHeader;
